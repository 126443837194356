import { css } from 'styled-components';
import { media, theme } from './theme';

export const InputPlaceholderTop = `
  top: -10px;
  left: 10px;
  font-size: 14px;
  background-color: white;
  padding: 4px 10px;
`;
export const InputPayloadPosition = `
  position: absolute;
  right: 17px;
  top: 15px;
`;

export const FormWidth = css`
  ${media.desktop} {
    width: 100%;
  }
  ${media.tablet} {
    width: 342px;
  }
  ${media.mobile} {
    width: 100%;
  }
`;

export const InputLikeTextField = css`
  height: auto;
  border: none;
  padding: 20px 0 0 0;
  line-height: 20px;
  background: ${theme.colors.white};
  font-weight: 500;
  max-height: 100px;
  :disabled {
    color: ${theme.colors.textPrimary};
  }
  :focus {
    left: 0px;
    padding-left: 0;
  }
  :not(:focus):not([value=""]),
  :not(:focus):not([data-value=""]),
  :focus {
    ~ label {
      left: 0;
      padding-left: 0;
    }
  }
`;

export const NoSelect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

export const TooltipWidth = css`
  width: 428px;
  ${media.tablet} {
    width: 342px;
  }
  ${media.mobile} {
    width: 100%;
  }
`;

export const NoScrollThumbs = css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  scrollbar-width: none;
`;
