import {
  createSlice,
  combineReducers,
  PayloadAction,
  isAnyOf
} from '@reduxjs/toolkit';

import { logOut } from '../../actions/actionCreators/logOut';

/* верно ли введены логин и пароль (проверка после отправки на бэк, перед смс) */
const authUserDataSlice = createSlice({
  name: 'authUserData',
  initialState: false,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requestAuth: (state, action: PayloadAction<{ phone: string, password: string }>) => state,
    successAuth: () => true,
  },
  extraReducers: (builder) => { builder.addCase(logOut, () => false) }
})
export const { successAuth, requestAuth } = authUserDataSlice.actions

/* ошибки при авторизации (логин, пароль) */
const userDataErrorSlice = createSlice({
  name: 'userDataError',
  initialState: '',
  reducers: {
    error: (state, action: PayloadAction<string>) => action.payload,
  },
  extraReducers: (builder) => { builder.addCase(logOut, () => '') }
})
export const { error } = userDataErrorSlice.actions

/* проверка смс при логине */
const authSmsSlice = createSlice({
  name: 'authSms',
  initialState: false,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requestAuthSms: (state, action: PayloadAction<string>) => state,
    successAuthSms: () => true,
  },
  extraReducers: (builder) => { builder.addCase(logOut, () => false) }
})
export const { requestAuthSms, successAuthSms } = authSmsSlice.actions

/* ошибки смс(!) при логине */
const authSmsErrorSlice = createSlice({
  name: 'authSmsError',
  initialState: '',
  reducers: {
    errorAuthSms: (state, action: PayloadAction<string>) => action.payload,
  },
  extraReducers: (builder) => { builder.addCase(logOut, () => '') }
})
export const { errorAuthSms } = authSmsErrorSlice.actions
/* используется для disable кнопки  при отправке на проверку логина и пароля + смс */
const sendingDataSlice = createSlice({
  name: 'sendingData',
  initialState: false,
  reducers: {},
  extraReducers: ((builder) => {
    builder.addMatcher(isAnyOf(requestAuth, requestAuthSms), () => true)
    builder.addMatcher(isAnyOf(successAuth, error, successAuthSms), () => false)
  })
})

/* используется для статуса логина в хуке useAuth */
const isAuthSlice = createSlice({
  name: 'isAuth',
  initialState: true,
  reducers: {},
  extraReducers: ((builder) => {
    builder.addCase(logOut, () => false)
    builder.addCase(successAuthSms, () => true)
  })
})
export const authReducer = combineReducers({
  correctData: authUserDataSlice.reducer,
  error: userDataErrorSlice.reducer,
  correctCode: authSmsSlice.reducer,
  smsError: authSmsErrorSlice.reducer,
  sendingData: sendingDataSlice.reducer,
  isAuth: isAuthSlice.reducer
});
