import React from 'react';

import { IProps } from './interfaces';

import BurgerStyled from './styles';

export default ({ isActive, onClick }: IProps) => {
  return (
    <BurgerStyled
      className={isActive ? 'active' : ''}
      onClick={() => onClick()}
    >
      <span />
      <span />
      <span />
    </BurgerStyled>
  );
};
