import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

const MoneySpan = styled.span<{ changed: boolean }>`
  pointer-events: none;
  font-size: 30px;
  line-height: 37px;
  letter-spacing: 0.068px;
  position: absolute;
  color: ${({ changed }) => (changed ? theme.colors.textPrimary : theme.colors.description)};
  left: 17px;
  top: 17px;
  z-index: 1;
`;
const WrapperMoneyInput = styled.div`
  position: relative;
  input {
    color: rgba(255, 255, 255, 0);
    caret-color: ${theme.colors.textPrimary};
  }
`;

export default { MoneySpan, WrapperMoneyInput };
