import axios from 'axios';
import map from 'lodash/map';
import { IAddress, IRequisites } from 'interfaces';

// eslint-disable-next-line import/no-cycle
import {
  IDadataRequest,
  IDadataResponse,
  ISuggestionResponse
} from './interfaces';

const addressGranulars = {
  region: 'region',
  area: 'area',
  city: 'city',
  settlement: 'settlement',
  street: 'street',
  house: 'house',
  district: 'city_district'
};
const fioGranulars = {
  surname: 'SURNAME',
  name: 'NAME',
  patronymic: 'PATRONYMIC'
};

export const granulars = {
  address: addressGranulars,
  fio: fioGranulars
};

export const types = {
  fio: 'fio',
  email: 'email',
  address: 'address',
  fms_unit: 'fms_unit',
  bank: 'bank'
};

export interface ISuggestion {
  value: string;
  data: {
    [key: string]: string;
  };
  unrestricted_value?: string;
}

export interface IGetSuggestionsData {
  value: string;
  parts?: string[];
}

const API_KEY = 'f2526f753c9fc70fefe96207b9ca84bf16659f44';

const baseUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/';

const options = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${API_KEY}`
  }
};
export function dadata(
  url: string,
  data?: IDadataRequest,
  type?: string
): Promise<ISuggestionResponse[]> {
  return axios
    .post(url, data, options)
    .then((response: IDadataResponse) => response.data.suggestions)
    .then((suggestions) => map(suggestions, (suggestion: ISuggestion) => {
      return parseSuggestion(suggestion, data, type)
    }));
}

function parseSuggestion(
  suggestion: ISuggestion,
  request?: IDadataRequest,
  type?: string
): ISuggestionResponse {
  const { value, data } = suggestion;

  let result: ISuggestionResponse = {
    textSuggestion: value
  };

  if (request && request.parts && request.parts.length) {
    result.textSuggestion = data[request.parts[0].toLowerCase()];
  }
  switch (type) {
    case types.address: {
      result = {
        ...result,
        payload: getAddressFromSuggestion(suggestion)
      };
      break;
    }
    case types.bank: {
      result = {
        ...result,
        payload: getBankParams(suggestion)
      };
    }
    default:
      break;
  }

  return result;
}
export function dadataTestRequest() {
  dadata('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio');
}

export function getSuggestions(
  type: string,
  { value, parts }: IGetSuggestionsData
) {
  const url = `${baseUrl}${type}`;
  const data: IDadataRequest = {
    parts,
    query: value,
    count: 3
  };
  return dadata(url, data, type);
}

export function getGenderByName(name: string) {
  const url = `${baseUrl}${types.fio}`;
  const data = {
    query: name,
    count: 1
  };
  return axios
    .post(url, data, options)
    .then((response) => response.data.suggestions)
    .then((suggestions) => {
      const suggestion = suggestions[0];
      try {
        return suggestion.data.gender;
      } catch (e) {
        return undefined;
      }
    });
}

function getAddressFromSuggestion(
  suggestion: ISuggestion
): { addressRegistration: IAddress } {
  const {
    city = '',
    street = '',
    postal_code = '',
    flat = '',
    house = '',
    block = '',
    region = '',
    area = '',
    settlement = '',
    city_district = ''
  } = suggestion.data;
  return {
    addressRegistration: {
      street,
      region,
      city: city || settlement,
      postcode: postal_code,
      regionalDistrict: area,
      district: city_district,
      house: {
        block,
        flat,
        number: house
      }
    }
  };
}

function getBankParams(suggestion: ISuggestion): { requisites: IRequisites } {
  const requisites: IRequisites = {
    bankName: suggestion.value,
    bik: suggestion.data.bic,
    ks: suggestion.data.correspondent_account,
    rs: ''
  };
  return { requisites };
}
