import React from 'react';

import includes from 'lodash/includes';

import { Item } from 'components/widgets/header/item';
import { useStore } from 'components/hooks/useStore';
import { duProductsId } from 'constants/duProductsIdAndName';

import S from './styles';

export const OrderDetails = () => {
  const {
    purchase: {
      data: {
        order,
        order: {
          number,
          date
        } = { number: '', date: '' },
        productId
      }
    }
  } = useStore()
  const currentTitle = includes(duProductsId, productId) ? 'договора' : 'заявки'
  return (
    <>
      {order && (
      <S.OrderDetails>
        <Item title={`Дата ${currentTitle}`}>{date}</Item>
        <Item title={`Номер ${currentTitle}`}>№{number}</Item>
      </S.OrderDetails>
      )}
    </>
  );
};
