import styled from 'styled-components';

import { Common } from 'components/shared';

const { LinkText } = Common

export const DisclaimerStyle = styled.div`
  display: inline-flex;
  align-items: baseline;
  
  ${LinkText} {
    font-size: 14px;
    cursor: pointer;
  }
`;
