import React from 'react';

import { IWizardForm } from 'interfaces';
import { formFields } from 'components/shared/formFields';
import { FormField } from 'entities/formField';
import { types, granulars } from 'helpers/dadata';
import { PostCodeHint } from 'components/shared/fieldHints/postCode';

import { DetailedAddressNotification } from './notification';

const {
  birthDetails,
  passport,
  address
} = formFields;
const { house } = address;

const dayBirth = new FormField(birthDetails.dayBirth, {
  isRequired: true,
  mask: '99.99.9999'
});
const placeBirth = new FormField(birthDetails.placeBirth, {
  isRequired: true,
  withSuggestions: {
    type: types.address
  }
});

const issuesDate = new FormField(passport.issuesDate, {
  mask: '99.99.9999',
  isRequired: true
});
const issuesPlace = new FormField(passport.issuesPlace, {
  withSuggestions: {
    type: 'fms_unit'
  }
});
const unitCode = new FormField(passport.unitCode, {
  isRequired: true,
  mask: '999 – 999'
});

const addressString = new FormField(address.addressString, {
  isRequired: true,
  description: 'Например: Москва, Краснопресненская наб., д. 12',
  withSuggestions: {
    type: 'address'
  },
  /* todo типизация для нового функционала (редактирование данных) */
  onToggleFields: (changeValue: any) => {
    const { disabled } = addressString.modifiers;
    addressString.setModifier('disabled', !disabled);
    changeValue('', address.addressString.name);

    const required = addressString.modifiers.isRequired;
    addressString.setModifier('isRequired', !required);
  }
});

const country: FormField = new FormField(address.country, {
  isRequired: false,
  hidden: true,
  onToggleFields: () => {
    const { isRequired } = country.modifiers;
    country.setModifier('isRequired', !isRequired);
  }
});

const postCode: FormField = new FormField(address.postCode, {
  isRequired: false,
  mask: '999999',
  hint: <PostCodeHint />,
  hidden: true,
  onToggleFields: () => {
    const { isRequired } = postCode.modifiers;
    postCode.setModifier('isRequired', !isRequired);
  }
});

const street: FormField = new FormField(address.street, {
  isRequired: false,
  withSuggestions: {
    type: types.address,
    parts: granulars.address.street
  },
  hidden: true,
  onToggleFields: () => {
    const { isRequired } = street.modifiers;
    street.setModifier('isRequired', !isRequired);
  }
});

const city: FormField = new FormField(address.city, {
  isRequired: false,
  withSuggestions: {
    type: types.address,
    parts: granulars.address.settlement
  },
  hidden: true,
  onToggleFields: () => {
    const { isRequired } = city.modifiers;
    city.setModifier('isRequired', !isRequired);
  }
});

const region = new FormField(address.region, {
  isRequired: false,
  withSuggestions: {
    type: types.address,
    parts: granulars.address.region
  },
  hidden: true
});

const regionalDistrict = new FormField(address.regionalDistrict, {
  isRequired: false,
  withSuggestions: {
    type: types.address,
    parts: granulars.address.area
  },
  hidden: true
});

const houseModifiers = { isRequired: false, halfWidth: true, hidden: true };

const flat = new FormField(house.flat, houseModifiers);

const building = new FormField(house.building, houseModifiers);

const block = new FormField(house.block, houseModifiers);

const number: FormField = new FormField(house.number, {
  isRequired: false,
  halfWidth: true,
  hidden: true,
  onToggleFields: () => {
    const { isRequired } = number.modifiers;
    number.setModifier('isRequired', !isRequired);
  }
});
export const passportDetailsField = {
  placeBirth,
  dayBirth,
  issuesPlace,
  issuesDate,
  unitCode,
  country,
  postCode,
  region,
  regionalDistrict,
  city,
  street,
  number,
  building,
  block,
  flat,
  addressString
};
export const passportDetails: IWizardForm = {
  title: 'Паспортные данные',
  fields: [
    dayBirth,
    placeBirth,
    issuesDate,
    issuesPlace,
    unitCode,
    addressString,
    country,
    postCode,
    region,
    regionalDistrict,
    city,
    street,
    number,
    building,
    block,
    flat
  ],
  AfterHiddenFields: DetailedAddressNotification,
  url: 'registration/step-three'
};
