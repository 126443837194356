import styled, { css } from 'styled-components';

import { Common } from 'components/shared';
import StylesNumber from 'components/shared/numberField/styles';
import { media } from 'assets/styles/theme';

import StylesItem from '../header/item/styles';

const { Item } = StylesItem;
const { Panel } = Common

const Title = styled.h1`
  margin-bottom: 20px;
  text-align: center;
  
  ${media.mobile} {
    font-size: 26px;
  }
`;

interface IActionsProps {
  isDU?: boolean;
}
const Actions = styled.div<IActionsProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  order: 5;
  a {
    text-decoration: none;
    margin-left: 20px;
    &.sell {
      button {
        background-color: white;
        color: ${({ theme }) => theme.colors.textPrimary};
        font-weight: 500;
        :hover{
          background-color: #F9F8F6;;
        }

        ${media.tablet} {
          margin-bottom: 0;
        }
      }
    }
  }
  ${media.desktop} {
    width: auto;
    justify-content: flex-start;
    position: absolute;
    top: 22px;
    right: 30px;
  }
  ${media.tablet} {
    flex-direction: column;
    a {
      width: 100%;
      margin-left: 0;
      button {
        justify-content: center;
        align-items: center;
        width: ${({ isDU }) => (isDU ? '100%' :'90%;')}
        margin-bottom: 10px;
      }
    }
  }
  ${media.tabletOnly} {
    position: relative;
    ${({ isDU }) => (isDU
    ? css`
          margin-top: 20px;
        `
    : css`
          order: -1;
          display: flex;
          flex-direction: row;
          align-items: baseline;
          min-width: 300px;
        `)
}
  }
  ${media.mobile} {
    width: 100%;
    margin-top: 25px;
  }
`;

const Content = styled.div`
  width: 100%;

  ${media.tablet} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px;
    box-shadow: 0 5px 10px 0 ${({ theme }) => theme.colors.shadow};
    border-radius: 10px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
  }

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px;
    box-shadow: none;
  }
`;

const Description = styled.div`
  margin-bottom: 26px;
  font-size: 16px;

  ${media.tablet} {
    margin-bottom: 12px;
  }
  ${media.tabletOnly} {
    order: -2;
  }
  ${media.mobile} {
    text-align: center;
  }
`;

const ContractWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  
  svg {
    margin-right: 14px;
  }
  
  ${media.tablet} {
    order: 4;
    margin-bottom: 0;
    flex-wrap: wrap;
  }
  ${media.mobile} {
    text-align: center;
    flex-direction: column;
    margin-top: 10px;
    svg {
      margin-bottom: 5px;
    }
  }
`;
const Text = styled.span`
  font-size: 16px;
  margin-right: 10px;

  ${media.tablet} {
    font-size: 15px;
  }
  
  ${media.mobile} {
    font-size: 14px;
  }
`;

const ContractInfo = styled(Text)`
  font-weight: 500;
  
  ${media.mobile} {
    margin-top: 8px;
  }
`;

const Account = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const Financials = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding-top: 26px;

  ${media.desktop} {
    padding-bottom: 0;
  }

  ${media.tablet} {
    padding-top: 0;
    border-top: none;
    flex-wrap: wrap;
  }

  ${Item} {
    ${media.desktop} {
      padding-right: 72px;
      margin-bottom: 15px;
    }

    ${media.tablet} {
      width: auto;
      max-width: none;
      margin-right: 50px;

      .title {
        font-size: 11px;
        line-height: 14px;
      }
    }

    ${media.mobile} {
      width: 100%;
      margin-right: 0;

      ${StylesNumber.NumberField}, .value {
        font-size: 15px;
      }
    }
  }
`;

const ProductCard = styled(Panel)`
  padding: 30px 0 15px;
  justify-content: space-between;
  align-items: flex-start;

  ${Title}, ${Description}, ${ContractWrapper} {
    padding: 0 30px;

    ${media.tablet} {
      padding: 0;
    }
  }
  ${media.desktop}{
    position:relative;
  }
  ${media.tablet} {
    box-shadow: none;
    padding: 0;
    background-color: transparent;

    ${Title} {
      padding: 0;

      h1 {
        font-size: 18px;
        font-weight: 600;
      }
    }

    ${Description} {
      max-width: 496px;
    }
  }

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  [data-name='row'] {
    width: 100%;
  }
`;

const More = styled.button`
  display: none;
  color: ${({ theme }) => theme.colors.link};
  border-bottom: 1px solid ${({ theme }) => theme.colors.link};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 26px;
  margin-right: 100%;

  ${media.tablet} {
    display: block;
    margin-right: 0;
  }

  ${media.mobile} {
    margin-bottom: 0;
  }
`;

export default {
  ContractInfo,
  Text,
  ProductCard,
  Title,
  Actions,
  Content,
  Account,
  Financials,
  Description,
  ContractWrapper,
  More
};
