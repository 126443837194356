import styled from 'styled-components';

const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  * {
    text-align: center;
  }
  svg {
    margin-bottom: 50px;
  }
`;
const Icon = styled.div`
  margin-bottom: 50px;
`;
const Title = styled.h1`
  margin-bottom: 34px;
  text-align: center;
`;
const Description = styled.span`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 34px;
  max-width: 1000px;
`;

const Link = styled(Description)`
  border-bottom: 1px solid rgba(0, 150, 183, 0.25);
  a {
    text-decoration: none;
  }
  * {
    color: ${({ theme }) => theme.colors.link};
  }
`;
export default {
  Error,
  Description,
  Title,
  Link,
  Icon
};
