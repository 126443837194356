import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { useDispatch } from 'react-redux';

import rootReducer from './reducers';
import { rootSaga } from './actions/sagas';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware)
  }
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
/* todo переписать все диспатчи в приложении на этот? */
export const useAppDispatch: () => AppDispatch = useDispatch
sagaMiddleware.run(rootSaga);
