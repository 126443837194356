import { createAction } from '@reduxjs/toolkit';

/* создаем экшн для запроса поверки смс при восстановлении пароля */
export const checkSmsRecoveryRequest = createAction(
  'checkSmsRecoveryRequest',
  (
    code,
    passportSeries,
    passportNumber,
    login
  ) => ({
    payload: {
      code,
      passportSeries,
      passportNumber,
      userName: login
    }
  })
)
