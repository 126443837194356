import React from 'react';

import { Icon } from 'assets/img/svg';

import { INavBarProps } from './interfaces';

import S from './styles';

export const NavBar = ({ onNextClick, onPreviousClick }: INavBarProps) => (
  <S.NavBar>
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <div
      tabIndex={0}
      role="button"
      aria-label="Previous Month"
      onClick={() => onPreviousClick()}
    >
      <Icon.CalendarArrow />
    </div>
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <div
      tabIndex={0}
      role="button"
      aria-label="Next Month"
      onClick={() => onNextClick()}
    >
      <Icon.CalendarArrow />
    </div>
  </S.NavBar>
);
