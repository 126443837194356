import React from 'react';

import { CommonTooltip, Common } from 'components/shared';
import { useLockBodyScroll } from 'components/hooks/useLockBodyScroll';

import S from './styles';

import { IProps } from './interfaces';

const { Title, ButtonsWrapper } = S
const { Button, InfoButton } = Common

export const LogOutTooltip = ({
  isShow,
  onLogOut,
  onCancel,
  isAuto
}: IProps) => {
  useLockBodyScroll(isShow)
  const title = isAuto
    ? 'Вы не были активны долгое время, и мы осуществили выход в целях безопасности'
    : 'Вы действительно хотите покинуть Личный кабинет?'
  const buttonLogoutName = isAuto ? 'Войти' : 'Да';
  return (
    <CommonTooltip
      data-test="logout-tooltip"
      isShow={isShow}
      onClose={onCancel}
    >
      <>
        <Title>{title}</Title>
        <ButtonsWrapper>
          {!isAuto &&
          <InfoButton
            onClick={() => onCancel()}
            data-test="logout-tooltip-cancel-button"
          >
            Нет
          </InfoButton>}
          <Button
            onClick={() => onLogOut()}
            data-test="logout-tooltip-logout-button"
          >
            {buttonLogoutName}
          </Button>
        </ButtonsWrapper>
      </>
    </CommonTooltip>
  );
};
