import styled from 'styled-components';

import StylesRadioButton from 'components/shared/radioButton/styles';
import StylesInput from 'components/shared/input/styles';

const DateFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${StylesRadioButton.RadioButton} {
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-right: -1px;
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-right: 0;
    }
    :hover {
      background-color: ${({ theme }) => theme.colors.white};
    }

    label {
      display: block;
      font-size: 13px;
      line-height: 16px;
      height: 35px;
      color: ${({ theme }) => theme.colors.textPrimary};
      padding: 10px 12px;
      cursor: pointer;
    }
  }

  input:checked ~ label {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

const Inputs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0 !important;

  ${StylesInput.Input} {
    width: auto;

    input {
      height: 35px;
      width: 88px;
      padding: 0 12px;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;
      border: none;

      &:not(:valid):not(:focus) {
        text-align: center;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .separator {
    display: block;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    margin: 0 7px;
  }
`;

const Button = styled.div`
  display: flex;
`;

export default {
  DateFilters,
  Inputs,
  Button
};
