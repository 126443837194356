import { apiUrl, onlineUrl } from './hosts';

export const env: string | undefined = process.env.REACT_APP_ENV;

export const host: string =
  env === 'development' ? apiUrl.dev : apiUrl.production;

export const onlineHost =
  env === 'development' ? onlineUrl.dev : onlineUrl.production;

export const Url = {
  sms: {
    sendCode: `${onlineHost}mfms-gateway/send-code`,
    checkCode: `${onlineHost}mfms-gateway/check-code`
  },
  edo: {
    check: `${host}api/api-sales/edo/check`,
    sendCode: `${host}api/api-sales/edo/send-code`,
    accept: `${host}api/api-sales/edo/accept`
  },
  clientInfo: {
    get: `${host}api/api-sales/client-info`
  },
  confirmation: {
    check: `${host}api/api-sales/confirmation/check`,
    agree: `${host}api/api-sales/confirmation/accept`
  },
  authentication: {
    sendUserData: `${host}login_dev`,
    sendSms: `${host}services/send-sms`,
    sms: `${host}services/check-sms`
  },
  passwordRecovery: {
    sendUserData: `${host}password-recovery`,
    sendNewPassword: `${host}password-recovery`,
    checkSmsCode: `${host}password-recovery`
  },
  refreshToken: `${host}token/refresh`,
  recommendedProducts: {
    get: `${host}product/get-product-list/lk`
  },
  reports: {
    get: `${host}api/api-sales/report`
  },
  portfolio: {
    get: `${host}api/portfolio/get-data`,
    securities: `${host}api/product/get-securities`
  },
  chart: {
    get: `${host}api/product/chart/get-personal-data`
  },
  purchase: {
    submit: `${onlineHost}purchase/from-uk`,
    /* todo удалить потом отдельный url для покупки */
    sms: {
      sendCode: `${onlineHost}mfms-gateway/send-code`,
      checkCode: `${onlineHost}mfms-gateway/check-code`
    },
    files: 'purchase/test-upload-file',
    passportSendData: 'trust-gate/send-data',
    passportCheckStatus: 'trust-gate/check-result',
    price: `${onlineHost}price`,
    requisites: `${onlineHost}api-sales/get-requisites`,
    frame: `${onlineHost}api-acquire/get-url-form`
  },
  productCharts: {
    get: `${host}api/product/chart/get-fond-data`
  },
  checkSms: {
    redemption: `${onlineHost}mfms-gateway/check-code/redemption`
  },
  rebuy: {
    getInfo: `${host}api/api-sales/re-buy-info`,
    submit: `${onlineHost}rebuy`
  },
  userDocs: { get: `${onlineHost}api-sales/templates/registration` },
  redemption: {
    get: `${onlineHost}api-sales/templates/redemption`,
    submit: `${onlineHost}api-sales/redemption-submit`
  },
  kval: {
    get: `${host}api/api-sales/kval`,
    files: `${host}api/api-sales/upload-file`
  },
  opifYield: {
    get: `${onlineHost}yield`
  },
  recognition: {
    submit: `${onlineHost}api-sales/recognition-submit`,
    files: `${host}api/api-sales/upload-file`
  },
  qualified: {
    check: `${host}api/api-sales/qualified-investor-check`
  },
  /* сообщения для клиента в т.ч. подтверждение текущих данных */
  client_message: {
    get: `${host}api-messages/get-messages`,
    submit: `${onlineHost}api-sales/client-data-submit`,
    check: `${host}api-messages/client-data-check`,
    mark_all: `${host}api-messages/set-read-messages`,
    mark_one: `${host}api-messages/set-read-message`
  }
};
