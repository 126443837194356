import { SchemaOf } from 'yup';

import { IFormFields, IPurchaseData } from 'interfaces';
import { getInitialValues, getValidationSchema } from 'helpers/form';
import { FormField } from 'entities/formField';

export interface IFormState {
  showBeforeNext: boolean;
  isDisableSend: boolean;
  isFormSend: boolean;
  initialValues: IFormFields;
  validationSchema: SchemaOf<any> | null;
}

interface IUpdateInitialValuesPayload {
  fields: FormField[];
  stateData: IPurchaseData;
}

type FormActionPayload = IUpdateInitialValuesPayload | boolean;

export interface IFormAction {
  type: string;
  payload: FormActionPayload;
}

export const formActions = {
  updateInitialValues: 'updateInitialValues',
  updateValidationSchema: 'updateValidationSchema',
  updateBoth: 'updateBoth',
  setDisableSend: 'setDisableSend',
  setFormSend: 'setFormSend',
  setShowBeforeNext: 'setShowBeforeNext'
};

export const initialFormState: IFormState = {
  showBeforeNext: false,
  isDisableSend: false,
  isFormSend: false,
  initialValues: {},
  validationSchema: null
};

export function formReducer(
  state: IFormState = initialFormState,
  { type, payload }: IFormAction
): IFormState {
  switch (type) {
    case formActions.updateInitialValues: {
      return {
        ...state,
        initialValues: updateInitialValues(payload)
      };
    }
    case formActions.updateValidationSchema: {
      return {
        ...state,
        validationSchema: updateValidationSchema(payload)
      };
    }
    case formActions.updateBoth: {
      const initialValues = updateInitialValues(payload);
      const validationSchema = updateValidationSchema(payload);
      return {
        ...state,
        initialValues,
        validationSchema
      };
    }
    case formActions.setDisableSend: {
      return {
        ...state,
        isDisableSend: payload as boolean
      };
    }
    case formActions.setFormSend: {
      return {
        ...state,
        isFormSend: payload as boolean
      };
    }
    case formActions.setShowBeforeNext: {
      return {
        ...state,
        showBeforeNext: payload as boolean
      };
    }
    default:
      return state;
  }
}

function updateInitialValues(payload: FormActionPayload): IFormFields {
  const { fields, stateData } = payload as IUpdateInitialValuesPayload;
  return getInitialValues(fields, stateData);
}

function updateValidationSchema(
  payload: FormActionPayload
): SchemaOf<any> | null {
  const { fields, stateData } = payload as IUpdateInitialValuesPayload;
  return getValidationSchema(fields, stateData) || null;
}
