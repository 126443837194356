import { IWizardForm } from 'interfaces';

import { passportDetailsField } from '../passportDetails';
import { innAndPassportNumberFields } from '../innAndPassportNumber';
import { contactDetailsFields } from '../contactDetails';

const { inn, seriesAndNumber } = innAndPassportNumberFields;
const {
  firstName,
  lastName,
  patronymic,
  email,
  phone
} = contactDetailsFields;

const {
  dayBirth,
  placeBirth,
  country,
  postCode,
  region,
  regionalDistrict,
  city,
  street,
  number,
  building,
  block,
  addressString,
  flat
} = passportDetailsField;

export const esia: IWizardForm = {
  title: 'Заполните недостающие данные',
  fields: [
    firstName,
    lastName,
    patronymic,
    email,
    phone,
    inn,
    seriesAndNumber,
    dayBirth,
    placeBirth,
    addressString,
    country,
    postCode,
    region,
    regionalDistrict,
    city,
    street,
    number,
    building,
    block,
    flat
  ],
  url: ''
};
