import React from 'react';

import { formFields } from 'components/shared/formFields';
import { FormField } from 'entities/formField';
import { types, granulars } from 'helpers/dadata';
import { PopupHint } from 'components/shared/fieldHints/popup';

const { name, contacts } = formFields;

const firstName = new FormField(name.firstName, {
  isRequired: true,
  withSuggestions: {
    type: types.fio,
    parts: granulars.fio.name
  }
});

const lastName = new FormField(name.lastName, {
  isRequired: true,
  withSuggestions: {
    type: types.fio,
    parts: granulars.fio.surname
  }
});

const patronymic = new FormField(name.patronymic, {
  isRequired: false,
  separatorAfter: true,
  hint: (
    <PopupHint>
      <>
        Если у Вас нет Отчества, просто пропустите это поле.
        <br />Убедитесь, что информация в полях соответствует данным Вашего
        паспорта гражданина Российской Федерации, иначе договор может быть
        признан недействительным.
      </>
    </PopupHint>
  ),
  withSuggestions: {
    type: types.fio,
    parts: granulars.fio.patronymic
  }
});

const email = new FormField(contacts.email, {
  isRequired: true,
  withSuggestions: {
    type: types.email
  }
});

const phone = new FormField(contacts.phone, {
  isRequired: true,
  mask: '+7 999 999-99-99'
});

export const contactDetailsFields = {
  firstName,
  lastName,
  patronymic,
  email,
  phone
};
