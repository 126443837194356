import {
  takeLatest,
  call,
  put
} from 'redux-saga/effects';

import {
  IAction,
  IDateRange,
  ISecurities
} from 'interfaces';
import { api } from 'api';
import { Url } from 'api/url';
import { Serializer } from 'api/serializer';
import { getDateRangeParams } from 'helpers/actions/getDateRangeParams';

import {
  errorSecurities,
  requestSecurities,
  successSecurities
} from '../../reducers/securities';

interface ICommonSecurities extends IDateRange {
  id?: string;
}
function* getSecurities(action: IAction<ICommonSecurities>) {
  try {
    const { payload } = action;
    const response: ISecurities<number>[] = yield call(
      api.get,
      getDateRangeParams(payload, `${Url.portfolio.securities}/${payload?.id}`)
    );
    yield put(successSecurities(Serializer.securities(response)));
  } catch (error) {
    console.log(error)
    yield put(errorSecurities(error.message))
  }
}

export function* securities() {
  yield takeLatest(
    requestSecurities.toString(),
    getSecurities
  );
}
