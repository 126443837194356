import axios from 'axios';
import { saveAs } from 'file-saver';

import {
  getHeaders,
  getPurchaseHeaders,
  getRedemptionHeaders
} from 'api/options';
import { IUserDocs } from 'interfaces/userDocs';
import { host, onlineHost } from 'api/url';

import { translate } from './string';

export const downloadFile = (
  doc?: string,
  nameFile?: string,
  isRedemption?: boolean
) => {
  if (doc) {
    const headers = isRedemption
      ? getRedemptionHeaders()
      : getPurchaseHeaders();
    const responseType = 'blob';
    const downloadUri = `api-sales/download-template?downloadUri=${doc}`;
    axios
      .get(`${onlineHost}${downloadUri}`, {
        headers,
        responseType
      })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          { type: 'application/octet-stream' }
        );
        saveAs(blob, `${nameFile}`);
      });
  }
};

export const downloadDocs = (doc?: IUserDocs, isRedemption?: boolean) => {
  if (doc) {
    const headers = isRedemption
      ? getRedemptionHeaders()
      : getPurchaseHeaders();
    const responseType = 'blob';

    const downloadUri = `api-sales/download-template?downloadUri=${doc.downloadUri}`;
    const titleDoc = translate(doc.title).trim();

    axios
      .get(`${onlineHost}${downloadUri}`, {
        headers,
        responseType
      })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          { type: 'application/octet-stream' }
        );
        saveAs(blob, `${titleDoc}.pdf`);
      });
  }
};

export const downloadRules = async (doc: string) => {
  const headers = await getHeaders();
  const responseType = 'arraybuffer';
  const downloadUri = `api/api-sales/${doc}`;
  axios
    .get(`${host}${downloadUri}`, {
      // @ts-expect-error
      headers,
      responseType
    })
    .then((response) => {
      const blob = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );
      const rulesUrl = URL.createObjectURL(blob);
      window.open(rulesUrl);
    });
};
