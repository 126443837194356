import React from 'react';

import { CommonTooltip, Common } from 'components/shared';
import { getAppeal } from 'helpers/form/getAppeal';
import { PHONE_LINK } from 'constants/phone';
import { useLockBodyScroll } from 'components/hooks/useLockBodyScroll';

import { IProps } from './intefaces';

import { Text, Title } from './styles';

const { LinkStyled } = Common

export const ServiceErrorAdditional = ({
  userName,
  gender,
  isShow = false
}: IProps) => {
  const appeal = getAppeal(gender, userName);
  useLockBodyScroll(isShow)

  return (
    <CommonTooltip isShow onClose={() => undefined}>
      <Title>{appeal}</Title>
      <Text>
        К сожалению, мы не нашли ни одной заявки по данному продукту.<br />
        Пожалуйста,  обратитесь в офис &quot; УК ВЕЛЕС Менеджмент &quot; по телефону:{PHONE_LINK}
      </Text>
      <LinkStyled to="/portfolio">
        Вернуться назад
      </LinkStyled>
    </CommonTooltip>
  );
};
