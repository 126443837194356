import { combineReducers } from '@reduxjs/toolkit'

import { purseSlice } from './purse';
import { recommendedProductsSlice } from './recommendedProducts';
import { reportsSlice } from './reports';
import { chartsDateRangeSlice, headerDateRangeSlice } from './dateRangeReducers';
import { authReducer } from './auth';
import { recoveryReducer } from './passwordRecovery';
import { currencySlice } from './switchCurrency';
import { chartReducer } from './chart';
import { accountReducer } from './account';
import { currencyCoursesSlice } from './currencyCourses';
import { purchaseReducer } from './purchase';
import { viewReducer } from './view';
import { securitiesSlice } from './securities';
import { confirmationSlice } from './confirmation';
import { qualifiedStatusSlice } from './qualifiedStatusData';
import { opifYieldSlice } from './opifYield';
import { Money } from '../../entities/money';
import { Percents } from '../../entities/percents';
import { checkAndApproveClientDataSlice } from './checkAndApproveClientData';
import { edoStatusSlice, sms } from './edoExistStatusReducer';
import { userMessagesSlice } from './userMessages';
import { userProductsSlice } from './products';
import { ALL_PRODUCTS } from '../../constants/products';
import { logOut } from '../actions/actionCreators/logOut';

const rootReducer = combineReducers({
  userMessagesData: userMessagesSlice.reducer,
  checkOfClientData: checkAndApproveClientDataSlice.reducer,
  authentication: authReducer,
  edoExistStatus: edoStatusSlice.reducer,
  confirmationExistStatus: confirmationSlice.reducer,
  passwordRecovery: recoveryReducer,
  userProducts: userProductsSlice.reducer,
  securities: securitiesSlice.reducer,
  chart: chartReducer,
  purse: purseSlice.reducer,
  recommendedProducts: recommendedProductsSlice.reducer,
  reports: reportsSlice.reducer,
  headerDateRange: headerDateRangeSlice.reducer,
  chartsDateRange: chartsDateRangeSlice.reducer,
  currency: currencySlice.reducer,
  account: accountReducer,
  currencyCourses: currencyCoursesSlice.reducer,
  purchase: purchaseReducer,
  view: viewReducer,
  qualifiedStatusData: qualifiedStatusSlice.reducer,
  opifYield: opifYieldSlice.reducer
});

const initialState = {
  edoExistStatus: {
    edoStatus: false,
    loaderStatus: true,
    sms
  },
  userMessagesData: {
    userMessages: [],
    isLoading: false
  },
  checkOfClientData: {
    status: true,
    submitSuccess: false,
    isAcceptedNow: false,
    sms
  },
  authentication: {
    correctData: false,
    sendingData: false,
    correctCode: false,
    isAuth: false,
    error: '',
    smsError: ''
  },
  opifYield: {
    isLoading: true,
    yields: []
  },
  passwordRecovery: {
    correctData: false,
    sendingData: false,
    correctCode: false,
    error: '',
    smsError: ''
  },
  userProducts: {
    byId: {},
    ids: [],
    fetched: false,
    error: ''
  },
  reports: [],
  chart: {
    chartData: [],
    chartDataLoader: false,
    activeProducts: [ALL_PRODUCTS],
    shareChartData: [],
    result: {
      value: new Money(0, undefined, 1),
      percent: new Percents(0)
    },
    productListForLegend: []
  },
  purse: {},
  recommendedProducts: {
    byId: {},
    ids: []
  },
  headerDateRange: null,
  chartsDateRange: null,
  currency: '₽',
  account: {
    dateBorders: {},
    name: '',
    phone: '',
  },
  currencyCourses: {
    '€': null,
    $: null,
    '₽': 1
  },
  purchase: {
    data: {
      order: {
        date: '',
        number: ''
      },
      firstName: '',
      price: 70000,
      inn: '',
      lastName: '',
      patronymic: '',
      latinName: '',
      email: '',
      phone: '',
      dayBirth: '',
      placeBirth: '',
      passport: {
        series: '',
        number: '',
        issuesPlace: '',
        issuesDate: '',
        unitCode: ''
      },
      addressRegistration: {
        addressString: ''
      },
      requisites: {
        bankName: '',
        rs: '',
        ks: '',
        bik: ''
      }
    },
    errors: {},
    request: {
      success: false,
      fail: false,
      loading: false,
      rebuy: {
        success: false,
        fail: false,
        loading: true
      }
    },
    type: '',
    terroristStatus: null,
    notRespond: false,
    sms: {
      send: {
        success: false,
        fail: false,
        loading: false
      },
      check: {
        request: {
          success: false,
          fail: false,
          loading: false
        },
        error: {
          message: ''
        }
      }
    }
  },
  view: {
    activeSidebar: false,
    editModal: false
  },
  qualifiedStatusData: {
    qualifiedStatus: {
      status: false,
      isLoading: true
    },
    submitSuccess: false,
    isOpenWindow: false,
    error: {},
    sms
  }
};
/* todo пока не знаю как типизирвать */
export default (state: any, action: any) => {
  if (action.type === logOut.toString()) {
    return initialState;
  }
  return rootReducer(state, action);
};
