import React from 'react';

import { Common } from 'components/shared';
import { downloadFile } from 'helpers/downloadFile';
import { useStore } from 'components/hooks/useStore';

import { DisclaimerStyle } from './styles';

const { ExtraText, LinkText } = Common

export const Disclaimer = () => {
  const { purchase: { data: { order } } } = useStore()
  return (
    <DisclaimerStyle>
      <ExtraText>
        Или использовать{' '}
        <LinkText
            onClick={() => downloadFile(order && order.files[0].file, 'rekvizity.pdf')}
        >
          Реквизиты для банковского перевода
        </LinkText>{' '}
      </ExtraText>
    </DisclaimerStyle>
  );
};
