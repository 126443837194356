import React, { useRef } from 'react';

import useOnClickOutside from 'use-onclickoutside';

import { Icon } from 'assets/img/svg';

import { ITooltipProps } from './interfaces';

import S from './styles';

const {
  Background,
  Tooltip,
  CloseButton
} = S

/* универсальный tooltip для всего приложения */
export const CommonTooltip = ({
  isShow = false,
  children,
  onClose: close,
  showCloseButton,
  notBlur
}: ITooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleOutsideClick = () => isShow && close();
  useOnClickOutside(ref, () => {
    handleOutsideClick();
  });
  return (
    <Background notBlur={notBlur} isShow={isShow}>
      <Tooltip data-test="tooltip-component" ref={ref}>
        {showCloseButton && (
        <CloseButton onClick={close}>
          <Icon.X />
        </CloseButton>
        )}
        {children}
      </Tooltip>
    </Background>
  );
};
