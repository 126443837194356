import React, { useState, useEffect } from 'react';

import { getProperty } from 'dot-prop';
import map from 'lodash/map';
import forEach from 'lodash/forEach';

import { FormField } from 'entities/formField';
import { FormProps } from 'types/formFieldTypes';
import { IField } from 'interfaces';

import { Field } from '../Field';
import StylesProductButton from '../../portfolioCharts/productButton/styles';
import { IProps } from './interfaces';

import S from '../styles';

const { ProductButton } = StylesProductButton;

export const FieldsContainer = ({
  errors,
  fields,
  AfterFields,
  AfterHiddenFields,
  onChangeFieldProps,
  formProps,
}: IProps) => {
  const [showHidden, setShowHidden] = useState(false);

  const [hiddenHandlers, setHiddenHandlers] = useState<any[]>([]);

  const [toggleCheckboxHandlers, setToggleCheckboxHandlers] = useState<any[]>([]);

  const hiddenFieldsNames: string[] = [];

  useEffect(() => {
    const hasError = hiddenFieldsNames.some((name) => getProperty(errors, name));
    if (hasError) {
      handleToggleFields(true);
    }
  }, [errors]);

  useEffect(() => {
    const newHiddenHandlers: any[] = [];
    const newCheckBoxHandlers: any[] = [];

    forEach(fields, (field) => {
      const objField = field.get();
      const { onToggleFields, onCheckboxToggle } = objField;
      if (onToggleFields) {
        newHiddenHandlers.push(onToggleFields);
      }
      if (onCheckboxToggle) {
        newCheckBoxHandlers.push(onCheckboxToggle);
      }
    });
    setHiddenHandlers(newHiddenHandlers);
    setToggleCheckboxHandlers(newCheckBoxHandlers);
  }, [fields]);

  const handleToggleFields = (flag?: boolean) => {
    /* todo пока не знаю как типизировать */
    hiddenHandlers.forEach((fn: (args: any) => void) => {
      fn(changeFieldValue());
    });
    setShowHidden(flag || !showHidden);
    if (onChangeFieldProps) {
      onChangeFieldProps(fields);
    }
  };

  const handleToggleCheckbox = (field: IField) => {
    toggleCheckboxHandlers.forEach((fn) => {
      fn(field, changeFieldValue());
    });
    if (onChangeFieldProps) {
      onChangeFieldProps(fields, field);
    }
  };

  const changeFieldValue = () => (value: string, name: string) => {
    const event = {
      target: { value, name }
    };
    formProps.handleChange(event);
  };

  const getFieldComponent = (
    field: FormField,
    currentFormProps: FormProps,
    key?: string
  ) => (
    <Field
      errors={errors}
      key={key}
      field={field}
      formProps={currentFormProps}
      onChangeCallback={handleToggleCheckbox}
      openHiddenFields={() => handleToggleFields(true)}
    />
  );
  /* todo переписать на lodash + ниже тоже */
  const Fields = fields
    .filter((field) => !field.modifiers.hidden && !field.modifiers.isLastField)
    .map((field, index) => getFieldComponent(
      field,
      formProps,
      `${field.name} ${index}`
    ));

  const HiddenFields = fields
    .filter((field) => field.modifiers.hidden && !field.modifiers.isLastField)
    .map((field, index) => {
      hiddenFieldsNames.push(field.name);
      return getFieldComponent(
        field,
        formProps,
        `${field.name} ${index}`
      );
    });
  const lastField = fields
    .filter((field) => field.modifiers.isLastField)
    .map((field, index) => getFieldComponent(field, formProps, `${field.name} ${index}`))[0];
  const containHiddenFields = !!HiddenFields.length;

  return (
    <S.FieldsContainer>
      {map(Fields, (field) => field)}
      {containHiddenFields && (
        <ProductButton onClick={() => handleToggleFields()} type="button">
          {showHidden
            ? 'Вернуться к поиску адреса'
            : 'Заполнить адрес вручную'}
        </ProductButton>
      )}
      <S.HiddenFields isShow={showHidden}>
        {map(HiddenFields, (hiddenField) => hiddenField)}
        {AfterHiddenFields && <AfterHiddenFields />}
      </S.HiddenFields>
      {lastField && lastField}
      {AfterFields && <AfterFields />}
    </S.FieldsContainer>
  );
};
