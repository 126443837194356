import React, { useState, useEffect } from 'react';

import map from 'lodash/map';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line import/no-cycle
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/reducers/chart';

// eslint-disable-next-line import/no-cycle
import { RadioButton } from '..';
import { IRadioButtonContainerProps } from './interfaces';

import S from './styles';

export const RadioButtonContainer = ({
  options,
  selectedOption,
  onChange,
  isCurrencySwitch,
  isChartWidget
}: IRadioButtonContainerProps) => {
  const [activeOption, setActiveOption] = useState(selectedOption || '');
  const dispatch = useDispatch()
  /* isCurrencySwitch - если есть этот параметр, то НЕ меняем статус лоадера в chart reducer,
  *   isChartWidget - нужен для включения лоадера только в графиках
  *  (в header-е тоже есть выбор дат) */
  const handleChange = (newValue: string) => {
    if (!isCurrencySwitch && newValue !== 'range' && isChartWidget) dispatch(setLoader())
    setActiveOption(newValue);
    onChange(newValue);
  };
  const isChecked = (optionValue: string) => activeOption === optionValue;

  useEffect(() => {
    setActiveOption(selectedOption || '');
  }, [selectedOption]);

  return (
    <S.RadioButtonContainer isCurrencySwitch={isCurrencySwitch} data-test="radio-button-container-component">
      {map(options, ({ value, label }) => (
        <RadioButton
          key={`${value}`}
          label={label}
          value={`${dayjs(value)}`}
          onChange={() => handleChange(value)}
          checked={isChecked(`${value}`)}
          id={uuid()}
        />
      ))}
    </S.RadioButtonContainer>
  );
};
