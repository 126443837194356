import React from 'react';

import { ScrollContainer, Common } from 'components/shared';
import { IBeforeNext, IWizardForm } from 'interfaces';
import { Url } from 'api/url';
import { PurchaseTypes } from 'enums/purchaseTypes';

import { SmsBeforeNext } from '../SmsBeforeNext';
import { agreement } from './docs';

import S from './styles';

const { ExtraText, DescriptionText } = Common
const {
  DocsManagement,
  Agreement,
  WrapperContent
} = S

export const DocsManagementComponent = () => (
  <DocsManagement>
    <ExtraText style={{ fontSize: '22px', fontWeight: 'normal', marginBottom: '30px' }}>
      Соглащение об электронном документообороте
    </ExtraText>
    <ExtraText>
      Документ необходим, чтобы Вам не пришлось посещать офис продаж лично.
    </ExtraText>
    <Agreement>
      <ScrollContainer renderView={() => <WrapperContent />}>
        <ExtraText>
          <p>
            <DescriptionText>1. ПРЕДМЕТ СОГЛАШЕНИЯ</DescriptionText>
          </p>
          {agreement}
        </ExtraText>
      </ScrollContainer>
    </Agreement>
  </DocsManagement>
);

const SmsEdo = (props: IBeforeNext) => <SmsBeforeNext {...props} type={PurchaseTypes.edo} />;

export const docsManagement: IWizardForm = {
  title: '',
  BeforeFields: DocsManagementComponent,
  fields: [],
  url: Url.edo.sendCode,
  submitTitle: 'Я принимаю соглашение',
  BeforeNext: SmsEdo
};
