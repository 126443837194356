import React from 'react';

import { ProductType } from 'enums';

import { ProductAreaChart } from './area';
import S from '../../shared/commonStyles/chartsPanel/styles';

interface IProductChartCard {
  productId: string;
  // eslint-disable-next-line react/no-unused-prop-types
  productType: ProductType;
}

export const ProductChartCard = ({ productId }:IProductChartCard) => (
  <S.ChartCard>
    <ProductAreaChart productId={productId} />
  </S.ChartCard>
);
