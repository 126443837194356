import reduce from 'lodash/reduce';

import { formatDate } from 'helpers/date';
import { Currency } from 'enums';
import { Money } from 'entities/money';
import { Percents } from 'entities/percents';
import {
  IClientInfo,
  IProduct,
  IPurchaseData,
  IPurse,
  IRangePrice,
  IResponseProducts,
  IResponsePurse,
  ISecurities,
  IterableEntity,
  IUserDocs
} from 'interfaces';
import { TBuyResponse } from './interfaces';

export class Serializer {
  static userDocs(responseDocs: IUserDocs[]) {
    interface IReducedDocs {
      [key: string]: IUserDocs;
    }
    return reduce(responseDocs, (accumulator: IReducedDocs, doc) => {
      // eslint-disable-next-line default-case
      switch (doc.title) {
        case 'Единый файл': {
          accumulator.unified = doc;
          break;
        }
        case 'Заявление об открытии лицевого счета': {
          accumulator.openLc = doc;
          break;
        }
        case 'Анкета зарегистрированного лица': {
          accumulator.profileRegistred = doc;
          break;
        }
        case 'Заявка на приобретение инвестиционных паев': {
          accumulator.applicationAcquisition = doc;
          break;
        }
        case 'Cогласие на обработку персональных данных': {
          accumulator.agreementPersonal = doc;
          break;
        }
        case 'Анкета физического лица': {
          accumulator.profilePhysical = doc;
          break;
        }
        case 'Реквизиты': {
          accumulator.transit = doc;
          break;
        }
        case 'Заявка на погашение инвестиционных паев': {
          accumulator.redemption = doc;
          break;
        }
        case 'Документы по заявке': {
          accumulator.applicationDocuments = doc;
          break;
        }
        case 'Заявлении о присоединении к Договору': {
          accumulator.joiningStatement = doc;
          break;
        }
      }
      return accumulator;
    }, {});
  }

  static purse(purseResponse: IResponsePurse): IPurse {
    return {
      currentValue: new Money(purseResponse.currentValue, Currency.rouble),
      inputValue: new Money(purseResponse.inputValue, Currency.rouble),
      outputValue: new Money(purseResponse.outputValue, Currency.rouble),
      result: {
        value: new Money(purseResponse.result.value, Currency.rouble),
        percent: new Percents(purseResponse.result.percent)
      }
    };
  }

  static securities(
    securitiesResponse: ISecurities<number>[]
  ): ISecurities<Money | Percents>[] {
    return securitiesResponse.map((securitie) => {
      const {
        baseCurrency,
        totalBuy,
        totalBuyCurrency,
        evaluation,
        evaluationCurrency,
        dividends,
        dividendsCurrency,
        efficiency,
        efficiencyCurrency,
        efficiencyPercent
      } = securitie;

      return {
        ...securitie,
        evaluationCurrency: Currency[evaluationCurrency.toLocaleLowerCase()],
        baseCurrency: baseCurrency.toLocaleUpperCase(),
        totalBuy: new Money(totalBuy, Currency[totalBuyCurrency.toLowerCase()]),
        evaluation: new Money(evaluation, Currency[evaluationCurrency.toLowerCase()], 0, true),
        dividends: new Money(dividends, Currency[dividendsCurrency.toLowerCase()]),
        efficiency: new Money(efficiency, Currency[efficiencyCurrency.toLowerCase()]),
        efficiencyPercent: new Percents(efficiencyPercent)
      };
    });
  }

  static products(
    productsResponse: IResponseProducts
  ): IterableEntity<IProduct> {
    const products: IterableEntity<IProduct> = {
      byId: {},
      ids: []
    };

    Object.keys(productsResponse).forEach((id) => {
      const {
        currentValue,
        partPercent,
        name,
        type,
        result,
        bankAccounts,
        description,
        inputValue,
        outputValue,
        pieValue,
        productNumber,
        typeName,
        availableFunds,
        productStartDate,
        productEndDate
      } = productsResponse[id];
      products.ids.push(Number(id));
      const route = '';
      const product: IProduct = {
        typeName,
        productNumber,
        id,
        type,
        name,
        route,
        description,
        bankAccounts,
        availableFunds,
        productStartDate,
        productEndDate,
        shareCount: pieValue,
        input: inputValue !== undefined ? new Money(+inputValue) : undefined,
        output: outputValue !== undefined ? new Money(parseInt(outputValue, 10)) : undefined,
        part: new Percents(parseFloat(partPercent)),
        cost: new Money(currentValue),
        result: {
          value: new Money(result.value),
          percents: new Percents(result.percent)
        }
      };
      products.byId[id] = product;
    });
    return products;
  }

  static clientInfo(response: IClientInfo): IPurchaseData {
    const [lastName, firstName, patronymic] = response.fullName.split(' ');
    const {
      fullName,
      email,
      phone,
      passport,
      passport: { issuesDate },
      inn,
      addressPost,
      addressRegistration,
      placeBirth,
      dateBirth,
      id,
      accountNumber,
      pieValue,
      requisites
    } = response;

    return {
      requisites,
      accountNumber,
      pieValue,
      placeBirth,
      inn,
      firstName,
      lastName,
      patronymic,
      email,
      phone,
      fio: fullName,
      passport: {
        ...passport,
        issuesDate: formatDate(issuesDate, 'DD.MM.YYYY')
      },
      addressPost: { addressString: addressPost },
      addressRegistration: { addressString: addressRegistration },
      dayBirth: formatDate(dateBirth, 'DD.MM.YYYY'),
      applicantId: id
    };
  }

  static order(response?: TBuyResponse): TBuyResponse | null {
    return response
      ? {
        date: formatDate(response.date, 'DD.MM.YYYY'),
        number: response.number,
        files: response.files
      } as TBuyResponse
      : null;
  }

  static rangePrice(response?: IRangePrice) {
    return response;
  }
}
