import React, { useEffect } from 'react';

import { FormField } from 'entities/formField';
import { IPurchaseData } from 'interfaces';

import { IFormAction, formActions } from './formReducer';

export const useUpdateFields = (
  fields: FormField[],
  stateData: IPurchaseData,
  dispatch: React.Dispatch<IFormAction>,
) => {
  useEffect(() => {
    dispatch({
      type: formActions.updateBoth,
      payload: {
        fields,
        stateData
      }
    });
  }, [stateData]);
}

export const useUpdateValidationScheme = (
  fields: FormField[],
  stateData: IPurchaseData,
  dispatch: React.Dispatch<IFormAction>
) => {
  useEffect(() => {
    dispatch({
      type: formActions.updateValidationSchema,
      payload: {
        fields,
        stateData
      }
    });
  }, [stateData]);
}
