import React from 'react';

import includes from 'lodash/includes';

import { downloadDocs, downloadRules } from 'helpers/downloadFile';
import { doc } from 'components/pages/kval/pageIformation';
import { linkVeles } from 'constants/links';
import { useStore } from 'components/hooks/useStore';
import { duProductsName } from 'constants/duProductsIdAndName';
import {
  CustomScrollBox,
  Hint,
  Common
} from 'components/shared';

import S from './styles';

const { LinkText } = Common
const { SmsDescription } = S

/* описание в тексте смс для разных кейсов
(покупка, подтверждение квал статуса, данных пользователя т.д.)
сделать универсальную ф-ю для разных случаев */
export const DescriptionCheckData = () => {
  const {
    purchase: {
      data: {
        productName,
        productDocs,
        lastName,
        firstName,
        patronymic,
        userDocs
      }
    }
  } = useStore()

  const userName = `${lastName || ''} ${firstName || ''} ${patronymic || ''}`.trim() || 'пользователь'
  const {
    redemption = { title: '', downloadUri: '' }
  } = userDocs || {};

  return (
    <SmsDescription>
      Нажимая кнопку подписать, я, {userName}, ставлю свою подпись на&nbsp;
      <LinkText onClick={() => downloadDocs(redemption, true)}>
        Заявке на погашение инвестиционных паев ОПИФ РФИ &laquo;{productName}&raquo;
        (далее – Фонд)
      </LinkText>{' '}
      и подтверждаю свое желание продать инвестиционные паи на условиях, указанных в&nbsp;
      <LinkText href={productDocs} target="_blank" rel="nofollow noopener">
        Правилах доверительного управления Фонда
      </LinkText>
      , а также понимаю, что&nbsp;
      <LinkText onClick={() => downloadDocs(redemption, true)}>
        Заявка на погашение инвестиционных паев Фонда
      </LinkText> носит безотзывный характер.
      Я подтверждаю достоверность предоставленных мной данных в подписываемом документе,
      а также осознаю и принимаю на себя риск убытков,
      связанных с изменением расчетной стоимости инвестиционного пая.
    </SmsDescription>
  );
};
export const DescriptionRecognition = () => (
  <SmsDescription isTextCenter>
    Нажимая кнопку «Подписать», я подписываю Заявление о признании меня
    квалифицированным инвестором и подтверждаю верность предоставленных
    мной документов
  </SmsDescription>
);

export const DescriptionApproveClientData = () => (
  <SmsDescription isTextCenter>
    Нажимая кнопку «Подписать», я подтверждаю верность указанных данных
  </SmsDescription>);

export const DescriptionPurchase = () => {
  const {
    purchase: {
      data: {
        productName,
        productDocs,
        lastName,
        firstName,
        patronymic,
        userDocs,
        productId
      }
    }
  } = useStore()

  const userName = `${lastName || ''} ${firstName || ''} ${patronymic || ''}`.trim() || 'пользователь'
  const defaultDoc = { title: '', downloadUri: '' };
  const {
    applicationAcquisition = defaultDoc,
    openLc = defaultDoc,
    profileRegistred = defaultDoc,
    profilePhysical = defaultDoc,
    agreementPersonal = defaultDoc,
    connectDog = defaultDoc
  } = userDocs || {};

  if (includes(duProductsName, productName)) {
    return (
      <CustomScrollBox>
        <SmsDescription>
          Нажимая кнопку «Подписать», я, {userName}, подтверждаю, что{' '}
          <Hint
            title={<LinkText>действую исключительно к своей выгоде,</LinkText>}
            content="Я не действую к выгоде лица - выгодоприобретателя"
            wrapperClassName="hint"
          />{' '}
          не являюсь{' '}
          <Hint
            title={<LinkText>публичным должностным лицом, иностранным агентом, </LinkText>}
            content="Я не являюсь: иностранным публичным должностным лицом,
            должностным лицом публичных международных организаций, лицом,
            занимающим государственные должности Российской Федерации,
            должности членов Совета директоров Центрального банка
            Российской Федерации, должности федеральной государственной
            службы, назначение на которые и освобождение от которых
            осуществляется Президентом Российской Федерации или
            Правительства Российской Федерации, должности в Центральном
            Банке Российской Федерации. государственных корпорациях и иных
            организациях, созданных Российской Федерацией на основании
            федеральных законов, включенные в перечни должностей
            , определяемые Президентом Российской Федерации, а также
            родственником указанных лиц."
            wrapperClassName="hint"
          />{' '}
          имею единственное налоговое резидентство – Российская Федерация,{' '}
          <LinkText
            href={`${linkVeles}/uploadedfiles/Lists-of-countries-territories-and-states.pdf`}
            target="_blank"
            rel="nofollow noopener"
          >
            ознакомлен(а) с&nbsp;перечнем стран, территорий и&nbsp;государств,
            в&nbsp;отношении которых имеются международные и&nbsp;иные
            ограничения,
          </LinkText>{' '}
          указанный в заявке и документах номер мобильного телефона принадлежит мне,{' '}
          <Hint
            title={<LinkText>не имею гражданства США,</LinkText>}
            wrapperClassName="hint"
            content="Не имеет вида на жительство в США («GREEN CARD») и/или наличие
            признака налогового резидента США (т.е. пребывание на территории США
            более 183 дней за 3 предшествующих года, за исключением учителей,
            студентов и стажеров, временно присутствующих на территории США
            на основании виз типа «F», «J», «М» или «Q»)"
          />{' '}
          действую как физическое лицо, ставлю свою подпись на{' '}
          <ul>
            <li>
              <LinkText onClick={() => downloadDocs(connectDog)}>
                Заявлении о присоединении к Договору доверительного управления
                ценными бумагами (стандартные стратегии управления)
              </LinkText>,{' '}
            </li>
            <li>
              <LinkText onClick={() => downloadDocs(profilePhysical)}>
                Анкете физического лица
              </LinkText>{' '}
            </li>
            <li>
              <LinkText onClick={() => downloadDocs(agreementPersonal)}>
                Согласии на обработку персональных данных
              </LinkText>{' '}
            </li>
          </ul>
          и подтверждаю, что с условиями {' '}
          <LinkText
            target="_blank"
            href={`${linkVeles}/uploadedfiles/56
            .ВМ%20-%2027.10.2020%20-%20Договор%20ДУ%20ценными%20бумагами_CC_ИИС_03.11.2020.pdf`}
          >
            Договора доверительного управления ценными бумагами
            (стандартные стратегии управления)
          </LinkText> и

          {' '}
          <LinkText
            target="_blank"
            href={`${linkVeles}/uploadedfiles/56.
            ВМ%20-%2027.10.2020%20-%20Регламент%20ДУ_03.11.2020.pdf`}
          >
            Регламента осуществления ООО «УК ВЕЛЕС Менеджмент»
            деятельности по доверительному управлению ценными бумагами
          </LinkText>,{' '}
          размещенным на странице Компании в сети Интернет по адресу:
          <LinkText target="_blank" href={linkVeles}>
                &nbsp;www.veles-management.ru
          </LinkText>{' '},
          ознакомлен(а) и согласен/(согласна), их содержание мне полностью понятно.
          Я подтверждаю достоверность предоставленных мной данных в подписываемых документах,
          уведомлен о рисках, связанных с осуществлением операций на рынке ценных бумаг,
          согласен/(согласна) с тем, что доверительное управление будет осуществляться в
          порядке, установленном Регламентом, ознакомлен(а) с условиями
          <LinkText
            target="_blank"
            href={`${linkVeles}/uploadedfiles/56
            .ВМ%20-%2027.10.2020%20-%20Стандартные%20стратегии%20управления_03.11.2020.pdf`}
          >
                &nbsp;Стандартных стратегий управления.
          </LinkText>{' '}
        </SmsDescription>
      </CustomScrollBox>
    );
  }
  /* кейс для ПИФ-ов и Рентаведа */
  return (
    <CustomScrollBox>
      <SmsDescription>
        Нажимая кнопку подписать, я, {userName}, подтверждаю, что{' '}
        <Hint
        title={<LinkText>действую исключительно к своей выгоде,</LinkText>}
        content="Я не действую к выгоде лица - выгодоприобретателя"
        wrapperClassName="hint"
        />{' '}
        не являюсь{' '}
        <Hint
        title={<LinkText>публичным должностным лицом,</LinkText>}
        content="Я не являюсь лицом или родственником иностранных
        публичных должностных лиц, должностных лиц публичных
        международных организаций, а также лиц,
        занимающих государственные должности Российской Федерации,
        должности членов Совета директоров Центрального банка
        Российской Федерации, должности федеральной государственной
        службы, назначение на которые и освобождение от которых
        осуществляются Президентом Российской Федерации или
        Правительством Российской Федерации, должности в
        Центральном банке Российской Федерации,государственных
        корпорациях и иных организациях, созданных Российской
        Федерацией на основании федеральных законов, включенные
        в перечни должностей, определяемые Президентом Российской Федерации."
        wrapperClassName="hint"
        />{' '}
        <Hint
        title={<LinkText>иностранным агентом,</LinkText>}
        wrapperClassName="hint"
        content="Я не действую от имени (в интересах), не являюсь руководителем
        или учредителем иностранных некоммерческих неправительственных организаций
        и их отделений, представительств и филиалов, осуществляющих
        свою деятельность на территории Российской Федерации"
        />{' '}
        имею единственное налоговое резидентство – Российская Федерация,{' '}
        <LinkText
        href={`${linkVeles}/uploadedfiles/Lists-of-countries-territories-and-states.pdf`}
        target="_blank"
        rel="nofollow noopener"
        >
          ознакомлен(а) с&nbsp;перечнем стран, территорий и&nbsp;государств,
          в&nbsp;отношении которых имеются международные и&nbsp;иные
          ограничения,
        </LinkText>{' '}
        указанный номер мобильного телефона принадлежит мне,{' '}
        <Hint
        title={<LinkText>не имею гражданства США,</LinkText>}
        wrapperClassName="hint"
        content="Не имеет вида на жительство в США («GREEN CARD»)
        и/или наличие признака налогового резидента США (т.е. пребывание
        на территории США более 183 дней за 3 предшествующих года, за
        исключением учителей, студентов и стажеров, временно присутствующих
        на территории США на основании виз типа «F», «J», «М» или «Q»)"
        />{' '}
        действую как физическое лицо, ставлю свою подпись на{' '}
        <LinkText onClick={() => downloadDocs(applicationAcquisition)}>
          Заявке на приобретение инвестиционных паев{' '}
          {productId === '35' ? 'ЗПИФ комбинированный' : 'ОПИФ РФИ' }{' '}
          &laquo;{productName}&raquo;{' '}
          (далее – Фонд)
        </LinkText>,{' '}
        <LinkText onClick={() => downloadDocs(openLc)}>
          Заявлении об открытии лицевого счета
        </LinkText>,{' '}
        <LinkText onClick={() => downloadDocs(profileRegistred)}>
          Анкете зарегистрированного физического лица в реестре владельцев инвестиционных паев
        </LinkText>,{' '}
        <LinkText onClick={() => downloadDocs(profilePhysical)}>
          Анкете физического лица
        </LinkText>,{' '}
        <LinkText onClick={() => downloadDocs(agreementPersonal)}>
          Согласии на обработку персональных данных
        </LinkText>{' '}
        и подтверждаю, что ознакомлен с{' '}
        { productId === '35'
          ?
            <LinkText onClick={() => downloadRules(doc)}>
              Правилами доверительного управления Фондом
            </LinkText>
          :
            <LinkText target="_blank" href={productDocs}>
              Правилами доверительного управления Фондом
            </LinkText>}
        ,{' '} а также понимаю,
        что Заявка на приобретение инвестиционных паев Фонда
        носит безотзывный характер. Я подтверждаю достоверность
        предоставленных мной данных в
        подписываемых документах и подтверждаю свое желание приобрести
        инвестиционные паи на условиях,
        указанных в{' '}
        { productId === '35'
          ?
            <LinkText onClick={() => downloadRules(doc)}>
              Правилах доверительного управления Фондом
            </LinkText>
          :
            <LinkText target="_blank" href={productDocs}>
              Правилах доверительного управления Фондом
            </LinkText>},{' '}
        осознаю и принимаю на себя риск убытков, связанных с изменением
        расчетной стоимости инвестиционного пая.
      </SmsDescription>
    </CustomScrollBox>
  );
};
