import styled from 'styled-components';

import { Common } from 'components/shared';
import { media } from 'assets/styles/theme';

const { ExtraText, ButtonLarge } = Common

const Title = styled.h2`
  text-align: center;
  font-size: 22px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.textPrimary};

  ${media.mobile} {
    font-size: 20px;
    margin-bottom: 10px;
  }
`

const Text = styled(ExtraText)`
  text-align: center;
`;

const Button = styled(ButtonLarge)`
  margin-top: 10px;
  margin-bottom: 0;

  ${media.mobile} {
    margin-bottom: 10px;
  }
`;
export default {
  Button,
  Text,
  Title
}
