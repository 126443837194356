// навзвание столбцов
export const columnsTitle = [
  'Активы',
  'ISIN / номер гос.рег.',
  'Валюта',
  'Кол-во бумаг',
  'Сумма покупки',
  'Текущая оценка',
  'Купонные выплаты / дивиденды'
];
