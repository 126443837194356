import styled, { css } from 'styled-components';

import {
  InputPlaceholderTop,
  InputLikeTextField
} from 'assets/styles/mixins';
import { ITheme } from 'assets/styles/theme';

interface IStylesInputProps {
  theme: ITheme;
  hasError?: boolean;
  likeTextField?: boolean;
  invisible?: boolean;
}

const Hint = styled.div`
  position: absolute;
  right: 17px;
  top: 15px;
  overflow: visible;
  cursor: pointer;
  transition: opacity 0.35s;

  * {
    text-decoration: none;
  }
`;

const TextArea = styled.textarea`
  resize: none;
  height: 100px;
  width: 100%;
  font-size: 16px;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 14px 16px;
  color: ${({ theme }) => `${theme.colors.textPrimary}`};
  letter-spacing: 0.06px;
  background: ${({ theme }) => theme.colors.background};
  outline: none;
  transition-duration: 0.35s;
  transition-property: border-color;

  :focus {
    background: transparent;
  }
  :disabled {
    color: ${({ theme }) => theme.colors.description};

    ~ label {
      cursor: default;
    }
  }
  :not(:focus):not([data-value=""]),
  :focus {
    ~ label {
      ${InputPlaceholderTop}
    }
  }
  :focus :not([data-value=""]):not(:focus) {
    background-color: ${({ theme }) => theme.colors.background};
  }
  :not([data-value=""]) {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const Input = styled.div<IStylesInputProps>`
  position: relative;
  width: 100%;

  input:not([value=""]) ~ ${Hint} {
    opacity: 0;
    height: 0;
  }

  input {
    height: 50px;
    width: 100%;
    border: ${({ likeTextField }) => (likeTextField ? 'none' : '1px solid')};

    border-color: ${({ hasError, theme }) => (hasError ? theme.colors.buttonPrimary : theme.colors.background)};

    border-radius: ${({ theme }) => theme.borderRadius};

    padding: ${({ likeTextField }) => (likeTextField ? '14px 0px' : '14px 16px')};

    color: ${({ theme }) => `${theme.colors.textPrimary}`};

    background: ${({ theme, likeTextField }) => (likeTextField ? theme.colors.white : theme.colors.background)};

    letter-spacing: 0.06px;
    font-size: 16px;
    font-weight: ${({ likeTextField }) => (likeTextField ? '500' : 'normal')};

    outline: none;
    transition-duration: 0.35s;
    transition-property: border-color;

    :focus {
      background: transparent;
      border-color: ${({ hasError, theme }) => (hasError ? theme.colors.buttonPrimary : '#edece6')};
    }
    :disabled {
      color: ${({ theme, likeTextField }) => (likeTextField ? theme.colors.textPrimary : theme.colors.description)};
      &:not(:focus)[value=""] {
        background: ${({ theme }) => theme.colors.white};
        ~ label {
          color: ${({ theme }) => theme.colors.disabledText};
        }
      }
    }
    :not(:focus):not([value=""]),
    :focus {
      ~ label {
        ${InputPlaceholderTop}
        ${({ likeTextField }) => likeTextField && 'left: 0; padding-left: 0'}
      }
    }
    :focus :not([value=""]):not(:focus) {
      background-color: ${({ theme }) => theme.colors.background};
    }
    :not([value=""]) {
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ hasError, theme }) => (hasError ? theme.colors.buttonPrimary : theme.colors.border)};
    }
    &[name="investSum"] {
      height: 75px;
      font-size: 30px;
      line-height: 37px;

      & + label {
        top: 50%;
        transform: translateY(-50%);
      }

      &[value=""] + label {
        ${InputPlaceholderTop}
      }

      :focus {
        ~ label {
          ${InputPlaceholderTop}
        }
      }
    }
    &[type="password"]::-ms-reveal,
    &[type="password"]::-ms-clear {
      display: none;
    }

    ::-webkit-validation-bubble {
      display: none;
    }
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
      margin: 0;
    }
    ${({ likeTextField }) => (likeTextField ? InputLikeTextField : '')}
  }

  ${TextArea} {
    border-color: ${({ hasError, theme }) => (hasError ? theme.colors.buttonPrimary : theme.colors.background)};

    :focus {
      border-color: ${({ hasError, theme }) => (hasError ? theme.colors.buttonPrimary : '#edece6')};
    }
    :not([data-value=""]) {
      border-color: ${({ hasError, theme }) => (hasError ? theme.colors.buttonPrimary : theme.colors.border)};
    }
    ${({ likeTextField }) => (likeTextField ? InputLikeTextField : '')}
  }

  label {
    font-size: 16px;
    color: ${({ hasError, theme }) => (hasError ? theme.colors.buttonPrimary : theme.colors.textExtra)};
    position: absolute;
    top: 14px;
    left: 16px;
    display: block;
    transition-duration: 0.3s;
    transition-property: top, left, font-size, padding;
    cursor: text;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.textPrimary};

    & + label {
      ${InputPlaceholderTop}
    }
  }

  ${({ invisible }) => (invisible ? 'display:none' : '')}
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06px;
  color: ${({ theme }) => theme.colors.description};
  margin-top: 5px;
  margin-left: 16px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Error = styled(Description)<IStylesInputProps>`
  color: ${({ theme }) => theme.colors.buttonPrimary} !important;
  width: inherit;
  margin-top: 5px;
  margin-left: 16px;
  overflow: hidden;
`;

interface ITextField {
  hasPlaceholder?: boolean;
  isCheckClientData?: boolean;
}

const TextField = styled.div<ITextField>`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  ${({ hasPlaceholder, isCheckClientData }) => (hasPlaceholder || isCheckClientData
    ? ''
    : css`
        margin-top: -26px;
    `)}
  .placeholder {
    color: ${({ theme }) => theme.colors.description};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.056px;
    margin-bottom: 10px;
  }
  .value {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textPrimary};
    white-space: pre-line;
    & ~ .value {
      margin-top: 10px;
    }
  }
`;
export default {
  Input,
  Error,
  Description,
  Info,
  Hint,
  TextArea,
  TextField
};
