import React, { useRef, useState } from 'react';

import useOnClickOutside from 'use-onclickoutside';

// eslint-disable-next-line import/no-cycle
import { Popup } from './Popup2';

export interface IHintProps {
  title: string | JSX.Element;
  content: string | JSX.Element;
  wrapperClassName?: string;
}

export const Hint = ({
  title,
  wrapperClassName,
  content
}: IHintProps) => {
  const [isShow, setIsShow] = useState(false);
  const hintRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(hintRef, () => setIsShow(false));
  return (
  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      onMouseOver={() => setIsShow(true)}
      onMouseLeave={() => setIsShow(false)}
      className={wrapperClassName}
      ref={hintRef}
    >
      {title}
      {isShow && <Popup>{content}</Popup>}
    </div>
  );
};
