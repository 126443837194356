import React, {
  useRef,
  useState,
  useEffect
} from 'react';

import { getWidth } from 'helpers/refs';
import map from 'lodash/map';

import { IStepProps } from './interfaces';

import { Step, StepsWrapper } from './styles';

export const Steps = ({
  steps,
  currentStep,
  isLastForm
}: IStepProps) => {
  const [stepWidth, setStepWidth] = useState(0);
  const stepRef = useRef<HTMLDivElement>(null);

  window.addEventListener('resize', () => setStepWidth(getWidth(stepRef)));

  useEffect(() => {
    setStepWidth(getWidth(stepRef));
  }, [stepRef]);

  const last = isLastForm;
  return (
    <StepsWrapper currentStep={currentStep} stepWidth={stepWidth} last={last}>
      {map(steps, (step, index) => (
        <Step key={step} currentStep={currentStep} index={index} ref={stepRef}>
          <Step.Title success={index < currentStep || isLastForm}>Шаг {index + 1}</Step.Title>
          <Step.Description>{step}</Step.Description>
        </Step>
      ))}
      <Step.TrackLine step={currentStep} stepWidth={stepWidth} last={last} />
    </StepsWrapper>
  );
};
