import React from 'react';

// eslint-disable-next-line import/no-cycle
import EngineeringWorks, { IEngineeringWorksErrorProps } from '.';
import { ErrorUser } from '../user';

export const SuspenseWorksUser = ({ dateOfCompletion, message }: IEngineeringWorksErrorProps) => (
  <ErrorUser data-test="engineering-works-user-component">
    <EngineeringWorks dateOfCompletion={dateOfCompletion} message={message} />
  </ErrorUser>
);
