import { useEffect } from 'react';
/* хук для блокировки скролла страницы (напр, когда вызван общий tooltip) */
export const useLockBodyScroll = (open: boolean) => {
  useEffect(() => {
    // get original value of body
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    // re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    }
  }, [open]);
}
