import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import size from 'lodash/size';
import { v4 as uuid } from 'uuid';

import { requestYields } from 'store/reducers/opifYield';
import { LoaderAndCap, ScrollContainer } from 'components/shared';
import { requestProducts } from 'store/reducers/recommendedProducts';

import { ProductPanel } from '../productPanel';
import { useStore } from '../../hooks/useStore';
import { IOwnProps, IProductCard } from './interfaces';

import Style from './styles';

export const RecommendedProducts = ({ inLk }: IOwnProps) => {
  const dispatch = useDispatch()
  const {
    recommendedProducts: { ids, byId },
    opifYield,
    userProducts,
    qualifiedStatusData: {
      qualifiedStatus: {
        status: qualifiedStatus, isLoading
      }
    },
    edoExistStatus: { edoStatus }
  } = useStore()
  useEffect(() => {
    /* запрашиваем здесь продукты для отображения в рекомендуемых когда клиент не залогинен! */
    if (!size(ids)) {
      dispatch(requestProducts());
    }
    dispatch(requestYields());
  }, [ids]);
  const { yields, isLoading: isLoadingOpifYields } = opifYield;
  const yieldsArray = map(yields, ({
    productId,
    Profitability,
    ProfitabilitySixMonths,
    ProfitabilityTwelveMonths,
    ProfitabilityThreeMonths
  }) => ({
    productId,
    Profitability,
    ProfitabilityThreeMonths,
    ProfitabilitySixMonths,
    ProfitabilityTwelveMonths
  }));
  const productPanels: (IProductCard | null)[] = map(ids, (id: number) => {
    const { name, typeId } = byId[id];

    // условия для скрытия продукта из ленты рекомендуемых //
    const isNotAuth = !inLk && (typeId === 5 || typeId === 4);
    const inLkWithoutStatus = inLk && !qualifiedStatus && (typeId === 4 || typeId === 5);
    const withProduct = !isEmpty(userProducts.byId[id]) && !qualifiedStatus;
    const withProductKval = !isEmpty(userProducts.byId[id])
      && typeId !== 5
      && qualifiedStatus
      && typeId !== 4;
    const isHiddenProduct = isNotAuth || inLkWithoutStatus || withProduct || withProductKval;

    if (!isHiddenProduct) {
      return {
        id,
        inLk,
        title: name
      };
    }
    return null
  });

  if ((inLk && isLoading) || isEmpty(productPanels) || isLoadingOpifYields) {
    return <LoaderAndCap isLoader />
  }

  const resultProductPanels = () => {
    const withoutNull = filter(
      productPanels,
      (product: IProductCard | null) => !isNull(product)
    );
    // ставим рентавед на 1 место в ленте, если это необходимо //
    if (find(withoutNull, ({ id }: IProductCard) => id === 35)) {
      return [...filter(withoutNull, ({ id }: IProductCard) => id === 35),
        ...filter(withoutNull, ({ id }: IProductCard) => id !== 35)]
    }
    return withoutNull;
  };

  return (
    <div style={{ width: '100%' }}>
      <ScrollContainer renderView={() => <Style.RecommendedProducts inLk={inLk} />}>
        <Style.Wrapper>
          {map(resultProductPanels(), (product: IProductCard): JSX.Element => {
            const { id, title } = product;
            const yieldProduct = find(yieldsArray, (itemYield) => itemYield.productId === `${id}`);
            return (
              <ProductPanel
                key={uuid()}
                id={id}
                yieldProduct={yieldProduct}
                title={title}
                inLk={inLk}
                edoExistStatus={edoStatus}
              />
            );
          })}
        </Style.Wrapper>
      </ScrollContainer>
    </div>
  );
};
