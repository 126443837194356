import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import { IUserMessage } from 'interfaces/userMessage';
import { api } from 'api';
import { Url } from 'api/url';
import { getHeaders } from 'api/options';

import {
  errorMessages,
  markAllAsRead,
  markOneAsRead,
  requestMessages,
  successMessages
} from '../../reducers/userMessages';

/* общая сага для получения сообщений и изменения их статуса */
function* commonUserMessages(action: any) {
  const mark = action.type === 'userMessages/requestMessages' ? '' : action.payload.mark;

  switch (mark) {
    case 'mark_all': {
      try {
        yield call(
          api.post,
          {
            url: `${Url.client_message.mark_all}`
          },
          getHeaders()
        );
        yield put(requestMessages());
      } catch (error) {
        const { code } = error;
        console.error(error, code);
      }
      break;
    }
    case 'mark_one': {
      const params = {
        url: `${Url.client_message.mark_one}`,
        data: {
          messageId: action.payload.id
        }
      };
      try {
        yield call(
          api.post,
          params,
          getHeaders()
        );
        yield put(requestMessages());
      } catch (error) {
        const { code } = error;
        console.log(error, code);
      }
      break;
    }
    default: {
      try {
        const response: {
          confirmMessages:IUserMessage[],
          code: number
        } = yield call(
          api.get,
          {
            url: `${Url.client_message.get}`
          },
          getHeaders()
        );
        yield put(successMessages(response.confirmMessages));
      } catch (error) {
        const { code } = error;
        console.log(error, code);
        yield put(errorMessages(error.message));
      }
    }
  }
}

export function* userMessages() {
  yield takeLatest(requestMessages.toString(), commonUserMessages);
  yield takeLatest(markAllAsRead.toString(), commonUserMessages);
  yield takeLatest(markOneAsRead.toString(), commonUserMessages);
}
