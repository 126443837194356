import { FormikErrors, FormikTouched } from 'formik';
/* todo нужна ли библиотека dot-prop? тможет лодаш подойдет? */
import { getProperty } from 'dot-prop';

import { IPurchaseErrors } from 'interfaces/purchase';

export const getFieldError = (
  name: string,
  formikErrors: FormikErrors<{ [fieldName: string]: string }>,
  touched: FormikTouched<{ [fieldName: string]: string }>,
  propsErrors?: IPurchaseErrors
): string => {
  let error;
  const formikError = getProperty(formikErrors, name);
  const propsErorr = propsErrors
    ? getProperty(propsErrors, name)
    : '';
  const isTouched = getProperty(touched, name);
  if (formikError && isTouched) {
    error = formikError;
  }
  if (propsErorr) {
    error = propsErorr;
  }

  return (error as string) || '';
};
