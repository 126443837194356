import { createSlice } from '@reduxjs/toolkit';

/* список продуктов user-a */
export const userProductsSlice = createSlice({
  name: 'userProducts',
  initialState: {
    byId: {},
    ids: [],
    fetched: false,
    error: ''
  },
  reducers: {
    requestUserProducts: (state) => state,
    successUserProducts: (state, action) => {
      state.byId = action.payload.byId
      state.ids = action.payload.ids
      state.fetched = true
      return state
    },
    errorUserProducts: (state, action) => {
      state.error = action.payload.error
    }
  }
})
export const {
  requestUserProducts,
  successUserProducts,
  errorUserProducts
} = userProductsSlice.actions
