import React from 'react';

import { ILegendItemProps } from './interfaces';

import S from './styles';

export const LegendItem = ({
  title,
  value,
  color,
  isActive,
  onMouseEnter,
  onMouseLeave
}: ILegendItemProps) => (
  <S.LegendItem
    data-test="legend-item-component"
    onTouchEnd={onMouseEnter}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div>
      <S.Circle color={color} data-test="legend-item-component-circle" />
      <S.Title
        data-test="legend-item-component-title"
        className={isActive ? 'active' : ''}
      >
        {title}
      </S.Title>
    </div>
    <S.Value data-test="legend-item-component-value">{value}</S.Value>
  </S.LegendItem>
);
