import React from 'react';

import map from 'lodash/map';
import find from 'lodash/find';
import size from 'lodash/size';
import split from 'lodash/split';
import includes from 'lodash/includes';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { IUserMessage } from 'interfaces/userMessage';
import { markAllAsRead, markOneAsRead } from 'store/reducers/userMessages';
import Message from 'assets/img/svg/message.svg';

import Styles from '../purchase/styles';
import { LoaderTooltip } from '../../widgets/tooltips/loader';
import { useStore } from '../../hooks/useStore';
import { LoaderAndCap } from '../../shared';

import S from './styles';

export const Messages = () => {
  const { userMessagesData: { userMessages, isLoading } } = useStore()
  const dispatch = useDispatch()

  if (isLoading) {
    return <LoaderTooltip isShow description="Пожалуйста, подождите..." />;
  }
  if (!size(userMessages)) {
    return <LoaderAndCap text="На текущий момент писем нет" icon={Message} />
  }
  const isExistNewMessage = find(userMessages, ({ isRead }) => !isRead);
  return (
    <Styles.Purchase>
      <S.Wrapper>
        {isExistNewMessage &&
        <S.TextAccept isRead>
          <S.Text onClick={() => dispatch(markAllAsRead({ mark: 'mark_all' }))}>
            Пометить всё как прочитанное
          </S.Text>
        </S.TextAccept>}
        <SimpleBar style={{ maxHeight: 800 }}>
          {map(userMessages, ({
            id,
            message,
            created,
            isRead
          }: IUserMessage) => {
            /* ф-я для получения ссылки если она есть в тексте сообщения */
            const textWithLink = () => {
              /* выбираем ссылку c текстом и тегом <a> */
              const hrefAll = message.substring(message.indexOf('<a'), message.lastIndexOf('a> ')+2)
              /* выбираем только адрес ссылки */
              const href = hrefAll.substring(hrefAll.indexOf('"/')+1, hrefAll.lastIndexOf('">'))
              /* выбираем только текст ссылки */
              const hrefText = message.substring(message.indexOf('">') + 2, message.lastIndexOf('</'))
              /* делим на массив по ссылке */
              const array = split(message, (`${hrefAll}`))
              return {
                href,
                array,
                hrefText
              }
            }
            return (
              <S.UserMessage isRead={isRead} key={id}>
                <S.Date isRead={isRead}>Дата отправки: {dayjs(created).format('DD.MM.YYYY')}г.</S.Date>
                {!isRead &&
                <S.TextAccept onClick={() => dispatch(markOneAsRead({ mark: 'mark_one', id }))}>
                  <S.Text>
                    Пометить как прочитанное
                  </S.Text>
                </S.TextAccept>}
                {includes(message, ('</a>'))
                  ?
                    <>
                      {textWithLink().array[0]}
                      <Link to={textWithLink().href}>{textWithLink().hrefText}</Link>
                      {textWithLink().array[1]}
                    </>
                  : message}
              </S.UserMessage>
            )
          })}
        </SimpleBar>
      </S.Wrapper>
    </Styles.Purchase>
  );
}
