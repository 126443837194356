export const getProductTitle = (id: string) :string => {
  switch (id) {
    case '17':
      return 'Велес — Системный';
    case '18':
      return 'Велес — Классический';
    case '30':
      return 'Российские акции — баланс';
    case '31':
      return 'Российские акции — риск';
    case '32':
      return 'Американские акции — баланс';
    case '33':
      return 'Американские акции — риск';
    case '34':
      return 'Smart Equity';
    case '35':
      return 'Рентавед Столица';
    default:
      return '';
  }
};
