import { IField, IFieldModifiers } from 'interfaces';
import { FormFieldTypes } from 'types/formFieldTypes';
import { getFieldComponent } from 'helpers/form/getFieldComponent';

export class FormField {
  readonly name: string;

  readonly type: FormFieldTypes;

  readonly placeholder: string | JSX.Element;

  readonly initialValue: string | number | boolean;

  modifiers: IFieldModifiers;

  options: IField & IFieldModifiers;

  constructor(mainParams: IField, modifiers?: IFieldModifiers) {
    this.name = mainParams.name;
    this.type = mainParams.type;
    this.placeholder = mainParams.placeholder;
    this.initialValue = mainParams.initialValue;

    this.modifiers = { ...modifiers };
    this.updateOptions();
  }

  updateOptions() {
    this.options = {
      name: this.name,
      type: this.type,
      placeholder: this.placeholder,
      initialValue: this.initialValue,
      ...this.modifiers
    };
  }

  get(): IField & IFieldModifiers {
    return this.options;
  }

  getView = () => getFieldComponent(this.get())

  setModifier(modifiedName: string, value: any) {
    this.modifiers[modifiedName] = value;
    this.updateOptions();
  }
}
