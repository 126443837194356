import styled from 'styled-components';

import { staticContent } from 'staticContent/index';
import { media, gridTheme } from 'assets/styles/theme';
import { Common } from 'components/shared';

import { FooterStyle, DetailedInformationStyle } from '../../widgets/footer/styles';

const { H2 } = Common

const Promo = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: ${gridTheme.container.maxWidth.xl}px;
  margin-bottom: 53px;  

  ~ ${H2} {
    margin-bottom: 22px;
    ${media.tablet} {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: inline-block;
      width: 100%;
      padding-left: 30px;
      padding-top: 28px;
      margin-bottom: 14px;
    }
    ${media.mobile} {
      padding-left: 15px;
      padding-top: 0;
      margin-top: -5px;
    }
  }

  ${media.tablet} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const LoginPageWrapper = styled.main<{ sidebarActive?: boolean }>`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: ${({ theme }) => theme.colors.background};
  ${Wrapper} {
    flex: 1 0 auto;
  }
  
  ${FooterStyle} {
    max-width: ${gridTheme.container.maxWidth.xl}px;
    margin: 0 auto 0;
    flex: 0 0 auto;
    [data-name="container"] {
      max-width: none;

      ${media.desktop} {
        padding: 30px 0;
      }

      ${media.tabletOnly} {
        padding: 24px 15px 0;
      }
    }
  }

  ${DetailedInformationStyle} {
    padding: 0;

    ${media.tablet} {
      padding: 0 15px;
    }
  }
`;
interface IBackgroundProps {
  activeSidebar: boolean
}

const Background = styled.div<IBackgroundProps>`
  margin-top: ${({ activeSidebar }) => activeSidebar && '50px'};
  width: 100%;
  height: 800px;
  background-image: url(${staticContent.loginBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 80px;
  margin-bottom: -140px;
  display: flex;
  justify-content: center;

  ${media.tablet} {
    height: 500px;
    padding: 30px 0;
    margin-bottom: 0;
  }

  ${media.mobile} {
    padding-top: 0;
    background: none;
  }
`;
export default {
  Background,
  LoginPageWrapper,
  Wrapper,
  Promo
}
