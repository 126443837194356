import React, { useState } from 'react';

import Media from 'react-media';
import { useParams } from 'react-router-dom';

import { TransitionHeight } from 'components/shared';
import { device } from 'assets/styles/theme';
import { isDev } from 'helpers/isDev';
import { getProductTitle } from 'staticContent/getProductTitle';

import { CurrentStatistics } from './currentStatistics/currentStatistics';
import { ContractData } from './contractData/contractData';
import { buttonsBlock } from './buttonsBlock/buttonsBlock';
import { IProductCardProps } from './intefaces';

import S from './styles';

export const ProductCard = ({
  productNumber,
  date,
  isDU,
  id,
  description,
  financial,
  type,
}: IProductCardProps) => {
  const [isShowResults, setShowResults] = useState(false);
  const { id: productId = '' } = useParams()

  return (
    <S.ProductCard data-test="product-card-component">
      <S.Title>{getProductTitle(id)}</S.Title>
      <S.Content>
        <S.Description>{description}</S.Description>
        <S.Description>
          <Media query={device.tablet}>
            {(matches) => !(matches && !isDev) &&
              <S.More onClick={() => setShowResults(!isShowResults)}>
                Подробнее
              </S.More>}
          </Media>
        </S.Description>
        {isDU && <ContractData productNumber={productNumber} date={date} />}
        <TransitionHeight
            isOpen={isShowResults}
            style={{ width: '100%' }}
            matched="tablet"
        >
          <Media query={device.tablet}>
            {(matches) => !(matches && !isDev) && <CurrentStatistics financial={financial} />}
          </Media>
        </TransitionHeight>
        <S.Actions isDU={isDU}>
          {buttonsBlock(isDU, productId, type)}
        </S.Actions>
      </S.Content>
    </S.ProductCard>
  );
};
