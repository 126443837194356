import styled from 'styled-components';

import { media } from '../../../assets/styles/theme';

const Report = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ${media.tablet} {
    height: 55vh;
  }
  
  ${media.mobile} {
    height: 40vh;
  }
`;

const Head = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 30px 22px 100px;

  ${media.tablet} {
    margin-bottom: 10px;
  }

  ${media.mobile} {
    margin: 0 15px 10px;
  }
  a {
    text-decoration: none;
  }

  button {
    height: 52px;
  }
`;

const Section = styled.section`
  margin: 0 30px 40px 30px;
  min-height: calc(100vh - 491px);

  ${media.tablet} {
    margin-bottom: 26px;
    min-height: none;
  }

  ${media.mobile} {
    margin: 0 0 26px;
    min-height: none;
  }
`;
export const Title = styled.h1`
  font-size: 30px;

  ${media.tablet} {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
}

`;

export default {
  Report,
  Head,
  Section
};
