import { IAction, IAddress } from 'interfaces';
import { ISuggestionResponse } from 'helpers/dadata/interfaces';

export interface ISuggestionsInputState {
  suggestions: ISuggestionResponse[];
  /* todo типизация пока не нужна в текущем функционале */
  event: any;
  showSuggestions: boolean;
  addressPayload?: IAddress;
}

export const initialState: ISuggestionsInputState = {
  suggestions: [],
  event: undefined,
  showSuggestions: false
};
export const suggestionsActions = {
  setEvent: 'set-event',
  setSuggestions: 'set-suggestions',
  showSuggestions: 'showing-suggestions',
  hideSuggestions: 'hide-suggestions'
};
export const suggestionsReducer = (
  state = initialState,
  action: IAction
): ISuggestionsInputState => {
  const { type, payload } = action;
  const {
    setSuggestions,
    setEvent,
    showSuggestions,
    hideSuggestions
  } = suggestionsActions;
  switch (type) {
    case setEvent: {
      if (payload) {
        return {
          ...state,
          event: payload
        };
      }
      return state;
    }
    case setSuggestions: {
      if (payload) {
        return {
          ...state,
          suggestions: payload as ISuggestionResponse[]
        };
      }
      return state;
    }
    case showSuggestions: {
      if (state.suggestions.length) {
        return {
          ...state,
          showSuggestions: true
        };
      }
      return {
        ...state,
        showSuggestions: false
      };
    }
    case hideSuggestions: {
      return {
        ...state,
        showSuggestions: false
      };
    }
    default:
      return state;
  }
};
