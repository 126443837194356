import React from 'react';

import classnames from 'classnames';

import { ITogglerProps } from './interfaces';

import S from './styles';

export const Toggler = ({ onClick, dotState }: ITogglerProps) => (
  <S.Toggler
      onClick={onClick}
      className={classnames({
        right: dotState
      })}
  >
    <div className="dot" />
  </S.Toggler>
);

export const SToggler = S.Toggler;
