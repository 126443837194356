import React from 'react';

import { Common } from 'components/shared';

import { IPromoPanelProps } from './interfaces';

import S from './styles';

const { H4, MainText } = Common

export const PromoPanel = ({
  Img,
  title,
  description
}: IPromoPanelProps) => (
  <S.PromoPanel data-test="promo-panel">
    <Img />
    <H4 data-test="promo-panel-title">{title}</H4>
    <MainText data-test="promo-panel-description">{description}</MainText>
  </S.PromoPanel>
);
