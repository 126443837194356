import React, { useState, useEffect } from 'react';

import { NavigationType, useNavigationType } from 'react-router';
import { Action } from 'history';

import { Change } from 'types/inputEvent';
import { getFakeEvent } from 'helpers/field/getFakeEvent';

// eslint-disable-next-line import/no-cycle
import { Input } from '../index';
import { IInputProps } from '../intefaces';

import S from './styles';

export const MoneyInput = (props: IInputProps) => {
  const [changed, setChanged] = useState(false);
  /* если клиент нажал кнопку назад в браузере
  показываем ранее введенное значение, а не пустое поле */
  const navType: NavigationType = useNavigationType();
  useEffect(() => {
    if (navType === Action.Pop) {
      setChanged(true);
    }
  }, [navType]);
  const {
    name = '',
    onChange,
    value
  } = props;
  const inputProps: IInputProps = {
    ...props,
    type: 'text',
    onChange: handleChange
  };
  function handleChange(event: Change) {
    setChanged(true);

    const valueWithoutMask = event.target.value
      .replace(/\s/g, '')
      .replace(/\.[0-9][0-9][0-9]/g, '.[0-9][0-9]');

    if (valueWithoutMask.length > 13) {
      return;
    }

    if (isNumber(valueWithoutMask)) {
      if (onChange) {
        onChange(getFakeEvent(
          valueWithoutMask || null,
          name
        ) as Change);
      }
    }
  }

  const currentValue = changed && value
    ? value
    : '';

  return (
    <S.WrapperMoneyInput>
      <Input {...inputProps} value={currentValue} />
      <S.MoneySpan changed={changed}>{value && `${currentValue} ₽`}</S.MoneySpan>
    </S.WrapperMoneyInput>
  );
};

function isNumber(value: string): boolean {
  return !Number.isNaN(value);
}
