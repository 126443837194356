import styled from 'styled-components';

import { Common } from 'components/shared';

const {
  DescriptionText,
  ExtraText,
  MainText,
  Button
} = Common

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${DescriptionText} {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  ${ExtraText} {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`;

const DownloadButton = styled(Button)`
  margin: 30px 0 50px;
`;

const Wrapper = styled.div`
  margin: 20px 0 ;
`;

const Text = styled(MainText)`
  display: block;
  font-weight: bold;
`;

export default {
  Text,
  Wrapper,
  DownloadButton,
  InfoWrapper
}
