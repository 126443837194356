import styled, { keyframes } from 'styled-components';

const SidebarItem = styled('li')`
  list-style-type: none;
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 51px;
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    &:hover {
      &:before {
        background-color: ${({ theme }) => theme.colors.buttonPrimary};
      }
      svg {
        * {
          fill: ${({ theme }) => theme.colors.buttonPrimary};
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -30px;
      height: 100%;
      width: 4px;
    }
    span {
      color: #ffffff;
      font-size: 16px;
      letter-spacing: 0.07px;
      line-height: 20px;
      margin-left: 13px;
    }
    &.active {
      cursor: default;
      svg {
        * {
          fill: ${({ theme }) => theme.colors.buttonPrimary};
        }
      }
      &:before {
        background-color: ${({ theme }) => theme.colors.buttonPrimary};
      }
    }
  }
`;
const rotate = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ExclamationMark = styled.span`
  width: 23px;
  height: 23px;
  transform: rotate(180deg);
  animation: ${rotate} 0.7s alternate infinite;
`

export default { SidebarItem };
