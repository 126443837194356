import dayjs from 'dayjs';

import { store } from '../store/configureStore';
import { IRecognitionSubmitData } from '../interfaces';
import { IFileSelect } from '../components/pages/kval/interfaces';

/* получаем данные из store для отправки на бэк для подтверждения
 актуальности данных и получения статуса квал инвестора */
export const getSubmitData = (docTypeVal?: string, selectedFiles?: IFileSelect[]) => {
  const {
    purchase: {
      data: {
        email,
        firstName,
        gender,
        inn,
        lastName,
        passport,
        passport: {
          series,
          number,
          issuesDate,
          issuesPlace,
          unitCode,
        } = {
          number: '',
          series: '',
          issuesPlace: '',
          issuesDate: '',
          unitCode: ''
        },
        dayBirth,
        patronymic,
        phone,
        placeBirth,
        requisites
      },
    },
  } = store.getState();
  const submitData: IRecognitionSubmitData = {
    dayBirth: dayBirth
      ? dayjs(dayBirth, 'DD.MM.YYYY').format('YYYY-MM-DD')
      : undefined,
    email,
    firstName,
    gender: gender || 'MALE',
    inn,
    lastName,
    passport: passport
      ? {
        series,
        number,
        issuesDate: dayjs(issuesDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        issuesPlace,
        unitCode: unitCode
          ? unitCode.replace('-', '')
          : ''
      }
      : undefined,
    patronymic,
    phone,
    placeBirth,
    requisites
  };
  return !docTypeVal && !selectedFiles
    ? submitData
    : {
      ...submitData,
      recognition: {
        docType: docTypeVal,
        files: selectedFiles
      }
    }
}
