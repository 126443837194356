import styled from 'styled-components';

import { media } from 'assets/styles/theme';

import { Common } from '../../shared';

const { H3, Panel } = Common

const ButtonWrapper = styled.div`
  margin: 20px 0;
`;
const Description = styled.div`
  display: block;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06px;
  color: ${({ theme }) => theme.colors.description};
`;
const Wrapper = styled.div`
  max-width: 870px;
  margin-bottom: 15px;
`;
const Text = styled(H3)`
  text-align: left;
  a {
    font-size: 16px !important;
  }
  
  ${media.mobile} {
    font-size: 16px;
  }
`;
const ClientDataPanel = styled(Panel)`
  align-items: start;
  
  ${media.mobile} {
    padding: 20px;
  }
`;
export default {
  ButtonWrapper,
  Description,
  Wrapper,
  Text,
  ClientDataPanel
}
