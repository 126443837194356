import styled from 'styled-components';

const FinResult = styled.div`
  display: flex;
  span {
    color: ${({ theme }) => theme.colors.border};
    white-space: pre;
  }
`;
export default { FinResult }
