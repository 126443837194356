import forEach from 'lodash/forEach';

import { ProductType } from 'enums';
import {
  IProduct,
  IterableEntity,
  IProductFinancialResults
} from 'interfaces';

export const getProductsByType = (
  products: IterableEntity<IProduct>,
  type: ProductType
): IProduct[] => {
  const selectedProducts: IProduct[] = [];
  const { ids } = products;
  forEach(ids, (id) => {
    if (products.byId[id].type === type) {
      selectedProducts.push(products.byId[id]);
    }
  });
  return selectedProducts;
};
export const getProductFinancialResults = (
  product: IProduct
): IProductFinancialResults => {
  const {
    input,
    output,
    result,
    cost,
    shareCount
  } = product;

  return {
    input,
    output,
    cost,
    result: {
      inMoney: result && result.value && result.value,
      percents: result && result.percents && result.percents
    },
    numberShares: shareCount || '0'
  };
};

export const getProductActionLink = (
  id: string | number,
  action: string,
  type: string = 'invest',
  step: string = '1',
  form: string = '1'
): string => `/products/${type}/${id}/${action}/${step}/${form}`;
