import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IOpifYieldState, ISmsState } from 'interfaces';

export const sms: ISmsState = {
  send: {
    success: false,
    fail: false,
    loading: false
  },
  check: {
    request: {
      success: false,
      fail: false,
      loading: false
    },
    error: {
      message: ''
    }
  }
}
interface IInitialState {
  edoStatus: boolean,
  loaderStatus: boolean,
  error: string,
  sms: ISmsState
}

/* получение и подтверждение статуса ЭДО */
export const edoStatusSlice = createSlice({
  name: 'edoStatus',
  initialState: {
    edoStatus: false,
    loaderStatus: true,
    error: '',
    sms
  } as IInitialState,
  reducers: {
    /* получение */
    requestCheckEdo: (state) => state,
    successCheckEdo: (state, action: PayloadAction<boolean>) => {
      state.edoStatus = action.payload
      state.loaderStatus = false
      return state
    },
    errorCheckEdo: (state, action: PayloadAction<IOpifYieldState>) => {
      state.error = action.payload.error
      return state
    },
    /* для смс */
    /* отправка смс */
    edoSmsSendSuccess: (state) => {
      state.sms.send.success = true
      state.sms.send.loading = false
      return state
    },
    edoSmsSendError: (state) => {
      state.sms.send.fail = true
      return state
    },
    edoSmsSendRequest: (state) => {
      state.sms.send.loading = true
      return state
    },
    /* проверка смс */
    edoSmsCheckSuccess: (state) => {
      state.sms.check.request.success = true
      state.sms.check.request.loading = false
      return state
    },
    edoSmsCheckError: (state, action: PayloadAction<string>) => {
      state.sms.check.request.fail = true
      state.sms.check.request.loading = false
      state.sms.check.error.message = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    edoSmsCheckRequest: (state, action: PayloadAction<string>) => {
      state.sms.check.request.loading = true
      return state
    },
    /* доп reducer-s */
    edoSmsCheckResetRequest: (state) => {
      state.sms.check.request.success = false
      return state
    },
    edoSmsCheckResetFail: (state) => {
      state.sms.check.request.fail = false
      return state
    },

    // edoSmsInConflict: () => {},
    // edoSmsNotRespond: () => {},
  }
})
export const {
  requestCheckEdo,
  successCheckEdo,
  errorCheckEdo,
  edoSmsSendSuccess,
  edoSmsSendError,
  edoSmsCheckSuccess,
  edoSmsCheckError,
  // edoSmsInConflict,
  // edoSmsNotRespond,
  edoSmsSendRequest,
  edoSmsCheckRequest
} = edoStatusSlice.actions
