import React, { useEffect, useState } from 'react';

import { Money } from 'entities/money';
import { Currency } from 'enums';

import { INumberFieldProps } from './interfaces';

import S from './styles';
import { useStore } from '../../hooks/useStore';

export const NumberField = (({
  value,
  type,
  colorize,
  currency = Currency.rouble,
  hasSign,
}: INumberFieldProps) => {
  const { currencyCourses } = useStore()
  const [formatValue, setFormatValue] = useState(value ? value.toString(type) : '');
  useEffect(() => {
    if (value instanceof Money) {
      value.changeCurrency(currency, currencyCourses[currency]);
    }
    setFormatValue(value ? value.toString() : '');
  }, [currency, currencyCourses, value]);

  // eslint-disable-next-line no-prototype-builtins
  const getValue = value && value.hasOwnProperty('get') ? value.get() : 0;

  return (
    <>
      {value
        ? (
          <S.NumberField
            type={type}
            value={getValue}
            data-test="number-field-component"
            colorize={colorize}
          >
            {hasSign && getValue > 0 ? '+ ' : ''}
            {formatValue}
          </S.NumberField>
        )
        : ''}
    </>
  );
}
);
