import styled from 'styled-components';

import { FormWidth } from 'assets/styles/mixins';

interface ISuggestionsProps {
  isShow?: boolean;
}

interface ISuggestionProps {
  isActive: boolean;
}

const Suggestions = styled.ul`
  width: 100%;
  padding-inline-start: 0;
  padding-left: 0;
  overflow: hidden;
  position: absolute;
  top: 75%;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(48, 45, 43, 0.25);
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  max-height: ${({ isShow }: ISuggestionsProps) => (isShow ? '500px' : '0px')};
  transition-property: max-height;
  transition-duration: 0.35s;
  ${FormWidth}
`;
const Suggestion = styled.li`
  list-style-type: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  align-items: center;
  min-height: 32px;
  margin: 0 10px;
  background: ${({ isActive }: ISuggestionProps) => (isActive ? 'rgba(0, 150, 183,0.05)' : 'inherit')};
  transition-property: background;
  transition-duration: 0.35s;
  :first-child {
    margin-top: 20px;
  }
  :last-child {
    margin-bottom: 20px;
  }
`;
const Dadata = styled.div`
  position: relative;
`;
export default { Dadata, Suggestion, Suggestions };
