import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { api } from 'api';
import { Url } from 'api/url';
import { getHeaders } from 'api/options';

import {
  requestConfirmationCheck,
  successConfirmationCheck,
  errorConfirmationCheck,
  agreeConfirmationRequest,
  agreeConfirmationError
} from '../../reducers/confirmation';

function* checkConfirmation() {
  try {
    const params = {
      url: Url.confirmation.check
    };
    const confirmationExistStatus: { confirmationExistStatus: boolean } = yield call(
      api.get,
      params
    );
    yield put(successConfirmationCheck(confirmationExistStatus.confirmationExistStatus));
  } catch (e) {
    console.log(e.message)
    yield put(errorConfirmationCheck(false));
  }
}

function* agreeConfirmation() {
  try {
    const params = {
      url: Url.confirmation.agree
    };
    yield call(
      api.get,
      params,
      getHeaders()
    );
    /* повторно запрашиваем статус confirmationExistStatus клиента,
     т.к. фактически никакой ответ не приходит после нажатия на кнопку
     "подтверждаю ознакомление",что может привести кнекорректному UI если статус
     confirmationExistStatus не обновится по какой-то причине на бэке на true */
    yield put(requestConfirmationCheck())
  } catch (error) {
    console.log(error.message)
    yield put(agreeConfirmationError(false));
  }
}

export function* getConfirmationExistStatus() {
  yield takeEvery(
    requestConfirmationCheck.toString(),
    checkConfirmation
  );
}

export function* sendAgreeConfirmation() {
  yield takeEvery(
    agreeConfirmationRequest.toString(),
    agreeConfirmation
  );
}
