import React, { useState } from 'react';

import map from 'lodash/map';

import { useStore } from 'components/hooks/useStore';
import {
  PieChart as Chart,
  Pie,
  ResponsiveContainer,
  Cell,
  Legend,
  Label
} from 'recharts';
import ActiveShapeStyles from './activeShape/styles';
import { LegendItem } from './legendItem';
import { ActiveShape } from './activeShape';
import {
  IEntryLegend,
  IOwnProps,
} from './interfaces';

import S from './styles';

export const PieChart = ({
  withLegend = false,
  data,
}: IOwnProps) => {
  const [activeIndex, setActiveIndex] = useState(NaN);
  const onPieEnter = (pieData: any, index: number) => setActiveIndex(index);
  const renderLegend = ({ payload }: any) => (
    <>
      {map(payload, (entry: IEntryLegend, index: number) => (
        <LegendItem
            key={`item-${index}`}
            title={entry.value.replace(/"/i, '').replace(/"/i, '')}
            value={` ${entry.payload.part} %`}
            color={entry.color}
            isActive={activeIndex === index}
            onMouseEnter={() => setActiveIndex(index)}
            onMouseLeave={() => setActiveIndex(NaN)}
        />
      ))}
    </>
  );

  const {
    currency,
    currencyCourses,
    purse: { currentValue: result }
  } = useStore()

  return (
    <S.PieChart data-test="pie-chart-component">
      <ResponsiveContainer width="99%" height={200}>
        <Chart onMouseLeave={() => setActiveIndex(NaN)}>
          <Pie
            data={data}
            dataKey="part"
            nameKey="name"
            activeIndex={activeIndex}
            activeShape={(props: any) => <ActiveShape {...props} />}
            onMouseEnter={onPieEnter}
            onTouchEnd={onPieEnter}
            innerRadius={75}
            outerRadius={105}
            startAngle={0}
            endAngle={-360}
            cy={0}
          >
            {Number.isNaN(activeIndex) && result && (
              <Label
                position="center"
                className="pie-result"
                content={({ viewBox }: any) => {
                  const { cx, cy } = viewBox;
                  result.changeCurrency(currency, currencyCourses[currency]);
                  return (
                    <ActiveShapeStyles.Result
                      x={cx}
                      y={cy}
                      dy={8}
                      textAnchor="middle"
                    >
                      <tspan x={cx} y={cy - 25} dy="1.2em">
                        Активы:
                      </tspan>
                      <tspan x={cx} y={cy} dy="1.2em">
                        {result.toString()}
                      </tspan>
                    </ActiveShapeStyles.Result>
                  );
                }}
              />
            )}
            {map(data, (entry) => (
              <Cell fill={entry.color} key={entry.name} />
            ))}
          </Pie>
          {withLegend && (
            <Legend
              verticalAlign="bottom"
              iconType="circle"
              iconSize={10}
              align="left"
              content={renderLegend}
            />
          )}
        </Chart>
      </ResponsiveContainer>
    </S.PieChart>
  );
};
