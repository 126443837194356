import cloneDeep from 'lodash/cloneDeep';

import { IFormFields, IPurchaseData } from 'interfaces';
import { PurchaseData } from 'entities/purchaseData';
import { getAdditionalNameParams } from 'helpers/actions/purchase/getAdditionalFields';

export async function getPurchaseDataFromFields(
  formFields: IFormFields,
  storeData: IPurchaseData
) {
  const purchaseData = new PurchaseData(cloneDeep(formFields));
  purchaseData.set({
    firstName: storeData.firstName,
    lastName: storeData.lastName,
    patronymic: storeData.patronymic,
    dayBirth: storeData.dayBirth,
    placeBirth: storeData.placeBirth,
    productName: storeData.productName,
    accountNumber: storeData.accountNumber,
    applicantId: storeData.applicantId,
    addressPost: storeData.addressPost,
    addressRegistration: storeData.addressRegistration,
    email: storeData.email,
    inn: storeData.inn,
    passport: storeData.passport,
    phone: storeData.phone,
    productId: storeData.productId,
    order: storeData.order,
    existIISOtherCompany: storeData.existIISOtherCompany
  });
  return setAdditionalNameParams(purchaseData);
}

async function setAdditionalNameParams(purchaseData: PurchaseData) {
  const data = purchaseData.get();
  const { firstName, lastName } = data;

  const additionalNameParams = await getAdditionalNameParams(
    firstName,
    lastName
  );
  purchaseData.set(additionalNameParams);
  return purchaseData;
}
