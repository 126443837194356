import React, { useState, useRef } from 'react';

import Media from 'react-media';
import DayPicker, { DayModifiers } from 'react-day-picker';
import useOnClickOutside from 'use-onclickoutside';
import 'react-day-picker/lib/style.css';

import { getLastDay } from 'helpers/date/lastDay';
import { device } from 'assets/styles/theme';
import { CommonTooltip } from 'components/shared';

import { NavBar } from './navBar';
import { Caption } from './caption';
import { IOwnProps } from './interfaces';
import { useStore } from '../../hooks/useStore';
import {
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  MONTHS
} from './locale';

import S from './styles';

export const Datepicker = (({
  isShow,
  range,
  onChange,
  header: Header,
  limits,
  onClose
}: IOwnProps) => {
  const { account: { dateBorders } } = useStore()
  const [isStartDay, setIsStartDay] = useState(true);
  const firstDate = new Date(0);
  const lastDate = getLastDay();

  const handleDayClick = (day: Date, { disabled }: DayModifiers) => {
    if (day && !disabled) {
      const newRange = {
        from: isStartDay
          ? day
          : range.from || dateBorders.from || firstDate,
        to: isStartDay
          ? range.to || dateBorders.to || lastDate
          : day
      };

      if (newRange.to < newRange.from) {
        newRange.to = newRange.from;
      }

      if (onChange && newRange.from && newRange.to) {
        onChange(newRange, day);
      }

      setIsStartDay(!isStartDay);
    }
  };

  const getDisabledDays = () => {
    if (limits) {
      return {
        before: limits.from,
        after: limits.to
      };
    }

    return undefined;
  };

  const getSelectedDays = () => {
    if (range && range.from && range.to) {
      return [{ from: range.from, to: range.to }];
    }

    return undefined;
  };
  const datepicker = (
    <>
      {Header && Header}
      <DayPicker
        fixedWeeks
        className="Selectable"
        month={range.to}
        modifiers={{ start: range.from, end: range.to }}
        disabledDays={getDisabledDays()}
        selectedDays={getSelectedDays()}
        onDayClick={handleDayClick}
        locale="ru"
        months={MONTHS}
        weekdaysLong={WEEKDAYS_LONG}
        weekdaysShort={WEEKDAYS_SHORT}
        firstDayOfWeek={1}
        navbarElement={(args) => <NavBar {...args} />}
        captionElement={({ months, date }) => (
          <Caption date={date} months={months} />
        )}
      />
    </>
  );
  const datepickerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(datepickerRef, onClose);

  return (
    <Media query={device.tablet}>
      {(matched) => (matched
        ?
          <S.DatepickerTooltip>
            {/* в данном кейсе в CommonTooltip не блокируется скролл,
             что не критично, т.к. на таком расширении экрана не
              отображается календарь с выбором дат */ }
            <CommonTooltip
              showCloseButton
              isShow={isShow}
              onClose={onClose}
            >
              {datepicker}
            </CommonTooltip>
          </S.DatepickerTooltip>
        :
          <S.Datepicker
              show={isShow}
              data-test="datepicker-component"
              ref={datepickerRef}
          >
            {datepicker}
          </S.Datepicker>
      )}
    </Media>
  );
});
