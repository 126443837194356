import styled from 'styled-components';

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  flex-shrink: 0;
  span {
    display: inline-block;
  }
`;

const Title = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.description};
`;
const Value = styled.span`
  color: ${({ theme }) => theme.colors.description};
  white-space: pre;
`;
const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .active {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
  div:first-child {
    display: flex;
    align-items: center;
    ${Circle} {
      margin-right: 10px;
    }
  }
`;
export default {
  LegendItem,
  Circle,
  Value,
  Title
};
