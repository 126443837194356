import styled from 'styled-components';

import { Common } from 'components/shared';
import { media } from 'assets/styles/theme';

import { IErrorPanelProps } from './interfaces';

const { Panel } = Common

const ErrorPanel = styled(Panel)<IErrorPanelProps>`
  width: 570px;
  margin-top: ${({ activeSidebar }) => (activeSidebar ? '110px' : '60px')} 100px 20px;
  align-self: center;
  h3 {
    text-align: center;
  }
  ${media.tabletOnly} {
    width: 60%;
    padding: 40px;
  }
  ${media.mobile} {
    width: 100%;
    span {
      font-size: 14px;
    }
    h1 {
      font-size: 1.6rem;
    }
  }
`;
export default {
  ErrorPanel
}
