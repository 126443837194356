import styled from 'styled-components';

import { FormWidth } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';
import { Common } from 'components/shared';

const {
  ExtraText,
  LinkText,
  ButtonLarge
} = Common

const ServiceError = styled.div`
  position: absolute;
  ${ExtraText} {
    text-align: center;
    ${LinkText} {
      font-size: 14px;
    }
  }
`;

const Content = styled.div`
  ${FormWidth} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const BackButton = styled(ButtonLarge)`
  margin-top: 10px;
  margin-bottom: 0;

  ${media.mobile} {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 22px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.textPrimary};
  
  ${media.mobile} {
    font-size: 20px;
    margin-bottom: 10px;
}
`
export const Link = styled(LinkText)`
  font-size: 14px;
`
export const Text = styled(ExtraText)`
  padding-bottom: 8px;
  text-align: center;
`

export default { ServiceError, Content };
