import styled from 'styled-components';

import { media } from 'assets/styles/theme';

import StylesUser from './sections/user/styles';
import StylesLink from './sections/link/styles';
import StylesFeedback from './sections/feedback/styles';
import StylesLogo from './sections/logo/styles';

interface ISidebarProps {
  collapse: boolean;
}
const Collapse = styled.button`
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;

  svg {
    margin-right: 13px;    
    * {
      fill: ${({ theme }) => theme.colors.white};
    }    
  }

  span {
    font-size: 16px;
    letter-spacing: 0.07px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Sidebar = styled.aside<ISidebarProps>`
  min-height: 100%;
  position: relative;
  flex-shrink: 0;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  background-color: ${({ theme }) => theme.colors.sidebar};
  transition-property: width;
  transition-duration: 0.35s;
  color: ${({ theme }) => theme.colors.white};
  width: ${({ collapse }) => (collapse ? '85px' : '320px')};
  .collapse-wrapper {
    width: 260px;
  }
  
  svg {
       min-width: 20px;       
  }

  ${media.tablet} {
    transform: translateX(-100%);
    transition: all 0.5s;
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    z-index: 1;
    &.active {
      transform: translateX(0);
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      position: fixed;
      z-index: 100;
      left: 0;
      bottom: 0;
    }
    ${StylesLogo.SidebarLogo} {
      display: none;
    }

    & > section {
      width: 56%;
    }
  }

  ${media.mobile} {
    & > section {
      width: 100%;
    }
  }

  span {
    display: block;
  }

  a {
    display: flex;
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    text-decoration: none;
  }

  ${StylesUser.SidebarUser} {
    margin-top: 26px;

    ${media.tablet} {
      margin-top: 30px;

      [data-name="logout-button"] {
        margin: 10px 30px 0 0;
      }
    }

    span {
      display: ${({ collapse }) => (collapse ? 'none' : 'block')};
    }
  }

  ${StylesLink.SidebarItem} {
    span {
      display: ${({ collapse }) => (collapse ? 'none' : 'flex')};
    }
  }

  ${StylesFeedback.Feedback} {
    .title {
      span {
        display: ${({ collapse }) => (collapse ? 'none' : 'block')};
      }
    }
    .manager {
      display: ${({ collapse }) => (collapse ? 'none' : 'block')};
    }
    .support {
      display: ${({ collapse }) => (collapse ? 'none' : 'block')};
    }    

    ${media.tablet} {
      padding: 30px;
    }    
  }

  ${Collapse} {
    span {
      display: ${({ collapse }) => (collapse ? 'none' : 'block')};
    }
    svg {
      transition-duration: 0.2s;
      transform: ${({ collapse }) => (collapse ? 'rotate(180deg)' : 'rotate(0)')};      
    }

    ${media.tablet} {
      display: none;
    }
  }

  .items {
    padding: 22px 0;
    ul {
      padding: 0;
    }
  }

  .separator {
    margin-bottom: 28px;
    height: 1px;
    opacity: 0.25;
    background-color: #aabcc5;

    ${media.tablet} {
      height: 100%;
      width: 1px;
      margin-bottom: 0;
    }

    ${media.mobile} {
      height: 1px;
      width: 100%;
    }
  }

  &.loginPage {
    ${StylesUser.SidebarUser},
    .items,
    .separator {
      display: none;
    }
  }
`;

export default { Sidebar, Collapse };
