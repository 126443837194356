import React from 'react';

import Media from 'react-media';
import map from 'lodash/map';
import includes from 'lodash/includes';

import { ALL_PRODUCTS } from 'constants/products';
import { Colors } from 'constants/colors';
import { Common } from 'components/shared';
import { device } from 'assets/styles/theme';

import { ILegendProps } from './interfaces';
import { ProductButton } from './productButton';
import Styles from '../../shared/commonStyles/chartsPanel/styles';
import { WrapperTooltip } from '../../shared/wrapperTooltip/wrapperTooltip';

const { ContainerProductButtons } = Styles
const { Button, H4 } = Common

export const Legend = ({
  products,
  activeProducts,
  isActive,
  moreTwoProducts,
  onClose
}: ILegendProps) => {
  const legend = (
    <ContainerProductButtons className={isActive ? 'active' : ''}>
      {moreTwoProducts
        ? (
          <ProductButton
            key={ALL_PRODUCTS}
            title={ALL_PRODUCTS}
            value={ALL_PRODUCTS}
            active
            color={Colors[0]}
          />)
        : null}

      {products &&
      map(products, (name, index) => {
        return (
          <ProductButton
            key={name}
            active={includes(activeProducts, name)}
            value={name}
            title={name}
            color={Colors[index + 1]}
          />
        );
      })}
    </ContainerProductButtons>
  );
  const withTooltip = (
    <WrapperTooltip
      isShow={isActive}
      onClose={() => onClose()}
      showCloseButton
    >
      <H4>Легенда</H4>
      {legend}
      <Button onClick={() => onClose()}>
        Применить
      </Button>
    </WrapperTooltip>
  );
  return (
    <Media query={device.tablet}>
      {(matches) => (matches ? isActive && withTooltip : legend)}
    </Media>
  );
};
