import React, {
  useState,
  useRef,
  useEffect,
  useReducer
} from 'react';

import { useDispatch } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';
import dayjs from 'dayjs';

import { IDateRange, IAction } from 'interfaces';
import { Datepicker, Input } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { formatDate } from 'helpers/date/format';
import { actionCreator } from 'helpers/actions/actionCreator';
import { setChartDateRange } from 'store/reducers/dateRangeReducers';

import { DateFilters } from '../../dateFilters';
import StylesDateFilters from '../../dateFilters/styles';
import { IOwnProps } from './interfaces';

import S from './styles';

const update = 'update-filter';
const setFromDate = 'set-from-date-filter';

const reducer = (state: string, action: IAction<string>) => {
  const { payload } = action;

  switch (action.type) {
    case update:
      return payload;
    case setFromDate: {
      if (!state && payload) {
        return payload;
      }
      return state;
    }
    default:
      return state;
  }
};

export const ChartsDatepicker = ({ range, isChartWidget }: IOwnProps) => {
  const [filter, dispatchFilter] = useReducer(reducer, '');
  const [datepickerShow, setDatepickerShow] = useState(false);
  const { account: { dateBorders } } = useStore()
  const dispatch = useDispatch()
  const setRange = (range: IDateRange) => dispatch(setChartDateRange(range))
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setDatepickerShow(false);
  });
  const { to, from } = range;

  useEffect(() => {
    if (dateBorders && JSON.stringify(dateBorders) !== JSON.stringify({})) {
      const stringifyFirstPurchase = dayjs(dateBorders.from).toString();
      const action = actionCreator(setFromDate, stringifyFirstPurchase);
      dispatchFilter(action as { type: string, payload: string });
    }
  }, [dateBorders]);
  const handleChange = (value: string) => {
    if (value !== 'range') {
      const newRange: IDateRange = {
        to: dateBorders.to,
        from: value === 'all' ? dateBorders.from : new Date(value)
      };
      setRange(newRange);
    }
    dispatchFilter(actionCreator(update, value) as { type: string, payload: string });
  };
  const handleDatepickerChange = (newRange: IDateRange) => {
    setRange(newRange);
    dispatchFilter(actionCreator(update, 'range') as { type: string, payload: string });
  };

  return (
    <S.ChartsDatepicker data-test="filters-outside-component">
      <S.Filters>
        <DateFilters
          isChartWidget={isChartWidget}
          onChange={handleChange}
          selectedOption={filter}
          from={from}
          to={to}
          handleLastElementClick={() => setDatepickerShow(true)}
        />
      </S.Filters>
      <S.Datepicker ref={ref}>
        <Datepicker
          isShow={datepickerShow}
          onClose={() => setDatepickerShow(false)}
          range={range}
          onChange={handleDatepickerChange}
          limits={dateBorders}
          header={
            <StylesDateFilters.Inputs>
              <Input
                disabled
                value={formatDate(from || dateBorders.from, 'DD.MM.YY')}
                placeholder=""
              />
              <span className="separator">–</span>
              <Input
                disabled
                value={formatDate(to || dateBorders.to, 'DD.MM.YY')}
                placeholder=""
              />
            </StylesDateFilters.Inputs>
          }
        />
      </S.Datepicker>
    </S.ChartsDatepicker>
  );
};
