import styled from 'styled-components';

const CurrencySwitch = styled.div`
  display: flex;

  label {
    margin-right: 10px;
    height: 28px;
    width: 26px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.description};
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    position: relative;
    border-bottom: 2px dotted #e0dad5;
  }

  input:checked ~ label {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.description};
    box-shadow: 0 2px 4px 0 rgba(83, 119, 138, 0.15);
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.41px;
    line-height: 22px;
    border-bottom: none;
  }
`;

export default { CurrencySwitch };
