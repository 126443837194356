import styled from 'styled-components';

interface INumberFieldStyledProps {
  type: 'diff' | 'common' | undefined;
  value: number;
  colorize?: boolean;
}
const NumberField = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${({ colorize, value }: INumberFieldStyledProps) => (
    colorize
      ? (value > 0
        ? '#779C48'
        : '#424249')
      : '#424249')};
  white-space: nowrap;
`;

export default { NumberField };
