import { logOutAction } from 'store/actions/actionCreators/logOut';
import { IAction } from 'interfaces';

export const isUnauthorized = (
  code: number | undefined
): IAction | undefined => {
  if (code && code === 401) {
    return logOutAction();
  }
  return undefined;
};
