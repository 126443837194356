import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import forEach from 'lodash/forEach';

import { IRecommendedProduct, IterableEntity } from 'interfaces';

/* получаем список рекомендуемых продуктов */
export const recommendedProductsSlice = createSlice({
  name: 'recommendedProducts',
  initialState: { byId: {}, ids: [], error: '' } as IterableEntity<IRecommendedProduct>,
  reducers: {
    requestProducts: (state) => state,
    successProducts: (state, action: PayloadAction<IRecommendedProduct[]>) => {
      const byId = {};
      const ids: number[] = [];
      forEach(action.payload, (product) => {
        const {
          id,
          name,
          about,
          risk,
          typeId
        } = product;
        const holds = product.holds
          ? {
            add: product.holds.rebuy,
            purchase: product.holds.buy,
            sale: product.holds.redemption
          }
          : {};
        byId[id] = {
          name,
          about,
          risk,
          holds,
          typeId
        };
        ids.push(Number(id));
      });
      state.ids = ids
      state.byId = byId
      return state
    },
    errorProducts: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      return state
    }
  }
})
export const {
  requestProducts,
  successProducts,
  errorProducts
} = recommendedProductsSlice.actions
