import styled from 'styled-components';

import { Common } from 'components/shared';

const { ExtraText, LinkText } = Common

export const DocsManagement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .scrollbar-container {
    padding: 26px;
  }
  ${LinkText} {
    margin-bottom: 46px;
    display: flex;
    svg {
      margin-right: 10px;
      * {
        fill: ${({ theme }) => theme.colors.link};
      }
    }
  }
  & > ${ExtraText} {
    text-align: center;
  }
`;
export const Title = styled(ExtraText)`
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 30px; 
`
