import styled from 'styled-components';

import StylesInput from 'components/shared/input/styles';
import StylesCheckbox, { checkboxBefore } from 'components/shared/checkbox/styles';
import StylesProductButton from 'components/widgets/portfolioCharts/productButton/styles';
import StylesRadio from 'components/shared/radioButton/styles';
import { FormWidth } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';
import StylesDateInput from 'components/shared/input/date/styles';
import { Common } from 'components/shared';

import SmsTooltipStyles from '../tooltips/sms/styles';
import StylesPassportNotification from '../applicationForms/innAndPassportNumber/passportNotification/styles';

interface IHiddenFields {
  isShow?: boolean;
}

const { ProductButton } = StylesProductButton;
const { Checkbox } = StylesCheckbox;
const { Input } = StylesInput;
const { PassportNotification } = StylesPassportNotification;
const { RadioButton } = StylesRadio;
const { SmsTooltip } = SmsTooltipStyles;
const {
  H2,
  ExtraText,
  Button,
  Panel
} = Common

const centred = 'margin-right: auto; margin-left:auto';

const GroupTitle = styled(H2)`
  margin-top: 25px;
  margin-bottom: 24px;
  display: flex;
  svg {
    margin-left: 10px;
    * {
      fill: ${({ theme }) => theme.colors.link};
    }
  }
  ${media.tablet} {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const GroupDescription = styled(ExtraText)`
  margin-top: 15px;
  display: block;
`;
const FormComponent = styled.div`
  width: 100%;
  max-width: 370px;
  &.payment-form {
    ${Button} {
      &[type="submit"] {
        display: none;
      }
    }
  }
  &.success-form {
    max-width: 100% !important;
    text-align: left;
    ${GroupTitle} {
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      text-align: left;
    }
    ${Button} {
      &[type="submit"], &[data-test="link-btn"] {
        border: none;
        background: inherit;
        box-shadow: none;
        color: ${({ theme }) => theme.colors.link};
        font-weight: normal;
        padding: 0;
        margin-top: 0;
        text-decoration: underline;
        text-decoration-color: ${({ theme }) => theme.colors.underline};
        ${media.mobile} {
          margin-top: 20px;
          text-align: left;
        }
      }
    }
  }

  ${GroupTitle} {
    &:first-child {
      width: 100%;
      text-align: left;
    }
  }

  ${ProductButton} {
    margin-bottom: 18px;
    margin-top: 0;
  }

  ${PassportNotification} {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    ${RadioButton},
    ${Checkbox} {
      margin-bottom: 26px;
    }

    ${RadioButton} {
      label {
        color: ${({ theme }) => theme.colors.textPrimary};
        font-size: 14px;
        line-height: 20px;
        padding-left: 30px;
        position: relative;

        :before {
          ${({ theme }) => checkboxBefore(theme, false)}
        }
      }

      input:checked ~ label {
        :before {
          ${({ theme }) => checkboxBefore(theme, true)}
        }
      }
    }

    button {
      ${centred}
    }
  }

  form ~ ${SmsTooltip} {
    ${Panel} {
      ${media.desktopOnly} {
        width: 700px;
        padding: 45px;
        align-items: center;
        h1 {
          font-weight: 400;
          font-size: 22px;
          line-height: 30px;
          text-align: center;
        }
      }
    }
  }
`;

interface IFieldContainer {
  halfWidth?: boolean;
}

const FieldContainer = styled.div`
  width: ${({ halfWidth }: IFieldContainer) => (halfWidth ? '170px' : '100%')};

  ${Input} {
    margin-top: 12px;
    ${FormWidth}
  }

  ${Button} {
    padding: 16px 23px;
    &[type="submit"] {
      margin-top: 16px;
    }
    ${media.mobile} {
      width: 100%;
      height: 52px;
      justify-content: center;
    }
  }
  ${StylesDateInput.DateInput} {
    ${FormWidth};
  }
`;

const Separator = styled.div`
  margin-top: 30px;
  margin-bottom: 0px;
  margin-left: -30px;
  height: 1px;
  background: ${({ theme }) => theme.colors.background};
  width: 100vw;
  max-width: 870px;
  ${GroupTitle} {
    margin-top: 25px;
  }
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 370px;
`;

const HiddenFields = styled(FieldsContainer)<IHiddenFields>`
  overflow: hidden;
  max-height: ${({ isShow }) => (isShow ? '1000px' : '0')};
  transition-property: max-height;
  transition-timing-function: ease-out;
  transition-duration: 0.5s;

  ${FieldContainer} {
    :first-child {
      margin-top: 5px;
    }
  }
`;

export default {
  Separator,
  FormComponent,
  FieldContainer,
  FieldsContainer,
  HiddenFields,
  GroupTitle,
  GroupDescription
};
