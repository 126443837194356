import React from 'react';

import { RadioButtonContainer, Input } from 'components/shared';
import { formatDate } from 'helpers/date/format';
import { useStore } from 'components/hooks/useStore';

import { getRadioButtons } from './options';
import { IOwnProps } from './interfaces';

import S from './styles';

export const DateFilters = ({
  onChange,
  selectedOption,
  hasLastElementText,
  handleLastElementClick,
  from,
  to,
  withInputs,
  isChartWidget
}: IOwnProps) => {
  const { account: { dateBorders } } = useStore()
  const radioButtons = getRadioButtons(
    dateBorders,
    hasLastElementText,
    handleLastElementClick
  );

  return (
    <S.DateFilters data-test="date-filters-component">
      <RadioButtonContainer
        isChartWidget={isChartWidget}
        options={radioButtons}
        onChange={onChange}
        selectedOption={selectedOption}
      />
      {withInputs
        ? (
          <S.Inputs>
            <Input disabled value={formatDate(from, 'DD.MM.YY')} placeholder="" />
            <span className="separator">–</span>
            <Input disabled value={formatDate(to, 'DD.MM.YY')} placeholder="" />
          </S.Inputs>)
        : null}
    </S.DateFilters>
  );
};
