import dayjs from 'dayjs';

export const getLastDay = (date: Date = new Date()): Date => {
  const day = date.getDay();
  switch (day) {
    case 0: {
      // воскресенье
      return dayjs(date)
        .subtract(3, 'day')
        .toDate();
    }
    case 1: {
      // понедельник
      const lastDay = dayjs(date)
        .subtract(4, 'd')
        .toDate();
      return lastDay;
    }
    case 2: {
      // вторник
      const lastDay = dayjs(date)
        .subtract(5, 'd')
        .toDate();
      return lastDay;
    }
    case 6: {
      return dayjs(date)
        .subtract(2, 'day')
        .toDate();
    }
    default: {
      return dayjs(date)
        .subtract(1, 'day')
        .toDate();
    }
  }
};
