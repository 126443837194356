import { takeLatest, put, call } from 'redux-saga/effects';

import { IAction } from 'interfaces';
import { api } from 'api';
import { Url } from 'api/url';
import { getPurchaseHeaders } from 'api/options';
import { Serializer } from 'api/serializer';
import { actionTypes } from 'store/actionTypes';

const { REQUEST, ERROR } = actionTypes.PURCHASE.RANGE_PRICE;

export function* rangePrice() {
  yield takeLatest(REQUEST, getRangePrice);
}

function* getRangePrice({ payload }: IAction) {
  try {
    const productId = payload || '';
    // @ts-expect-error
    const response = yield call(
      api.get,
      {
        url: `${Url.purchase.price}/${productId}`
      },
      getPurchaseHeaders()
    );
    const range = Serializer.rangePrice(response);

    yield put({
      type: actionTypes.PURCHASE.RANGE_PRICE.SUCCESS,
      payload: { data: { rangePrice: range } }
    });
  } catch (error) {
    yield put({ type: ERROR, payload: error });
    yield put({ type: actionTypes.PURCHASE.REDIRECT_ERROR, payload: error });
  }
}
