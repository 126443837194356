import dayjs from 'dayjs';
import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import { api } from 'api';
import { IAction } from 'interfaces';
import { getDateRangeParams } from 'helpers/actions/getDateRangeParams';
import { Url } from 'api/url';
import { isUnauthorized } from 'helpers/actions/isUnauthorized';
import { ALL_PRODUCTS } from 'constants/products';

import { setChartDateRange } from '../../reducers/dateRangeReducers';
import {
  getChartDataRequest,
  getChartDataSuccess,
  getResultSuccess, setChartError
} from '../../reducers/chart';

/* сага для получения данных для графика на странице портфолио и продкута ПИФ */
function* fetchChartData(action: IAction) {
  try {
    const { payload } = action;
    const { data: rawData, percent, value: val } = yield call(
      api.get,
      getDateRangeParams(payload, Url.chart.get)
    );

    const products: Set<string> = new Set();
    const data = Object.keys(rawData).map((date) => {
      const productsData = {};
      Object.keys(rawData[date]).forEach((productName) => {
        if (productName !== ALL_PRODUCTS) products.add(productName);
        productsData[productName] = rawData[date][productName];
      });
      return {
        date: dayjs(date).unix() * 1000,
        data: {
          ...productsData
        }
      };
    });
    const result = {
      percent,
      value: val
    };
    yield put(getChartDataSuccess({ data, products: Array.from(products) }));
    yield put(getResultSuccess(result));
  } catch (e) {
    const { code } = e;
    const unauthorized = isUnauthorized(code);
    yield put(unauthorized || setChartError(e));
  }
}

export function* chartData() {
  yield takeLatest(getChartDataRequest.toString(), fetchChartData);
  yield takeLatest(setChartDateRange.toString(), fetchChartData);
}
