import { IDateRange } from 'interfaces';

export function getRange(
  range: IDateRange,
  dateBorders: IDateRange
): IDateRange {
  const { from, to } = range;
  if (from && to) {
    return {
      to,
      from
    };
  }
  return dateBorders;
}
