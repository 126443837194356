import {
  createSlice,
  PayloadAction,
  combineReducers
} from '@reduxjs/toolkit';
import size from 'lodash/size';

import { IChartValue } from 'interfaces/chart';
import { Money } from 'entities/money';
import { Percents } from 'entities/percents';
import { ALL_PRODUCTS } from 'constants/products';
import { IDateRange } from 'interfaces';

import { IResult } from './interfaces';

/* данные для графика на странице портфолио */
export const chartDataSlice = createSlice({
  name: 'chartData',
  initialState: [] as IChartValue[],
  reducers: {
    getChartDataRequest: (state) => state,
    getChartDataSuccess: (
      state,
      action:PayloadAction<{ data: IChartValue[], products: string[] }>
    ) => {
      state = action.payload.data
      return state
    },
  }
})
export const {
  getChartDataRequest,
  getChartDataSuccess
} = chartDataSlice.actions

/* статус loader-a в виджете с графиком
 (нужен при изменении интервалов дат на странице портофило и пифов) */
export const chartDataLoaderSlice = createSlice({
  name: 'chartDataLoader',
  initialState: true,
  reducers: {
    setLoader: () => true
  },
  extraReducers: (builder) => {
    builder.addCase(getChartDataSuccess, () => false)
    builder.addCase(getChartDataRequest, () => true)
    builder.addCase(requestSharesData, () => true)
    builder.addCase(successSharesData, () => false)
  }
})
export const { setLoader } = chartDataLoaderSlice.actions

/* список продуктов для легенды */
export const productListForLegendSlice = createSlice({
  name: 'productListForLegend',
  initialState: [] as string[],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getChartDataSuccess,
      (state, action: PayloadAction<{ data: IChartValue[]; products: string[] }>) => {
        state = action.payload.products
        return state
      }
    )
  }
})

/* активные продукты в легенде */
export const activeProductsSlice = createSlice({
  name: 'activeProducts',
  initialState: [ALL_PRODUCTS] as string[],
  reducers: {
    setActiveProduct: (state, action: PayloadAction<string>) => {
      if (action.payload === ALL_PRODUCTS) {
        return state
      }
      if (state.includes(action.payload)) {
        state = state.filter((el) => el !== action.payload);
        return state;
      }
      state.push(action.payload)
      return state
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChartDataSuccess, (state, action) => {
      if (action.payload) {
        const { products } = action.payload;
        if (size(products) === 1) {
          state = [products[0]]
          return state;
        }
      }
      state = [ALL_PRODUCTS, ...action.payload.products]
      return state;
    })
  }
})
export const { setActiveProduct } = activeProductsSlice.actions

/* слайс с результатом для графика (%/валюта) */
export const resultSlice = createSlice({
  name: 'result',
  initialState: {
    value: new Money(0),
    percent: new Percents(0)
  } as IResult,
  reducers: {
    getResultSuccess: (state, action) => {
      state.percent = new Percents(action.payload.percent)
      state.value = new Money(parseFloat(action.payload.value))
      return state
    }
  }
})
export const { getResultSuccess } = resultSlice.actions

/* данные для графика стоимости пая (страница пиф продукта) */
export const sharesDataSlice = createSlice({
  name: 'sharesData',
  initialState: [] as IChartValue[],
  reducers: {
    requestSharesData: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ productId: string, range: IDateRange }>
    ) => state,
    successSharesData: (state, action: PayloadAction<{ data: IChartValue[] }>) => {
      state = action.payload.data
      return state
    },
  }
})
export const { requestSharesData, successSharesData } = sharesDataSlice.actions

/* slice для ошибок */
export const errorsSlice = createSlice({
  name: 'errors',
  initialState: {},
  reducers: {
    setChartError: (state, action) => {
      state = action.payload
      return state
    }
  }
})
export const { setChartError } = errorsSlice.actions

export const chartReducer = combineReducers({
  chartData: chartDataSlice.reducer,
  chartDataLoader: chartDataLoaderSlice.reducer,
  shareChartData: sharesDataSlice.reducer,
  activeProducts: activeProductsSlice.reducer,
  result: resultSlice.reducer,
  productListForLegend: productListForLegendSlice.reducer,
});
