import React from 'react';

import { Icon } from 'assets/img/svg';

// eslint-disable-next-line import/no-cycle
import { SuspenseWorksUser } from './user';
// eslint-disable-next-line import/no-cycle
import { SuspenseWorksGuest } from './guest';

import S from '../errorStyles';

interface IEngineeringWorksErrorProps {
  // eslint-disable-next-line react/no-unused-prop-types
  dateOfCompletion: Date;
  message?: {
    event: string;
    head: string;
    body: string;
    link: string;
  };
}

export default ({ message }: IEngineeringWorksErrorProps) => {
  const {
    event,
    head,
    body,
    link
  } = message ||
    {
      event: '',
      head: '',
      body: '',
      link: ''
    };

  return (
    <>
      <S.Title>{event}</S.Title>
      <S.Title>{head}</S.Title>
      <S.Icon>
        <Icon.EngineeringWorks />
      </S.Icon>
      <S.Description>
        {body}
      </S.Description>
      <br />
      <br />
      {link && <a href={link} className="link">Подробнее</a>}
    </>
  );
};

export {
  SuspenseWorksGuest,
  SuspenseWorksUser,
  IEngineeringWorksErrorProps
};
