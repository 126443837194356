import { FieldTypes } from 'enums/fieldTypes';

export const getSignByType = (value: number, type: FieldTypes) => {
  switch (type) {
    case FieldTypes.common: {
      if (value < 0) {
        return '– ';
      }
      return '';
    }
    case FieldTypes.diff: {
      if (value > 0) {
        return '+ ';
      }
      if (value < 0) {
        return '– ';
      }
      return '';
    }
    default:
      return '';
  }
};
export const getFormatNumberValue = (value: number, type: FieldTypes, notSign?: boolean) => {
  if (!value) {
    return '0,00';
  }
  const sign = !notSign ? getSignByType(value, type) : '';

  const formattedValue = Math.abs(value).toLocaleString('ru-Ru', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

  return `${sign}${formattedValue}`;
};
