import { Currency, FieldTypes } from 'enums';
import { getFormatNumberValue } from 'helpers/field';

export class Money {
  private readonly value: number;

  private course: number;

  private readonly zero: boolean;

  private currency: Currency;

  constructor(
    value: number,
    currency: Currency = Currency.rouble,
    course: number = 1,
    zero: boolean = false
  ) {
    this.value = value;
    this.course = course;
    this.currency = currency;
    this.zero = zero;
    this.changeCurrency(currency, course);
  }

  get = () => this.value / this.course;

  getZero = () => this.value;

  getCourse = () => this.course;

  getCurrency = () => this.currency;

  toString = (
    type: FieldTypes = FieldTypes.common,
    showCurrency: boolean = true
  ) => `${getFormatNumberValue(this.zero ? this.getZero() : this.get(), type)} ${
    showCurrency ? this.currency : ''
  }`

  changeCurrency = (newCurrency: Currency, course: number) => {
    this.currency = newCurrency;
    this.course = course;
  }
}
