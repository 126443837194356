import { createSlice } from '@reduxjs/toolkit';

import { Currency } from 'enums';

export const currencySlice = createSlice({
  name: 'currency',
  initialState: Currency.rouble,
  reducers: {
    switchCurrency: (state, action) => {
      state = action.payload
      return state
    }
  }
})
export const { switchCurrency } = currencySlice.actions
