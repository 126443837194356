import React from 'react';

import { ITextFieldProps } from './interfaces';

import S from '../styles';

export const TextField = ({
  value,
  placeholder,
  styles,
  isCheckClientData
}: ITextFieldProps) => (
  <S.TextField
      isCheckClientData={isCheckClientData}
      hasPlaceholder={!!placeholder}
      style={styles}
  >
    <span className="placeholder">{placeholder && placeholder}</span>
    <span className="value">{value && value}</span>
  </S.TextField>
);
