import React from 'react';

import { useStore } from 'components/hooks/useStore';
import { CommonTooltip, Common } from 'components/shared';
import { PHONE_LINK } from 'constants/phone';
import { getAppeal } from 'helpers/form';

import { Title, Text } from './styles';

const { LinkStyled } = Common

export const UserError = ({ isShow = false }: { isShow?: boolean }) => {
  const {
    purchase: {
      data: {
        firstName,
        lastName,
        patronymic,
        gender: storeGender
      }
    }
  } = useStore()
  const userName = `${lastName || ''} ${firstName || ''} ${patronymic || ''}`.trim() || 'пользователь'
  const gender = storeGender || undefined;
  return (
    <CommonTooltip
      isShow={isShow}
      onClose={() => undefined}
      showCloseButton={false}
    >
      <Title>{getAppeal(gender, userName)}</Title>
      <Text>
        Для продолжения оформления, пожалуйста, обратитесь в&nbsp;офис ООО
        &laquo;УК&nbsp;ВЕЛЕС&nbsp;Менеджмент&raquo;.<br /> При возникновении
        вопросов свяжитесь с&nbsp;нами по&nbsp;телефону: {PHONE_LINK}
      </Text>
      <LinkStyled to="/portfolio">
        Вернуться на главную
      </LinkStyled>
    </CommonTooltip>
  );
};
