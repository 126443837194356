import React from 'react';

import { FormField } from 'entities/formField';
import { IWizardForm } from 'interfaces';
import { Url } from 'api/url';

import { OrderDetails } from './orderDetails';

const header = new FormField(
  {
    name: 'orderDetails',
    type: 'content',
    placeholder: '',
    initialValue: ''
  },
  {
    content: <OrderDetails />
  }
);

const investSum = new FormField(
  {
    name: 'investSum',
    placeholder: 'Укажите сумму инвестирования',
    type: 'money',
    initialValue: '0'
  },
  { isRequired: true }
);

export const additionalPurchase: IWizardForm = {
  title: '',
  fields: [header, investSum],
  url: Url.rebuy.submit,
  submitTitle: 'Оплатить'
};
