import React from 'react';

export function getRefHeight(ref: React.RefObject<HTMLElement>): number {
  if (ref.current) {
    return ref.current.offsetHeight;
  }
  return 0;
}

export function scrollIntoRef(
  ref: React.RefObject<HTMLElement>,
  block: 'start' | 'center' | 'end' | 'nearest' = 'start',
  smooth: boolean = true
): void {
  if (ref && ref.current) {
    ref.current.scrollIntoView({
      block,
      behavior: smooth ? 'smooth' : 'auto'
    });
  }
}
