import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState: true,
  reducers: {
    /* получение данных о сonfirmation */
    requestConfirmationCheck: (state) => state,
    successConfirmationCheck: (state, action: PayloadAction<boolean>) => action.payload,
    errorConfirmationCheck: (state, action: PayloadAction<boolean>) => action.payload,
    /* изменение статуса сonfirmation */
    agreeConfirmationRequest: (state) => state,
    agreeConfirmationError: (state, action: PayloadAction<boolean>) => action.payload,
  }
})
export const {
  agreeConfirmationError,
  agreeConfirmationRequest,
  successConfirmationCheck,
  requestConfirmationCheck,
  errorConfirmationCheck
} = confirmationSlice.actions
