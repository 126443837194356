import {
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { api } from 'api';
import { IAction } from 'interfaces';
import { Url } from 'api/url';
import { getRecognitionHeaders } from 'api/options';
import { PurchaseTypes } from 'enums/purchaseTypes';

import { getHost } from '../purchase';
import {
  recognitionSmsCheckError,
  recognitionSmsCheckRequest,
  recognitionSmsCheckSuccess,
  recognitionSmsSendError,
  recognitionSmsSendRequest,
  recognitionSmsSendSuccess
} from '../../../reducers/qualifiedStatusData';

/* сага для смс с подтверждением отправки доков на квал инвестора */
function* sendCodeForSubmitData() {
  try {
    const url = `${Url.sms.sendCode}/${PurchaseTypes.recognition}`

    yield call(
      api.post,
      { url },
      getRecognitionHeaders()
    );
    yield put(recognitionSmsSendSuccess());
  } catch ({ code, payload }) {
    console.log(payload)
    yield put(recognitionSmsSendError());
  }
}

function* checkCodeForSubmitData({ payload }: IAction<{ code: string }>) {
  try {
    const url = `${Url.sms.checkCode}/${PurchaseTypes.recognition}`
    const { host } = getHost(url);

    const params = {
      host,
      url,
      data: { code: payload }
    };
    yield call(api.post, params, getRecognitionHeaders());
    yield put(recognitionSmsCheckSuccess());
  } catch (error) {
    yield put(recognitionSmsCheckError(error.message))
  }
}

export function* recognitionQualifiedStatusSms() {
  yield takeLatest(recognitionSmsSendRequest.toString(), sendCodeForSubmitData);
  yield takeLatest(recognitionSmsCheckRequest.toString(), checkCodeForSubmitData);
}
