import styled from 'styled-components';

export const PaymentContainer = styled.div`
  position: relative;
  height: 800px;
  width: 100%;
  .payment {
    &-loader {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
      z-index: 0;
    }
    &-frame {
      z-index: 1;
      position: relative;
    }
  }
`;
