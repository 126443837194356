import React from 'react';

import { Common } from 'components/shared';
import { ISuggestionResponse } from 'helpers/dadata/interfaces';

import S from './styles';

const { ExtraText } = Common

/* todo почему здесь интерфейс */
interface IOwnProps {
  suggestions: ISuggestionResponse[];
  onClick: (suggestion: ISuggestionResponse) => void;
  active: number;
  setActive: (index: number) => void;
  isShow?: boolean;
}

export const Suggestions = ({
  suggestions,
  onClick,
  isShow,
  active,
  setActive
}: IOwnProps) => {
  const handleClick = (suggestion: ISuggestionResponse, index: number) => {
    onClick(suggestion);
    setActive(index);
  };
  return (
    <S.Suggestions isShow={isShow}>
      {suggestions.map((suggestion, index) => {
        if (suggestion) {
          return (
            <S.Suggestion
              key={`${suggestion} ${index}`}
              onClick={() => handleClick(suggestion, index)}
              onMouseEnter={() => setActive(index)}
              isActive={active === index}
            >
              <ExtraText>{suggestion.textSuggestion}</ExtraText>
            </S.Suggestion>
          );
        }
        return null;
      })}
    </S.Suggestions>
  );
};
