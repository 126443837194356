import styled from 'styled-components';

import StylesInput from 'components/shared/input/styles';
import passwordStyles from 'components/shared/input/password/styles';
import { media } from 'assets/styles/theme';
import { Common } from 'components/shared';

import { Stages } from './stages';

const { Input } = StylesInput;

const Form = styled.form``;
const {
  Button,
  Panel,
  LinkText
} = Common

const RowButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile} {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  ${Button} {
    height: 52px;
    width: 118px;
    padding: 0;
    justify-content: center;
    align-items: center;

    ${media.mobile} {
      width: 100%;
    }

    & + button {
      margin-left: 20px;

      ${media.mobile} {
        margin-left: 0;
        margin-bottom: 15px;
      }
    }
  }
`;

interface ILoginPanelProps {
  stage: Stages
}
const LoginPanel = styled(Panel)<ILoginPanelProps>`
  width: 100%;
  max-width: 570px;
  height: 520px;
  padding: 57px 0 67px 0;

  ${media.tablet} {
    max-width: ${({ stage }) => ([Stages.login, Stages.loginSms].indexOf(stage) >= 0 ? 342 : 464)}px;
    height: auto;
    align-self: flex-start;
    padding: 29px 26px 37px;

    h1 {
      font-size: 18px;
      text-align: center;
      font-weight: 600;
    }

    button {
      ${({ stage }) => (stage === Stages.login ? 'width: 100%;' : '')};
    }
  }

  ${media.mobile} {
    max-width: none;
    padding: 30px 15px;

    h1 {
      font-size: 16px;
    }

    button {
      width: 100%;
    }
  }

  ${Form} {
    width: 100%;
    max-width: 370px;
    margin-top: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ${media.tablet} {
      margin-top: 30px;
    }

    ${media.mobile} {
      max-width: none;
    }
  }

  ${Input} {
    margin-bottom: 50px;

    ${media.desktop} {
      width: 370px;
    }

    ${media.tablet} {
      margin-bottom: 68px;
    }

    ${media.mobile} {
      margin-bottom: 50px;
    }
  }

  ${passwordStyles.InputPassword} {
    ${Input} {
      ${media.tablet} {
        margin-bottom: 27px;
      }
    }
  }
`;

const Text = styled.div`
  text-align: center;
  max-width: 450px;
  margin-bottom: 27px;
`;

const Registration = styled(LinkText)`
  margin-top: 30px;
`;
export default {
  LoginPanel,
  Form,
  RowButtons,
  Registration,
  Text
};
