import styled from 'styled-components';

import { media } from 'assets/styles/theme';

const PieChart = styled.div`
  flex-shrink: 0;
  position: relative;
  z-index: 20;
  width: 100%;

  text {
    width: 60px;
    display: table-caption;
  }

  ${media.mobile} {
    top: 70px;
  }
  .recharts-surface {
    position: relative;
    overflow: visible;

    ${media.mobile} {
      border: none;
    }
  }
  
  .recharts-responsive-container, .recharts-wrapper {
    height: auto!important;
  }

  ${media.mobile} {
    .recharts-wrapper {
      height: auto !important;
    }
  }

  .pie-result {
    tspan {
      fill: ${({ theme }) => theme.colors.textPrimary};
      font-size: 16px;
    }
  }

  .recharts-tooltip-wrapper {
    z-index: 1;
  }

  .recharts-legend-wrapper {
    position: static!important;
    margin-top: -50px;
    margin-bottom: 50px;

    ${media.tabletOnly} {
      padding: 0 5px;
    }

    ${media.tablet} {
      padding: 0 15px;
      width: 100% !important;
    }
    
    ${media.mobile} {
      padding-bottom: 15px;
    }

  }
  .recharts-default-tooltip {
    border: none !important;
    padding: 13px 16px 0 16px !important;
    .recharts-tooltip-item {
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
      &-name {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.06px;
        line-height: 18px;
        color: ${({ theme: { colors: { description } } }) => description};
      }
      &-value {
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        text-align: center;
        &:after {
          content: "%";
        }
      }
      &-separator {
        display: none;
      }
    }
  }
`;
const COLORS = [
  '#CD7147',
  '#DC9B7D',
  '#EBC5B4',
  '#F5982E',
  '#F8B76C',
  '#FBD5A9'
];
const PieText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.textPrimary};
  .title {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .value {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`;
export default {
  PieChart,
  COLORS,
  PieText
};
