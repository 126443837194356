import { IField } from 'interfaces';

const inn: IField = {
  name: 'inn',
  type: 'text',
  placeholder: 'ИНН',
  initialValue: ''
};

export default inn;
