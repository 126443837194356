import styled, { css } from 'styled-components';

import { isUndefined } from 'lodash';

import { NoScrollThumbs } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';

import { Common } from 'components/shared';

const { Panel } = Common

interface IRecommendedProducts {
  inLk?: boolean;
}
const RecommendedProducts = styled.div<IRecommendedProducts>`
  ${({ inLk }) => (isUndefined(inLk)
    ? css`padding: 0 15px 0 50px;`
    : css`padding: 0 15px;`)
}
  max-height: 550px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow: scroll;
  ${NoScrollThumbs}
  ${media.tablet} {
    max-height: 600px;
    width: 100%;
    padding: 0 30px;
  }
  ${media.mobile} {
    padding: 0 15px;
  }
  ${Panel} {
    margin-bottom: 15px;
    
    &.hidden {
      display: none;
    }
    
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
      ${media.tablet} {
        margin-right: 30px;
      }
      ${media.mobile} {
        margin-right: 15px;
      }
    }
    ${media.tablet} {
      margin-bottom: 12px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  ${media.desktop} {
    width: 2782px;
    justify-content: center;
  }
  ${media.tablet} {
    width: auto;
    justify-content: flex-start;
  }
`;

export default { RecommendedProducts, Wrapper };
