import {
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { api } from 'api';
import { IAction } from 'interfaces';
import { Url } from 'api/url';
import { getClientDataHeaders } from 'api/options';
import { PurchaseTypes } from 'enums/purchaseTypes';

import { getHost } from '../purchase';
import {
  dataSmsCheckError,
  dataSmsCheckRequest,
  dataSmsCheckSuccess,
  dataSmsSendError,
  dataSmsSendRequest,
  dataSmsSendSuccess
} from '../../../reducers/checkAndApproveClientData';

function* sendCodeForPassportData() {
  try {
    const url = `${Url.sms.sendCode}/${PurchaseTypes.client_data_check}`

    yield call(
      api.post,
      { url },
      getClientDataHeaders()
    );
    yield put(dataSmsSendSuccess());
  } catch ({ code, payload }) {
    console.log(payload)
    yield put(dataSmsSendError());
  }
}

function* checkCodeForPassportData({ payload }: IAction<{ code: string }>) {
  try {
    const url = `${Url.sms.checkCode}/${PurchaseTypes.client_data_check}`
    const { host } = getHost(url);

    const params = {
      host,
      url,
      data: { code: payload }
    };
    yield call(api.post, params, getClientDataHeaders());
    yield put(dataSmsCheckSuccess());
  } catch (error) {
    yield put(dataSmsCheckError(error.message))
  }
}

export function* confirmClientPassportDataSms() {
  yield takeLatest(dataSmsSendRequest.toString(), sendCodeForPassportData);
  yield takeLatest(dataSmsCheckRequest.toString(), checkCodeForPassportData);
}
