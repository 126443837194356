import React, { useState } from 'react';

import { useLockBodyScroll } from 'components/hooks/useLockBodyScroll';
import {
  CommonTooltip,
  CustomScrollBox,
  Common
} from 'components/shared';

import { recommendation } from './recommendation';
import { IProps } from './interfaces';

import S from './styles';

const {
  Wrapper,
  Description,
  Title
} = S
const { Button } = Common

export const ConfirmationTooltip = ({ isShow, agreeConfirmation }: IProps) => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const title = 'Рекомендации по работе с личным кабинетом';
  useLockBodyScroll(isShow)
  return (
    <CommonTooltip
      data-test="confirmation-tooltip"
      isShow={isShow}
      onClose={() => null}
    >
      <Wrapper>
        <Title>{title}</Title>
        <CustomScrollBox setButtonDisabled={setButtonDisabled}>
          {recommendation}
        </CustomScrollBox>
        <Button disabled={isButtonDisabled} onClick={agreeConfirmation}>
          Подтверждаю ознакомление
        </Button>
        <Description>
          Чтобы кнопка стала активной – необходимо пролистать документ
        </Description>
      </Wrapper>
    </CommonTooltip>
  );
};
