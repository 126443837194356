import styled from 'styled-components';

import S from 'components/shared/commonTooltip/styles';
import { media } from 'assets/styles/theme';

const { Tooltip, Background } = S

const TableOptions = styled.div`
  display: flex;
  width: 26px;
  height: 26px;
  border: 1px solid ${({ theme }) => theme.colors.description};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  position: relative;

   ${media.tablet} {
    width: 38px;
    height: 38px;
    position: absolute;
    right: 30px;
    top: 30px;
  }

  ${media.mobile} {
    right: 15px;
    top: 15px;
  }

  ${Background} {
    position: absolute;
    background-color: transparent;
    overflow-y: visible;
  }

  ${Tooltip} {
    position: absolute;
    padding: 20px 23px;
    top: 25px;
    right: -5px;
  }
`;

const TableOption = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.textPrimary};
  text-align: left;
  margin: 7px 0;
  transition: .3s;

  :hover {
    opacity: .7;
  }
`;

export default { TableOptions, TableOption };
