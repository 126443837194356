import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import { api } from 'api';
import { Url } from 'api/url';
import { Token } from 'entities/token';
import { IAction, IRecognitionSubmitData } from 'interfaces';

import {
  errorSubmit,
  requestSubmit,
  successSubmit
} from '../../reducers/qualifiedStatusData';

/* saga для подачи доков на квал инвестора */
function* sendRecognition({ payload }: IAction<IRecognitionSubmitData>) {
  try {
    const params = {
      url: Url.recognition.submit,
      data: payload
    };
    // @ts-expect-error
    const response = yield call(
      api.post,
      params
    );
    const { token } = response;

    Token.set('recognition', token);
    yield put(successSubmit());
  } catch (e) {
    console.log(e.message)
    yield put(errorSubmit(e.message));
  }
}
export function* submitRecognition() {
  yield takeLatest(
    requestSubmit.toString(),
    sendRecognition
  );
}
