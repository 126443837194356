import { IPurchaseData, IFormFields } from 'interfaces';
import { getSuggestions } from 'helpers/dadata';

export enum addresses {
  registration = 'addressRegistration',
  post = 'addressPost'
}
export class PurchaseData {
  private data: IPurchaseData;

  private readonly rawData: IFormFields;

  constructor(fieldsData: IFormFields) {
    this.rawData = fieldsData;
    this.set(fieldsData);
  }

  get(): IPurchaseData {
    return { ...this.data };
  }

  set(fieldsData: IFormFields): void {
    this.data = {
      ...this.data,
      ...fieldsData
    };
    this.setPassportFields();
    this.setPurchase();
  }

  async setAddress(changedAddress: addresses) {
    if (this.rawData[changedAddress]) {
      const { addressString, city } = this.rawData.addressRegistration;
      if (addressString && !city) {
        const suggestions = await getSuggestions('address', {
          value: addressString
        });
        if (suggestions.length) {
          const address = suggestions[0].payload;
          this.data[changedAddress] = {
            ...this.rawData[changedAddress],
            ...address.addressRegistration
          };
        } else {
          this.data[changedAddress] = {
            addressString
          };
        }
      }
    }
  }

  private setPurchase() {
    const { purchase, investSum, existIISOtherCompany } = this.rawData;

    this.data.purchase = {
      productId: purchase ? purchase.productId : '',
      price: Number(`${investSum}`.replace(' ', ''))
    };
    this.data.existIISOtherCompany = existIISOtherCompany;
  }

  private setPassportFields(): void {
    if (this.rawData.passport) {
      if (this.rawData.passport.series_number) {
        const [series, number] = this.rawData.passport.series_number.split(' ');
        this.data = {
          ...this.data,
          passport: {
            ...this.data.passport,
            number,
            series
          }
        };
      }
    }
  }
}
