import React from 'react';

import { useDispatch } from 'react-redux';

import { RadioButtonContainer } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { Currency } from 'enums';
import { switchCurrency } from 'store/reducers/switchCurrency';

import S from './styles';

const radioButtons = [
  {
    label: '₽',
    value: 'rouble'
  },
  {
    label: '$',
    value: 'dollar'
  },
  {
    label: '€',
    value: 'euro'
  }
];

export const CurrencySwitch = () => {
  const { currency } = useStore()
  const dispatch = useDispatch()
  const setCurrency = (newCurrency: Currency) => dispatch(switchCurrency(newCurrency))
  const changeCurrency = (value: string) => setCurrency(Currency[value]);
  const selectedOption = () => {
    // eslint-disable-next-line default-case
    switch (currency) {
      case Currency.rouble:
        return 'rouble';
      case Currency.euro:
        return 'euro';
      case Currency.dollar:
        return 'dollar';
      case Currency.pound:
        return 'pound';
    }
  };
  return (
    <S.CurrencySwitch data-test="currency-switch-component">
      <RadioButtonContainer
        isCurrencySwitch
        options={radioButtons}
        onChange={(value) => changeCurrency(value)}
        selectedOption={selectedOption()}
      />
    </S.CurrencySwitch>
  );
};
