import styled from 'styled-components';

import { media } from 'assets/styles/theme';

export default styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 18px;
  height: 12px;
  cursor: pointer;
  margin-right: 15px;

  span {
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.textPrimary};
    transition: .3s;
  }

  &.active span:first-child {
    transform: rotateZ(45deg) translate(1px,6px);
  }

  &.active span:last-child {
    transform: rotateZ(-45deg) translate(1px,-6px);
  }

  &.active span:nth-child(2) {
    opacity: 0;
  }

  ${media.tablet} {
    display: flex;
  }
`;
