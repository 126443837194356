import React from 'react';

import { Link } from 'react-scroll';
import isEmpty from 'lodash/isEmpty';

import { ProductType } from 'enums';
import { getProductsByType } from 'helpers/products';
import { LoaderTooltip } from 'components/widgets/tooltips/loader';
import {
  ProductTable,
  PortfolioChartsPanel,
  RecommendedProducts
} from 'components/widgets';

import { NoProductOrReports } from '../errors';
import { PageInformation } from './pageInformation';
import { Notes } from '../../widgets/notes';
import { useStore } from '../../hooks/useStore';

import S from './styles';

const {
  Portfolio,
  Wrapper,
  Header,
  Title,
  SubTitle,
  Section,
  NewProductButton
} = S

export const PortfolioPage = () => {
  const {
    userProducts: { byId },
    userProducts,
    purse: purseData,
    edoExistStatus: { loaderStatus },
    confirmationExistStatus
  } = useStore()
  if (isEmpty(byId) && isEmpty(purseData) || loaderStatus || !confirmationExistStatus) {
    return <LoaderTooltip isShow description="Пожалуйста, подождите..." />;
  }

  const {
    mainTitle,
    changeAssets,
    recommendedProducts,
    trustFunds,
    mutualFunds,
    buttonName
  } = PageInformation;
  const mutualFund = [...getProductsByType(userProducts, ProductType.mutualFund),
    ...getProductsByType(userProducts, ProductType.closedMutualFund)]
  const trustFund = getProductsByType(userProducts, ProductType.trustFund)

  const noProducts = !isEmpty(mutualFund) || !isEmpty(trustFund);
  return (
    <Portfolio>
      {!noProducts
        ?
          <Wrapper>
            <NoProductOrReports text="На данный момент Ваш портфель пуст" />
          </Wrapper>
        :
          <>
            <Header>
              <Title>{mainTitle}</Title>
              <Link
                to="recommended-products"
                smooth
                duration={500}
              >
                <NewProductButton>{buttonName}</NewProductButton>
              </Link>
            </Header>
            {!isEmpty(mutualFund) &&
            <Section>
              <SubTitle isFundGroup>{mutualFunds}</SubTitle>
              <ProductTable products={mutualFund} />
            </Section>}
            {!isEmpty(trustFund) &&
            <Section>
              <SubTitle isFundGroup>{trustFunds}</SubTitle>
              <ProductTable isTrustFunds products={trustFund} />
            </Section>}
            <Section>
              <SubTitle>{changeAssets}</SubTitle>
              <PortfolioChartsPanel />
            </Section>
          </>}
      <Section id="recommended-products">
        <SubTitle isRecommended>{recommendedProducts}</SubTitle>
        <RecommendedProducts inLk />
      </Section>
      <Notes />
    </Portfolio>
  );
};
