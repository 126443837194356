import React from 'react';

import { CommonTooltip } from 'components/shared';
import { getAppeal } from 'helpers/form';
import { useStore } from 'components/hooks/useStore';

import { IAcquiringErrorOwnProps } from './interfaces';

import S from './styles';

const {
  Title,
  Text,
  Button
} = S

export const AcquiringError = ({
  isShow,
  errorMessage,
  repeat
}: IAcquiringErrorOwnProps) => {
  const {
    purchase: {
      data: {
        firstName,
        lastName,
        patronymic,
        gender
      }
    }
  } = useStore()
  const userName = `${firstName} ${patronymic || lastName}`;

  const appeal = getAppeal(gender, userName);

  const tooltipProps = {
    isShow,
    onClose: () => undefined
  };

  return (
    <CommonTooltip {...tooltipProps} showCloseButton={false}>
      <Title>{appeal}</Title>
      <Text>{errorMessage}</Text>
      <Button onClick={() => repeat()}>
        Попробовать еще раз
      </Button>
    </CommonTooltip>
  );
};
