import { IField } from 'interfaces';

const dayBirth: IField = {
  name: 'dayBirth',
  placeholder: 'Дата рождения',
  type: 'date',
  initialValue: ''
};
const placeBirth: IField = {
  name: 'placeBirth',
  placeholder: 'Место рождения',
  type: 'text',
  initialValue: ''
};

const birthDetails = { placeBirth, dayBirth };

export default birthDetails;
