import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';
import dayjs from 'dayjs';

import { Serializer } from 'api/serializer';
import { Url } from 'api/url';
import { getDateRangeParams } from 'helpers/actions/getDateRangeParams';
import { isUnauthorized } from 'helpers/actions/isUnauthorized';
import { DEFAULT_ERROR_MESSAGE } from 'constants/codeMessages';
import { api } from 'api';
import {
  IAction,
  IDateRange,
  IResponseProduct,
} from 'interfaces';

import { setDateBorders, setUserName } from '../../reducers/account';
import { setHeaderDateRange } from '../../reducers/dateRangeReducers';
import {
  requestPurse,
  successPurse,
  errorPurse
} from '../../reducers/purse';
import {
  errorUserProducts,
  requestUserProducts,
  successUserProducts
} from '../../reducers/products';
import { setCourses } from '../../reducers/currencyCourses';

interface IAccountResponse {
  account: {
    name: string;
    dateBorders: { from: string; to: string };
    firstname: string
    lastname: string
    patronymic: string
  },
  currencies: {
    dollar: string,
    euro: string
  },
  portfolio: { [key: number]: IResponseProduct }
  purse: {
    currentValue: number,
    inputValue: number,
    outputValue: number,
    result: {
      percent: number,
      value: number
    }
  }
}

function* getPortfolio(action: IAction<IDateRange>) {
  try {
    const { payload } = action;
    const response: IAccountResponse = yield call(
      api.get,
      getDateRangeParams(payload, Url.portfolio.get)
    );
    const {
      purse,
      portfolio: products,
      account,
      currencies: currencyCourses
    } = response;
    const { dollar, euro } = currencyCourses;
    yield put(setCourses(
      {
        '~': parseFloat(dollar),
        $: parseFloat(dollar),
        '£': 1,
        '€': parseFloat(euro)
      }
    ));
    yield put(successPurse(Serializer.purse(purse)));
    yield put(successUserProducts(Serializer.products(products)));
    const { name, dateBorders } = account;
    /* строки с датами необходимо завернуть в dayjs, т.к. в противном случае
     неверно считаются интервалы при выборе данных за определенный период времени
      в виджетах с графиками (страницы продукта и портфолио)) */
    yield put(
      setDateBorders({
        from: new Date(dayjs(dateBorders.from).toString()),
        to: new Date(dayjs(dateBorders.to).toString())
      })
    );
    yield put(setUserName(name));
  } catch (e) {
    const { code } = e;
    const unauthorized = isUnauthorized(code);
    yield put(unauthorized || errorPurse(DEFAULT_ERROR_MESSAGE));
    yield put(errorUserProducts(e.message));
  }
}

export function* portfolio() {
  yield takeLatest(requestPurse.toString(), getPortfolio);
  yield takeLatest(requestUserProducts.toString(), getPortfolio);
  yield takeLatest(setHeaderDateRange.toString(), getPortfolio);
}
