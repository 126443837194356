import React from 'react';
import { Field, ErrorMessage } from 'formik';

import StylesInput from '../input/styles';
import { IProps } from './interfaces';

import S from './styles';

const { Error } = StylesInput;

export const CheckBox = ({
  checked,
  placeholder,
  name,
  ...rest
}: IProps) => (
  <S.Checkbox checked={checked} tabIndex={0}>
    <Field name={name} type="checkbox" {...rest} />
    {placeholder}
    <ErrorMessage name={name} component={Error} />
  </S.Checkbox>
);
