import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Colors } from 'constants/colors';
import { Toggler } from 'components/shared';
import { getChartDataRequest, requestSharesData } from 'store/reducers/chart';

import { Header } from '../portfolioCharts/header';
import { AreaChart } from '../charts';
import { useStore } from '../../hooks/useStore';
import S from '../../shared/commonStyles/chartsPanel/styles';

import { IOwnProps } from './interfaces';

export const ProductAreaChart = ({ productId }: IOwnProps) => {
  const [chartState, setChartState] = useState('actives')
  const {
    chartsDateRange: range,
    chart: {
      chartData,
      shareChartData,
    },
    userProducts
  } = useStore()
  const productName = userProducts.byId[productId].name
  const dispatch = useDispatch()
  useEffect(() => {
    if (isEmpty(chartData)) {
      dispatch(getChartDataRequest())
    }
  }, [])
  useEffect(() => {
    if (chartState === 'shares') {
      dispatch(requestSharesData({ productId, range }))
    }
  }, [chartState])
  const changePays = () => setChartState('shares')
  const changeActivates = () => setChartState('actives')
  const currentChartData = chartState === 'actives'
    ? chartData
    : shareChartData;
  return (
    <S.AreaChart>
      <div className="chart-header">
        <S.Title>Изменение стоимости активов</S.Title>
        <S.ToggleState>
          <button
            type="button"
            className={classnames({ active: chartState === 'actives' })}
            onClick={() => changeActivates()}
          >
            Стоимость активов
          </button>
          <Toggler
              dotState={chartState === 'shares'}
              onClick={() => {
                if (chartState === 'shares') {
                  changeActivates();
                } else {
                  changePays();
                }
              }}
          />
          <button
              type="button"
              className={classnames({ active: chartState === 'shares' })}
              onClick={() => changePays()}
          >
            Стоимость пая
          </button>
        </S.ToggleState>
        {range &&
          <Header
            isProductWidget
            isChartWidget
            withoutResult
            withoutRange={chartState === 'shares'}
          />}
      </div>
      <AreaChart
          activeProducts={[productName]}
          data={currentChartData}
          colors={[Colors[0]]}
          showAllResult={false}
      />
    </S.AreaChart>
  );
}
