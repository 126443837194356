import React, { useState } from 'react';

import { Icon } from 'assets/img/svg';

import { Input } from '../index';
import { IInputProps } from '../intefaces';

// eslint-disable-next-line import/no-cycle
import { IInputPasswordProps } from './interfaces';

import S from './styles';

export enum PasswordStates {
  empty = 'transparent',
  insecure = 'red',
  simple = '#F4860A',
  secure = '#779C48'
}

export const InputPassword = (props: IInputProps & IInputPasswordProps) => {
  const [type, setType] = useState(true);
  const {
    onClickRestore,
    passwordState,
    passwordStatePercentage
  } = props;

  return (
    <S.InputPassword className={onClickRestore ? 'with-restore' : ''}>
      <Input {...props} type={type ? 'password' : 'text'} />
      {onClickRestore
        ? (
          <S.Restore
            type="button"
            onClick={() => onClickRestore()}
          >
            Забыли пароль?
          </S.Restore>)
        : null}
      <S.Payload>
        <button onClick={() => setType(!type)} type="button" tabIndex={0}>
          <S.Icon>
            {type
              ? <Icon.EyeOpen />
              : <Icon.EyeClose />}
          </S.Icon>
        </button>
      </S.Payload>
      {passwordState && passwordStatePercentage
        ? (
          <S.Indicator
            color={passwordState}
            percentage={passwordStatePercentage}
          />)
        : null}
    </S.InputPassword>
  );
};
