import React from 'react';

export const InnHint = () => (
  <a
    target="_blank"
    href="https://service.nalog.ru/static/personal-data.html?svc=inn&from=%2Finn.do"
    rel="noreferrer"
  >
    Узнать ИНН
  </a>
);
