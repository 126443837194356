import React from 'react';

import { Common } from '../../../shared';

const { ExtraText, DescriptionText } = Common

export const recommendation = (
  <ExtraText>
    <p>
      <DescriptionText>
        Рекомендации
        по защите информации от воздействия программных кодов, приводящих к
        нарушению нормального функционирования вычислительной техники, в целях
        противодействия незаконным финансовым операциям
      </DescriptionText>
    </p>
    <span style={{ color: 'grey' }}>
      <p>
        В соответствие с требованиями Положения Банка России от 17.04.2019 № 684-П
        «Об установлении обязательных для некредитных финансовых организаций
        требований к обеспечению защиты информации при осуществлении деятельности
        в сфере финансовых рынков в целях противодействия осуществлению незаконных
        финансовых операций» ООО «УК ВЕЛЕС Менеджмент» (далее по тексту - Компания)
        доводит до вашего сведения основные рекомендации по защите информации от
        воздействия программных кодов, приводящих к нарушению штатного функционирования
        средств вычислительной техники (вредоносный код), в целях противодействия
        незаконным финансовым операциям.
      </p>
      <p>
        Рекомендации по соблюдению информационной безопасности (совокупности мер,
        применение которых направлено на непосредственное обеспечение защиты
        информации, процессов, ресурсного и организационного обеспечения, необходимого
        для применения указанных мер защиты (здесь и далее термины из ГОСТ Р
        57580.1-2017) не гарантируют обеспечение конфиденциальности, целостности и
        доступности информации, но позволяют в целом снизить риски информационной
        безопасности и минимизировать возможные негативные последствия в случае их реализации.
      </p>
      <p>
        В связи с тем, что требования информационной безопасности так же могут быть отражены
        в договорах, регламентах, правилах и иных документах Компании, регламентирующих
        предоставление услуг/сервисов, настоящие рекомендации действуют в части не
        противоречащей положениям внутренних документов.
      </p>
      <p>
        В целях снижения риска реализации инцидентов информационной безопасности
        (ГОСТ Р 57580.1-2017) – нежелательные или неожиданные события защиты информации,
        которые могут привести к риску нарушения выполнения бизнес-процессов (клиента),
        технологических процессов Компании и (или) нарушить конфиденциальности, целостности
        и доступности информации вследствие:
      </p>
      <ul>
        <li>
          несанкционированного доступа к вашей информации лицами, не обладающими правом
          осуществления значимых (критичных) операций (в т.ч. финансовых);
        </li>
        <li>
          потери (хищения) носителей ключей электронной подписи, с использованием которых,
          осуществляются критичные (финансовые) операции;
        </li>
        <li>
          воздействия вредоносного кода на устройства, с которых совершаются критичные
          (финансовые) операции;
        </li>
        <li>
          совершения в отношении вас иных противоправных действий, связанных с
          информационной безопасностью.
        </li>
      </ul>
      <p>
        Рекомендуется соблюдать ряд профилактических мероприятий, направленных на повышение
        уровня информационной безопасности при использовании объектов информатизации
        (совокупности объектов, ресурсов, средств и систем обработки информации, в т.ч.
        автоматизированных систем, используемых для обеспечения информатизации бизнес-процессов
        (ГОСТ Р 57580.1-2017) Компании.
      </p>
      <p>
        Внимательно изучите договор, приложения к договору и иные документы, связанные с
        исполнением договора, ознакомьтесь с разделами, посвященными информационной
        безопасности/конфиденциальности.
      </p>
    </span>
    <hr style={{ color: '#EDECE6' }} />
    <ul style={{ listStyleType: 'upper-roman' }}>
      <li>
        <p>
          При осуществлении критичных (финансовых) операций следует принимать во внимание
          риск получения третьими лицами несанкционированного доступа к защищаемой информации
          с целью осуществления финансовых операций лицами, не обладающими правом их
          осуществления, такие риски могут быть обусловлены включая, но не ограничиваясь
          следующими примерами:
        </p>
        <ul>
          <li>
            кража пароля и идентификатора доступа или иных конфиденциальных данных, например,
            CVV/CVC номера карты, ключей электронной подписи/шифрования посредством
            технических средств и/или вредоносного кода, и использование злоумышленниками
            указанных данных с других устройств для несанкционированного доступа;
          </li>
          <li>
            технических средств и/или вредоносного кода, и использование злоумышленниками
            указанных данных с других устройств для несанкционированного доступа;
          </li>
          <li>
            использования злоумышленником утерянного или украденного телефона (SIM-карты)
            для получения СМС-кодов, которые могут применяться Компанией в качестве простой
            электронной подписи или дополнительной защиты для несанкционированных финансовых
            операций, что позволит им обойти защиту;
          </li>
          <li>
            кража или несанкционированный доступ к устройству, с которого вы пользуетесь
            услугами/сервисами Компании для получения данных и/или несанкционированного
            доступа к сервисам Компании с этого устройства;
          </li>
          <li>
            получение пароля и идентификатора доступа и/или кода из СМС и/или кодового
            слова и прочих конфиденциальных данных, в т.ч. паспортных данных, номеров счетов
            и т.д. путем обмана и/или злоупотребления доверием, когда злоумышленник
            представляется сотрудником Компании  или техническим специалистом, или
            использует иную легенду и просит вас сообщить ему эти секретные данные; или
            направляет поддельные сообщения по электронной почте или письмо по обычной
            почте с просьбой предоставить информацию или совершить действие, которое
            может привести к компрометации устройства;
          </li>
          <li>
            перехват электронных сообщений и получения несанкционированного доступа к
            выпискам, отчетам и прочей финансовой информации, если ваша электронная почта
            используется для информационного обмена с Компанией. Или в случае получения
            доступа к вашей электронной почте, отправка сообщений от вашего имени в Компанию.
          </li>
        </ul>
      </li>
      <li>
        <p>
          Для снижения риска финансовых потерь:
        </p>
        <ul style={{ listStyleType: 'upper' }}>
          <li>
            <p>
              Обеспечьте защиту устройства, с которого вы пользуетесь услугами Компании,
              к таким мерам включая, но не ограничиваясь могут быть отнесены:
            </p>
            <ul>
              <li>
                использование только лицензионного программного обеспечения,
                полученного из доверенных источников;
              </li>
              <li>
                запрет на установку программ из непроверенных источников;
              </li>
              <li>
                наличие средства защиты, таких как: антивирус (с регулярно и
                своевременно обновляемыми базами), персональный межсетевой экран;
              </li>
              <li>
                настройка прав доступа к устройству с целью предотвращения
                несанкционированного доступа;
              </li>
              <li>
                обеспечение защиты устройства от рисков кражи и/или утери;
              </li>
              <li>
                своевременные обновления операционной системы, особенно в части
                обновлений безопасности. Имейте в виду, что обновления снижают
                риски заражения вредоносным кодом. Злоумышленники часто используют
                старые уязвимости;
              </li>
              <li>
                активация парольной или иной защиты для доступа к устройству.
              </li>
            </ul>
          </li>
          <li>
            <p>
              Обеспечьте конфиденциальность:
            </p>
            <ul>
              <li>
                храните в тайне аутентификационные/идентификационные данные и ключевую
                информацию, полученные от Компании: пароли, СМС-коды, кодовые слова,
                ключи электронной подписи/шифрования, а в случае компрометации -
                немедленно примите меры для смены и/или блокировки;
              </li>
              <li>
                соблюдайте принцип разумного раскрытия информации о номерах счетов, о
                ваших паспортных данных, о номерах кредитных и дебетовых карт, о CVC/CVV
                кодах, в случае если у вас запрашивают указанную информацию, в привязке
                к услугам Компании, по возможности, оцените ситуацию и уточните полномочия
                и процедуру через независимый канал, например, через телефон
                контакт-центра Компании.
              </li>
            </ul>
          </li>
          <li>
            <p>
              Проявляйте осторожность и предусмотрительность:
            </p>
            <ul>
              <li>
                будьте осторожны при получении электронных писем со ссылками и вложениями
                - они могут привести к заражению вашего устройства вредоносным кодом.
                Вредоносный код, попав на ваше устройство через электронную почту или
                интернет-ссылку, может получить доступ к любым данным и информационным
                системам на вашем устройстве;
              </li>
              <li>
                внимательно проверяйте адресата, от которого пришло электронное письмо.
                Входящее электронное письмо может быть от злоумышленника, который
                маскируется под Компанию или иных доверенных лиц;
              </li>
              <li>
                будьте осторожны при просмотре/работе с интернет-сайтами, так как
                вредоносный код может быть загружен с сайта;
              </li>
              <li>
                будьте осторожны с файлами из новых или «недоверенных» источников (в т.ч.
                архивы с паролем, зашифрованные файлы/архивы, т.к. такого рода файлы не
                могут быть проверены антивирусным программным обеспечением в автоматическом
                режиме);
              </li>
              <li>
                не заходите в системы удаленного доступа с недоверенных устройств, которые
                вы не контролируете. На таких устройствах может быть вредоносный код,
                собирающий пароли и идентификаторы доступа или способный подменить операцию;
              </li>
              <li>
                следите за информацией в прессе и на сайте Компании о последних критичных
                уязвимостях и о вредоносном коде;
              </li>
              <li>
                при обращении в Компанию осуществляйте звонок только по номеру телефона,
                указанному в договоре или на официальном сайте Компании. И имейте в виду,
                что от лица Компании не могут поступать звонки или сообщения, в которых от
                вас требуют передать СМС-код, пароль, номер карты, кодовое слово и т.д.
                Кодовое слово может быть запрошено только, если вы сами позвонили в контакт-центр;
              </li>
              <li>
                имейте в виду, что, если вы передаете ваш телефон и/или устройство другим
                пользователям, они могу установить на него вредоносный код, а в случае кражи
                или утери - злоумышленники могут воспользоваться им для доступа к системам
                Компании, которыми пользовались вы. В связи с этим, при утере, краже телефона
                или SIM-карты, используемых для получения СМС-кодов или доступа к системам
                Компании с мобильного приложения: 1) незамедлительно проинформировать Компанию
                через контактный центр, 2) целесообразно по возможности оперативно с учетом
                прочих рисков и особенностей использования вашего телефона заблокировать и
                перевыпустить SIM-карту, а также сменить пароль в мобильном приложении;
              </li>
              <li>
                при подозрении на несанкционированный доступ и/или компрометацию устройства
                необходимо сменить пароль, воспользовавшись другим доверенным устройством и/или
                заблокировать доступ, обратившись в Компанию; в отношении ключевой информации,
                если это уместно для вашей услуги – аннулировать скомпрометированный ключ
                электронной подписи/шифрования, в соответствии с правилами, отраженными в
                договоре, приложениях к договору и иных документах, связанных с
                исполнением договора;
              </li>
              <li>
                помните, что наличие «эталонной» резервной копии может облегчить и ускорить
                восстановление вашего устройства;
              </li>
              <li>
                лучше всего использовать для финансовых операций отдельное, максимально
                защищенное устройство, доступ к которому есть только у вас;
              </li>
              <li>
                не отключайте и не взламывайте встроенные механизмы безопасности устройства;
              </li>
              <li>
                не устанавливайте и не используйте программы для удаленного
                управления устройством;
              </li>
              <li>
                контролируйте свой телефон, используемый для получения СМС-кодов. В случае
                выхода из строя SIM-карты, незамедлительно обращайтесь к сотовому оператору
                для уточнения причин и восстановления связи;
              </li>
              <li>
                устанавливайте пароль на SIM-карту.
              </li>
            </ul>
          </li>
          <li>
            <p>
              При работе с ключами электронной подписи необходимо:
            </p>
            <ul>
              <li>
                использовать для хранения ключей электронной подписи внешние носители,
                настоятельно рекомендуется использовать специальные защищенные носители
                ключевой информации (ключевые носители), например: e-token, смарт-карта и т.п.
              </li>
              <li>
                крайне внимательно относиться к ключевому носителю, не оставлять его без
                присмотра и не передавать третьим лицам, извлекать носители из компьютера,
                если они не используются для работы;
              </li>
              <li>
                использовать сложные пароли для входа на устройство и для доступа к ключам
                электронной подписи/ключевым носителям, не хранить пароли открытом виде.
              </li>
            </ul>
          </li>
          <li>
            <p>
              При работе на компьютере необходимо:
            </p>
            <ul>
              <li>
                использовать лицензионное программное обеспечение (операционные
                системы, офисные пакеты и т.д.)
              </li>
              <li>
                своевременно устанавливать актуальные обновления безопасности
                (операционные системы, офисные пакеты и т.д.)
              </li>
              <li>
                использовать антивирусное программное обеспечение, регулярно
                обновлять антивирусные базы;
              </li>
              <li>
                использовать специализированные программы для защиты информации
                (персональные межсетевые экраны и средства защиты от несанкционированного
                доступа), средства контроля конфигурации устройств;
              </li>
              <li>
                использовать сложные пароли;
              </li>
              <li>
                ограничить доступ к компьютеру, исключить либо ограничить возможность
                дистанционного подключения к компьютеру третьим лицам.
              </li>
            </ul>
          </li>
          <li>
            <p>
              При работе с мобильным приложением необходимо:
            </p>
            <ul>
              <li>
                не оставлять свое мобильное устройство без присмотра, чтобы исключить
                несанкционированное использование мобильного приложения;
              </li>
              <li>
                использовать только официальные мобильные приложения;
              </li>
              <li>
                не переходить по ссылкам и не устанавливать приложения/обновления
                безопасности, пришедшие в SMS-сообщении, рush-уведомлении или по
                электронной почте, в том числе от имени Компании;
              </li>
              <li>
                установить на мобильном устройстве пароль для доступа к устройству и приложению.
              </li>
            </ul>
          </li>
          <li>
            <p>
              При обмене информацией через сеть Интернет необходимо:
            </p>
            <ul>
              <li>
                не использовать общедоступные сети Wi-Fi;
              </li>
              <li>
                не открывать письма и вложения к ним, полученные от неизвестных отправителей
                по электронной почте, не переходить по содержащимся в таких письмах ссылкам;
              </li>
              <li>
                не вводить персональную информацию на подозрительных сайтах и
                других неизвестных вам ресурсах;
              </li>
              <li>
                ограничить посещения сайтов сомнительного содержания;
              </li>
              <li>
                не сохранять пароли в памяти интернет-браузера, особенно, если к компьютеру
                есть доступ третьих лиц;
              </li>
              <li>
                не нажимать на баннеры и всплывающие окна, возникающие во время работы с
                сетью Интернет;
              </li>
              <li>
                не открывать файлы, полученные (скачанные) из неизвестных источников.
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p>
      При подозрении в компрометации ключей электронной подписи/шифрования
      или несанкционированном движении ценных бумаг, денежных средств или иных
      финансовых активов необходимо незамедлительно обращаться в Компанию.
    </p>
  </ExtraText>
);
