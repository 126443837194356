import React from 'react';

import { Input } from '..';
import { IInputProps } from '../intefaces';

export const InputPassport = (props: IInputProps) => (
  <Input
    {...props}
    data-test="input-passport-component"
    mask="99 99 999 999"
    placeholder="Серия и номер паспорта"
  />
);
