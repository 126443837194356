import React, { useState, useRef } from 'react';

import { useDispatch } from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';

import { IDateRange } from 'interfaces';
import { setHeaderDateRange } from 'store/reducers/dateRangeReducers';
import { Datepicker } from 'components/shared/datepicker';
import { DateFilters } from 'components/widgets/dateFilters';
import { Icon } from 'assets/img/svg';
import { dateToFormatString } from 'helpers/date';
import { isDev } from 'helpers/isDev';
import { useStore } from 'components/hooks/useStore';

import { DatepickerLabel } from '../label';

import S from './styles';

export const HeaderDatepicker = () => {
  const { headerDateRange: range, account: { dateBorders } } = useStore()
  const dispatch = useDispatch()
  const setRange = (range: IDateRange) => dispatch(setHeaderDateRange(range))
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState('range');
  const ref = useRef(null);

  useOnClickOutside(ref, () => setShow(false));

  const handleFilterChange = (value: string) => {
    if (value !== 'range') {
      const newRange: IDateRange = {
        to: dateBorders.to,
        from: value === 'all'
          ? dateBorders.from
          : new Date(value)
      };

      setRange(newRange);
    }

    setFilter(value);
  };
  const handleDatepickerChange = (newRange: IDateRange) => {
    setRange(newRange);
    setFilter('range');
  };

  const titleString = range
    ? `${dateToFormatString(range.to)}`
    : '';
  return (
    <S.HeaderDatepicker
      data-test="datepicker-string-date"
      ref={ref}
      onClick={(event) => event.stopPropagation()}
    >
      <DatepickerLabel onClick={() => isDev && setShow(!show)}>
        {titleString}
        {isDev && <Icon.Calendar />}
      </DatepickerLabel>
      {range && (
        <Datepicker
          onChange={handleDatepickerChange}
          onClose={() => setShow(false)}
          range={range}
          isShow={show}
          limits={!isEmptyObject(dateBorders) ? dateBorders : undefined}
          header={
            <DateFilters
              onChange={handleFilterChange}
              selectedOption={filter}
              from={range.from}
              to={range.to}
              hasLastElementText
              withInputs
            />
          }
        />
      )}
    </S.HeaderDatepicker>
  );
};

function isEmptyObject(obj: object) {
  return stringifyObject(obj) === stringifyObject({});
}

function stringifyObject(obj: object): string {
  return JSON.stringify(obj);
}
