import React, { useEffect, useState } from 'react';

import Sticky from 'react-sticky-el';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link'
import Media from 'react-media';

import { device } from 'assets/styles/theme';
import { Icon } from 'assets/img/svg';

import SItem from './sections/link/styles';
import { IProps } from './interfaces';
import { useStore } from '../../hooks/useStore';
import {
  Feedback,
  User,
  Logo,
  SidebarLink
} from './sections';

import S from './styles';

export const Sidebar = (({
  isActive,
  isLoginPage,
  toggleSidebar,
  showLogOut = false,
  setShowLogOut = () => undefined
}: IProps) => {
  const { purchase: { data: { patronymic, firstName } } } = useStore()
  const [collapse, setCollapse] = useState(false);
  const newProudctClassName = isPurchasePage()
    ? 'active'
    : '';
  function isPurchasePage() {
    return document.location.href.indexOf('purchase') !== -1;
  }
  /* в зависимости от состояния sidebar меняем scroll */
  // @ts-expect-error
  useEffect(() => {
    if (isActive) {
      return document.body.style.overflow = 'hidden'
    }
    return document.body.style.overflow = 'visible'
  }, [isActive])
  function handleCloseSidebar() {
    if (isActive) {
      toggleSidebar();
    }
  }
  const {
    Message,
    Portfolio,
    Reports,
    Kval,
    VelesLogo,
    VelesLogoSmall
  } = Icon;

  const sidebar = (
    <>
      <section>
        <User
          userName={`${firstName} ${patronymic}`}
          showLogOut={showLogOut}
          setShowLogOut={setShowLogOut}
        />
      </section>
      <section className="items">
        <ul>
          <SidebarLink
            Icon={Portfolio}
            title="Портфель"
            to="/portfolio"
            onClick={handleCloseSidebar}
          />
          <SidebarLink
            Icon={Reports}
            title="Отчёты"
            to="/reports"
            onClick={handleCloseSidebar}
          />
          <SidebarLink
              Icon={Kval}
              title="Стать&nbsp;квалифицированным инвестором"
              to="/kval"
              onClick={handleCloseSidebar}
          />
          <SItem.SidebarItem
            data-test="sidebar-item"
            onClick={handleCloseSidebar}
          >
            <HashLink
              to="/portfolio#recommended-products"
              smooth
              className={newProudctClassName}
            >
              <Icon.NewProduct />
              <span data-test="sidebar-item-title">Новый продукт</span>
            </HashLink>
          </SItem.SidebarItem>
          <SidebarLink
              isMessage
              Icon={Message}
              title="Сообщения"
              to="/messages"
              onClick={handleCloseSidebar}
          />
        </ul>
      </section>
      <div className="separator" />
      <Feedback />
      <Sticky>
        <S.Collapse onClick={() => setTimeout(() => setCollapse(!collapse), 0)}>
          <Icon.CollapseSidebar />
          <span>Свернуть</span>
        </S.Collapse>
      </Sticky>
    </>
  );
  return (
    <S.Sidebar
      collapse={collapse}
      className={classNames({
        active: isActive,
        loginPage: isLoginPage
      })}
      onMouseDown={(event: React.MouseEvent) => event.preventDefault()}
    >
      <Logo Icon={!collapse ? VelesLogo : VelesLogoSmall} />
      <Media query={device.tablet}>
        {(isMatched) => (isMatched
          ? sidebar
          : <div className="collapse-wrapper">{sidebar}</div>)}
      </Media>
    </S.Sidebar>
  );
});
