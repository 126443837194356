import axios from 'axios';
import { Token } from 'entities/token';

import { Url } from './url';

export const refreshToken = () => {
  const refresh_token = Token.get('refresh');
  return axios
    .post(Url.refreshToken, { refresh_token })
    .then((response) => {
      const { refresh_token: refresh, token: access } = response.data;
      Token.set('refresh', refresh);
      Token.set('access', access);
      return true;
    })
    .catch(() => {
      Token.removeTokens();
    });
};
