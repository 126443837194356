import styled from 'styled-components';

import { Common } from 'components/shared';
import StylesLoginForm from 'components/widgets/loginPanel/styles';
import { media } from 'assets/styles/theme';

const { Button } = Common

const Form = styled.form`
  margin-top: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap !important;
    
  ${media.desktop} {
    flex-direction: column !important;
  }
  ${media.tablet} {
    flex-direction: column !important;
  }
  ${media.mobile} {
    flex-direction: column !important;
    margin-top: 30px;
  }
`;

const SendAgain = styled.button`
  display: inline;
  color: ${({ theme }) => theme.colors.link};
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.description};

  ${SendAgain} {
    margin: 0;
  }
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.link};
  border-color: ${({ theme }) => theme.colors.link};
  background-color: ${({ theme }) => theme.colors.white};
`;

export default {
  Form,
  CancelButton,
  Description,
  SendAgain,
  DescriptionContainer,
  RowButtons: StylesLoginForm.RowButtons
};
