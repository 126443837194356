import React from 'react';

import { useDispatch } from 'react-redux';

import { Icon } from 'assets/img/svg';
import { Common } from 'components/shared';
import { staticContent } from 'staticContent';
import Burger from 'components/shared/burger';
import { Sidebar } from 'components/widgets/sidebar';
import { setStatus } from 'store/reducers/view/activeSidebar';

import { useStore } from '../../hooks/useStore';

import S from './styles';

const {
  Phone,
  Back,
  Container,
  Header
} = S
const { LinkText } = Common
const { ArrowBack } = Icon

const {
  email,
  Logo,
  hotLinePhone,
  mainSiteLink
} = staticContent;

export const LoginHeader = () => {
  const dispatch = useDispatch()
  const toggleActiveSidebar = () => dispatch(setStatus())
  const { view: { activeSidebar } } = useStore()
  return (
    <Header data-test="header" activeSidebar={activeSidebar}>
      <Container>
        <Sidebar
            isActive={activeSidebar}
            /* от этого параметра зависит скрыты ли пункты меню */
            isLoginPage
            toggleSidebar={toggleActiveSidebar}
        />
        <Burger
            onClick={toggleActiveSidebar}
            isActive={activeSidebar}
        />
        <a
            className="logo"
            href="/login"
            aria-label="Вернуться на страницу логина"
        >
          <Logo />
        </a>
        <Phone href={`phoneto:${hotLinePhone}`}>{hotLinePhone}</Phone>
        <LinkText
            className="email"
            href={`mailto:${email}`}
            data-test="header-email"
        >
          {email}
        </LinkText>
        <Back>
          <ArrowBack />
          <LinkText href={mainSiteLink} data-test="header-go-back">
            Вернуться на сайт
          </LinkText>
        </Back>
      </Container>
    </Header>
  );
};
