import React from 'react';

import { Icon } from 'assets/img/svg';

import S from './styles';

const { Feedback: FeedbackStyles } = S

export const Feedback = () => (
  <FeedbackStyles>
    <div className="title">
      <span>Обратная связь</span>
      <Icon.Feedback />
    </div>
    <section className="support">
      <a className="phone" href="tel:+74959670913">
        +7 (495) 967-09-13
      </a>
      <div className="ask-a-question-container">
        <a
          href="mailto:online@veles-management.ru"
          className="ask-a-question ask-a-question-custom"
        >
          ✉ Задать вопрос
        </a>
      </div>
      <a
          href="mailto:supportlk@veles-management.ru"
          className="ask-a-question"
      >
        ✉ Сообщить об ошибке
      </a>
    </section>
  </FeedbackStyles>
);
