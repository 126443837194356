import styled from 'styled-components';

import { Common } from 'components/shared'

const { ExtraText } = Common

export const Text = styled(ExtraText)`
  text-align: center;
  padding-bottom: 8px;
`
export const Title = styled.h2`
  margin-bottom: 10px;
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.textPrimary};
`
