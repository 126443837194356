import React from 'react';

import { Icon } from 'assets/img/svg';
// eslint-disable-next-line import/no-cycle
import { NotFoundUser } from './user';
// eslint-disable-next-line import/no-cycle
import { NotFoundGuest } from './guest';

import S from '../errorStyles';

interface INotFoundProps {
  link: JSX.Element;
}
export default ({ link }: INotFoundProps) => (
  <S.Error data-test="not-found-component">
    <Icon.NotFound />
    <S.Title>Ошибка 404, страница не найдена</S.Title>
    <S.Description>
      Сообщение об ошибке уже отправлено администраторам.
    </S.Description>
    <S.Link> {link}</S.Link>
  </S.Error>
);

export {
  NotFoundGuest,
  NotFoundUser,
  INotFoundProps
};
