import { media } from 'assets/styles/theme';

import styled from 'styled-components';

const Table = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  box-shadow: 0 5px 10px rgba(168,160,153,.25);
  border-radius: 10px;
  padding: 10px;

  ${media.mobile} {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
`;

const Items = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 5px 0 17px;

  ${media.max('md')} {
    flex-direction: column;
  }

  &:hover {
    background: rgba(0,150,183,.05);

    div {
      color: #424249;
    }

    .title {
      color: ${({ theme }) => theme.colors.link};
    }

    .download {
      path {
        fill: ${({ theme }) => theme.colors.link} !important;
      }
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 20px;
  color: #A8A099;
  padding: 0 15px 0 45px;
  font-weight: 500;
  position: relative;

  ${media.mobile} {
    padding-left: 52px;
    font-size: 14px;
  }

  &:first-child {
    width: 100%;
  }

  &.title {
    color: #424249;
    font-weight: normal;
    margin-top: 12px;
  }

  .icon {
    position: absolute;
    left: 10px;

    ${media.mobile} {
      left: 15px;
    }

    top: 2px;
    width: 24px;
    height: 24px;
    display: block;
  }
`;

const Meta = styled.span`
  display: inline-flex;
  justify-content: center;
  margin-top: 12px;
  white-space: nowrap;

  ${Item} {
    font-size: 14px;

    &:first-child {
      width: auto;
      padding-left: 10px;

      ${media.mobile} {
        padding-left: 15px;
      }
    }
  }
  ${media.mobile} {
    ${Item} {
      padding-left: 15px;
      font-size: 13px;
    }
  }
`;

export default {
  Table,
  Meta,
  Items,
  Item
};
