import { getAsyncTypes } from '../helpers/asyncTypes';

export const actionTypes = {
  CLIENT_INFO: getAsyncTypes('CLIENT_INFO'),
  SET_SHARE_COUNT: 'SET_SHARE_COUNT',
  SET_SHARE_COUNT_THROTTLE: 'SET_SHARE_COUNT_THROTTLE',

  PURCHASE: {
    USER_DOCS: {
      GET: getAsyncTypes('USER_DOCS_GET')
    },
    REQUEST_RESET_DATA: 'REQUEST_RESET_DATA',
    SET_PARAMS: 'QUESTIONNAIRE_SET_PARAMS',
    CHECK_TERRORIZM: { ...getAsyncTypes('PURCHASE_CHECK_TERRORIZM') },
    SET_USER_PARAMS: getAsyncTypes('SET_USER_PARAMS'),
    NOT_RESPOND: 'SERVICE_IS_NOT_RESPOND',
    RESET_NOT_RESPOND: 'RESET_NOT_RESPOND',
    IS_CONFLICT: 'SERVICE_IS_CONFLICT',
    SET_ADDRESS: 'PURCHASE_SET_ADDRESS',
    REDIRECT_ERROR: 'REDIRECT_ERROR',
    SMS: {
      SEND: { ...getAsyncTypes('PURCHASE_SMS_SEND') },
      CHECK: { ...getAsyncTypes('PURCHASE_SMS_CHECK') },
      RESET_SUCCESS: 'RESET_SUCCESS',
      RESET_FAIL: 'RESET_FAIL'
    },
    OPIF: {
      ...getAsyncTypes('PURCHASE_OPIF'),
      RESET_FAIL: 'RESET_FAIL',
      /* сбрасываем лоадер если клиент вышел до отправки смс, но нажал его получить */
      RESET_LOADER: 'RESET_LOADER',
      RESET_SUCCESS: 'RESET_SUCCESS'
    },
    SET_TYPE: 'QUESTIONNAIRE_SET_TYPES',
    SET_PRODUCT: 'SET_PRODUCT',
    SET_PRODUCT_NAME: 'SET_PRODUCT_NAME',
    EDO: {
      SMS: { ...getAsyncTypes('EDO_SMS') }
    },
    CHECK_DATA: {
      SMS: { ...getAsyncTypes('CHECK_DATA_SMS') }
    },
    RANGE_PRICE: getAsyncTypes('RANGE_PRICE')
  },
  VIEW: {
    EDIT_MODAL: {
      SHOW: 'VIEW_EDIT_MODAL_SHOW',
      HIDE: 'VIEW_EDIT_MODAL_HIDE'
    },
  },
  REDEMPTION: {
    SUBMIT: getAsyncTypes('REDEMPTION_SUBMIT'),
    GET: getAsyncTypes('REDEMPTION_DOCS_GET'),
    SMS_CHECK: getAsyncTypes('REDEMPTION_SMS_CHECK')
  },
  REBUY: {
    GET_INFO: getAsyncTypes('REBUY_GET_INFO'),
    NOT_REBUY: 'SERVICE_IS_NOT_REBUY'
  },
  REQUISITES: {
    GET_FILE: getAsyncTypes('REQUISITES_GET_INFO')
  },
};
