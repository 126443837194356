import React from 'react';

import { CommonTooltip, Common } from 'components/shared';
import { PHONE_LINK } from 'constants/phone';
import { useLockBodyScroll } from 'components/hooks/useLockBodyScroll';

import { Text } from './styles';

const { LinkStyled } = Common

const ServiceError = ({ isShow, extraData }: { isShow: boolean, extraData?: () => void }) => {
  useLockBodyScroll(isShow)
  return (
    <CommonTooltip
      isShow={isShow}
      onClose={() => extraData && extraData() || undefined}
      showCloseButton={false}
    >
      <Text>
        К сожалению, сервис недоступен.{' '}
        <br />
        <LinkStyled onClick={extraData} to="/portfolio">
          Повторите&nbsp;попытку
        </LinkStyled>
        {' '}
        позже или свяжитесь с <br /> консультантом по телефону: {PHONE_LINK}
      </Text>
    </CommonTooltip>
  )
}

export { ServiceError };
