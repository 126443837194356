import Axios, { AxiosResponse } from 'axios';

import { errorHandler } from 'helpers/api/errors';

import { getHeaders } from './options';

const objToUri = (params: { from: string, to: string }): string => Object.keys(params)
  .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
  .join('&');

export interface IRequestParams {
  url: string;
  /* todo пока не знаю как сделать типизацию */
  data?: any;
  urlParams?: { from: string, to: string, productId?:string };
  host?: string;
}

interface IRequestParamsWithMethod extends IRequestParams {
  method: 'post' | 'get' | 'patch';
}

class Api {
  public get = (params: IRequestParams, headers?: any) => this.request({ ...params, method: 'get' }, headers)

  public post = (params: IRequestParams, headers?: any) => this.request({ ...params, method: 'post' }, headers)

  public patch = (params: IRequestParams) => this.request({ ...params, method: 'patch' })

  public request = async (params: IRequestParamsWithMethod, headers?: any) => {
    const urlParams = params.urlParams ? `${objToUri(params.urlParams)}` : '';
    try {
      const response: AxiosResponse = await Axios({
        ...params,
        headers: { ...(await getHeaders()), ...headers },
        url: `${params.url}?${urlParams}`
      });
      return response.data;
    } catch (error) {
      throw errorHandler(error);
    }
  }
}

export const api = new Api();
