import React from 'react';

import { linkVeles } from 'constants/links';

import { Common } from '../../shared';

import S from './styles';

const { SpanCondition } = S
const { LinkText } = Common

export const doc = 'pdf-rules';

export const PageInformation = {
  mainTitle: 'Стать квалифицированным инвестором',
  subTitle: [
    `Прошу признать квалифицированным инвестором
     в отношении следующих видов ценных бумаг:`,

    'Документы-основание',

    `Настоящим заявляю о соответствии следующим
     требованиям, необходимым для признания меня квалифицированным инвестором
     (достаточно выбрать любое из нижеуказанных требований):`
  ],
  typesDocument: [
    `ценные бумаги иностранных эмитентов, которые не допущены
     к публичному размещению и (или) публичному обращению в Российской Федерации;`,

    ` инвестиционные паи закрытого и интервального паевых инвестиционных
     фондов, ограниченные в обороте.`
  ],
  addDocument: `Выберете один или несколько файлов в формате pdf, tif, jpg, jpeg или bmp
    и размером не более 10 Мб для каждого`,
  textResult: [`Ваше заявление о признании Вас квалифицированным
    инвестором принято к рассмотрению.`,

  'В течение пяти рабочих дней Вам придет ответ на электронную почту.'
  ]
};

const regulationsURL = `${linkVeles}/uploadedfiles/Reglament_21.02.2022.pdf`;

export const radioButtons = [
  {
    label: `общая стоимость ценных бумаг, которыми владеет заявитель, и (или) общий размер
            обязательств из договоров, являющихся производными финансовыми, заключенных за счет
            заявителя - не менее 6 миллионов рублей`,
    value: '1'
  },
  {
    label:
  <SpanCondition>
    наличие надлежащего опыта работы в соответствии с подп. 1.1.2&nbsp;
    <LinkText target="_blank" href={regulationsURL}>
      Регламента
    </LinkText>
  </SpanCondition>,
    value: '2'
  },
  {
    label: `совершало сделки с ценными бумагами и (или) заключало договоры, являющиеся
            производными финансовыми инструментами, за последние четыре квартала в среднем не
            реже 10 раз в квартал, но не реже одного раза в месяц. При этом совокупная цена таких  
            сделок (договоров) должна составлять не менее 6 миллионов рублей,`,
    value: '3'
  },
  {
    label:
  <SpanCondition>
    размер имущества, принадлежащего лицу, составляет не менее 6 миллионов рублей.
    При этом учитывается только имущество, перечисленное в подп. 1.1.4.&nbsp;
    <LinkText target="_blank" href={regulationsURL}>
      Регламента
    </LinkText>
  </SpanCondition>,
    value: '4'
  },
  {
    label:
  <SpanCondition>имеет образование, соответствующее подп. 1.1.5.&nbsp;
    <LinkText target="_blank" href={regulationsURL}>
      Регламента
    </LinkText>
  </SpanCondition>,
    value: '5'
  },
  {
    label: 'признано иным лицом квалифицированным инвестором',
    value: '6'
  }
];
