import styled, { css } from 'styled-components';

import { media } from 'assets/styles/theme';

import StylesInput from '../input/styles';
import { Common } from '../commonStyles';

const { Panel } = Common

interface IStylesProps {
  show: boolean;
}

const DayPickerStyles = css`
  .DayPicker {
    color: ${({ theme }) => theme.colors.textPrimary};
    &-Month {
      position: relative;
    }
    &-NavBar {
      position: absolute;
      top: 10px;
      right: -180px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &-NavButton {
      &--next,
      &--prev {
        position: static;
        color: ${({ theme }) => theme.colors.link};
        background-image: none;
        margin: 0;
      }
    }
    &-Day {
      color: ${({ theme }) => theme.colors.textPrimary};
      font-size: 13px;
      letter-spacing: 0.05px;
      line-height: 16px;
      height: 30px;
      width: 30px;
      border-radius: 5px;
      padding: 4px;
      &--selected {
        background-color: #f0f8ff !important;
        color: ${({ theme }) => theme.colors.link} !important;
        border-radius: 0;
      }
      &--start {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &--end {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &--start,
      &--end {
        color: #f0f8ff !important;
        background-color: ${({ theme }) => theme.colors.link} !important;
      }
      &--outside {
        background: none;
        color: ${({ theme }) => theme.colors.description};
      }
      &--today {
        font-weight: normal;
      }
      &--disabled {
        color: ${({ theme }) => theme.colors.description};
        outline: none;
      }
    }
    &-Weekday {
      padding-bottom: 18px;

      abbr {
        color: #cbd2d9;
        font-family: Montserrat;
        font-size: 13px;
        letter-spacing: 0.05px;
        line-height: 16px;
      }
    }
  }
  span {
    margin-right: 15px;
  }

  svg {
    cursor: pointer;
  }
`;

const Datepicker = styled(Panel)<IStylesProps>`
  position: absolute;
  padding: 20px;
  display: ${({ show }) => (show ? 'inline-flex' : 'none')};
  z-index: 1;

  ${media.mobile} {
    box-shadow: 0 5px 10px 0 ${({ theme }) => theme.colors.shadow};
  }

  ${DayPickerStyles}
`;
const DatepickerTooltip = styled.div`
    ${StylesInput.Input} {
      margin: 0;
    }
    ${DayPickerStyles}
`;
export default { Datepicker, DatepickerTooltip };
