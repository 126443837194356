import React from 'react';

// eslint-disable-next-line import/no-cycle
import { Loader } from '../index';
import { WrapperContent, Text } from './styles';

import { ILoaderAndCapProps } from './interfaces';

/*  универсальный компонент для лоадера и заглушек на
 страницах для корректного расположения элементов по центру */
export const LoaderAndCap = ({
  isLoader,
  text,
  icon,
  children
}: ILoaderAndCapProps): JSX.Element => (
  <WrapperContent text={text}>
    {isLoader
      ? <Loader />
      :
      <>
        <img src={icon} alt="message" />
        <Text>
          {children || text }
        </Text>
      </>}
  </WrapperContent>
);
