import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ILogoProps } from './interfaces';

import S from './styles';

export const Logo = ({ Icon }: ILogoProps) => {
  const navigate = useNavigate()
  return (
    <S.SidebarLogo onClick={() => navigate('/portfolio', { replace: true })}>
      <Icon />
    </S.SidebarLogo>
  )
};
