import styled from 'styled-components';

import StylesDatepicker from 'components/shared/datepicker/styles';
import StylesDateFilters from 'components/widgets/dateFilters/styles';
import { media } from 'assets/styles/theme';

const HeaderDatepicker = styled.div`
  display: inline-flex;
  position: relative;
  outline: none;
  min-width: 100px;

  ${StylesDatepicker.Datepicker} {
    top: 25px;
    right: -180px;

    ${media.tablet} {
      position: fixed;
      top: 120px;
      left: calc(50% - 160px);
      right: auto;
    }

    ${media.mobile} {
      top: 190px;
    }
  }

  ${StylesDateFilters.DateFilters} {
    margin-bottom: 18px;
    ${StylesDateFilters.Inputs} {
      margin-top: 24px;
    }
  }
`;

const AllPeriod = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default {
  HeaderDatepicker,
  AllPeriod,
  Header
};
