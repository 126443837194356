import React from 'react';

import { ScrollContainer } from '../scrollContainer';
import { ICustomScrollBoxProps } from './interfaces';

// eslint-disable-next-line import/no-cycle
import S from './styles';

const { Wrapper, WrapperContent } = S

/* компонент используется, когда необходимо большой текст
 завернуть в контейенр с кастомным скроллом */
export const CustomScrollBox = ({ children, setButtonDisabled }: ICustomScrollBoxProps) => (
  <Wrapper>
    <ScrollContainer
        setButtonDisabled={setButtonDisabled}
        renderView={() => <WrapperContent />}
    >
      {children}
    </ScrollContainer>
  </Wrapper>
)
