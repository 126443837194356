import { useSelector } from 'react-redux';

import { IState } from '../../interfaces';

export const useStore = () => {
  const {
    userMessagesData,
    checkOfClientData,
    account,
    authentication,
    chart,
    chartsDateRange,
    confirmationExistStatus,
    currency,
    currencyCourses,
    edoExistStatus,
    headerDateRange,
    opifYield,
    passwordRecovery,
    productCharts,
    userProducts,
    purchase,
    purse,
    qualifiedStatusData,
    recommendedProducts,
    reports,
    requests,
    securities,
    view
  } = useSelector((state: IState) => state)
  return {
    userMessagesData,
    checkOfClientData,
    account,
    authentication,
    chart,
    chartsDateRange,
    confirmationExistStatus,
    currency,
    currencyCourses,
    edoExistStatus,
    headerDateRange,
    opifYield,
    passwordRecovery,
    productCharts,
    userProducts,
    purchase,
    purse,
    recommendedProducts,
    reports,
    requests,
    securities,
    view,
    qualifiedStatusData
  }
}
