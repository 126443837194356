import { IField } from 'interfaces';

const addressString: IField = {
  name: 'addressRegistration.addressString',
  placeholder: 'Адрес регистрации',
  type: 'text',
  initialValue: ''
};
const country: IField = {
  name: 'address.country',
  placeholder: 'Страна',
  type: 'text',
  initialValue: 'Россия'
};

const postCode: IField = {
  name: 'address.postcode',
  type: 'text',
  placeholder: 'Индекс',
  initialValue: ''
};

const region: IField = {
  name: 'address.region',
  placeholder: 'Область',
  type: 'text',
  initialValue: ''
};

const regionalDistrict: IField = {
  name: 'address.regionalDistrict',
  placeholder: 'Областной район',
  type: 'text',
  initialValue: ''
};

const city: IField = {
  name: 'address.city',
  placeholder: 'Город, поселение, поселок, деревня',
  type: 'text',
  initialValue: ''
};

const street: IField = {
  name: 'address.street',
  placeholder: 'Улица',
  type: 'text',
  initialValue: ''
};

const houseNumber: IField = {
  name: 'address.house.number',
  placeholder: 'Дом',
  type: 'text',
  initialValue: ''
};

const houseBuilding: IField = {
  name: 'address.house.building',
  placeholder: 'Строение',
  type: 'text',
  initialValue: ''
};

const houseBlock: IField = {
  name: 'address.house.block',
  placeholder: 'Корпус',
  type: 'text',
  initialValue: ''
};

const houseFlat: IField = {
  name: 'address.house.flat',
  placeholder: 'Квартира',
  type: 'text',
  initialValue: ''
};

const address = {
  addressString,
  city,
  country,
  region,
  regionalDistrict,
  street,
  postCode,
  house: {
    number: houseNumber,
    building: houseBuilding,
    flat: houseFlat,
    block: houseBlock
  }
};

export default address;
