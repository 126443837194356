import { createAction } from '@reduxjs/toolkit';

import { Token } from 'entities/token';
import { IAction } from 'interfaces';

export const logOut = createAction('logOut')

export const logOutAction = (): IAction => {
  Token.removeTokens();
  return logOut();
};
