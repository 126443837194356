import React from 'react';

import { Common } from 'components/shared';
import { serviceErrorText } from 'staticContent/defaultError';

const { ExtraText } = Common

const ForeignerError = () => <ExtraText style={{ paddingTop: '15px' }}>{serviceErrorText}</ExtraText>;

export { ForeignerError };
