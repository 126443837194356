import React, { useReducer, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';

import { getProductActionLink } from 'helpers/products';
import { Common } from 'components/shared';

import { multiStepReducer } from './reducer/reducer';
import { Steps } from './step';
import { Form } from '../form';
import { IOwnProps, WizardForms } from './interfaces';

import S from './styles';

const { Panel, H1, H2 } = Common
const { MultiStepWizard: MultiStepWizardStyle } = S

export const MultiStepWizard = ({
  forms,
  goto,
  steps,
  title: wizardTitle
}: IOwnProps) => {
  const {
    productId = '',
    action = '',
    productType = '',
    step,
    form
  } = useParams()
  const navigate = useNavigate()
  const [state, next] = useReducer(multiStepReducer, {
    forms,
    currentStep: 0,
    currentForm: 0
  });

  const { currentStep, currentForm } = state;
  const [urlParams, setUrlParams] = useReducer(multiStepReducer, {
    forms,
    currentStep: 0,
    currentForm: 0
  });
  const lastForm = isLastForm(
    forms,
    currentStep,
    currentForm
  );

  const {
    title,
    fields,
    BeforeNext,
    BeforeFields,
    AfterFields,
    AfterHiddenFields,
    modals,
    url,
    submitTitle,
    formComponentClassName,
    disclaimer
  } = forms[urlParams.currentStep][urlParams.currentForm];

  const handleSubmit = () => {
    if (isEqual(urlParams, state)) {
      next({ type: 'NEXT' });
    }
    setUrlParams({ type: 'NEXT' });
  };

  useEffect(() => {
    updateUrl(urlParams.currentStep, urlParams.currentForm);
  }, [urlParams]);

  useEffect(() => {
    if (goto) {
      setUrlParams({ type: 'GOTO', payload: goto });
    }
  }, [goto]);
  useEffect(() => () => next({ type: 'initial' }), [])

  const updateUrl = (newStep: number, newForm: number) => {
    const nextParams = { currentForm: newForm, currentStep: newStep };
    const { step, form } = getUrlParams();
    const prevParams = {
      currentForm: form,
      currentStep: step
    };

    if (!isEqual(prevParams, nextParams)) {
      const productUrl = getProductActionLink(
        productId,
        action,
        productType,
        `${newStep + 1}`,
        `${newForm + 1}`
      );
      navigate(productUrl);
    }
  };

  const getUrlParams = () => ({
    step: Number(step) - 1,
    form: Number(form) - 1
  });
  /* отслеживаем нажатие кнопки назад в браузере */
  window.onpopstate = () => {
    const { step, form } = getUrlParams();
    if (step <= currentStep && form <= currentForm) {
      setUrlParams({
        type: 'GOTO',
        payload: { currentStep: step, currentForm: form }
      });
    }
  };

  return (
    <MultiStepWizardStyle data-test="multi-step-wizard-component">
      <H1>{wizardTitle}</H1>
      <Steps
        steps={steps}
        currentStep={currentStep}
        isLastForm={lastForm}
      />
      <Panel>
        {title
          ? <H2>{title}</H2>
          : null}
        <div>
          <Form
            fields={fields}
            onSubmit={handleSubmit}
            BeforeNext={BeforeNext}
            BeforeFields={BeforeFields}
            AfterFields={AfterFields}
            AfterHiddenFields={AfterHiddenFields}
            url={url}
            modals={modals}
            submitTitle={submitTitle}
            formComponentClassName={formComponentClassName}
            isLastForm={lastForm}
            disclaimer={disclaimer}
          />
        </div>
      </Panel>
    </MultiStepWizardStyle>
  );
};

export function isLastForm(forms: WizardForms, step: number, form: number) {
  const countSteps = size(forms);
  if (countSteps) {
    const countForms = size(forms[countSteps - 1]);
    if (step + 1 === countSteps && form + 1 === countForms) {
      return true;
    }
  }
  return false;
}
