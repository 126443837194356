import styled from 'styled-components';

import StylesHeader from 'components/widgets/portfolioCharts/header/styles';
import { media } from 'assets/styles/theme';
import StylesProductButton from 'components/widgets/portfolioCharts/productButton/styles';
import { Common } from 'components/shared/commonStyles';

import { SToggler } from '../../toggler';

const { Header } = StylesHeader;
const { ProductButton, Color } = StylesProductButton;
const {
  H4,
  Panel,
  ButtonLarge
} = Common

const LegendActivator = styled.button`
  display: none;
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: 1px solid #edebe8;
  border-radius: 5px;
  font-size: 13px;
  letter-spacing: 0.05px;
  height: 36px;
  font-weight: normal;
  padding: 0 13px;
  margin: 25px auto 0;
  width: calc(100% - 30px);
  transition-property: background;
  transition-duration: 0.35s;

  :hover,
  &.active {
    background: ${({ theme }) => theme.colors.background};
  }

  &.with-product::before {
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    top: -4px;
    right: -4px;
    background-color: ${({ theme }) => theme.colors.buttonPrimary};
    border-radius: 100%;
  }

  ${media.tablet} {
    display: block;
  }
`;

const ContainerProductButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 30px;

  ${media.tablet} {
    display: none;

    &.active {
      display: flex;
      align-items: stretch;
      justify-content: left;
      flex-direction: column;
      width: 230px;
    }
  }
`;

const Title = styled.h3`
  font-size: 22px;
  line-height: 21px;
  font-weight: normal;

  ${media.mobile} {
    text-align: center;
  }
`;

const DateTo = styled.span`
  margin-bottom: 120px!important;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.textPrimary};

  ${media.minmax(0, 520)} {
    margin-bottom: 50px!important;
  }

  ${media.tablet} {
    display: none;
  }
`;

const AreaChart = styled(Panel)`
  ${Header} {
    margin-top: 22px;
    width: 100%;

    ${media.tablet} {
      margin-top: 0;
    }
  }
  ${media.tabletOnly} {
    overflow: hidden;
  }
  .chart-header {
    padding-left: 30px;

    ${media.max('md')} {
      padding-left: 0;
    }
    .datepicker-label {
      margin-bottom: 22px;
      * {
        font-size: 16px;
      }
    }
  }
`;

const PieChart = styled(Panel)`
  padding: 0;
  justify-content: flex-start;

  ${Title} {
    text-align: center;
  }
`;

const ChartCard = styled.div`
  width: 100%;
  display: flex;

  ${media.tablet} {
    box-shadow: 0 5px 10px 0 ${({ theme }) => theme.colors.shadow};
    border-radius: 10px;
    overflow: hidden;
  }

  ${media.mobile} {
    flex-wrap: wrap;
    box-shadow: none;
    border-radius: 0;
  }

  ${media.max('md')} {
    flex-direction: column;
  }
  
  ${Title} {
    margin-bottom: 18px;
    ${media.tablet} {
      text-align: left;
      font-size: 16px;
      margin-bottom: 16px;
    }

    ${media.mobile} {
      height: auto;
      padding: 0;
    }
  }

  ${AreaChart} {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 15px;

    ${media.tablet} {
      width: 100%;
      margin-right: 0;
      border-radius: 0;

      & > span {
        padding: 0 30px;
        width: 100%;
      }
    }

    ${media.mobile} {
      align-items: center;
    }

    &:only-child {
      width: 100%;
      margin-right: 0;
    }
  }

  ${PieChart} {
    width: 30%;
    margin-left: 15px;

    ${media.tablet} {
      width: 40%;
      margin-left: 0;
      border-radius: 0;
    }

    ${media.mobile} {
      border-top: 1px solid ${({ theme }) => theme.colors.background};
    }
  }

  ${AreaChart} {
    padding: 30px;

    ${media.mobile} {
      width: 100%;
      padding: 20px;
    }
  }
  ${PieChart} {
    padding-bottom: 40px;
    ${media.desktop} {
      padding: 30px;
    }
    ${media.tablet} {
      width: 100%;
      padding-top: 35px;
      padding-bottom: 0;
      box-shadow: none;
      border-left: 1px solid ${({ theme }) => theme.colors.background};
      ${Title} {
        padding: 0;
      }
      ${DateTo} {
        display: block;
        margin: 0;
      }
    }
    ${media.mobile} {
      width: 100%;
      padding-bottom: 20px;
    }
  }
`;

const Legend = styled.div`
  ${media.tablet} {
    ${Panel} {
      padding: 30px;
      width: 290px;
      ${H4} {
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 29px;
        margin-top: 10px;
        margin-bottom: 40px;
      }
      ${ContainerProductButtons} {
        margin: 0 0 30px 0;
        padding: 0;
        ${ProductButton} {
          margin-right: 0;
          width: 230px;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          ${Color} {
            flex-shrink: 0;
          }
          ${StylesProductButton.Title} {
            width: 100%;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      ${ButtonLarge} {
        width: 100%;
        justify-content: center;
      }
    }
  }
`;

const ToggleState = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 22px;
  ${SToggler} {
    margin: 0 12px;
    cursor: pointer;
  }
  button {
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textPrimary};
    transition-property: color;
    transition-duration: 0.35s;
    &.active {
      color: ${({ theme }) => theme.colors.link};
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.underline};
    }
  }
`;
export default {
  ToggleState,
  Title,
  ChartCard,
  AreaChart,
  PieChart,
  LegendActivator,
  ContainerProductButtons,
  DateTo,
  Legend
};
