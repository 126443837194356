import dayjs from 'dayjs';
import {
  takeLeading,
  call,
  put
} from 'redux-saga/effects';

import { IAction, IDateRange } from 'interfaces';
import { api, IRequestParams } from 'api';
import { Url } from 'api/url';
import { getHeaders } from 'api/options';
import { isUnauthorized } from 'helpers/actions/isUnauthorized';
import { formatDate } from 'helpers/date';

import {
  requestSharesData,
  setChartError,
  successSharesData
} from '../../reducers/chart';

/* сага для получения данных о стоимости паев в пиф-ах */
function* fetchProductChartData({
  payload = {
    productId: '',
    range: {
      from: new Date(),
      to: new Date()
    }
  }
}: IAction<{ productId: string, range: IDateRange }>) {
  try {
    const { range: { from, to }, productId } = payload
    const fromDate = formatDate(from);
    const toDate = formatDate(to);
    const requestParams: IRequestParams = {
      url: Url.productCharts.get,
      urlParams: {
        from: fromDate,
        to: toDate,
        productId
      }
    };
    const { data: rawData } = yield call(api.get, requestParams, getHeaders());
    const data = Object.keys(rawData).map((date) => {
      const productsData = {};
      Object.keys(rawData[date]).forEach((productName) => {
        productsData[productName] = rawData[date][productName];
      });
      return {
        date: dayjs(date).unix() * 1000,
        data: {
          ...productsData
        }
      };
    });
    yield put(successSharesData({ data }))
  } catch (e) {
    const { code } = e;
    const unauthorized = isUnauthorized(code);
    yield put(unauthorized || setChartError(e));
  }
}

export function* productChartData() {
  yield takeLeading(
    requestSharesData.toString(),
    fetchProductChartData
  );
}
