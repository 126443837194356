export enum Currency {
  'rouble' = '₽',
  'rub' = '₽',
  'rur' = '₽',
  'euro' = '€',
  'eur' = '€',
  'dollar' = '$',
  'pound' = '£',
  'gbp' = '£',
  'usd' = '$'
}
