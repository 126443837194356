import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IOpifYieldState } from 'interfaces';
import { IOpifYield } from 'interfaces/opifYields';

/* slice для доходностей пиф-ов в footer */
export const opifYieldSlice = createSlice({
  name: 'opifYield',
  initialState: { isLoading: true, yields: [], error: '' } as IOpifYieldState,
  reducers: {
    requestYields: (state) => state,
    successYields: (state, action: PayloadAction<IOpifYield[]>) => {
      state.yields = action.payload
      state.isLoading = false
      return state
    },
    errorYields: (state, action: PayloadAction<IOpifYieldState>) => {
      state.error = action.payload.error
      return state
    }
  }
})
export const {
  requestYields,
  successYields,
  errorYields
} = opifYieldSlice.actions
