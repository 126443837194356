import styled from 'styled-components';

import { media } from 'assets/styles/theme';

import { Common } from '../../shared';

interface ITextProps {
  isRead?: boolean
}
const { DescriptionText, Panel } = Common

const UserMessage = styled(Panel)<ITextProps>`
  background-color: ${({ isRead }) => !isRead && '#dcdcdc'};
  padding: 40px;
  font-size: 16px;
  margin: 15px 15px 40px 10px;
  display: block;
  text-align: justify;
  
  ${media.tablet} {
    padding: 30px;
  }
  ${media.mobile} {
    text-align: left;
    border-radius: 10px;
    padding: 15px;
    margin-left: 5px;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  .simplebar-scrollbar::before {
    width: 4px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.2);
   }
  .simplebar-track.simplebar-vertical {
    border-radius: 5px;
   }
  
  ${media.mobile} {
    width: 98%;
  }
;
`
const Date = styled(DescriptionText)<ITextProps>`
  text-align: left;
  display: inline-block;
  width: ${({ isRead }) => (isRead ?'100%' : '50%')};
  margin-bottom: 15px;
`
const TextAccept = styled(Date)<ITextProps>`
  width: ${({ isRead }) => isRead && '100%'};
  text-align: end;
  text-decoration: underline;
`
const Text = styled.span`
  cursor: pointer;
  text-decoration: underline;
  :hover, :active {
    color: ${({ theme }) => theme.colors.buttonHover};
  }
`
export default {
  UserMessage,
  Wrapper,
  Date,
  TextAccept,
  Text
}
