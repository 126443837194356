import React from 'react';

import { Icon } from 'assets/img/svg';
import { dateToFormatString } from 'helpers/date';
import { isDev } from 'helpers/isDev';

import { IDateRangeProps } from './interfaces';

import S from './styles';

export const DateRange = ({
  onClick,
  from,
  to
}: IDateRangeProps) => (
  <S.DateRange onClick={onClick}>
    <span>
      {dateToFormatString(from)} – {dateToFormatString(to)}
    </span>
    {isDev
      ? <Icon.ArrowDown />
      : null}
  </S.DateRange>
);

export const StylesDateRange = S.DateRange;
