import size from 'lodash/size';
import forEach from 'lodash/forEach';

import { IterableEntity, IProduct } from 'interfaces';
import { Colors } from 'constants/colors';
import { Percents } from 'entities/percents';
import { Money } from 'entities/money';
import { Currency } from 'enums';
import { ICurrencyCourses } from 'interfaces/state';
import { ALL_PRODUCTS } from 'constants/products';

import { IPieChartData } from '../../components/widgets/portfolioCharts/interfaces';

export const computedPieChartData = (
  products: IterableEntity<IProduct>,
  currency: Currency,
  courses: ICurrencyCourses
): IPieChartData[] => {
  const { ids } = products;
  const pieChartData: IPieChartData[] = [];
  const productsLength: number = size(ids);
  const other = {
    name: 'Другое',
    color: Colors[Colors.length - 1],
    cost: new Money(NaN),
    part: NaN
  };

  forEach(ids, (id, index) => {
    const product: IProduct = products.byId[id];
    const {
      name,
      cost,
      part = new Percents(NaN)
    } = product;
    const color = Colors[index + 1];
    if (productsLength < 10) {
      cost.changeCurrency(currency, courses[currency]);
      pieChartData.push({
        name,
        color,
        cost,
        part: part.get()
      });
    } else if (part.get() < 2) {
      other.part += part.get();
      other.cost = new Money(other.cost.get() + cost.get());
    }
  });

  if (other.part) {
    pieChartData.push(other as IPieChartData);
  }

  return pieChartData;
};

export const getChartColor = (
  activeProduct: string[],
  products: string[]
): string[] => {
  const chartColors: string[] = [];
  if (size(products) > 1) {
    chartColors.push(Colors[0]);
  }
  Array.from(activeProduct).forEach((productName: string, index: number) => {
    chartColors.push(Colors[index + 1]);
  });
  return chartColors;
};

export const formatPieChartLabel = (label: string): string[] => {
  const tireIndex = label.indexOf('-');
  label.slice(tireIndex - 1, tireIndex);
  const withoutFirstWhiteSpace = label.replace(' ', '');
  return withoutFirstWhiteSpace.split(' ');
};

export const formatAreaChartLabel = (name: string): string => {
  if (name === 'value') {
    return ALL_PRODUCTS;
  }

  return name.replace('data.', '');
};
