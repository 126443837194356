import React from 'react';

import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import NotFound from '.';
// eslint-disable-next-line import/no-cycle
import { ErrorGuest } from '../guest/guest';

export const NotFoundGuest = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  return (
    <ErrorGuest>
      <NotFound
        link={<button type="button" onClick={goBack}>Вернуться назад</button>}
      />
    </ErrorGuest>
  );
};
