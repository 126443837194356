import styled from 'styled-components';

import { Common } from 'components/shared';

const {
  DescriptionText,
  LinkText,
  ExtraText
} = Common

const DocsManagement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .scrollbar-container {
    padding: 26px;
  }
  ${LinkText} {
    margin-bottom: 46px;
    display: flex;
    svg {
      margin-right: 10px;
      * {
        fill: ${({ theme }) => theme.colors.link};
      }
    }
  }
  & > ${ExtraText} {
    text-align: center;
  }
`;
const Agreement = styled.div`
  height: 320px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 5px;
  p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 20px;
    ${DescriptionText} {
      color: ${({ theme }) => theme.colors.textPrimary};
    }
  }
  margin-top: 34px;
  margin-bottom: 32px;
`;
const WrapperContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: -12px;
  margin-bottom: -12px;
  position: absolute;
  padding: 26px;
  overflow: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
`;
export default {
  DocsManagement,
  Agreement,
  WrapperContent
};
