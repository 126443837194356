import styled from 'styled-components';

import { Common } from 'components/shared';
import { media, theme } from 'assets/styles/theme';

const { Panel } = Common

export default styled.span`
  display: inline-block;
  float: right;
  vertical-align: middle;
  padding-right: 20px;

  :hover ${Panel} {
    opacity: 1;
  }

  svg * {
    fill: ${theme.colors.link};
  }

  ${Panel} {
    width: 400px !important;
    position: absolute;
    left: 50px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    z-index: 10;

    ${media.mobile} {
      box-shadow: 0 5px 10px 0 ${theme.colors.shadow};
      width: 250px !important;
    }
  }
`;
