import { Token } from 'entities/token';
import { compareRangeWithLastDay } from 'helpers/date/compareDates';
import { formatDate } from 'helpers/date/format';
import { IDateRange } from 'interfaces/account';

import { refreshToken } from './refreshToken';

export const getHeaders = async () => {
  let token = Token.get('access');
  const remainingTime = Token.getRemainingTime();
  if (token && remainingTime < 2) {
    try {
      await refreshToken();
      token = Token.get('access');
    } catch (e) {
      return {};
    }
  }
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
  };
};

export const getRangeParams = (range: IDateRange | undefined) => {
  if (!range || !range.from) {
    return undefined;
  }
  let params;
  const { from, to } = range;
  if (!compareRangeWithLastDay(range)) {
    params = {
      date_from: formatDate(from),
      date_to: formatDate(to)
    };
  }
  return params;
};

export const getPurchaseHeaders = () => {
  return { Authenticate: `Token ${Token.get('purchase')}` };
};

export const getClientDataHeaders = () => {
  return { Authenticate: `Token ${Token.get('clientApprove')}` };
};

export const getRedemptionHeaders = () => {
  return { Authenticate: `Token ${Token.get('redemption')}` };
};

export const getRecognitionHeaders = () => {
  return { Authenticate: `Token ${Token.get('recognition')}` };
};
