import React from 'react';

import { Common } from 'components/shared';
import { PHONE } from 'constants/phone';

const { ExtraText } = Common

export const serviceErrorText = (
  <ExtraText>
    Для продолжения оформления, пожалуйста, обратитесь в&nbsp;офис продаж
    &laquo;УК&nbsp;ВЕЛЕС Менеджмент&raquo; по&nbsp;адресу: г.&nbsp;Москва,
    Краснопресненская набережная, д. 12, подъезд 7,
    помещение &#8544;аж, этаж 14, комнаты 40, 42, 43, 44,
    <br />
    При возникновении вопросов свяжитесь с&nbsp;консультантом по&nbsp;телефону{' '}
    {PHONE}.
  </ExtraText>
);
