import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import size from 'lodash/size';

import { Input, Common } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { InputEvent } from 'types/inputEvent';

import { Props } from './interfaces';

import S from '../styles';

const { Button, InfoButton } = Common
const {
  RowButtons,
  DescriptionContainer,
  Description: DescriptionStyle,
  Form
} = S

const second = 1000;
const startTimer = 60;

export const Sms = ({
  nextPage,
  checkCodeAction,
  sendCodeAction,
  onSubmit,
  onClose,
  submitTitle = 'Войти',
  showCancelButton = true,
  description: Description,
  currentReducer
}: Props) => {
  const [counter, setCounter] = useState(startTimer)
  const [code, setCode] = useState('')
  const [timerId, setTimerId] = useState(NaN)
  const {
    // @ts-expect-error
    [currentReducer]: {
      sms: {
        check: {
          error: { message },
          request: { loading, success }
        }
      }
    }
  } = useStore()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const checkCode = (code: string) => dispatch(checkCodeAction(code))
  const sendCode = () => sendCodeAction && dispatch(sendCodeAction())
  useEffect(() => {
    if (!success) sendCode();
    handleSuccessCheck(success);
    startCountdown();
  }, [success])
  useEffect(() => () => clearInterval(timerId), [])
  const handleSuccessCheck = (success: boolean) => {
    if (success && onSubmit) {
      onSubmit();
      if (nextPage) navigate(`${nextPage}`);
    }
  }

  const resetTimer = () => {
    clearInterval(timerId);
    setCounter(startTimer);
    startCountdown();
  }

  const handleSendAgain = () => {
    sendCode();
    resetTimer();
  }

  const startCountdown = () => {
    const timerId = window.setInterval(() => {
      setCounter((counter) => (counter >= 1 ? counter-1 : 0));
    }, second);
    setTimerId(timerId);
  }

  const codeChange = (event: InputEvent) => {
    const { value } = event.currentTarget;
    const code = value.replace(/\D/g, '');

    if (size(code) <= 4) {
      setCode(code);
    }
  }

  const formSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (size(code) === 4) {
      checkCode(code);
    }
  }

  return (
    <Form onSubmit={formSubmit}>
      <Input
        placeholder="Код из СМС"
        value={code}
        onChange={codeChange}
        error={message}
        autoFocus
        type="tel"
        mask="9 9 9 9"
        maskChar="_"
        disabled={loading}
      />
      <DescriptionContainer>
        <DescriptionStyle>Не пришло СМС?</DescriptionStyle>
        <DescriptionStyle>
          {counter
            ? (`Повторно отправить код через: ${counter} сек.`)
            : (
              <S.SendAgain onClick={() => handleSendAgain()}>
                Отправить код еще раз
              </S.SendAgain>)}
        </DescriptionStyle>
      </DescriptionContainer>
      <RowButtons>
        {showCancelButton && (
          <InfoButton type="button" onClick={onClose}>
            Отмена
          </InfoButton>
        )}
        <Button type="submit" disabled={loading}>
          {submitTitle}
        </Button>
      </RowButtons>
      {Description && <Description />}
    </Form>
  );
}
