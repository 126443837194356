import {
  takeLatest,
  call,
  put
} from 'redux-saga/effects';

import { actionTypes } from 'store/actionTypes';
import { IAction } from 'interfaces';
import { api, IRequestParams } from 'api';
import { Url } from 'api/url';
import { Serializer } from 'api/serializer';

const { GET_INFO } = actionTypes.REBUY;

interface IRebuyResponse {
  number: string;
  date: string;
}
function* getRebuyInfo({ payload: productId }: IAction<string>) {
  try {
    const params: IRequestParams = {
      url: `${Url.rebuy.getInfo}/${productId}`
    };
    const response: IRebuyResponse = yield call(api.get, params);
    const order = Serializer.order(response);
    yield put({
      type: GET_INFO.SUCCESS,
      payload: { data: { order } }
    });
  } catch ({ code, message }) {
    if (code === 404) {
      yield put({
        type: actionTypes.REBUY.NOT_REBUY,
        payload: message
      });
    } else {
      yield put({
        type: GET_INFO.ERROR,
        payload: message
      });
    }
  }
}

export function* rebuy() {
  yield takeLatest(GET_INFO.REQUEST, getRebuyInfo);
}
