import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { IRecommendedProduct } from 'interfaces';
import { Url } from 'api/url';
import { api } from 'api';
import {
  requestProducts,
  errorProducts,
  successProducts
} from '../../reducers/recommendedProducts';

function* fetchRecommendedProducts() {
  try {
    const params = {
      url: Url.recommendedProducts.get
    };
    const recommendedProducts: IRecommendedProduct[] = yield call(
      api.get,
      params
    );
    yield put(successProducts(recommendedProducts));
  } catch (e) {
    console.log(e.message)
    yield put(errorProducts(e.message));
  }
}
export function* getRecommendedProducts() {
  yield takeEvery(
    requestProducts.toString(),
    fetchRecommendedProducts
  );
}
