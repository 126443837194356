import { IAction } from 'interfaces';

// eslint-disable-next-line import/no-cycle
import { IMultiStepWizardState } from '../interfaces';
import { IStepForm } from './interfaces';

export const initialState = {
  currentForm: 0,
  currentStep: 0,
  forms: [[]]
};

export const multiStepReducer = (
  state: IMultiStepWizardState = initialState,
  action: IAction<IStepForm>
): IMultiStepWizardState => {
  const { forms, currentStep } = state;
  const stepsCount = forms.length;
  const formsCount = forms[currentStep].length;

  switch (action.type) {
    case 'GOTO':
      if (action.payload) {
        const { currentForm, currentStep } = action.payload;
        return { forms, currentStep, currentForm };
      }

      return state;

    case 'NEXT':
      return {
        ...getNextState(state, stepsCount, formsCount),
        forms
      };

    default:
      return state;
  }
};

const getNextState = (
  state: IMultiStepWizardState,
  stepsCount: number,
  formsCount: number
): IStepForm => {
  if (isLastForm(state, stepsCount, formsCount)) {
    return state;
  }

  return state.currentForm === formsCount - 1
    ? getNextStep(state.currentStep)
    : getNextForm(state);
};

const isLastForm = (
  state: IMultiStepWizardState,
  stepsCount: number,
  formsCount: number
): boolean => {
  const { currentForm, currentStep } = state;

  return currentStep + 1 === stepsCount && currentForm + 1 === formsCount;
};

const getNextForm = ({
  currentForm,
  currentStep
}: IMultiStepWizardState): IStepForm => ({
  currentStep,
  currentForm: currentForm + 1
});

const getNextStep = (step: number): IStepForm => ({
  currentStep: step + 1,
  currentForm: 0
});
