import styled from 'styled-components';

import { Common } from 'components/shared';
import { media, theme } from 'assets/styles/theme';

const { ExtraText } = Common

const Title = styled(ExtraText)`
  margin-top: -36px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  ${media.tablet} {
    font-weight: 600;
    font-size: 16px;
  }
  ${media.mobile} {
    line-height: 24px;
    text-align: left;
    white-space: pre-line;
    margin-top: -38px;
    margin-bottom: -15px;
  }
`;

const Description = styled(ExtraText)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  text-align: left;
  margin-bottom: 28px;
  a {
    color: ${theme.colors.textPrimary};
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    ${media.mobile} {
      font-size: 17px;
      line-height: 23px;
    }
  }

  ${media.mobile} {
    text-align: left;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;
  }
`;
export default { Description, Title }
