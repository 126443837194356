import styled from 'styled-components';

import { ITheme } from 'assets/styles/theme';
import ArrowConfirm from 'assets/img/svg/arrow-confirm.svg';

interface ICheckboxProps {
  checked: boolean;
  theme: ITheme;
}
export const checkboxBefore = (theme: ITheme, checked: boolean) => `
  content: "";
  position: absolute;
  cursor: pointer;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  transition: background 0.1s;
  background: ${checked ? theme.colors.link : theme.colors.border}
    url(${checked ? `${ArrowConfirm}` : ''})
    center no-repeat;
`;

const Checkbox = styled.label<ICheckboxProps>`
  position: relative;
  display: block;
  padding-left: 36px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme: { colors: { textPrimary } } }) => textPrimary};
  a {
    font-size: 14px;
    line-height: 20px;
  }
  input {
    display: none;
  }
  :focus {
    outline: none;
  }
  :before {
    ${({ theme, checked }) => checkboxBefore(theme, checked)}
  }
`;

export default { Checkbox };
