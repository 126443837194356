import React, { useEffect, useRef } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import smoothscroll from 'smoothscroll-polyfill';
import flatten from 'lodash/flatten';
import size from 'lodash/size';
import keys from 'lodash/keys';
import map from 'lodash/map';
import intersection from 'lodash/intersection';

import { LoaderAndCap } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { tooltipFormatter } from 'helpers/chart/tooltipFormatter';
import {
  AreaChart as Chart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';

import { IAreaChartProps } from './inerfaces';

import S, {
  ItemStyle,
  WrapperStyle,
  CursorStyle,
  WrapperContent,
  Text
} from './styles';

dayjs.locale('ru');
const tooltipCoords = {
  x: 0,
  y: 20
};
const AxisTick = ({
  index,
  fill,
  formatter,
  payload: { value },
  x,
  y,
  width
}: any) => {
  const tickText = formatter(value);
  const isFirst = !index;
  const letterWidth = 6;
  const maxX = width - tickText.length * letterWidth;
  const tickParams = {
    fill,
    y,
    x: isFirst ? letterWidth : Math.min(x, maxX),
    textAnchor: isFirst || x >= maxX ? 'left' : 'middle',
    dy: 12
  };

  return <text {...tickParams}>{tickText}</text>;
};

export const AreaChart = ({
  data,
  activeProducts,
  dateRange,
  dateBorders,
  colors
}: IAreaChartProps) => {
  const { chart: { chartDataLoader } } = useStore()
  const dateFrom = (dateRange && dateRange.from) || (dateBorders && dateBorders.from);
  const dateTo = (dateRange && dateRange.to) || (dateBorders && dateBorders.to);
  const isDiffYears: boolean = !!dateFrom &&
      !!dateTo &&
      dateFrom.getFullYear()
      !== dateTo.getFullYear();
  const dateFormat = (date: Date) => dayjs(date).format('DD MMM YYYY');
  const maxTicks = isDiffYears ? 5 : 7;
  const intervals =
    data.length > maxTicks
      ? Math.max(1, Math.ceil(data.length / maxTicks))
      : 'preserveStartEnd';
  const sortedData = [...data].sort((left, right) => (left.date < right.date
    ? -1
    : 1));
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      smoothscroll.polyfill();
      ref.current.scrollTo(10000, 0);
    }
  });

  if (chartDataLoader) {
    return <LoaderAndCap isLoader />
  }
  /* проверяем есть ли в текущих данных необходимый продукт для отображения диаграммы с ним */
  const keysDataArray = map(data, (el) => keys(el.data))
  if (!size(intersection(flatten(keysDataArray), activeProducts))) {
    return (
      <WrapperContent>
        <Text>
          Нет данных за указанный период, попробуйте выбрать другой интервал
        </Text>
      </WrapperContent>
    )
  }
  return (
    <S.AreaChart data-test="area-chart-component" ref={ref}>
      <ResponsiveContainer width="99.9%" height={357}>
        <Chart data={sortedData}>
          <CartesianGrid strokeWidth={0.6} />
          <XAxis
            dataKey="date"
            interval={intervals}
            tick={
              <AxisTick
                textAnchor="end"
                angle={-45}
                formatter={dateFormat}
                intervals={intervals}
              />
            }
            axisLine={false}
            tickLine={false}
          />
          <YAxis hide type="number" domain={['auto', 'dataMax']} />
          <Tooltip
            separator=""
            coordinate={tooltipCoords}
            cursor={CursorStyle}
            formatter={tooltipFormatter}
            // @ts-expect-error
            itemStyle={ItemStyle}
            wrapperStyle={WrapperStyle}
            labelFormatter={() => ''}
          />
          {Array.from(activeProducts).map((productName, index) => (
            <Area
              type="monotone"
              key={`data.${productName}`}
              dataKey={`data.${productName}.price`}
              stroke={colors[index]}
              fill={colors[index]}
              fillOpacity={0.1}
              strokeWidth={2}
              activeDot={{ strokeWidth: 3, r: 5 }}
            />
          ))}
        </Chart>
      </ResponsiveContainer>
    </S.AreaChart>
  );
};
