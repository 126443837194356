import styled from 'styled-components';

import S from 'components/shared/commonTooltip/styles';
import { TooltipWidth } from 'assets/styles/mixins';

const { Tooltip } = S

const SidebarUser = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    display: block;
  }
  .greeting {
    font-size: 12px;
    letter-spacing: 0.05px;
    line-height: 15px;
    margin-bottom: 5px;
  }
  .user-name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.05px;
    line-height: 19px;
  }
  ${Tooltip} {
    ${TooltipWidth}
  }
`;

export default { SidebarUser };
