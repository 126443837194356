import {
  takeLatest,
  put,
  call,
  select
} from 'redux-saga/effects';
import UrlParse from 'url-parse';
import reduce from 'lodash/reduce';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { PuchaseRequestData } from 'entities/purchaseRequestData';
import { Token } from 'entities/token';
import { getPurchaseHeaders, getRedemptionHeaders } from 'api/options';
import { env, Url } from 'api/url';
import { apiUrl } from 'api/hosts';
import { swapOriginToOnline } from 'helpers/swapOriginToOnline';
import {
  IAction,
  IPurchaseData,
  IState,
  IUserDocs
} from 'interfaces';

const {
  OPIF,
  NOT_RESPOND,
  IS_CONFLICT
} = actionTypes.PURCHASE;

const getPurchaseAction = (state: IState) => state.purchase.data.action;

interface IPurchaseAction {
  payload: {
    data:IAction<IPurchaseData>,
    requestId: string
  }
  url: string;
}

export function* sendPurchaseData({ url, payload }: IPurchaseAction) {
  const { SUCCESS, ERROR } = OPIF;
  try {
    if (payload) {
      const { data: purchaseData, requestId } = payload;
      if (url && url !== Url.edo.sendCode) {
        // @ts-expect-error
        const purchaseAction = yield select(getPurchaseAction);
        const { requestData } = new PuchaseRequestData(
          purchaseData,
          purchaseAction
        );
        const purchaseHeaders = getPurchaseHeaders();
        // @ts-expect-error
        const response = yield call(
          api.post,
          { url, data: requestData },
          purchaseHeaders
        );
        const { token } = response;
        if (token && url !== Url.redemption.submit) {
          Token.set('purchase', token);
        } else if (token && url === Url.redemption.submit) {
          Token.set('redemption', token);
        }
      }
      if (url === Url.purchase.submit) {
        const response: IUserDocs[] = yield call(
          api.get,
          { url: Url.userDocs.get },
          getPurchaseHeaders()
        );
        interface IReducedDocs {
          [key: string]: IUserDocs;
        }

        const docs = reduce(response, (accumulator: IReducedDocs, doc) => {
          // eslint-disable-next-line default-case
          switch (doc.title) {
            case 'Единый файл': {
              accumulator.unified = doc;
              break;
            }
            case 'Заявление об открытии лицевого счета': {
              accumulator.openLc = doc;
              break;
            }
            case 'Заявлении о присоединении к Договору': {
              accumulator.connectDog = doc;
              break;
            }
            case 'Анкета зарегистрированного лица': {
              accumulator.profileRegistred = doc;
              break;
            }
            case 'Заявка на приобретение инвестиционных паев': {
              accumulator.applicationAcquisition = doc;
              break;
            }
            case 'Cогласие на обработку персональных данных': {
              accumulator.agreementPersonal = doc;
              break;
            }
            case 'Анкета физического лица': {
              accumulator.profilePhysical = doc;
              break;
            }
            case 'Реквизиты': {
              accumulator.transit = doc;
              break;
            }
            case 'Документы по заявке': {
              accumulator.applicationDocuments = doc;
              break;
            }
          }
          return accumulator;
        }, {});

        yield put({
          type: actionTypes.PURCHASE.USER_DOCS.GET.SUCCESS,
          payload: docs
        });

        yield put({
          payload: { requestId, data: purchaseData },
          type: SUCCESS
        });
      } else if (url === Url.redemption.submit) {
        const response: IUserDocs[] = yield call(
          api.get,
          { url: Url.redemption.get },
          getRedemptionHeaders()
        );
        interface IReducedDocs {
          [key: string]: IUserDocs;
        }

        const docs = reduce(response, (accumulator: IReducedDocs, doc) => {
          // eslint-disable-next-line default-case
          switch (doc.title) {
            case 'Заявка на погашение инвестиционных паев': {
              accumulator.redemption = doc;
              break;
            }
          }
          return accumulator;
        }, {});

        yield put({
          type: actionTypes.REDEMPTION.GET.SUCCESS,
          payload: docs
        });

        yield put({
          payload: { requestId, data: purchaseData },
          type: SUCCESS
        });
      } else {
        yield put({
          payload: { requestId, data: purchaseData },
          type: SUCCESS
        });
      }
    }
  } catch ({ code, payload }) {
    if (code === 409) {
      yield put({
        type: IS_CONFLICT
      });
    } else if (code !== 500) {
      yield put({
        payload,
        type: ERROR
      });
    } else {
      yield put({
        type: NOT_RESPOND
      });
    }
  }
}

export function* purchase() {
  // @ts-expect-error
  yield takeLatest(OPIF.REQUEST, sendPurchaseData);
}

export function getHost(url: string): { host: string; pathname: string } {
  const parsedUrl = new UrlParse(url);
  const host = `${swapOriginToOnline(parsedUrl.origin)}/`;
  const pathname = parsedUrl.pathname.replace('/', '');
  if (parsedUrl.hostname) {
    return { pathname, host };
  }
  return {
    pathname,
    host: env === 'development' ? apiUrl.dev : apiUrl.production
  };
}
