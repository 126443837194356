import styled from 'styled-components';

import { Common, StylesPopup } from 'components/shared';
import { media } from 'assets/styles/theme';

interface ISmsDescriptionProps {
  isTextCenter?: boolean;
}

const { ExtraText, LinkText } = Common
const SmsDescription = styled(ExtraText)<ISmsDescriptionProps>`
  max-width: 500px;
  display: block;
  text-align: ${({ isTextCenter }) => (isTextCenter ? 'center' : 'justify')};
  position: relative;
  ${LinkText} {
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
  }
  .hint {
    display: inline;
    z-index: 20;
    ${StylesPopup} {
      width: 390px;
      ${media.mobile} {
        width: 260px;
      }
      ${ExtraText} {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;
export default { SmsDescription }
