import styled from 'styled-components';

import StylesDateFilters from 'components/widgets/dateFilters/styles';
import StylesDatepicker from 'components/shared/datepicker/styles';
import { media } from 'assets/styles/theme';

const Title = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.link};
  font-size: 16px;
  line-height: 20px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.link};
  svg {
    margin-left: 10px;
  }
`;

const Filters = styled.div`
  padding-top: 20px;
`;

const ChartsDatepicker = styled.section`
  ${StylesDateFilters.DateFilters} {
    flex-direction: row;
    align-items: center;
  }

  ${StylesDateFilters.Inputs} {
    margin: 0 0 0 20px;
  }
`;

const Datepicker = styled.div`
  position: absolute;

  ${StylesDatepicker.Datepicker} {
    background-color: ${({ theme }) => theme.colors.white};
    left: 450px;
    bottom: -220px;
    z-index: 21;

    ${media.mobile} {
      left: -240px;
    }
  }
`;

export default {
  ChartsDatepicker,
  Title,
  Filters,
  Datepicker
};
