import styled from 'styled-components';

const Result = styled.text`
  tspan {
    fill: ${({ theme }) => theme.colors.textPrimary};
    font-size: 16px;
  }
`;
const ActiveShape = styled.g`
  tspan.pie-chart-label {
    font-size: 10px;
  }
`;
export default { ActiveShape, Result };
