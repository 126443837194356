import { RefObject } from 'react';

export function getWidth(ref: RefObject<HTMLElement>) {
  return ref && ref.current
    ? ref.current.clientWidth
    : 0;
}

export function scrollIntoRef(ref: RefObject<HTMLElement>) {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }
}
