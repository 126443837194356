import { throttle, put } from 'redux-saga/effects';

import { actionTypes } from 'store/actionTypes';
import { IAction } from 'interfaces';

function* setShareCount(action:IAction) {
  yield put({ ...action, type: actionTypes.SET_SHARE_COUNT });
}

export function* throttleSetShareCount() {
  yield throttle(250, actionTypes.SET_SHARE_COUNT_THROTTLE, setShareCount);
}
