import styled, { css } from 'styled-components';

import StylesInput from 'components/shared/input/styles';
import { media } from 'assets/styles/theme';

interface ITooltipStyles {
  isShow: boolean;
  notBlur?: boolean;
}

const { Input } = StylesInput;

const Background = styled.div<ITooltipStyles>`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  ${({ notBlur }) => !notBlur && css`backdrop-filter: blur(2px);`}
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  ${({ isShow }) => (isShow
    ? css` 
        display: flex;
        justify-content: center;
        align-items: center;`
    : css`
        display: none
      `)}
`;

const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 ${({ theme }) => theme.colors.shadow};
  padding: 30px;
  
  ${media.mobile} {
    padding: 20px;
  }
  ${Input} {
    margin: 0 20px 0 20px;
    width: 370px;
    ${media.mobile} {
      width: 300px;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  padding: 15px 16px;
  margin: 0 !important;
`;

export default {
  CloseButton,
  Tooltip,
  Background
}
