import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IReportsState } from './interfaces';
import { IReports } from '../../../interfaces';

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: { items: [], error: '' } as IReportsState,
  reducers: {
    requestReports: (state) => state,
    successReports: (state, action: PayloadAction<IReports[]>) => {
      state.items = action.payload
    },
    errorReports: (state, action) => {
      state.error = action.payload
    }
  }
})
export const {
  requestReports,
  successReports,
  errorReports
} = reportsSlice.actions
