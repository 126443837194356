import styled, { css } from 'styled-components';

import { media } from 'assets/styles/theme';

interface IWrapperContentProps {
  text?: string | boolean
}

export const WrapperContent = styled.div<IWrapperContentProps>`
  width: 100%;
  height: 100%;
  ${({ text }) => text && css` 
    flex-direction: column
  `}
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 100px;
    margin-bottom: 40px;
    
    ${media.mobile} {
      margin-top: 40px;
    }
  }
`;

export const Text = styled.div`
  width: 60%;
  text-align: center;
  margin-bottom: 100px;
  font-weight: 500;
  font-size: 23px;
  line-height: 37px;
  color: #424249;

  ${media.tablet} {
    line-height: 30px;
    font-size: 20px;
  }

  ${media.mobile} {
    line-height: 25px;
    width: 80%;
    margin-bottom: 50px;
    font-size: 18px;
  }
`;
