import { createSlice } from '@reduxjs/toolkit';
/* установка текущих курсов валют */
export const currencyCoursesSlice = createSlice({
  name: 'currencyCourses',
  initialState: {
    '€': NaN,
    $: NaN,
    '₽': 1
  },
  reducers: {
    setCourses: (state, action) => ({
      ...state,
      ...action.payload
    })
  }
})
export const { setCourses } = currencyCoursesSlice.actions
