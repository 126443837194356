import axios, { AxiosResponse } from 'axios';

import { onlineHost, Url } from 'api/url';
import { getPurchaseHeaders } from 'api/options';
import {
  IRecommendedProduct,
  IterableEntity,
  IZPIFRequisites
} from 'interfaces';

import { IGetStatusResponse } from './interfaces';

export async function initRequest(
  recommendedProducts: IterableEntity<IRecommendedProduct>,
  productId: string,
  setRequisites: (data:IZPIFRequisites) => void,
  typeId?: number
): Promise<{ orderId: string; formUrl: string }> {
  try {
    const currentUrl = typeId === 4 || typeId === 5
      ? Url.purchase.requisites
      : Url.purchase.frame;
    const response = await axios.get(currentUrl, {
      headers: { ...getPurchaseHeaders() }
    });
    setRequisites(response.data);
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function getStatus(
  productId: string,
  orderId: string
): Promise<IGetStatusResponse> {
  const res: AxiosResponse<IGetStatusResponse> = await axios.get(
    `${onlineHost}api-acquire/get-status/${productId}`,
    {
      params: { orderId },
      headers: getPurchaseHeaders()
    }
  );

  return res.data;
}
