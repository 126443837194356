import React, { useRef } from 'react';

import ScrollBar from 'react-custom-scrollbars';

import { IProps } from './interfaces';

import S from './styles';

export const ScrollContainer = ({
  renderView,
  children,
  setButtonDisabled
}: IProps) => {
  const scrollRef = useRef<any>(null);
  // для активации кнопки при подписи ЭДО //
  const onScrollHandler = () => {
    if (scrollRef.current.getScrollTop() > 350) {
      if (setButtonDisabled) {
        setButtonDisabled(false);
      }
    }
  };
  return (
    <ScrollBar
        onScrollStart={onScrollHandler}
        renderView={renderView}
        renderTrackVertical={() => <S.TrackElement />}
        renderThumbVertical={() => <S.Thumb />}
        thumbSize={50}
        ref={scrollRef}
    >
      {children}
    </ScrollBar>
  );
};
