import React from 'react';

import { ITooltipProps } from '../commonTooltip/interfaces';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';
import { CommonTooltip } from '../commonTooltip';

/* !!!дополнительная обертка WrapperTooltip нужна для корректной работы хука useLockBodyScroll */
export const WrapperTooltip = ({
  isShow = false,
  children,
  onClose,
  showCloseButton
}: ITooltipProps) => {
  useLockBodyScroll(isShow)
  return (
    <CommonTooltip
      isShow
      onClose={() => onClose()}
      showCloseButton={showCloseButton}
    >
      {children}
    </CommonTooltip>
  );
};
