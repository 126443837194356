import { Icon } from '../assets/img/svg';

export const promoPanelData = [
  {
    Img: Icon.PromoGlobe,
    title: 'Управляйте активами',
    description: 'Доступ к инвестициям 24/7. Везде, где есть интернет.'
  },
  {
    Img: Icon.Portfel,
    title: 'Пополняйте портфель',
    description: 'Покупайте новые продукты. Наращивайте активы.'
  },
  {
    Img: Icon.Lock,
    title: 'Не беспокойтесь',
    description: 'Операции и вход в кабинет с подтверждением по СМС. Используем SSL- шифрование.'
  }
];
