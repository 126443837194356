import { call, put, takeLatest } from 'redux-saga/effects';

import { api } from 'api';
import { IAction } from 'interfaces';
import { Token } from 'entities/token';
import { removePhoneMask } from 'helpers/phone/phoneMask';
import { authMethod } from 'api/authMethod';
import { host } from 'api/url';
import {
  errorAuthSms,
  error,
  requestAuthSms,
  successAuth,
  successAuthSms,
  requestAuth
} from '../../reducers/auth';

interface IAuthDataAction {
  type: string;
  payload: {
    phone: string;
    password: string;
  };
}

let userData: {
  username: string;
  password: string;
};

function* fetchAuthData({ payload }: IAuthDataAction) {
  try {
    const { phone, password } = payload;
    const username = removePhoneMask(phone);
    const params = {
      url: host + authMethod,
      data: {
        username,
        password
      }
    };
    yield call(api.post, params);
    yield put(successAuth());
    userData = { username, password };
  } catch ({ message }) {
    console.log(message)
    yield put(error(message));
  }
}

function* fetchAuthCode({ payload }: IAction<string>) {
  try {
    const params = {
      url: host + authMethod,
      data: {
        code: payload,
        ...userData
      }
    };
    const { token, refresh_token } = yield call(api.post, params);
    Token.set('access', token);
    Token.set('refresh', refresh_token);
    yield put(successAuthSms());
  } catch ({ message }) {
    yield put(errorAuthSms(message));
  }
}

export function* authData() {
  yield takeLatest(
    requestAuth.toString(),
    fetchAuthData
  );
}

export function* authSms() {
  yield takeLatest(
    requestAuthSms.toString(),
    fetchAuthCode
  );
}
