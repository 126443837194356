import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useDispatch } from 'react-redux';

import { PurchaseTypes } from 'enums/purchaseTypes';
import { SmsBeforeNext } from 'components/widgets/applicationForms';
import Arrow from 'assets/img/svg/arrow.svg';
import { DescriptionRecognition } from 'components/widgets/applicationForms/checkData/sms/description';
import { LoaderTooltip } from 'components/widgets/tooltips/loader';
import { ServiceError } from 'components/widgets/applicationForms/error/serviceError';
import { IRecognitionSubmitData } from 'interfaces';
import { useStore } from 'components/hooks/useStore';
import { getSubmitData } from 'helpers/getSubmitData';
import { RadioButtonContainer, Common } from 'components/shared';
import {
  closeWindow,
  recognitionSmsCheckResetFail,
  recognitionSmsCheckResetRequest,
  requestSubmit,
  resetError
} from 'store/reducers/qualifiedStatusData';

import { PageInformation, radioButtons } from './pageIformation';
import { WrapperTooltip } from '../../shared/wrapperTooltip/wrapperTooltip';
import { LoaderAndCap } from '../../shared';
import { IFileSelect } from './interfaces';
import { DropZone } from '../../shared/dropzone';

import S from './styles';

const { LinkStyled, Button } = Common
const {
  Title,
  SubTitle,
  KvalPageWrapper,
  TypeDocumentWrapper,
  DescriptionType,
  Span,
  Section,
  DocTypeSelectWrapper,
  Input,
  TextResult
} = S

export const KvalPage = () => {
  const {
    qualifiedStatusData: {
      qualifiedStatus: {
        status: isQualifiedInvestor,
        isLoading: isQualifiedLoader
      },
      isOpenWindow,
      submitSuccess,
      error,
      sms: {
        check:
          {
            request:
              { loading, success }
          }
      },
    }
  } = useStore()

  const [docTypeVal, setDocTypeVal] = useState('');
  const [selectedFile, setSelectedFile] = useState<IFileSelect[]>([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const dispatch = useDispatch()
  const submitRecognition = (recognition: IRecognitionSubmitData) => {
    dispatch(requestSubmit(recognition))
  }
  const closeModalWindow = () => {
    dispatch(closeWindow());
    dispatch(recognitionSmsCheckResetRequest());
  }
  const resetFail = () => {
    dispatch(recognitionSmsCheckResetFail())
  }
  const {
    mainTitle,
    subTitle,
    typesDocument,
    addDocument,
    textResult
  } = PageInformation;

  useEffect(() => {
    if (!size(docTypeVal) || !size(selectedFile)) {
      setButtonDisabled(true);
    } else setButtonDisabled(false)
  }, [docTypeVal, selectedFile]);
  useEffect(() => () => {
    dispatch(resetError())
  }, [])

  const [status, changeStatus] = useState(false);

  const confirm = (): void => {
    changeStatus(!status);
    setButtonDisabled(true);
    submitRecognition(getSubmitData(docTypeVal, selectedFile));
  };

  const isShow = submitSuccess
    && !success
    && !loading
    && status;
  const closeSMSWindow = () => {
    resetFail();
    changeStatus(!status);
    setButtonDisabled(false);
  };
  const isShowErrorWindow = !isEmpty(error)

  const isSMSRequestLoading = loading;

  const isShowSuccessWindow = success && isOpenWindow;

  if (isQualifiedLoader) {
    return (
      <LoaderTooltip isShow description="Пожалуйста, подождите..." />
    );
  }

  if (isQualifiedInvestor) {
    return (
      <LoaderAndCap
        icon={Arrow}
        text="Уважаемый клиент, Вы уже являетесь квалифицированным инвестором"
      />
    );
  }

  return (
    <KvalPageWrapper>
      <Title>{mainTitle}</Title>
      <Section>
        <SubTitle>{subTitle[0]}</SubTitle>
        <TypeDocumentWrapper>
          <Input
            type="checkbox"
            checked
            disabled
            aria-checked="true"
          />
          <Span>{typesDocument[0]}</Span>
        </TypeDocumentWrapper>
        <TypeDocumentWrapper>
          <Input
            type="checkbox"
            checked
            disabled
            aria-checked="true"
          />
          <Span>{typesDocument[1]}</Span>
        </TypeDocumentWrapper>
      </Section>
      <Section>
        <SubTitle>{subTitle[2]}</SubTitle>
        <Section>
          <SubTitle>{subTitle[1]}</SubTitle>
          <DescriptionType>{addDocument}</DescriptionType>
        </Section>
        <DropZone
            setSelectedFile={(files: IFileSelect[]) => setSelectedFile(files)}
            name="dropZone"
            title="Перетащите сюда файлы или кликните для выбора"
        />
        <DocTypeSelectWrapper>
          <RadioButtonContainer
            options={radioButtons}
            onChange={setDocTypeVal}
            selectedOption={docTypeVal}
          />
        </DocTypeSelectWrapper>
      </Section>
      <Section>
        <Button
        disabled={isButtonDisabled}
        onClick={confirm}
        >
          Получить СМС-код
        </Button>
        {isShow &&
        <SmsBeforeNext
          onCancel={closeSMSWindow}
          isShow
          description={() => <DescriptionRecognition />}
          type={PurchaseTypes.recognition}
        />}
        {isSMSRequestLoading && <LoaderTooltip isShow description="Пожалуйста, подождите..." /> }
        {isShowErrorWindow && <ServiceError isShow />}
        {isShowSuccessWindow &&
        <WrapperTooltip isShow onClose={closeModalWindow}>
          <TextResult>{textResult[0]}</TextResult>
          <TextResult>{textResult[1]}</TextResult>
          <LinkStyled
            onClick={closeModalWindow}
            to="/portfolio"
          >
            Продолжить работу с личным кабинетом
          </LinkStyled>
        </WrapperTooltip>}
      </Section>
    </KvalPageWrapper>
  );
};
