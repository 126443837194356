import React from 'react';

import { IWizardForm } from 'interfaces';
import { FormField } from 'entities/formField';
import { getAppeal } from 'helpers/form';

import { Form } from '../../form';
import { useStore } from '../../../hooks/useStore';

import S from './styles';

const { Description, Title } = S

const SuccessTitle = () => {
  const {
    purchase: {
      data: {
        firstName,
        lastName,
        patronymic,
        gender
      }
    }
  } = useStore()
  const userName = `${firstName} ${patronymic || lastName}`;
  return <Title>{getAppeal(gender, userName)}</Title>;
};

const SuccessDescription = () => (
  <Description>
    Ваши документы находятся в&nbsp;обработке. При возникновении вопросов
    свяжитесь с&nbsp;консультантом по&nbsp;телефону{' '}
    <a href="tel:+7 (495) 967-09-13" tabIndex={0}>
      {' '}
      +7&nbsp;(495)&nbsp;967-09-13
    </a>
    .
  </Description>
);

const title = <SuccessTitle />;
const description = <SuccessDescription />;

const successField = new FormField(
  {
    name: 'success_field',
    placeholder: '',
    type: 'content',
    initialValue: ''
  },
  {
    groupTitle: title,
    groupDescription: description
  }
);

export const successPage: IWizardForm = {
  title: '',
  url: '',
  fields: [successField],
  submitTitle: 'Продолжить работу с личным кабинетом',
  formComponentClassName: 'success-form'
};

export const TestSuccess = () => (
  <Form fields={[successField]} url="" onSubmit={() => undefined} />
);
