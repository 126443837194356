import React from 'react';

import { Provider } from 'react-redux';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';
import { ThemeProvider } from 'styled-components';
import 'normalize.css';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';

import { store } from './store/configureStore';
import { GlobalStyles } from './assets/styles/globalStyles';
import { theme, gridTheme } from './assets/styles/theme';
import { useAuth } from './components/hooks/useAuth';
import { useStore } from './components/hooks/useStore';
import { NotFoundGuest } from './components/pages/errors';
import {
  ClientData,
  Messages,
  KvalPage,
  LkPanel,
  LoginPage,
  PortfolioPage,
  Product,
  Reports,
  Purchase
} from './components/pages';

const ProtectedRoutes = () => {
  const { authentication: { isAuth } } = useStore()
  const isAuthCurrent = useAuth(isAuth);
  if (!isAuthCurrent) {
    return <Navigate to="login" replace />
  }
  return <LkPanel />
}

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <BaseCSS />
      <GlobalStyles />
      <GridThemeProvider gridTheme={gridTheme}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              <Route
                path="products/:productType/:productId/:action/:step/:form"
                element={<Purchase />}
              />
              <Route path="/messages" element={<Messages />} />
              <Route path="/messages/client-data" element={<ClientData />} />
              <Route path="portfolio" element={<PortfolioPage />} />
              <Route path="products/:id" element={<Product />} />
              <Route path="reports" element={<Reports />} />
              <Route path="kval" element={<KvalPage />} />
              <Route path="/" element={<Navigate to="portfolio" />} />
            </Route>
            <Route path="login" element={<LoginPage />} />
            <Route
                path="*"
                element={<NotFoundGuest />}
            />
          </Routes>
        </ThemeProvider>
      </GridThemeProvider>
    </Provider>
  </BrowserRouter>
)

export default App;
