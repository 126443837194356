import styled from 'styled-components';

import NumberFieldStyles from 'components/shared/numberField/styles';
import { media } from 'assets/styles/theme';
import { StylesDateRange } from 'components/shared';

const { NumberField } = NumberFieldStyles;

interface IAreaChartInfoProps {
  isProductWidget?: boolean
}

const AreaChartInfo = styled.div<IAreaChartInfoProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 22px;

    .date-range-wrapper {
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: flex-start;
      ${media.tablet} {
        width: 100%;
        margin-bottom: 10px;
        align-items: ${({ isProductWidget }) => (isProductWidget ? 'flex-start' : 'center')}
      }
      ${media.mobile} {
        align-items: center;
      }
    }

  ${media.tablet} {
    flex-direction: column;
    align-items: center;
  }

    ${media.mobile} {
      justify-content: center;
    }
`;

const Result = styled.span`
  font-size: 16px;
  max-height: 21px;
  display: flex;
  white-space: pre;

  ${media.mobile} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const ResultTitle = styled.span`
  color: ${({ theme }) => theme.colors.textPrimary};
  ${media.mobile} {
    display: block;
    width: 100%;
    margin: 5px 0 5px;
    text-align: center;
  }
`;

const Separator = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.description};
  white-space: pre;
`;
const ResultValue = styled.div`
  display: flex;
  flex-wrap: nowrap;
  ${media.mobile} {
    ${Separator},${NumberField}{
      font-size:17px !important;
      }
  }
`;

const Header = styled.div`
  margin-top: 0!important;
  ${Result}, ${StylesDateRange} {
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export default {
  Header,
  AreaChartInfo,
  Result,
  ResultTitle,
  Separator,
  ResultValue
};
