import React from 'react';

import dayjs, { Dayjs } from 'dayjs';
import forEach from 'lodash/forEach';

import { Icon } from 'assets/img/svg';
import { IDateRange } from 'interfaces';

import { IRadioButtonElement } from '../../shared/radioButtonContainer/interfaces';
import { IOption } from './interfaces';

import S from './styles';

export const getRadioButtons = (
  limits: IDateRange,
  hasLastElementText?: boolean,
  handleLastElementClick?: () => void
) => {
  const LastElementLabel = () => (
    <S.Button role="button" onClick={handleLastElementClick}>
      <span style={{ fontSize: '13px' }}>
        {hasLastElementText && 'Период'}
      </span>
      <Icon.Calendar />
    </S.Button>
  );
  const lastElement: IOption = {
    label: <LastElementLabel />,
    value: 'range'
  };

  return [...getOptions(limits), lastElement];
};

export const getOptions = (limits: IDateRange) => {
  const lastDay = dayjs(limits.to);
  const firstPurchase = dayjs(limits.from);
  const defaultOptions = [
    {
      label: '1 месяц',
      value: lastDay.subtract(1, 'month')
    },
    {
      label: '3 месяца',
      value: lastDay.subtract(3, 'month')
    },
    {
      label: '6 месяцев',
      value: lastDay.subtract(6, 'month')
    },
    {
      label: '1 год',
      value: lastDay.subtract(1, 'year')
    }
  ];
  const options: IRadioButtonElement[] = [];

  forEach(defaultOptions, (option: { label: string; value: Dayjs }) => {
    if (dayjs(firstPurchase) < option.value) {
      options.push({
        label: option.label,
        value: option.value.toString()
      });
    }
  });

  options.push({
    label: 'Все время',
    value: firstPurchase.toString()
  });

  return options;
};
