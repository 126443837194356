import {
  put,
  call,
  takeLatest,
  select
} from 'redux-saga/effects';

import { actionTypes } from 'store/actionTypes';
import { isUnauthorized } from 'helpers/actions/isUnauthorized';
import { DEFAULT_ERROR_MESSAGE } from 'constants/codeMessages';
import { api } from 'api';
import { getHeaders } from 'api/options';
import { Url } from 'api/url';
import { Serializer } from 'api/serializer';
import { IState, IClientInfo } from 'interfaces';
import { getAdditionalNameParams } from 'helpers/actions/purchase/getAdditionalFields';

const { REQUEST, ERROR, SUCCESS } = actionTypes.CLIENT_INFO;

function* getClientInfo() {
  try {
    const response: IClientInfo = yield call(
      api.get,
      {
        url: `${Url.clientInfo.get}`
      },
      getHeaders()
    );

    const userInfo = Serializer.clientInfo(response);

    yield put({
      type: SUCCESS,
      payload: { data: userInfo }
    });

    yield put({ type: actionTypes.PURCHASE.SET_USER_PARAMS.REQUEST });
  } catch (error) {
    const { code } = error;

    const unautorized = isUnauthorized(code);

    console.error(error);

    yield put(
      unautorized || {
        type: ERROR,
        payload: DEFAULT_ERROR_MESSAGE
      }
    );
  }
}

function* getUserParams() {
  try {
    const {
      purchase: {
        data: { firstName, lastName }
      }
    }: IState = yield select();
    if (firstName && lastName) {
      const { latinName } = yield call(
        getAdditionalNameParams,
        firstName,
        lastName
      );
      yield put({
        type: actionTypes.PURCHASE.SET_USER_PARAMS.SUCCESS,
        payload: {
          data: {
            latinName
          }
        }
      });
    } else {
      throw new Error('Отсутствует имя');
    }
  } catch (error) {
    yield put({
      type: actionTypes.PURCHASE.SET_USER_PARAMS.ERROR,
      payload: error
    });
  }
}
export function* clientInfo() {
  yield takeLatest(REQUEST, getClientInfo);
  yield takeLatest(actionTypes.PURCHASE.SET_USER_PARAMS.REQUEST, getUserParams);
}
