import React from 'react';

import { Link } from 'react-router-dom';

import { getProductActionLink } from 'helpers/products';
import { Common } from 'components/shared';

const { ButtonLarge, SellButton } = Common

export const buttonsBlock = (isDu: boolean, productId:string, type: string):JSX.Element => (
  isDu
    ?
      <Link to={getProductActionLink(productId, 'add')}>
        <ButtonLarge>Пополнить</ButtonLarge>
      </Link>
    :
      <>
        <Link to={getProductActionLink(productId, 'add')}>
          <ButtonLarge isHidden={type === '5'}>Докупить</ButtonLarge>
        </Link>
        <Link
            to={getProductActionLink(productId, 'sale')}
            className="sell"
        >
          <SellButton isHidden={type === '5'}>Продать</SellButton>
        </Link>
      </>);
