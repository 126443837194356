import { IRequest } from 'interfaces/request';
import { IAction } from 'interfaces';

import { ITypes } from './interfaces';

export const requestInitialState: IRequest = {
  success: false,
  fail: false,
  loading: false
};

export const requestReducer = ({ SUCCESS, REQUEST, ERROR }: ITypes) => (
  state: IRequest = requestInitialState,
  action: IAction
) => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...requestInitialState,
        success: true
      };
    case ERROR:
      return {
        ...requestInitialState,
        fail: true
      };
    case REQUEST:
      return {
        ...requestInitialState,
        loading: true
      };
    default:
      return state;
  }
};
