import React, {
  useReducer,
  useState,
  useEffect,
  FC,
  useMemo
} from 'react';

import { Link } from 'react-router-dom';
import { Action } from 'history';
import { Formik, Form as FormikForm } from 'formik';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import size from 'lodash/size';
import map from 'lodash/map';
import { NavigationType, useNavigationType } from 'react-router';

import { actionTypes } from 'store/actionTypes';
import { FormField } from 'entities/formField';
import { Modal } from 'types/modal';
import { IBeforeFields } from 'interfaces/wizardForm';
import { getPurchaseDataFromFields } from 'helpers/form/getPurchaseDataFromFields';

import { LoaderAndCap, Common } from 'components/shared';
import { actionCreator, purchaseAction } from 'helpers/actions/actionCreator';
import {
  IBeforeNext,
  IField,
  IPurchaseData,
  IFormFields,
} from 'interfaces';

import { useUpdateFields } from './formHooks';
import { useStore } from '../../hooks/useStore';
// eslint-disable-next-line import/no-cycle
import { FieldsContainer } from './FieldContainer/FieldsContainer';
import { LoaderTooltip } from '../tooltips/loader';
import { UserError } from '../applicationForms/error/userError';
import { ForeignerError } from '../applicationForms/foreignerError';
import { ServiceError } from '../applicationForms/error/serviceError';
import { ServiceErrorAdditional } from '../applicationForms/additionalPurchase/serviceError';
import { DEFAULT_ERROR_MESSAGE } from '../../../constants/codeMessages';
import {
  formActions,
  formReducer,
  initialFormState
} from './formReducer';

// eslint-disable-next-line import/no-cycle
import S from './styles';

const { FormComponent, FieldContainer } = S
const { Button } = Common

export const Form = ({
  modals,
  url,
  isLastForm,
  BeforeNext,
  BeforeFields,
  AfterFields,
  AfterHiddenFields,
  onSubmit,
  submitTitle = 'Далее',
  fields,
  formComponentClassName,
  hiddenSubmit,
  disclaimer,
  isModal,
}: IFormProps) => {
  const {
    purchase: {
      request: {
        fail,
        requestId,
        loading,
        success
      },
      data,
      data: {
        firstName,
        patronymic,
        lastName,
        productId,
        gender
      },
      notRespond,
      isConflict,
      notRebuy,
      errors,
      sms: {
        check: {
          request: {
            loading: smsCheckLoading,
          },
          error: {
            message
          }
        }
      },
    }
  } = useStore()
  const reduxDispatch = useDispatch()
  const sendForm = (data: IPurchaseData, requestId: string) => {
    reduxDispatch(
      purchaseAction(actionTypes.PURCHASE.OPIF.REQUEST, url, {
        data,
        requestId
      })
    );
  }
  const dataLoading = !!data.latinName
  const [formId] = useState(uuid());
  const [isForeigner, setForeigner] = useState(false);
  const [state, dispatch] = useReducer(formReducer, initialFormState);

  const {
    isFormSend,
    validationSchema,
    initialValues,
    isDisableSend,
    showBeforeNext
  } = state;

  const isCorrectRequest = useMemo(
    () => formId === requestId,
    [requestId, formId]
  );

  const formIsSubmitted = useMemo(
    () => isCorrectRequest && !isFormSend && loading,
    [isCorrectRequest, isFormSend, loading]
  );

  const formIsSended = useMemo(
    () => isCorrectRequest && isFormSend && success,
    [isCorrectRequest, isFormSend, success]
  );
  /* если клиент нажал кнопку назад в браузере:
  1) повторно запрашиваем данные для формы при
  покупке продукта ДУ (полный список полей)
  2) сбрасываем SUCCESS в purchase.request (для ПИФ)  */
  const navType: NavigationType = useNavigationType();
  useEffect(() => {
    if (navType === Action.Pop) {
      dispatch({
        type: formActions.updateBoth,
        payload: {
          fields,
          stateData: data
        }
      });
    }
  }, [navType, fields, data]);
  useEffect(() => {
    if (navType === Action.Pop) {
      reduxDispatch(actionCreator(actionTypes.PURCHASE.OPIF.RESET_SUCCESS))
    }
  }, [navType]);

  useUpdateFields(fields, data, dispatch);

  useEffect(() => {
    if (formIsSubmitted) {
      dispatch({ type: formActions.setFormSend, payload: true });
    }
  }, [formIsSubmitted]);
  useEffect(() => () => {
    /* сбрасываем данные в запросе на покупке/докупку */
    reduxDispatch(actionCreator(actionTypes.PURCHASE.REQUEST_RESET_DATA))
  }, [])

  useEffect(() => {
    const handleNext = () => {
      if (BeforeNext) {
        dispatch({ type: formActions.setShowBeforeNext, payload: true });
      } else {
        onSubmit();
      }
      dispatch({ type: formActions.setFormSend, payload: false });
    };
    if (formIsSended) {
      handleNext();
    }
  }, [formIsSended]);

  const handleSubmit = async (formFields: IFormFields) => {
    if (isModal) {
      onSubmit(formFields);
    } else {
      const purchaseData = await getPurchaseDataFromFields(
        formFields,
        data
      );
      sendForm(purchaseData.get(), formId);
    }
  };

  const closeServiceErrorWindow = () => {
    reduxDispatch(actionCreator(actionTypes.PURCHASE.OPIF.RESET_FAIL))
  }
  const isShowLoader = (loading && !notRespond && !notRebuy && !isConflict)
    || (smsCheckLoading && !notRespond && !notRebuy && !isConflict)

  const handleChangeFieldProps = (
    updatedFields: FormField[],
    changedField?: IField
  ) => {
    dispatch({
      type: formActions.updateValidationSchema,
      payload: {
        fields,
        stateData: data
      }
    });
    handleDisableSend(changedField);
  };

  const handleDisableSend = (changedField?: IField) => {
    if (changedField && changedField.name === 'foreigner') {
      setForeigner(!isForeigner);
      dispatch({
        type: formActions.setDisableSend,
        payload: !isForeigner
      });
    }
  };

  const getSubmitIsDisable = (isValid: boolean) => {
    if (isLastForm) {
      return false;
    }
    if (validationSchema) {
      return !isValid;
    }
    return loading;
  };

  const setShowBeforeNext = () => {
    dispatch({ type: formActions.setShowBeforeNext, payload: false });
    /* сбрасываем SUCCESS в purchase.request для
    предотвращения бесконечного рендеринга в окне с вводом смс */
    reduxDispatch(actionCreator(actionTypes.PURCHASE.OPIF.RESET_SUCCESS))
  };
  const userName = `${firstName} ${patronymic || lastName}`;
  if (!dataLoading) {
    return (<LoaderAndCap isLoader />)
  }
  return (
    <FormComponent
      data-test="form-component"
      className={formComponentClassName}
    >
      {BeforeFields && <BeforeFields onSubmit={() => onSubmit()} />}
      <Formik
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        initialErrors={{ isInitialValid: 'false' }}
      >
        {(formProps) => {
          const { isValid } = formProps;
          return (
            <>
              <FormikForm data-test="form-component">
                <FieldsContainer
                  fields={fields}
                  formProps={formProps}
                  AfterFields={AfterFields}
                  AfterHiddenFields={AfterHiddenFields}
                  onChangeFieldProps={handleChangeFieldProps}
                  errors={errors}
                />
                {!isDisableSend
                  ? (
                    <FieldContainer>
                      {!url && isLastForm
                        ? (
                          <Link to="/portfolio">
                            <Button
                              data-test="link-btn"
                              disabled={getSubmitIsDisable(isValid)}
                            >
                              {submitTitle}
                            </Button>
                          </Link>)
                        : !hiddenSubmit
                          ? (
                            <Common.Button
                              type="submit"
                              disabled={getSubmitIsDisable(isValid)}
                            >
                              {submitTitle}
                            </Common.Button>
                          )
                          : null}
                      {disclaimer && productId !== '35' && disclaimer}
                    </FieldContainer>)
                  : <ForeignerError />}
              </FormikForm>
              {modals && size(modals)
                ? map(modals, (ComponentModal, index) => (
                  <ComponentModal
                    key={index}
                    onHide={() => undefined}
                    parentFormProps={formProps}
                  />))
                : null}
            </>
          );
        }}
      </Formik>
      {isShowLoader && <LoaderTooltip isShow description="Пожалуйста, подождите..." />}
      {BeforeNext && !smsCheckLoading && showBeforeNext && message !== DEFAULT_ERROR_MESSAGE
        ? (
          <BeforeNext
            isShow
            onCancel={() => setShowBeforeNext()}
            onSubmit={() => {
              setShowBeforeNext();
              onSubmit();
            }}
          />)
        : null}
      {(fail || message === DEFAULT_ERROR_MESSAGE) &&
      <ServiceError
        extraData={closeServiceErrorWindow}
        isShow
      />}
      {notRebuy &&
      <ServiceErrorAdditional
        isShow
        gender={gender}
        userName={userName}
      /> }
      {isConflict && <UserError isShow />}
    </FormComponent>
  );
};

export interface IFormProps {
  fields: FormField[];
  onSubmit: (formFields?: IFormFields) => void;
  url: string;
  BeforeNext?: FC<IBeforeNext>;
  BeforeFields?: FC<IBeforeFields>;
  AfterFields?: FC<{}>;
  AfterHiddenFields?: FC<{}>;
  modals?: Modal[];
  submitTitle?: string;
  isModal?: boolean;
  isLastForm?: boolean;
  formComponentClassName?: string;
  hiddenSubmit?: boolean;
  disclaimer?: string | JSX.Element;
}
