import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { api } from 'api';
import { Url } from 'api/url';
import { IReports } from 'interfaces';

import {
  errorReports,
  requestReports,
  successReports
} from '../../reducers/reports';

function* fetchReports() {
  try {
    const params = {
      url: Url.reports.get
    };
    const reports: IReports[] = yield call(
      api.get,
      params
    );
    yield put(successReports(reports));
  } catch (e) {
    console.log(e)
    yield put(errorReports(e.message));
  }
}
export function* getReports() {
  yield takeEvery(
    requestReports.toString(),
    fetchReports
  );
}
