import React, { useEffect, useState } from 'react';

import size from 'lodash/size';

import { Input, Common } from 'components/shared';
import { useStore } from 'components/hooks/useStore';
import { InputEvent } from 'types/inputEvent';
import { requestAuthSms } from 'store/reducers/auth';
import { useAppDispatch } from 'store/configureStore';

import { ISmsProps } from './interfaces';

import S from './styles';

const { Button, InfoButton } = Common
const {
  Form,
  DescriptionContainer,
  RowButtons,
  Description: DescriptionStyle
} = S

const second = 1000;
const startTimer = 60;

export const Sms = ({
  sendActionCreator,
  isAutoSend,
  sendSms,
  submitTitle = 'Войти',
  showCancelButton = true,
  onClose,
  description: Description,
  isAutoFocus,
  isDisabled
}: ISmsProps) => {
  const [counter, setCounter] = useState(startTimer)
  const [code, setCode] = useState('')
  const [timerId, setTimerId] = useState(NaN)

  const dispatch = useAppDispatch()
  const sendCode = (code: string) => dispatch(
    sendActionCreator
      ? sendActionCreator(code)
      : requestAuthSms(code)
  )
  const { authentication, passwordRecovery } = useStore()
  const error = authentication.smsError || passwordRecovery.smsError
  useEffect(() => startCountdown(), [])
  useEffect(() => () => clearInterval(timerId), [timerId])

  const resetTimer = () => {
    clearTimeout(timerId);
    setCounter(startTimer);
    startCountdown();
  }

  const startCountdown = () => {
    const timerId = window.setInterval(() => {
      setCounter((counter) => (counter >= 1 ? counter-1 : 0));
    }, second);
    setTimerId(timerId);
  }

  const codeChange = (event: InputEvent) => {
    const { value } = event.currentTarget;
    const code = value.replace(/\D/g, '');

    if (size(code) <= 4) {
      setCode(code);
    }

    if (size(code) === 4 && isAutoSend) {
      sendCode(code);
    }
  }

  const handleSendAgain = () => {
    sendSms();
    resetTimer();
  }

  const formSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (size(code) === 4) {
      sendCode(code);
    }
  }
  return (
    <Form onSubmit={formSubmit}>
      <Input
        placeholder="Код из СМС"
        value={code}
        onChange={codeChange}
        type="tel"
        error={error || (code
          ? ''
          : 'Введите код')}
        autoFocus={isAutoFocus || true}
      />
      <DescriptionContainer>
        <DescriptionStyle>Не пришло СМС?</DescriptionStyle>
        <DescriptionStyle>
          {counter
            ? `Повторно отправить код через: ${counter} сек.`
            : <S.SendAgain onClick={handleSendAgain}>Отправить код еще раз</S.SendAgain>}
        </DescriptionStyle>
      </DescriptionContainer>
      <RowButtons>
        {showCancelButton && (
        <InfoButton type="button" onClick={onClose}>
          Отмена
        </InfoButton>
        )}
        <Button disabled={isDisabled} type="submit">{submitTitle}</Button>
      </RowButtons>
      {Description && <Description />}
    </Form>
  );
}
