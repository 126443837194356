import styled from 'styled-components';

import { media } from 'assets/styles/theme';
import { Common } from 'components/shared';

const { MainText, LinkText } = Common

const FooterStyle = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
  width: 100%;
  padding: 25px;
  margin-top: auto!important;
  
   ${media.mobile} {
     padding: 10px 15px;
   }
`;

const TextContainer = styled.div`
  grid-row-gap: 8px;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr 1fr;
  
    ${media.mobile} {
      justify-items: center;
      grid-template-columns: 1fr;
      &:first-child {
        margin-bottom: 10px;
      }
    }
`;

const Wrapper = styled.div`
  ${media.mobile} {
    display: grid;
    justify-items: center;
  }`;

const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  
    ${media.mobile} {
      grid-template-columns: 1fr;
    }
`;

const Button = styled.button`
  a {
    margin-right: 7px;
  }
`;

const Text = styled(MainText)`
  text-align: start;
  font-size: 13px;
  width: 100%;
  
    ${media.mobile} {
      text-align: center;
      font-size: 12px; 
    }
`;

const Link = styled(LinkText)`
  font-size: 13px;
`;

const DetailedInformationStyle = styled.div`
  font-size: 13px;
  line-height: 20px;
  padding: 0 15px;
  text-align: justify;
  color: ${({ theme }) => theme.colors.description};
  overflow: hidden;
  transition: 0.35s;

  a {
    font-size: 13px;
  }

    ${media.mobile} {
      padding: 0;
    }
`;

export {
  Link,
  FooterStyle,
  DetailedInformationStyle,
  TextContainer,
  Wrapper,
  DataContainer,
  Button,
  Text
};
