import styled from 'styled-components';

const NavBar = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 15px;
  padding: 0 10px;
  z-index: 1;
  div {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      transform: rotateY(180deg);
    }
  }
`;

export default { NavBar };
