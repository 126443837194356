import React from 'react';

import Slider from 'rc-slider';

import { IDisplayingCount, IRunnerProps } from './interfaces';

import S from './styles';

export const Runner = (
  {
    label,
    value,
    limits,
    limits: { max, min },
    onChange,
    displayingValue,
    displayingMax,
    error,
    step,
    tooltip
  }: IRunnerProps
) => (
  <S.Runner disabled={min === max}>
    <S.Label>{label}</S.Label>
    <DisplayingCount
      currentValue={displayingValue}
      maxValue={displayingMax}
      tooltip={tooltip}
    />
    <Slider
      {...limits}
      step={step || 1}
      value={value}
      onChange={onChange}
    />
    {error
      ? <S.Error>{error}</S.Error>
      : null}
  </S.Runner>);

const DisplayingCount = ({
  currentValue,
  maxValue,
  tooltip
}: IDisplayingCount) => (
  <>
    <S.hint>{currentValue}</S.hint>
    {tooltip || null}
    <S.hintBack>{maxValue}</S.hintBack>
  </>
);
