import React from 'react';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

import { ISecurities } from 'interfaces';
import { LoaderAndCap, NumberField } from 'components/shared';
import { FieldTypes } from 'enums';
import { Money } from 'entities/money';
import { Percents } from 'entities/percents';
import { useStore } from 'components/hooks/useStore';

import { columnsTitle } from './data';

import Table from '../table';

import S from './styles';

interface IIssuerCardProps {
  availableFunds?: number;
  isIssuerCard?: boolean;
}

/* todo нужна ли эта ф-я - считает количество бумаг, но они итак приходят целыми числами бэка
    + доделать рефакторинг таблицы (зачем NumberField) */
// const prettify = (num) => {
//   const n = num.toString();
//   return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/, '$1' + ' ').replace('.', ',');
// };
/*  флаг isIssuerCard необходим для адаптивной верстки
 виджета на странице продукта ДУ (разбивка по составу продукта) */
export const IssuerCard = ({ isIssuerCard, availableFunds }: IIssuerCardProps) => {
  const { securities: { currentSecurities } } = useStore()
  if (isEmpty(currentSecurities) && availableFunds) {
    return <LoaderAndCap isLoader />;
  }
  /* если нет availableFunds, то виджет не отображаем (может быть если клиент все продал,
   но пока не оформили документально продажу) */
  return !availableFunds
    ? null
    : (
      <S.Issuer isIssuerCard={isIssuerCard} data-test="issuer-card-component">
        <Table.Wrapper>
          <Table.Header className="issuer-header">
            <Table.Row className="issuer-header-row">
              {map(columnsTitle, (title: string): JSX.Element => (
                <Table.Cell
                    aria-label={title}
                    key={uuid()}
                >
                  {title}
                </Table.Cell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(currentSecurities, ({
              issuer,
              registrationNumberISIN,
              baseCurrency,
              quantity,
              totalBuy,
              evaluation,
              evaluationCurrency,
              dividends
            }:ISecurities<Percents>, index:number): JSX.Element => {
              const getSimpleCellData = (cellData: string | number, currentTitle: string)
              :JSX.Element => (
                <Table.Cell isIssuerCard={isIssuerCard} aria-label={currentTitle}>
                  { cellData && <>{cellData}</> }
                </Table.Cell>
              )
              const getComputedCellData = (cellData:Money | Percents, currentTitle: string)
              :JSX.Element => (
                <Table.Cell isIssuerCard={isIssuerCard} aria-label={currentTitle}>
                  {cellData &&
                  <NumberField
                    value={cellData}
                    type={FieldTypes.diff}
                    baseCurrency={evaluationCurrency}
                  />}
                </Table.Cell>
              )
              return (
                <Table.Row
                      isIssuerCard={isIssuerCard}
                      key={index}
                      className={`${issuer === 'Свободные средства' && !isIssuerCard
                        ? 'issuer-body issuer-free'
                        : 'issuer-body'}`}
                >
                  {getSimpleCellData(issuer, 'Активы')}
                  {getSimpleCellData(registrationNumberISIN, 'ISIN / номер гос.рег.')}
                  {getSimpleCellData(baseCurrency, 'Валюта')}
                  {getSimpleCellData(quantity, 'Кол-во бумаг')}
                  {getComputedCellData(totalBuy, 'Сумма покупки')}
                  {getComputedCellData(evaluation, 'Текущая оценка')}
                  {getComputedCellData(dividends, 'Купонные выплаты / дивиденды')}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Wrapper>
      </S.Issuer>
    );
};
