import React, {
  useRef,
  useState,
  useEffect
} from 'react';

import Media from 'react-media';
import { getRefHeight } from 'helpers/ref';

import { device } from 'assets/styles/theme';

import { ITransitionHeightProps } from './interfaces';

import { Wrapper } from './styles';

export const TransitionHeight = ({
  isOpen,
  children,
  initHide = true,
  matched,
  style
}: ITransitionHeightProps) => {
  const [initHeight, setInitHeight] = useState('0');
  const [height, setHeight] = useState(initHeight);
  const ref = useRef<HTMLDivElement>(null);

  function getHeight() {
    const refHeight = getRefHeight(ref);

    if (initHide) {
      if (isOpen) return `${refHeight}px`;
      return '0';
    }
    return `${refHeight}px`;
  }
  useEffect(() => {
    if (!initHide) {
      setInitHeight(getHeight());
    }
  }, []);
  useEffect(() => {
    setHeight(getHeight());
  }, [isOpen]);
  if (matched) {
    return (
      <Media query={device[matched]}>
        {(isMatched) => (isMatched
          ? (
            <Wrapper
            height={height}
            isOpen={isOpen}
            initHide={initHide}
            data-test="transition-height-component"
            style={style}
            >
              <div ref={ref}>{children}</div>
            </Wrapper>)
          : (
            children
          ))}
      </Media>)
  }
  return (
    <Wrapper
      height={height}
      isOpen={isOpen}
      initHide={initHide}
      data-test="transition-height-component"
      style={style}
    >
      <div ref={ref}>{children}</div>
    </Wrapper>
  );
};
