import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};

  .wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    .header {
      background-color: ${({ theme }) => theme.colors.white};
    }
    main {
      padding-top: 30px;
      flex: 1 1 auto;
    }
  }
`;
export default { Wrapper }
