import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';

import { dateToFormatString } from 'helpers/date/format';
import { getChartDataRequest } from 'store/reducers/chart';
import { computedPieChartData, getChartColor } from 'helpers/chart';
import { useStore } from 'components/hooks/useStore';

import { Header } from './header';
import { Legend } from './legend';
import { PieChart, AreaChart } from '../charts';
import S from '../../shared/commonStyles/chartsPanel/styles';

export const PortfolioChartsPanel = () => {
  const [isLegendActive, setIsLegendActive] = useState(false)
  const dispatch = useDispatch()
  const {
    currency,
    currencyCourses: courses,
    userProducts: pieProducts,
    chart: {
      productListForLegend,
      chartData: data,
      chartDataLoader,
      activeProducts,
    },
    chartsDateRange,
    headerDateRange: dateRange
  } = useStore()

  const showLegend = () => setIsLegendActive(!isLegendActive)

  useEffect(() => {
    if (isEmpty(data)) {
      dispatch(getChartDataRequest())
    }
  }, [])
  const pieChartData = () => computedPieChartData(pieProducts, currency, courses)
  const moreTwoProducts = () => size(productListForLegend) > 1;
  const currentProducts = map(productListForLegend, (x) => x.replace(/"/g, ''));
  return (
    <S.ChartCard>
      <S.AreaChart data-test="portfolio-charts-component">
        <Header isChartWidget />
        {data && chartsDateRange && dateRange && (
          <AreaChart
            data={data}
            colors={getChartColor(
              activeProducts,
              currentProducts
            )}
            activeProducts={activeProducts}
            showAllResult={moreTwoProducts()}
            dateBorders={chartsDateRange}
            dateRange={dateRange}
          />
        )}
        <S.LegendActivator
             disabled={chartDataLoader}
             className={classNames({
               active: isLegendActive,
               'with-product': size(activeProducts) > 1
             })}
            onClick={() => showLegend()}
        >
          Легенда
        </S.LegendActivator>
        <S.Legend>
          <Legend
              isActive={isLegendActive}
              products={productListForLegend}
              activeProducts={activeProducts}
              moreTwoProducts={moreTwoProducts()}
              onClose={() => showLegend()}
          />
        </S.Legend>
      </S.AreaChart>
      <S.PieChart>
        <S.Title>Актуальный состав портфеля</S.Title>
        {dateRange && (
          <S.DateTo>
            на {dateToFormatString(dateRange.to)}
          </S.DateTo>
        )}
        <PieChart data={pieChartData()} withLegend />
      </S.PieChart>
    </S.ChartCard>
  );
}
