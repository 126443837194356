import React from 'react';

import styled from 'styled-components';

// eslint-disable-next-line import/no-cycle
import { Common } from '.';

const { ExtraText } = Common
/* todo - если нужен этот компонент сделать декомпозицию */
interface IPopupProps {
  children: JSX.Element | string | Text;
}

const SPopup = styled.div`
  z-index: 1;
  background: #ffffff;
  box-shadow: 0 5px 10px rgba(48, 45, 43, 0.25);
  border-radius: 10px;
  padding: 22px;
  position: absolute;
  max-width: 350px;
`;

export const Popup = ({ children }: IPopupProps) => (
  <SPopup>
    <ExtraText>{children}</ExtraText>{' '}
  </SPopup>
);
export const StylesPopup = SPopup;
