export function getAppeal(gender: string | undefined, userName: string): string {
  let ending = 'ый(-ая)';

  // eslint-disable-next-line default-case
  switch (gender) {
    case 'FEMALE': {
      ending = 'ая';
      break;
    }
    case 'MALE': {
      ending = 'ый';
      break;
    }
  }
  return `Уважаем${ending} ${userName}!`;
}
