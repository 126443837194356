import { createSlice } from '@reduxjs/toolkit';

import { logOut } from '../../actions/actionCreators/logOut';

/* slice для статуса sidebar */
export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: false,
  reducers: {
    setStatus: (state) => !state
  },
  extraReducers: (builder) => { builder.addCase(logOut, () => false) }
})
export const { setStatus } = sidebarSlice.actions
