import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { ReportsTable } from 'components/widgets/reportsTable';
import { requestReports } from 'store/reducers/reports';

// eslint-disable-next-line import/no-cycle
import { NoProductOrReports } from '../errors';
import { useStore } from '../../hooks/useStore';

import S, { Title } from './styles';

export const Reports = () => {
  const dispatch = useDispatch()

  const { reports: { items } } = useStore()

  useEffect(() => {
    dispatch(requestReports());
  }, []);

  if (isEmpty(items)) {
    return <NoProductOrReports text="На данный момент в Вашем портфеле отчёты отсутствуют" />
  }

  return (
    <S.Report>
      <S.Head>
        <Title>Отчёты</Title>
      </S.Head>
      <S.Section>
        {/* @ts-ignore */}
        <ReportsTable items={items} />
      </S.Section>
    </S.Report>
  );
};
