import {
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { api } from 'api';
// import { actionTypes } from 'store/actionTypes';
import { IAction } from 'interfaces';
import { Url } from 'api/url';
import { getPurchaseHeaders } from 'api/options';
import {
  edoSmsCheckError,
  edoSmsCheckRequest,
  edoSmsCheckSuccess,
  edoSmsSendError,
  edoSmsSendRequest,
  edoSmsSendSuccess,
  requestCheckEdo
} from 'store/reducers/edoExistStatusReducer';

import { getHost } from '../purchase';

// import { getHost } from '.';

// const {
//   NOT_RESPOND,
//   IS_CONFLICT,
//   SMS: { CHECK, SEND }
// } = actionTypes.PURCHASE;

interface IPayload {
  code: string,
  extraUrl?: string
}

function* sendCodeForEdo() {
  // const { ERROR, SUCCESS } = SEND;

  try {
    const url = Url.edo.sendCode

    yield call(
      api.post,
      { url },
      getPurchaseHeaders()
    );
    yield put(edoSmsSendSuccess());
  } catch ({ code, payload }) {
    // eslint-disable-next-line max-len
    // проверить зачем нужны IS_CONFLICT и NOT_RESPOND с редьюсере покупки - если нигде не юзается удалить и не использвать в других редьюсерах где используется  смс
    // if (code === 409) {
    //   yield put({
    //     type: IS_CONFLICT
    //   });
    // }
    // else if (code !== 500) {
    console.log(code, payload)
    yield put(edoSmsSendError());
    // }
    // else {
    //   yield put({
    //     type: NOT_RESPOND
    //   });
    // }
  }
}

function* checkCodeForEdo({ payload = { code: '' } }: IAction<IPayload>) {
  // const { SUCCESS, ERROR } = CHECK;
  try {
    // const { code } = payload;
    const url = Url.edo.accept;
    const { host } = getHost(url);

    const params = {
      host,
      url,
      data: { code: payload }
    };
    yield call(api.post, params, getPurchaseHeaders());
    yield put(edoSmsCheckSuccess());
    /* повторно запрашиваем статус ЭДО клиента, т.к. фактически никакой ответ не приходит
       после отправки смс с подтверждением, что может привести к некорректному UI если статус
       ЭДО не обновится по какой-то причине на бэке */
    yield put(requestCheckEdo());
  } catch (error) {
    yield put(edoSmsCheckError(error.message))
    // const { code, message } = error;
    // if (code === 409) {
    //   yield put({
    //     type: IS_CONFLICT
    //   });
    // } else if (code !== 500) {
    //   yield put({
    //     payload: { message },
    //     type: ERROR
    //   });
    // } else {
    //   yield put({
    //     type: NOT_RESPOND
    //   });
    // }
  }
}

export function* acceptEdoSms() {
  yield takeLatest(edoSmsSendRequest.toString(), sendCodeForEdo);
  yield takeLatest(edoSmsCheckRequest.toString(), checkCodeForEdo);
}
