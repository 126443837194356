import React from 'react';

import { ICaptionProps } from './interfaces';

import S from './styles';

export const Caption = ({ months, date }: ICaptionProps) => {
  const month = months ? months[date.getMonth()] : '';
  const year = date.getFullYear();
  return (
    <S.Caption
      className="DayPicker-Caption"
      data-test="datepicker-caption-component"
    >
      <div>
        {month} {year}
      </div>
    </S.Caption>
  );
};
