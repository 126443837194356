import { call, put, takeEvery } from 'redux-saga/effects';

import { api } from 'api';
import { Url } from 'api/url';

import {
  errorCheckEdo,
  requestCheckEdo,
  successCheckEdo
} from '../../../reducers/edoExistStatusReducer';

interface IAgreementSigned {
  edoExistStatus: boolean;
}

function* fetchEdoExistStatus() {
  try {
    const params = {
      url: Url.edo.check
    };
    const edoExistStatus: IAgreementSigned = yield call(
      api.get,
      params
    );
    yield put(successCheckEdo(edoExistStatus.edoExistStatus));
  } catch ({ code, payload }) {
    console.log(payload)
    yield put(errorCheckEdo(payload));
  }
}

export function* getEdoStatus() {
  yield takeEvery(
    requestCheckEdo.toString(),
    fetchEdoExistStatus
  );
}
