import React from 'react';

import { IBeforeNext, IAction } from 'interfaces';
import { actionTypes } from 'store/actionTypes';
import { useLockBodyScroll } from 'components/hooks/useLockBodyScroll';
import { PurchaseTypes } from 'enums/purchaseTypes';
import { edoSmsCheckRequest, edoSmsSendRequest } from 'store/reducers/edoExistStatusReducer';
import { dataSmsCheckRequest, dataSmsSendRequest } from 'store/reducers/checkAndApproveClientData';
import { recognitionSmsCheckRequest, recognitionSmsSendRequest } from 'store/reducers/qualifiedStatusData';

import { SmsTooltip } from '../../tooltips/sms';
import { ISmsBeforeNextProps } from './interfaces';

type Props = ISmsBeforeNextProps & IBeforeNext;

const { PURCHASE } = actionTypes;

export const SmsBeforeNext = ({
  isShow,
  onSubmit = () => undefined,
  description,
  nextPage,
  type,
  onCancel
}: Props) => {
  useLockBodyScroll(isShow);
  const onClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const sendCodeAction = () => currentType(type)
  /* helper для текущего случая вызова смс */
  const currentType = (type?: PurchaseTypes) => {
    switch (type) {
      case PurchaseTypes.edo: {
        return edoSmsSendRequest()
      }
      case PurchaseTypes.client_data_check: {
        return dataSmsSendRequest()
      }
      case PurchaseTypes.recognition: {
        return recognitionSmsSendRequest()
      }
      // case PurchaseTypes.purchase: {
      //   return purchaseSmsSendRequest()
      // }
      default: {
        return smsAction(PURCHASE.SMS.SEND.REQUEST, type, undefined);
      }
    }
  }
  const checkCodeAction = (code: string) => {
    switch (type) {
      /* ЭДО */
      case PurchaseTypes.edo: {
        return edoSmsCheckRequest(code)
      }
      /* паспортные данные */
      case PurchaseTypes.client_data_check: {
        return dataSmsCheckRequest(code)
      }
      /* признание квал инвестором */
      case PurchaseTypes.recognition: {
        return recognitionSmsCheckRequest(code)
      }
      /* покупка */
      // case PurchaseTypes.purchase: {
      //   return purchaseSmsCheckRequest(code)
      // }
      default: {
        return smsAction(actionTypes.PURCHASE.SMS.CHECK.REQUEST, type, code)
      }
    }
  }
  /* название reducer-а откуда берем данные для смс */
  const currentReducer = (type?: PurchaseTypes) => {
    switch (type) {
      case PurchaseTypes.edo: {
        return 'edoExistStatus'
      }
      case PurchaseTypes.client_data_check: {
        return 'checkOfClientData'
      }
      case PurchaseTypes.recognition: {
        return 'qualifiedStatusData'
      }
      case PurchaseTypes.purchase: {
        return 'purchase'
      }
      default: {
        return 'purchase';
      }
    }
  }

  if (isShow) {
    return (
      <SmsTooltip
        tooltipProps={{
          isShow,
          onClose,
          showCloseButton: false
        }}
        smsProps={{
          onSubmit,
          description,
          checkCodeAction,
          sendCodeAction,
          onClose,
          smsSended: false,
          showCancelButton: false,
          submitTitle: 'Подписать'
        }}
        nextPage={nextPage}
        currentReducer={currentReducer(type)}
      />
    );
  }
  return null;
};

const smsAction = (
  action: string,
  extraUrl?: PurchaseTypes,
  code?: string
): IAction<{ extraUrl?: PurchaseTypes; code?: string }> => {
  return {
    type: action,
    payload: { code, extraUrl }
  };
}
