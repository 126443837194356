import { IPurchaseData } from 'interfaces/purchase';
import {
  IAction,
  IDateRange,
  IRecognitionSubmitData,
} from 'interfaces';

import { IAreaProps } from '../../components/widgets/productCharts/interfaces';
import { ISuggestionResponse } from '../dadata/interfaces';
import { Change } from '../../types/inputEvent';

type CommonDataType = IDateRange | IAreaProps | string |
{ data: { productId: string; type: string; productName: string; action: string; }; } |
IRecognitionSubmitData | { id: string } | ISuggestionResponse[] | Change

export const actionCreator = (
  type: string,
  payload?: CommonDataType
): IAction<CommonDataType> => {
  const action: IAction<CommonDataType> = {
    type,
  };
  if (payload) {
    action.payload = payload;
  }
  return action;
};

export const purchaseAction = (
  type: string,
  url: string,
  payload?: { data: IPurchaseData; requestId: string }
) => ({
  type,
  url,
  payload
});
