import styled, { css } from 'styled-components';

import StyledNumberField from 'components/shared/numberField/styles';
import { media } from 'assets/styles/theme';

import StyledTable from '../table';

interface IisIssuerProps {
  isIssuerCard?: boolean
}

const Issuer = styled.div<IisIssuerProps>`
  margin: 30px 0;

   ${media.mobile} {
    overflow: hidden;
    ${StyledTable.Wrapper} {
      border-radius: 0;
    }
    ${StyledTable.Body} {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

   ${media.max(1400)} {
     width: 85%;
     margin-bottom: 1rem;
   } 
  
   ${media.max('sm')} {
    width: 100%;
    margin-bottom: 0;
  }

  .issuer-header {
    height: auto;

    ${media.max(1400)} {
      display: none;
    }
  }

  .issuer-header-row {
    align-items: flex-start;
    padding: 20px 0;

    div {
      min-width: 120px !important;
      width: auto !important;
      max-width: 120px !important;
      padding: 0 10px;
      justify-content: flex-end !important;
      text-align: right;

      &:first-child {
        width: 220px !important;
        max-width: 220px !important;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0 !important;
      }

      &:first-child, &:nth-child(2), &:nth-child(3) {
        justify-content: flex-start !important;
        text-align: left;
      }

      ${media.min(1501)} {
        &:nth-child(3) {
          max-width: 80px !important;
          min-width: 80px !important;
        }
      }
    }

    span {
      text-align: left;
    }
  }

  .issuer-label {
    color: ${({ theme }) => theme.colors.textExtra};

    ${media.min(1401)} {
      display: none;
    }

    ${media.max(1400)} {
      margin-bottom: 10px;
    }

    ${media.mobile} {
      font-size: 11px;
      font-weight: 500;
      line-height: 14px;
    }
  }
  
  .issuer-free > div:nth-child(2){
    color: #fff;
  }
  
  .issuer-free > div:nth-child(2) div {
    display: none;
  }
   
  .issuer-free > div:nth-child(4){
    color: #fff;
  } 
  
  .issuer-free > div:nth-child(4) div {
    display: none;
  }
  
  .issuer-free > div:nth-child(5){
    color: #fff;
  } 
  
  .issuer-free > div:nth-child(5) div {
    display: none;
  }
  
  .issuer-free > div:nth-child(7) {
    color: #fff;
  }
  
  .issuer-free > div:nth-child(7) div {
    display: none;
  }
  
  .issuer-body {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};

    ${media.mobile} {
      margin: 0 -15px;
      width: auto;
      padding-bottom: 15px;
    }

    ${media.max(1400)} {
      justify-content: flex-start;
    }

    ${StyledTable.Cell} {
      align-items: flex-start;
      font-size: 15px !important;
      line-height: 19px !important;
      min-width: 120px;
      width: auto !important;
      max-width: 120px !important;
      text-align: right;
      padding: 0 10px;
      color: ${({ theme }) => theme.colors.textPrimary};

      &:first-child {
        width: 220px !important;
        max-width: 220px !important;
        padding-left: 0;
        text-align: left;

        ${media.max(1400)} {
          ${({ isIssuerCard }) => isIssuerCard && css`
            padding-left: 10px;
            min-width: 100%;
        `}
        }
      }

      //&:first-child, &:nth-child(2), &:nth-child(3) {
      //  justify-content: flex-start !important;
      //}

      ${StyledNumberField.NumberField} {
        white-space: nowrap;
      }

      ${media.min(1401)} {
        &:nth-child(3) {
          max-width: 80px !important;
          min-width: 80px !important;
        }
      }

      ${media.max(1400)} {
        max-width: none !important;
        ${({ isIssuerCard }) => !isIssuerCard && css`
          flex-direction: column
        `}
        justify-content: space-between !important;
        text-align: left;

        &:not(:first-child) {
          margin-top: 16px;
        }

        &:first-child {
          flex-basis: 100% !important;
          width: 100% !important;
          max-width: none !important;
          font-weight: 500;
          margin-bottom: 12px;
          padding-left: 10px;
        }
      }

      ${media.min(1450)} {
        flex-basis: 16.16667%;
      }

      ${media.min('xl')} {
        flex-basis: 20%;
      }

      ${media.tablet} {
        ${({ isIssuerCard }) => !isIssuerCard && css`
          width: 25% !important;
        `}
      }

      ${media.max('lg')} {
        ${({ isIssuerCard }) => !isIssuerCard && css`
          width: 33.3% !important;
        `}
      }

      ${media.max('md')} {
        ${({ isIssuerCard }) => !isIssuerCard && css`
          width: 50% !important;
        `}
      }

      ${media.mobile} {
        &:not(:first-child) {
          margin-top: 7px;
          flex-direction: row;
          justify-content: space-between !important;
          width: 100% !important;
        }
      }
    }
  }
`;

// const Wrapper = styled.div`
//   height: 30vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export default { Issuer };
