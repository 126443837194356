import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRecognitionSubmitData, ISmsState } from 'interfaces';

import { sms } from '../edoExistStatusReducer';

interface IInitialState {
  status: boolean,
  submitSuccess: boolean,
  isAcceptedNow: boolean,
  error: string,
  sms: ISmsState
}

/* slice для проверки и подтверждения актуальности данных user-а */
export const checkAndApproveClientDataSlice = createSlice({
  name: 'checkAndApproveClientData',
  initialState: {
    status: true,
    submitSuccess: false,
    isAcceptedNow: false,
    error: '',
    sms
  } as IInitialState,
  reducers: {
    /* получение текущего статуса актуальности паспортных данных user-а */
    requestClientDataStatus: (state) => state,
    successClientDataStatus: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload
      return state
    },
    errorClientDataStatus: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      return state
    },
    /* подтверждение данных user-а */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    approveRequest: (state, payload: PayloadAction<IRecognitionSubmitData>) => state,
    approveSuccess: (state) => {
      state.submitSuccess = true
      return state
    },
    approveError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      return state
    },
    /* для смс */
    /* отправка смс */
    dataSmsSendSuccess: (state) => {
      state.sms.send.success = true
      state.sms.send.loading = false
      return state
    },
    dataSmsSendError: (state) => {
      state.sms.send.fail = true
      return state
    },
    dataSmsSendRequest: (state) => {
      state.sms.send.loading = true
      return state
    },
    /* проверка смс */
    dataSmsCheckSuccess: (state) => {
      state.sms.check.request.success = true
      state.sms.check.request.loading = false
      return state
    },
    dataSmsCheckError: (state, action: PayloadAction<string>) => {
      state.sms.check.request.fail = true
      state.sms.check.request.loading = false
      state.sms.check.error.message = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dataSmsCheckRequest: (state, action: PayloadAction<string>) => {
      state.sms.check.request.loading = true
      return state
    },
    /* доп reducer-s */
    dataSmsCheckResetRequest: (state) => {
      state.sms.check.request.success = false
      return state
    },
    dataSmsCheckResetFail: (state) => {
      state.sms.check.request.fail = false
      state.sms.check.error.message = ''
      return state
    },
    acceptedNowReset: (state) => {
      state.isAcceptedNow = false
      return state
    }
  }
})

export const {
  requestClientDataStatus,
  successClientDataStatus,
  errorClientDataStatus,
  approveRequest,
  approveSuccess,
  approveError,
  acceptedNowReset,
  dataSmsCheckRequest,
  dataSmsCheckError,
  dataSmsCheckSuccess,
  dataSmsSendRequest,
  dataSmsSendSuccess,
  dataSmsSendError,
  dataSmsCheckResetFail,
  dataSmsCheckResetRequest
} = checkAndApproveClientDataSlice.actions
