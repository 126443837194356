import React from 'react';

import { useDispatch } from 'react-redux';

import { Icon } from 'assets/img/svg';
import { LogOutTooltip } from 'components/widgets/tooltips/logout';
import { logOutAction } from 'store/actions/actionCreators/logOut';

import { IOwnProps } from './interfaces';

import S from './styles';

export const User = ({
  userName,
  showLogOut,
  setShowLogOut
}: IOwnProps) => {
  const dispatch = useDispatch()
  const logOut = () => dispatch(logOutAction())

  return (
    <S.SidebarUser>
      <div>
        <span className="greeting">Здравствуйте,</span>
        <span className="user-name">{userName}</span>
      </div>
      <button data-name="logout-button" type="button" onClick={() => !showLogOut && setShowLogOut(true)}>
        <Icon.LogOut />
      </button>
      {showLogOut && <LogOutTooltip
        isShow
        onLogOut={logOut}
        onCancel={() => setShowLogOut(false)}
      />}
    </S.SidebarUser>
  );
};
