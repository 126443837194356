import styled from 'styled-components';

import { media } from 'assets/styles/theme';
import StylesRadio from 'components/shared/radioButton/styles';

import StylesProductButton from '../portfolioCharts/productButton/styles';
import { StylesDatepickerLabel } from '../datepickers/label';

const Header = styled.header`
  width: 100%;

  .header-items-wrapper {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border-left: 1px solid #f9f8f6;

    ${media.tablet} {
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 38px;
        height: 38px;
        bottom: -19px;
        left: calc(50% - 19px);
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 100%;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: -4px;
        border-left: 3px solid;
        border-top: 3px solid;
        border-color: ${({ theme }) => theme.colors.link};;
        border-radius: 2px;
        transform: rotate(225deg);
        left: calc(50% - 5px);
        transition: 0.4s;
      }

      &.active::after {
        transform: rotate(45deg);
        bottom: -7px;
      }

      &.active .header-items {
        height: 290px;
      }
    }
  }

  .header-items {
    display: grid;
    grid-template-columns: minmax(160px, 200px) minmax(140px, 180px) minmax(130px, 125px) minmax(130px, 150px) minmax(130px, 160px) minmax(130px, 160px) minmax(130px, 160px) minmax(130px, 170px); 
    height: 126px;
    width: 100%;
    transition: height 0.35s ease-in-out;
    ${StylesDatepickerLabel} {
      font-size: 16px;
      line-height: 20px;
      color: #424249;
      display: flex;
    }
    ${media.tablet} {
      display: grid;
      grid-template-columns: repeat(3, minmax(150px, 1fr));
      justify-items: center;
      padding: 18px 30px;
      height: 74px;
      overflow: hidden;
    }

    ${media.mobile} {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 130px;
      padding: 18px 15px;
    }
  }
  .activeSidebar {
    ${media.tablet} {
      margin-top: 50px;
    } 
  }

  .value {
    ${StylesRadio.RadioButton} {
      display: block;
    }
  }

  .header-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    height: 50px;
    background-color: ${({ theme }) => theme.colors.background};

    ${media.mobile} {
      padding: 10px 15px;
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 21;
    }
    
    &.blur {
      filter: blur(2px);
    }

    ${media.desktop} {
      display: none;
    }

    .logo {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-right: auto;
      overflow: hidden;

      svg {
        margin-left: -70px;
      }
    }
    a {
      text-decoration: none;
    }
    ${StylesProductButton.ProductButton} {
      margin: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      svg {
        margin-right: 8px;
      }
    }
  }
`;
export default { Header };
