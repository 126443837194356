import React, {
  useState,
  useReducer,
  useRef
} from 'react';

import debounce from 'lodash/debounce';

import useOnClickOutside from 'use-onclickoutside';
import { actionCreator } from 'helpers/actions/actionCreator';
import { Change } from 'types/inputEvent';
import { getFakeEvent } from 'helpers/field/getFakeEvent';
import { getProperty } from 'dot-prop';
import { ISuggestionResponse } from 'helpers/dadata/interfaces';
import {
  nextSuggestion,
  prevSuggestion,
  selectSuggestion
} from 'helpers/inputs/suggestions';

import { Suggestions } from './suggestions';
import { Input } from '..';
import { ISuggestionsInputProps } from './interfaces';
import {
  suggestionsReducer,
  initialState,
  suggestionsActions
} from './reducer';

import S from './styles';

export const SuggestionsInput = (props: ISuggestionsInputProps) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [state, dispatch] = useReducer(suggestionsReducer, initialState);
  const ref = useRef<HTMLDivElement>(null);
  const {
    onChange,
    setFieldTouched,
    autocompleateFields,
    name,
    getSuggestions
  } = props;
  const { showSuggestions, suggestions } = state;

  const setSuggestions = async (value: string) => {
    const newSuggestions = await getSuggestions(value);
    dispatch(actionCreator(suggestionsActions.setSuggestions, newSuggestions));
  };
  const debouncedSetSuggestions = debounce(setSuggestions, 250);

  const setEvent = (event: Change) => dispatch(actionCreator(suggestionsActions.setEvent, event));

  const setShowSuggestions = () => dispatch(actionCreator(suggestionsActions.showSuggestions));

  const hideSuggestions = () => dispatch(actionCreator(suggestionsActions.hideSuggestions));

  useOnClickOutside(ref, hideSuggestions);

  const handleChange = async (event: Change) => {
    if (onChange) {
      onChange(event);
    }

    const { value } = event.currentTarget;
    debouncedSetSuggestions(value);
    setEvent(event);
    setShowSuggestions();
  };

  const handleSuggestionClick = (suggestion: ISuggestionResponse) => {
    if (onChange) {
      const event = getFakeEvent(
        suggestion.textSuggestion,
        name || ''
      ) as Change;
      onChange(event);
      setAutocompleateFields(suggestion);
      hideSuggestions();
    }
  };

  const setAutocompleateFields = (suggestion: ISuggestionResponse) => {
    if (autocompleateFields) {
      autocompleateFields.forEach((fieldName) => {
        setFieldValue(getProperty(suggestion.payload, fieldName), fieldName);
      });
    }
    /* todo нужны ли вообще подсказки в ЛК? */
    function setFieldValue(value: any, fieldName: string) {
      if (value) {
        if (onChange) {
          onChange(getFakeEvent(value, fieldName) as Change);
          setFieldTouched(fieldName, true, true);
        }
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const { key } = event;
    // eslint-disable-next-line default-case
    switch (key) {
      case 'ArrowUp':
        setActiveSuggestion(prevSuggestion(activeSuggestion));
        break;
      case 'ArrowDown':
        setActiveSuggestion(
          nextSuggestion(activeSuggestion, suggestions)
        );
        break;
      case 'Escape':
      case 'Tab':
        hideSuggestions();
        break;
      case 'Enter':
        selectSuggestion(event, state, activeSuggestion, handleSuggestionClick);
        break;
    }
  };

  const inputProps = {
    ...props,
    onChange: handleChange,
    onFocus: () => setShowSuggestions(),
    autoComlete: 'off',
    onKeyDown: handleKeyDown
  };

  return (
    <S.Dadata ref={ref}>
      <Input {...inputProps} autoComplete="off" />
      <Suggestions
        suggestions={suggestions}
        onClick={handleSuggestionClick}
        isShow={showSuggestions}
        active={activeSuggestion}
        setActive={setActiveSuggestion}
      />
    </S.Dadata>
  );
};
