import React from 'react';

import { NavLink } from 'react-router-dom';
import find from 'lodash/find';

import exclamationMark from 'assets/img/svg/exclamationMark.svg';
import { useStore } from 'components/hooks/useStore';

import { ISidebarLinkProps } from './interfaces';

import S, { ExclamationMark } from './styles';

export const SidebarLink = ({
  Icon,
  title,
  to,
  onClick,
  isMessage
}: ISidebarLinkProps) => {
  const { userMessagesData: { userMessages } } = useStore()
  const isExistNewMessage = isMessage && find(userMessages, ({ isRead }) => !isRead);
  return (
    <S.SidebarItem data-test="sidebar-item" onClick={onClick}>
      <NavLink to={to}>
        <Icon data-test="sidebar-item-icon" />
        <span data-test="sidebar-item-title">{title}</span>
        {isExistNewMessage && isMessage &&
        <ExclamationMark>
          <img src={exclamationMark} alt="!" />
        </ExclamationMark>}
      </NavLink>
    </S.SidebarItem>
  )
}
