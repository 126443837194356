import React from 'react';

import { CommonTooltip } from 'components/shared/commonTooltip';
import { Loader, Common } from 'components/shared';

import S from './styles';
import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';

const { LoaderWrapper } = S
const { ExtraText } = Common

export const LoaderTooltip = ({ isShow, description }: {
  isShow: boolean;
  description: string;
}) => {
  useLockBodyScroll(isShow)
  return (
    <CommonTooltip
      isShow={isShow}
      onClose={() => undefined}
      showCloseButton={false}
    >
      <LoaderWrapper>
        <Loader />
        <ExtraText>
          {description}
        </ExtraText>
      </LoaderWrapper>
    </CommonTooltip>
  )
}
