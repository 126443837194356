import {
  createSlice,
  combineReducers,
  PayloadAction
} from '@reduxjs/toolkit';

import { IDateRange } from 'interfaces';

import { requestAuth } from '../auth';

export const dateBordersSlice = createSlice({
  name: 'dateBorders',
  initialState: {},
  reducers: {
    setDateBorders: (state, action: PayloadAction<IDateRange>): IDateRange => action.payload
  }
})
export const { setDateBorders } = dateBordersSlice.actions

export const userNameSlice = createSlice({
  name: 'userName',
  initialState: '',
  reducers: {
    setUserName: (state, action: PayloadAction<string>): string => action.payload
  }
})
export const { setUserName } = userNameSlice.actions

export const phoneReducerSlice = createSlice({
  name: 'phoneReducer',
  initialState: '',
  reducers: {},
  extraReducers: ((builder) => {
    builder.addCase(
      requestAuth,
      (state, action: PayloadAction<{ phone: string, password: string }>) => {
        state = action.payload.phone
        return state
      }
    )
  })
})

export const accountReducer = combineReducers({
  name: userNameSlice.reducer,
  phone: phoneReducerSlice.reducer,
  dateBorders: dateBordersSlice.reducer
});
