import { checkDataPurchase } from '.';
import { acquiring, successAcquiring } from './acquiring';

const stepOne = [checkDataPurchase()];
const stepOneWithIisCheck = [checkDataPurchase(true)];

const stepTwo = [acquiring, successAcquiring];

export const opifPurchase = [stepOne, stepTwo];
export const opifPurchaseWithIisCheck = [stepOneWithIisCheck, stepTwo];
