import React from 'react';

import {
  IWizardForm,
  IBeforeNext
} from 'interfaces';
import runner from 'components/shared/input/shareCount';
import { PurchaseTypes } from 'enums/purchaseTypes';
import { Url } from 'api/url';
import { FormField } from 'entities/formField';
import { formFields } from 'components/shared/formFields';
import { Icon } from 'assets/img/svg';
import { Hint, Common } from 'components/shared';

import { bankFields } from './bankFields';
import { SmsBeforeNext } from '../SmsBeforeNext';
import {
  DescriptionCheckData,
  DescriptionPurchase,
  DescriptionRecognition
} from './sms/description';

const { ExtraText } = Common

const investSum = new FormField(
  {
    name: 'investSum',
    placeholder: 'Укажите сумму инвестирования',
    type: 'money',
    initialValue: '0'
  },
  { isRequired: true }
);

const notification = new FormField(
  {
    name: 'docsNotification',
    placeholder: '',
    type: 'content',
    initialValue: ''
  }
);

const hint = new FormField(
  {
    name: 'hint',
    placeholder: '',
    type: 'textarea',
    initialValue: ''
  },
  {
    likeTextField: true,
    groupDescription: 'Проверьте указанные данные – если Ваши паспортные данные изменились, ' +
      'обратитесь в Управляющую компанию.'
  }
);

const name = new FormField(
  {
    name: 'fio',
    placeholder: 'ФИО',
    initialValue: '',
    type: 'textarea'
  },
  {
    likeTextField: true,
    groupTitle: 'Проверьте Ваши данные'
  }
);
const latinName = new FormField(
  {
    name: 'latinName',
    placeholder: '',
    initialValue: '',
    type: 'text'
  },
  {
    likeTextField: true,
    getInitialValueFromState: true
  }
);
const passport = new FormField(
  {
    name: 'passportSeriesNumber',
    placeholder: 'Паспорт',
    initialValue: '',
    type: 'text'
  },
  {
    mask: '99 99 999999',
    accumulateStateFields: ['passport.series', 'passport.number'],
    likeTextField: true
  }
);

const inn = new FormField(formFields.inn, {
  isRequired: false,
  likeTextField: true,
  getInitialValueFromState: true
});

const passportIssuesPlace = new FormField(
  {
    name: 'passport.issuesPlace',
    placeholder: 'Выдан',
    initialValue: '',
    type: 'textarea'
  },
  {
    getInitialValueFromState: true,
    likeTextField: true
  }
);

const dayBirth = new FormField(formFields.birthDetails.dayBirth, {
  likeTextField: true,
  getInitialValueFromState: true
});

const placeBirth = new FormField(formFields.birthDetails.placeBirth, {
  likeTextField: true,
  getInitialValueFromState: true
});

const address = new FormField(formFields.address.addressString, {
  likeTextField: true,
  getInitialValueFromState: true
});

const ForegnerPlaceholder = (
  <ExtraText
    style={{ marginTop: '35px', display: 'block', marginBottom: '-14px' }}
  >
    Являюсь иностранным налогоплательщиком
  </ExtraText>
);

const foreignerCheckbox = new FormField({
  name: 'foreigner',
  type: 'checkbox',
  placeholder: ForegnerPlaceholder,
  initialValue: false
});

const existIISOtherCompanyPlaceholder = (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <ExtraText style={{ display: 'block', paddingRight: '5px' }}>
      Наличие ИИС в другой компании
    </ExtraText>
    <Hint
      title={<Icon.Question />}
      wrapperClassName="hint"
      content="Отметить, если имеется индивидуальный инвестиционный счет (ИИС) в другой компании, который необходимо закрыть не позднее месяца с даты настоящего заявления"
    />
  </div>
);

const existIISOtherCompany = new FormField({
  name: 'existIISOtherCompany',
  type: 'checkbox',
  placeholder: existIISOtherCompanyPlaceholder,
  initialValue: false
});

const email = new FormField(formFields.contacts.email, {
  getInitialValueFromState: true,
  groupTitle: 'Контактные данные',
  disabled: true
});

const phone = new FormField(formFields.contacts.phone, {
  isRequired: false,
  disabled: true,
  getInitialValueFromState: true,
  mask: '+7 999 999 99 99'
});

const SmsCheckDataRedemption = (props: IBeforeNext) => (
  <SmsBeforeNext
    {...props}
    description={() => <DescriptionCheckData />}
    type={PurchaseTypes.redemption}
  />
);

const SmsCheckDataPurchase = (props: IBeforeNext) => (
  <SmsBeforeNext
    {...props}
    description={() => <DescriptionPurchase />}
    type={PurchaseTypes.purchase}
  />
);

const SmsCheckDataRecognition = (props: IBeforeNext) => (
  <SmsBeforeNext
     {...props}
     description={() => <DescriptionRecognition />}
     type={PurchaseTypes.recognition}
  />
);

const shareCountTitle = new FormField(
  {
    name: 'shareCountTitle',
    type: 'content',
    placeholder: '',
    initialValue: ''
  }
);

const productId = new FormField(
  {
    name: 'purchase.productId',
    placeholder: '',
    type: 'text',
    initialValue: ''
  },
  {
    disabled: true,
    invisible: true,
    accumulateStateFields: ['productId']
  }
);

const passportIssuesDate = new FormField(formFields.passport.issuesDate, {
  getInitialValueFromState: true,
  likeTextField: true
});

const passportUnitCode = new FormField(formFields.passport.unitCode, {
  likeTextField: true,
  mask: '999 – 999'
});

const {
  bankName,
  bankBik,
  correspondentAccount,
  checkingAccount,
  bankPlace,
  bankPlaceType
} = bankFields;

export const checkDataSale: IWizardForm = {
  title: '',
  fields: [
    shareCountTitle,
    runner,
    notification,
    hint,
    name,
    latinName,
    dayBirth,
    placeBirth,
    passport,
    passportIssuesPlace,
    passportIssuesDate,
    passportUnitCode,
    bankPlace,
    bankPlaceType,
    inn,
    address,
    bankName,
    bankBik,
    correspondentAccount,
    checkingAccount,
    foreignerCheckbox,
    email,
    phone,
    productId
  ],
  url: Url.redemption.submit,
  submitTitle: 'Получить СМС-код',
  BeforeNext: SmsCheckDataRedemption
};

export const checkDataPurchase = (needCheckIis?: boolean): IWizardForm => ({
  title: '',
  fields: [
    investSum,
    notification,
    hint,
    name,
    latinName,
    dayBirth,
    placeBirth,
    passport,
    passportIssuesPlace,
    passportIssuesDate,
    passportUnitCode,
    bankPlace,
    bankPlaceType,
    inn,
    address,
    bankName,
    bankBik,
    correspondentAccount,
    checkingAccount,
    foreignerCheckbox,
    ...(needCheckIis ? [existIISOtherCompany] : []),
    email,
    phone,
    productId
  ],
  url: Url.purchase.submit,
  enableSubmit: false,
  BeforeNext: SmsCheckDataPurchase,
  submitTitle: 'Получить СМС-код'
});

export const checkDataRecognition: IWizardForm = {
  title: '',
  fields: [],
  url: Url.recognition.submit,
  submitTitle: 'Получить СМС-код',
  BeforeNext: SmsCheckDataRecognition
};
