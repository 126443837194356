import { useEffect, useState } from 'react';

import { Token } from 'entities/token';
import { refreshToken } from 'api/refreshToken';

export const useAuth = (storeIsAuth: boolean) => {
  const wait = 'wait';
  const [tokenState, setTokenState] = useState<boolean | string>(wait);
  const remainingTime = Token.getRemainingTime();

  useEffect(() => {
    const checkToken = async () => {
      if (remainingTime) {
        if (remainingTime < 2) {
          await refreshToken();
        }
        return setTokenState(true);
      }
      return setTokenState(false);
    }
    checkToken();
  }, [remainingTime]);

  const isAuth = () => {
    if (tokenState === wait) return wait;
    return tokenState && storeIsAuth;
  };
  return isAuth();
}
