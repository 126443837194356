import React, {
  FormEvent,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logOutAction } from 'store/actions/actionCreators/logOut';
import { InputEvent } from 'types/inputEvent';
import { scrollToTop } from 'helpers/scrollToTop';
import { requestCheck, resetData } from 'store/reducers/passwordRecovery';
import { requestAuth } from 'store/reducers/auth';
import { checkSmsRecoveryRequest } from 'store/actions/actionCreators/checkSmsRecoveryRequest';
import {
  Common,
  InputPassport,
  InputPassword,
  InputPhone
} from 'components/shared';

import { Sms } from '../sms';
import { useStore } from '../../hooks/useStore';
import { IState } from './interfaces';
import { Stages } from './stages';

import S from './styles';

const {
  Form,
  RowButtons,
  Text,
  LoginPanel: LoginPanelStyle
} = S
const {
  InfoButton,
  ButtonLarge,
  H1,
  H2,
  ExtraText
} = Common

export const LoginPanel = () => {
  const {
    passwordRecovery: {
      sendingData,
      correctCode,
      correctData,
      error,
    },
    authentication: {
      correctData: correctDataAuthentication,
      correctCode: correctCodeAuthentication,
      sendingData: authenticationSendingData,
      error: authenticationError
    }
  } = useStore()
  /* стейт для логина */
  const [login, setLogin] = useState('')
  /* стейт для пароля */
  const [password, setPassword] = useState('')
  /* стейт для паспорта */
  const [passport, setPassport] = useState('')
  /* текущий виджет - ввод пароля, восстановление пароля, авторизация и т.д. */
  const [stage, setStage] = useState(Stages.login)
  const [errors, setErrors] = useState({
    login: '',
    password: '',
    passport: '',
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  /* отправляем логин и пароль на проверку */
  const currentAuthData = (
    phone: string,
    password: string
  ) => dispatch(requestAuth({ phone, password }))
  /* отправляем логин-паспортные данные на проверку */
  const currentRecoveryData = (
    userName: string,
    passportSeries: string,
    passportNumber: string
  ) => dispatch(requestCheck({
    userName,
    passportSeries,
    passportNumber
  }))
  const currentRecoveryDataClear = () => dispatch(resetData())
  const logOut = () => dispatch(logOutAction())
  const setError = (field: string, error: string = 'Заполните это поле') => setErrors((prevState: IState) => ({
    ...prevState,
    [field]: error
  }))
  useEffect(() => {
    /* если юзер ввел корректную пару логин-пароль меняем stage на ввод смс */
    if (correctDataAuthentication && stage === Stages.login) {
      setStage(Stages.loginSms);
      return;
    }
    /* если юзер ввел ввел верное смс при авторизации - redirect на страницу portfolio */
    if (correctCodeAuthentication) {
      navigate('/portfolio', { replace: true });
      return;
    }
    /* если юзер ввел корректную пару логин-паспорт меняем stage на ввод смс */
    if (correctData && stage === Stages.recovery) {
      setStage(Stages.recoverySms);
      return;
    }
    /* если юзер ввел ввел верное смс при восстановлении пароля - сообщаем что пароль создан */
    if (correctCode && stage === Stages.recoverySms) {
      setStage(Stages.createPass);
    }
  }, [
    correctDataAuthentication,
    correctCodeAuthentication,
    correctCode,
    correctData,
    stage
  ])
  /* универсальный обработчик событий в input */
  const inputChange = ({ currentTarget: { name, value } }: InputEvent) => {
    switch (name) {
      case 'login':
        return setLogin(value)
      case 'password':
        return setPassword(value)
      default:
        return setPassport(value)
    }
  }
  const submitForm = (event?: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    removeErrors();
    // eslint-disable-next-line default-case
    switch (stage) {
      case Stages.login:
      case Stages.loginSms:
        if (!login) {
          setError('login', 'Введите номер Вашего мобильного телефона');
          return;
        }
        if (!password) {
          setError('password', 'Введите пароль');
          return;
        }
        scrollToTop();
        currentAuthData(login, password);
        break;
      case Stages.recovery:
      case Stages.recoverySms:
        if (!login) {
          setError('login', 'Введите номер Вашего мобильного телефона');
          return;
        }
        const passportData = (passport && passport.replace(/\D/g, '')) || '';
        if (passportData.length !== 10) {
          setError('passport', 'Введите серию и номер паспорта');
          return;
        }
        const passportSeries = passportData.substr(0, 4);
        const passportNumber = passportData.substr(4);
        currentRecoveryData(login, passportSeries, passportNumber);
        break;
    }
  }

  const cancelSmsVerification = () => {
    setStage(stage === Stages.recoverySms
      ? Stages.recovery
      : Stages.login);

    if (stage === Stages.recoverySms) {
      currentRecoveryDataClear()
    } else {
      logOut();
    }
  }
  const removeErrors = () => {
    setErrors((prevState) => ({
      ...prevState,
      login: '',
      password: '',
      passport: '',
    }))
  };

  const onClickRestore = () => setStage(Stages.recovery);

  const smsForm = () => {
    const passportData = (passport && passport.replace(/\D/g, '')) || '';

    const passportSeries = passportData.substr(0, 4);
    const passportNumber = passportData.substr(4);
    const checkSmsRecovery = (code: string) => {
      return checkSmsRecoveryRequest(code, passportSeries, passportNumber, login);
    }

    let isAutoFocus: boolean = true;

    if (window.innerWidth <= 600) {
      isAutoFocus = false;
    }

    return (
      <Sms
        onClose={cancelSmsVerification}
        sendSms={submitForm}
        isDisabled={stage === Stages.recoverySms
          ? sendingData
          : authenticationSendingData}
        sendActionCreator={stage === Stages.recoverySms
          ? checkSmsRecovery
          : null}
        isAutoFocus={isAutoFocus}
        isAutoSend
      />
    );
  }

  const stageData = () => ({
    [Stages.login]: {
      title: 'Вход в личный кабинет',
      component: (
        <Form onSubmit={submitForm}>
          <InputPhone
              placeholder="Логин"
              description="Номер Вашего мобильного телефона"
              value={login}
              name="login"
              onChange={inputChange}
              error={errors.login ? errors.login : authenticationError && ' '}
              disabled={sendingData}
              autoComplete="off"
          />
          <InputPassword
              placeholder="Пароль"
              value={password}
              name="password"
              onChange={inputChange}
              error={errors.password ? errors.password : authenticationError}
              disabled={sendingData}
              autoComplete="current-password"
              onClickRestore={onClickRestore}
          />
          <ButtonLarge
              type="submit"
              className="center-block"
              disabled={authenticationSendingData}
          >
            Войти
          </ButtonLarge>
        </Form>
      )
    },
    [Stages.loginSms]: {
      title: 'Ввод кода из СМС',
      component: smsForm()
    },
    [Stages.recovery]: {
      title: 'Восстановление пароля',
      component: (
        <Form onSubmit={submitForm}>
          <InputPhone
              placeholder="Логин"
              description="Номер Вашего мобильного телефона"
              value={login}
              name="login"
              onChange={inputChange}
              error={errors.login ? errors.login : ''}
              disabled={sendingData}
              autoComplete="off"
          />
          <InputPassport
              value={passport}
              name="passport"
              onChange={inputChange}
              error={errors.passport || error}
          />
          <RowButtons>
            <InfoButton
                type="button"
                onClick={() => setStage(Stages.login)}
                className="center-block"
            >
              Назад
            </InfoButton>
            <ButtonLarge
                disabled={sendingData}
                type="submit"
                className="center-block"
            >
              Далее
            </ButtonLarge>
          </RowButtons>
        </Form>
      )
    },
    [Stages.recoverySms]: {
      title: 'Ввод кода из СМС',
      component: smsForm()
    },
    [Stages.createPass]: {
      title: 'Создание нового пароля',
      component: (
        <Form onSubmit={submitForm}>
          <Text>
            <ExtraText style={{ textAlign: 'center' }}>
              Уважаемый пользователь! Новый пароль отправлен Вам в&nbsp;
              СМС-сообщении. Если Вы&nbsp;не&nbsp;получили СМС, обратитесь
              в&nbsp;службу поддержки:{' '}
              <a className="phone" href="tel:+74959670913">
                +7 (495) 967-09-13.
              </a>
            </ExtraText>
          </Text>
          <ButtonLarge
              style={{
                margin: 'auto',
                marginTop: '25px'
              }}
              onClick={() => {
                setErrors({
                  login: '',
                  password: '',
                  passport: '',
                })
                setLogin(login)
                setPassword('')
                setPassport('')
                setStage(Stages.login)
              }}
          >
            {' '}
            Вернуться к&nbsp;авторизации
          </ButtonLarge>
        </Form>
      )
    }
  }[stage])

  const { title, component } = stageData();
  return (
    <LoginPanelStyle data-test="login-panel" stage={stage}>
      <>
        {stage === Stages.login
          ? <H1 className="center-block">{title}</H1>
          : <H2 className="center-block">{title}</H2>}
        {component}
      </>
    </LoginPanelStyle>
  );
}
