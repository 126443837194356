import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  IAddress,
  IPassport,
  PurchaseActions
} from 'interfaces';

import {
  IPurchaseDataEnhanced,
  IRedemption
} from './intefaces';

dayjs.extend(customParseFormat);

export class PuchaseRequestData {
  readonly requestData: IPurchaseDataEnhanced;

  readonly files?: FormData;

  constructor(purchaseData: IPurchaseDataEnhanced, type?: PurchaseActions) {
    const address = PuchaseRequestData.getFormatAddressData(purchaseData.addressRegistration);

    const redemptionFields =
      type === 'sale'
        ? {
          redemption: PuchaseRequestData.getRedemption(purchaseData),
          gender: purchaseData.gender
        }
        : {};
    const purchaseFields =
      type === 'purchase'
        ? {
          purchase: PuchaseRequestData.getPurchase(purchaseData),
          gender: purchaseData.gender
        }
        : {};

    this.requestData = {
      ...redemptionFields,
      ...purchaseFields,
      firstName: purchaseData.firstName,
      lastName: purchaseData.lastName,
      patronymic: purchaseData.patronymic,
      email: purchaseData.email,
      inn: purchaseData.inn,
      placeBirth: purchaseData.placeBirth,
      phone: purchaseData.phone,
      passport: PuchaseRequestData.getFormatPassportData(
        purchaseData.passport,
        purchaseData.passportSeriesNumber
      ),
      dayBirth: PuchaseRequestData.formatDate(purchaseData.dayBirth),
      addressRegistration: address,
      addressPost: address,
      requisites: purchaseData.requisites,
      existIISOtherCompany: purchaseData.existIISOtherCompany
    };
    if (type === 'add') {
      this.requestData = {
        purchase: {
          price: Number(purchaseData.investSum) || NaN,
          productId: purchaseData.productId || '',
          productName: purchaseData.productName
        },
        fullNumber: purchaseData.order ? purchaseData.order.number : '',
        applicantId: purchaseData.applicantId,
        firstName: purchaseData.firstName,
        lastName: purchaseData.lastName,
        patronymic: purchaseData.patronymic,
        reBuyDate: PuchaseRequestData.getOrderDate(purchaseData.order)
      };
    }
  }

  private static getOrderDate(order?: { date: string }) {
    if (order && order.date) {
      return PuchaseRequestData.formatDate(order.date);
    }
    return '';
  }

  private static getPurchase(purchaseData: IPurchaseDataEnhanced) {
    const { productName, purchase } = purchaseData;
    if (purchase) {
      const { productId, price } = purchase;
      return {
        productId,
        price,
        productName: productName || ''
      };
    }
    return undefined;
  }

  private static getRedemption(purchaseData: IPurchaseDataEnhanced): IRedemption {
    return {
      amount: purchaseData.shareCount,
      productId: purchaseData.purchase ? purchaseData.purchase.productId : '0',
      accountNumber: purchaseData.accountNumber
    };
  }

  private static getFormatAddressData(address?: IAddress): IAddress | undefined {
    if (address && address.addressString) {
      return { addressString: address.addressString };
    }
    return undefined;
  }

  private static formatDate(date?: string): string | undefined {
    const result = dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
    if (result) return result;
    return undefined;
  }

  private static getFormatPassportData(
    passport?: IPassport,
    seriesNumber?: string
  ): IPassport | undefined {
    if (passport) {
      let result = { ...passport };

      if (passport.unitCode) {
        result = {
          ...result,
          unitCode: passport.unitCode.replace('-', '')
        };
      }
      if (passport.issuesDate) {
        result = {
          ...result,
          issuesDate: this.formatDate(passport.issuesDate)
        };
      }
      if (seriesNumber) {
        const seriesNumberWithoutWhiteSpace = seriesNumber.replace(/\s/g, '');
        const series = seriesNumberWithoutWhiteSpace.substr(0, 4);
        const number = seriesNumberWithoutWhiteSpace.substr(4, 6);
        result = {
          ...result,
          series,
          number
        };
      }
      return result;
    }
    return undefined;
  }
}
