import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISecurities } from 'interfaces';
import { Money } from 'entities/money';
import { Percents } from 'entities/percents';

/* slice для продуктов ДУ(!) */
export const securitiesSlice = createSlice({
  name: 'securities',
  initialState: {
    currentSecurities: [] as ISecurities<Money | Percents>[],
    error: ''
  },
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requestSecurities: (state, action: PayloadAction<{ id: string }>) => state,
    successSecurities: (state, action: PayloadAction<ISecurities<Money | Percents>[]>) => {
      state.currentSecurities = action.payload
      return state
    },
    errorSecurities: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      return state
    }
  }
})
export const {
  requestSecurities,
  successSecurities,
  errorSecurities
} = securitiesSlice.actions
