import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { IDateRange } from 'interfaces';

import { setDateBorders } from './account';

/* slice для даты в header */
export const headerDateRangeSlice = createSlice({
  name: 'headerDateRange',
  initialState: {},
  reducers: {
    setHeaderDateRange: (state, action: PayloadAction<IDateRange>): IDateRange => action.payload
  },
  extraReducers: (builder) => {
    builder.addCase(setDateBorders, (state, action: PayloadAction<IDateRange>): IDateRange | {} => {
      state = isEmpty(state) ? action.payload : state
      return state
    })
  }
})
export const { setHeaderDateRange } = headerDateRangeSlice.actions

/* slice для даты в charts */
export const chartsDateRangeSlice = createSlice({
  name: 'chartsDateRange',
  initialState: {},
  reducers: {
    setChartDateRange: (state, action: PayloadAction<IDateRange>): IDateRange => action.payload
  },
  extraReducers: (builder) => {
    builder.addCase(setDateBorders, (state, action: PayloadAction<IDateRange>): IDateRange | {} => {
      state = isEmpty(state) ? action.payload : state
      return state
    })
  }
})
export const { setChartDateRange } = chartsDateRangeSlice.actions
