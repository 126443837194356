import styled from 'styled-components';

import StylesWizard from 'components/widgets/multistepWizard/styles';
import { Common } from 'components/shared';
import StylesForm from 'components/widgets/form/styles';
import S from 'components/shared/commonTooltip/styles';
import { media } from 'assets/styles/theme';
import StylesProductButton from 'components/widgets/portfolioCharts/productButton/styles';

const { ProductButton } = StylesProductButton;
const { H1, Panel } = Common
const { Tooltip } = S

const Purchase = styled.div`
  width: 100%;
  padding: 0 30px;

  ${media.mobile} {
    padding: 0;
  }

  ${H1} {
    ${media.tablet} {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 15px;
    }

    ${media.mobile} {
      padding: 0 15px;
      font-size: 16px;
      line-height: 1.3;
      margin-top: 0;
    }
  }

  ${StylesWizard.MultiStepWizard} {
    align-items: flex-start;

    ${Panel} {
      width: 100%;
      max-width: 870px;
      align-items: flex-start;
      padding: 25px 25px 25px;

      & > div {
        width: 100%;
      }

      ${media.mobile} {
        padding: 30px 15px;
      }
    }
    ${Tooltip} {
      ${StylesForm.FormComponent} {
        max-width: 370px;
        [type="submit"] {
          margin-left: auto;
        }
      }
    }
    ${StylesForm.FormComponent} {
      max-width: 480px;
      
      ${ProductButton} {
        margin-bottom: 30px;
      }

      & > form {
        width: 100%;
      }
    }

    ${StylesForm.FieldsContainer} {
      max-width: none;
      width: 100%;
    }
  }
`;

export default { Purchase };
