import React from 'react';

import { Change } from 'types/inputEvent';
import { getFakeEvent } from 'helpers/field/getFakeEvent';

import { IRadioButtonProps } from './interfaces';

import S from './styles';

export const RadioButton = ({
  label,
  onChange,
  id,
  value,
  checked,
  name
}: IRadioButtonProps) => {
  const [currentName] = name ? name.split('-') : [''];
  const handleChange = (event: Change) => (name
    ? onChange(getFakeEvent(id, name) as Change)
    : onChange(event));

  return (
    <S.RadioButton data-test="radio-button-component">
      <input
        name={currentName}
        type="radio"
        value={value}
        id={id}
        checked={checked}
        aria-checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={id}>{label}</label>
    </S.RadioButton>
  );
};
