import React from 'react';

import map from 'lodash/map';

import S from './styles';

export const Loader = () => {
  const divs = [];
  for (let i = 0; i < 8; i += 1) {
    divs.push(<div key={`div-${i}`} />);
  }
  return (
    <S.Loader>
      <div className="lds-spinner">{map(divs, (div) => div)}</div>
    </S.Loader>
  );
};
