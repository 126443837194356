import styled from 'styled-components';

import { media } from 'assets/styles/theme';
import { Common } from 'components/shared';

const { H1, Panel, H2 } = Common

const MultiStepWizard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.du input[name^="requisites."] {
    pointer-events: none;
  }
  
  ${H1} {
    margin-bottom: 38px;
  }

  ${Panel} {
    height: auto;
    padding: 48px 100px 60px 100px;
    margin-bottom: 60px;
    justify-content: start;
    align-items: center;
    transition: height 10s;
    ${H2} {
      text-align: left;
      margin-bottom: 30px;
      width: 370px;
      ${media.mobile} {
        width: 100%;
      }
    }
  }
`;

export default { MultiStepWizard };
