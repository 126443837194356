import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { api } from 'api';
import { Url } from 'api/url';
import {
  errorClientDataStatus,
  requestClientDataStatus,
  successClientDataStatus
} from '../../../reducers/checkAndApproveClientData';

interface IDisplayMessageStatus {
  IsRelevantClientData: boolean;
}

/* сага для получения актуальности паспортных данных */
function* getPassportDataStatus() {
  try {
    const params = {
      url: Url.client_message.check
    };
    const displayMessageStatus: IDisplayMessageStatus = yield call(
      api.get,
      params
    );
    yield put(successClientDataStatus(displayMessageStatus.IsRelevantClientData));
  } catch (e) {
    console.log(e.message)
    yield put(errorClientDataStatus(e.message));
  }
}

export function* checkPassportData() {
  yield takeEvery(
    requestClientDataStatus.toString(),
    getPassportDataStatus
  );
}
