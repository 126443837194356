import styled from 'styled-components';

import { theme, media } from 'assets/styles/theme';
import { Common } from 'components/shared';

const { DescriptionText } = Common

const AreaChart = styled.div`
  width: 100%;

  ${media.tablet} {
    width: calc(100% + 10px);
    margin: 0 -5px;
    overflow-x: auto;
    overflow-y: hidden;
    ${theme.scrollbar};
  }

  .recharts-responsive-container {
    min-width: 300px;
  }

  .recharts-wrapper {
    height: auto !important;
    width: 100% !important;
    transition-duration: 0s;

    svg {
      width: 100% !important;
    }
  }

  .recharts-cartesian-axis-tick-value {
    tspan {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.06px;
      line-height: 18px;
      fill: ${theme.colors.description};
    }
  }

  .recharts-default-tooltip {
    border: none !important;
    padding: 16px !important;
    box-shadow: 0 3px 7px 0 rgba(83, 119, 138, 0.15);
    border-radius: 5px;

    .recharts-tooltip-item-name {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.06px;
      line-height: 18px;
      color: ${theme.colors.description};
    }

    .recharts-tooltip-item-value {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;
export const ItemStyle = {
  fontSize: '17px',
  lineHeight: '20px',
  color: theme.colors.textPrimary,
  border: 'none',
  display: 'flex',
  flexDirection: 'column'
};
export const WrapperStyle = {
  height: '76px',
  borderRadius: '5px',
  border: 'none',
  backgroundColor: '#FFFFFF',
  boxShadow: ' 0 3px 7px 0 rgba(83,119,138,0.15)'
};
export const CursorStyle = {
  stroke: theme.colors.link,
  strokeWidth: 1,
  strokeDasharray: '1',
  'stroke-dashoffset': 100
};

export const WrapperContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled(DescriptionText)`
  text-align: center;
`

export default { AreaChart };
