export function getProductLink(productName?: string) {
  if (!productName) {
    return false;
  }
  switch (productName.trim()) {
    case 'ВЕЛЕС - Системный':
      return 'https://www.veles-management.ru/uploadedfiles/rules_Systemniy.pdf';
    case 'ВЕЛЕС - Классический':
      return 'https://www.veles-management.ru/uploadedfiles/rules_Classic.pdf';
    default:
      return false;
  }
}

export function getProductRequisites(productName?: string) {
  if (!productName) {
    return false;
  }

  switch (productName.trim()) {
    case 'ВЕЛЕС - Системный':
      return 'ttps://www.veles-management.ru/uploadedfiles/%D0%A0%D0%B5%D0%BA%D0%B2%D0%B8%D0%B7%D0%B8%D1%82%D1%8B%20%D1%82%D1%80%D0%B0%D0%BD%D0%B7%D0%B8%D1%82%D0%BD%D0%BE%D0%B3%D0%BE%20%D1%81%D1%87%D0%B5%D1%82%D0%B0_%D0%A1%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%BD%D1%8B%D0%B9.pdf';
    case 'ВЕЛЕС - Классический':
      return 'https://www.veles-management.ru/uploadedfiles/%D0%A0%D0%B5%D0%BA%D0%B2%D0%B8%D0%B7%D0%B8%D1%82%D1%8B%20%D1%82%D1%80%D0%B0%D0%BD%D0%B7%D0%B8%D1%82%D0%BD%D0%BE%D0%B3%D0%BE%20%D1%81%D1%87%D0%B5%D1%82%D0%B0_%D0%9A%D0%BB%D0%B0%D1%81%D1%81%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9.pdf';
    default:
      return false;
  }
}
