import React from 'react';

import { NumberField } from 'components/shared';
import FinResStyle from 'components/shared/commonStyles/finResult/styles';
import { FieldTypes } from 'enums';

import { Item } from '../../header/item';
import { ICurrentStatisticsProps } from './interfaces';

import S from '../styles';

const { FinResult } = FinResStyle

export const CurrentStatistics = ({ financial }: ICurrentStatisticsProps):JSX.Element => {
  const { diff } = FieldTypes;
  const {
    numberShares,
    cost,
    input,
    output,
    result: {
      percents,
      inMoney
    }
  } = financial;

  return (
    <S.Financials>
      <Item title="Стоимость активов">
        <NumberField value={cost} />
      </Item>
      <Item title="Вводы" hideOnMobile>
        <NumberField value={input} />
      </Item>
      <Item title="Выводы" hideOnMobile>
        <NumberField value={output} />
      </Item>
      <Item title="Доходность">
        <FinResult>
          <NumberField
            value={percents}
            type={diff}
            colorize
            hasSign
          />
        </FinResult>
      </Item>
      <Item title="Прирост">
        <FinResult>
          <NumberField
            value={inMoney}
            type={diff}
            colorize
            hasSign
          />
        </FinResult>
      </Item>
      {numberShares !== '0' &&
      <Item title="Количество паев, шт." hideOnMobile>
        {parseFloat(numberShares as string).toLocaleString(
          'ru-Ru',
          { minimumFractionDigits: 5 }
        )}
      </Item>}
    </S.Financials>
  );
};
