import React from 'react';

// eslint-disable-next-line import/no-cycle
import { Common } from '..';
import { IPopupProps } from './interfaces';

import S from './styles';

const { ExtraText } = Common
const { Popup: PopupStyles } = S

export const Popup = ({ children }: IPopupProps) => (
  <PopupStyles>
    <ExtraText>
      {children}
    </ExtraText>
    {' '}
  </PopupStyles>
);
