import styled from 'styled-components';

const RadioButton = styled.div`
  display: flex;
  cursor: pointer;

  input {
    height: 0;
    width: 0;
    opacity: 0;
    position: absolute;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input:checked ~ label {
    cursor: default;
  }
`;

export default { RadioButton };
