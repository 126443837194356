import React, {
  useState,
  useEffect,
  useRef
} from 'react';

import InputMask from 'react-input-mask';

import { IMask } from 'constants/masks';

// eslint-disable-next-line import/no-cycle
import { MoneyInput } from './money';
import { TextField } from './textField';
import { IInputProps } from './intefaces';

import S from './styles';

const Input = (props: IInputProps) => {
  const {
    autoFocus,
    placeholder,
    name,
    type,
    value,
    disabled,
    error,
    autoComplete,
    hint,
    maskChar,
    invisible,
    likeTextField,
    description,
    mask,
    onBlur,
    onFocus,
    onChange,
    onKeyDown
  } = props;

  const inputRef = useRef<HTMLDivElement>(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error]);

  const maskCurrent = mask || '';
  const id = `input-${placeholder}`;
  const descriptionCurrent = description && (
    <S.Description data-test="input-description">
      {description}
    </S.Description>
  );
  const maskProps = {
    maskPlaceholder: maskChar,
    mask: value ? maskCurrent : ''
  };

  const inputProps = {
    id,
    type,
    name,
    onKeyDown,
    autoComplete,
    autoFocus,
    disabled: likeTextField || disabled,
    value: value || '',
    onChange: onChange || mockHandler,
    onBlur: onBlur || mockHandler,
    onFocus: onFocus || mockHandler
  };

  function mockHandler() {
    return undefined;
  }

  return (
    <>
      {!likeTextField
        ? (
          <S.Input
            hasError={!inputProps.disabled && hasError}
            likeTextField={likeTextField}
            invisible={invisible}
            ref={inputRef}
          >
            {type === 'textarea'
              ? <S.TextArea {...inputProps} data-value={value} />
              : type === 'money'
                ? <MoneyInput {...inputProps} />
              // @ts-expect-error
                : <InputMask {...inputProps} {...maskProps} />}
            <label htmlFor={id}>{placeholder}</label>
            {!inputProps.disabled
              ? (
                <S.Info>
                  {String(error).trim()
                    ? null
                    : descriptionCurrent}
                  <S.Error hasError={!!error}>{error}</S.Error>
                  <S.Hint>{hint}</S.Hint>
                </S.Info>)
              : null}
          </S.Input>)
        : <TextFieldMaskedInput {...props} />}
    </>
  );
};

export { Input };

const TextFieldMaskedInput = ({
  value,
  onChange,
  placeholder,
  mask
}: IInputProps) => {
  return (
    <>
      <InputMask
        value={value || ''}
        onChange={onChange}
        style={{ display: 'none' }}
        mask={getMask(mask)}
      />
      <TextField
        value={value}
        placeholder={placeholder}
      />
    </>
  );
};

function getMask(possibleMask?: string | IMask) {
  if (possibleMask) {
    if (typeof possibleMask === 'string') {
      return possibleMask;
    }
    const { mask } = possibleMask;
    return mask;
  }
  return '';
}
