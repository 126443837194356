import { all } from 'redux-saga/effects';

import { getRecommendedProducts } from './recommendedProducts';
import { getReports } from './reports';
import { authData, authSms } from './auth';
import { passwordRecoveryData, passwordRecoverySms } from './passwordRecovery';
import { getConfirmationExistStatus, sendAgreeConfirmation } from './confirmation';
import { chartData } from './chartData';
import { portfolio } from './portfolio';
import { purchase } from './purchase';
import { clientInfo } from './getClientInfo';
import { sms } from './purchase/sms';
import { productChartData } from './productChart';
import { throttleSetShareCount } from './purchase/throttleSetShareCount';
import { rebuy } from './rebuy';
import { securities } from './securities';
import { rangePrice } from './purchase/rangePrice';
import { getEdoStatus } from './edo/checkEdo';
import { submitRecognition } from './recognition';
import { getQualifiedStatus } from './qualifiedStatus/qualifiedStatus';
import { getOpifYield } from './getOpifYield';
import { checkPassportData } from './clientPassportData/checkClientPassportData';
import { submitApproveClientData } from './clientPassportData/approveClientPassportData';
import { userMessages } from './userMessages';
import { acceptEdoSms } from './edo/acceptEdoSms';
import { confirmClientPassportDataSms } from './clientPassportData/approveClientPassportDataSms';
import { recognitionQualifiedStatusSms } from './qualifiedStatus/recognitionQualifiedStatusSms';

export function* rootSaga() {
  yield all([
    userMessages(),
    getOpifYield(),
    getQualifiedStatus(),
    getEdoStatus(),
    throttleSetShareCount(),
    sms(),
    acceptEdoSms(),
    confirmClientPassportDataSms(),
    recognitionQualifiedStatusSms(),
    clientInfo(),
    getRecommendedProducts(),
    getReports(),
    authData(),
    authSms(),
    getConfirmationExistStatus(),
    sendAgreeConfirmation(),
    passwordRecoveryData(),
    passwordRecoverySms(),
    chartData(),
    portfolio(),
    securities(),
    purchase(),
    productChartData(),
    rebuy(),
    rangePrice(),
    submitRecognition(),
    checkPassportData(),
    submitApproveClientData()
  ]);
}
