export const Colors = [
  '#0096B7',
  '#CD7147',
  '#DC9B7D',
  '#EBC5B4',
  '#F5982E',
  '#F8B76C',
  '#FBD5A9',
  '#FBD5A9',
  '#FBD5A9',
  '#FBD5A9'
];
