import React from 'react';

import Portfel from 'assets/img/svg/portfel.svg';
import { LoaderAndCap } from 'components/shared';

import { IOwnProps } from '../../reports/interfaces';

export const NoProductOrReports = ({ text }:IOwnProps):JSX.Element => (
  <LoaderAndCap icon={Portfel} text={text} />
);
