import React from 'react';

import { Icon } from 'assets/img/svg';

import dayjs from 'dayjs';
import { IContractDataProps } from './interfaces';

import S from '../styles';

export const ContractData = ({ productNumber, date }:IContractDataProps):JSX.Element => (
  <S.ContractWrapper>
    <Icon.File />
    <S.Text>Номер договора:</S.Text>
    <S.ContractInfo>
      {` №${productNumber} от ${dayjs(date).format('DD-MM-YYYY')}`}
    </S.ContractInfo>
  </S.ContractWrapper>
);
