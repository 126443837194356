import React from 'react';

import { IWizardForm } from 'interfaces';
import { formFields } from 'components/shared/formFields';
import { FormField } from 'entities/formField';
import { InnHint } from 'components/shared/fieldHints/inn';
import { PassportNotification } from './passportNotification';

const { passport } = formFields;

const inn = new FormField(formFields.inn, {
  isRequired: true,
  mask: '999999999999',
  hint: <InnHint />
});

const seriesAndNumber = new FormField(passport.seriesAndNumber, {
  isRequired: true,
  mask: '99 99 999999'
});

export const innAndPassportNumber: IWizardForm = {
  title: 'ИНН и номер паспорта',
  fields: [inn, seriesAndNumber],
  AfterFields: PassportNotification,
  url: 'registration/step-two'
};

export const innAndPassportNumberFields = {
  inn,
  seriesAndNumber
};
