import styled, { css } from 'styled-components';

import XCircle from 'assets/img/svg/delete.svg';
import { ITheme } from 'assets/styles/theme';

import { Common } from '../index';

const { ExtraText, DescriptionText } = Common

const RemoveButton = styled.button`
  background-image: url(${XCircle});
  width: 17px;
  height: 17px;
  cursor: pointer;
`;

interface IDropZone {
  hasError?: boolean;
  theme: ITheme;
  isFile?: boolean;
}

const DropZoneWrapper = styled.div<IDropZone>`
  ${({ isFile }) => (isFile
    ? css`
        height: 50px;
        border: 1px solid ${({ theme }) => theme.colors.description};
        background-color: ${({ theme }) => theme.colors.underline};
    `
    : css`
        cursor: pointer;
        width: 230px;
        height: 70px;
        border: 1px dashed`)}
  ${({ theme, hasError }) => (!hasError ? theme.colors.description : theme.colors.buttonPrimary)};
  width: 230px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  margin-bottom: 18px;

  ${RemoveButton} {
    position: absolute;
    top: 0;
    right: 0;
  }
  ${ExtraText}, ${DescriptionText} {
    display: block;
    color: ${({ theme, hasError }) => hasError && theme.colors.buttonPrimary};
  }
`;

const DropZoneContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;
interface IFilesWrapper {
  fileContains: boolean;
}
const FilesWrapper = styled.div<IFilesWrapper>`
  transform: ${({ fileContains }) => (fileContains ? 'scaleY(1)' : ' scaleY(0)')};

  transform-origin: top;
  transition: transform .5s ease;
  ${DropZoneWrapper} {
    margin-bottom: 18px;
  }
`;

const Error = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06px;
  color: ${({ theme }) => theme.colors.buttonPrimary};
`;
export default {
  Error,
  DropZoneWrapper,
  RemoveButton,
  FilesWrapper,
  DropZoneContainer
};
