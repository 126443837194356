import { createGlobalStyle } from 'styled-components';
import { theme, media } from './theme';

export const GlobalStyles = createGlobalStyle`
  * {
    font-family: 'Montserrat'/* , sans-serif */;
    font-size: 13px;
  }
  body {
    height: 100%;
  }
  html {
    height: 100%;
  }

  h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 37px;
    margin: 0;
    color: ${theme.colors.textPrimary}
  }

  h2 {
    font-size: 22px;
    line-height: 27px;
    font-weight: normal;
    margin: 0;
    color: ${theme.colors.textPrimary}

  }

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: ${theme.colors.textPrimary}
  }

  a {
    color: ${theme.colors.link};
    font-size: 16px;
    letter-spacing: 0.07px;
    line-height: 20px;
    text-decoration: underline;
    text-decoration-color: ${theme.colors.underline};
    text-align: center;

    button {
      text-decoration: none;
    }
  }

  button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    outline:none;
  }

  .no-scroll {
    overflow:hidden;
  }

  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .show-tablet {
    ${media.desktop} {
      display: none !important;
    }
  }

  .hide-tablet {
    ${media.tablet} {
      display: none !important;
    }
  }

  .show-mobile {
    ${media.notMobile} {
      display: none !important;
    }
  }

  .hide-mobile {
    ${media.mobile} {
      display: none !important;
    }
  }
  
  #root {
    display: flex;
    flex-direction: column;
  }
`;
