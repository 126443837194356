import { IDateRange } from 'interfaces';

import { getLastDay } from './lastDay';

export const compareRangeWithLastDay = (range: IDateRange) => {
  if (range.from) {
    const { from, to } = range;
    const modifiedFrom = resetTime(from);
    const modifiedTo = resetTime(to);
    const modifiedLastDay = getLastDay().setHours(0, 0, 0, 0);
    if (modifiedFrom === modifiedLastDay && modifiedTo === modifiedLastDay) {
      return true;
    }
  }
  return false;
};

export const compareDates = (dateA: Date, dateB: Date) => {
  const a = resetTime(dateA);
  const b = resetTime(dateB);
  if (a > b) {
    return 1;
  }
  if (a === b) {
    return 0;
  }
  return -1;
};

const resetTime = (date: Date) => date.setHours(0, 0, 0, 0);
