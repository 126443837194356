import React from 'react';

export const agreement = (
  <>
    <p>
      1. Общие положения
      <br />
      1.1. Термины и&nbsp;определения
      <br />
      Если в&nbsp;тексте явно не&nbsp;оговорено иное, термины и&nbsp;понятия,
      используемые в&nbsp;настоящем Соглашении об&nbsp;электронном
      документообороте и&nbsp;использовании простой электронной подписи, имеют
      следующие значения:
    </p>
    <p>
      Компания&nbsp;&mdash; Общество с&nbsp;ограниченной ответственностью
      &laquo;Управляющая компания ВЕЛЕС Менеджмент&raquo;;
    </p>
    <p>
      Заявитель&nbsp;&mdash; физическое лицо, намеренное присоединиться
      к&nbsp;Соглашению;
    </p>
    <p>
      Клиент&nbsp;&mdash; физическое лицо, присоединившееся к&nbsp;Соглашению;
    </p>
    <p>
      Электронная подпись&nbsp;&mdash; информация в&nbsp;электронной форме,
      которая присоединена к&nbsp;другой информации в&nbsp;электронной форме
      (подписываемой информации) или иным образом связана с&nbsp;такой
      информацией и&nbsp;которая используется для определения лица,
      подписывающего информацию;
    </p>
    <p>
      Простая электронная подпись&nbsp;&mdash; электронная подпись, которая
      посредством использования кодов, паролей или иных средств, определенных
      Соглашением, подтверждает факт формирования электронной подписи
      определенным лицом.
      <br />
      Простая электронная подпись применяется Сторонами Соглашения
      в&nbsp;случаях и&nbsp;в&nbsp;порядке, установленных Соглашением, при этом:
      <br />
      простая электронная подпись состоит из&nbsp;идентификатора и&nbsp;ключа
      электронной подписи, где
      <br />
      идентификатор простой электронной подписи&nbsp;&mdash; это информация,
      указывающая на&nbsp;лицо, от&nbsp;имени которого был создан и/или
      отправлен электронный документ: фамилия, имя, отчество (если иное
      не&nbsp;вытекает из&nbsp;закона или национального обычая) и&nbsp;серия
      и&nbsp;номер основного документа, удостоверяющего личность или фамилия,
      имя, отчество (если иное не&nbsp;вытекает из&nbsp;закона или национального
      обычая) и&nbsp;идентификационный номер Клиента (ID) во&nbsp;внутренней
      системе учета клиентов;
      <br />
      ключ простой электронной подписи&nbsp;&mdash; код, сформированный
      Компанией и&nbsp;переданный заявителю посредством сервиса коротких
      сообщений (СМС) на&nbsp;номер мобильного телефона заявителя/ Клиента.
      <br />
      Код подлежит вводу в&nbsp;соответствующие поля Сайта Компании или Личного
      кабинета. Совпадение отправленного Компанией кода с&nbsp;кодом,
      полученного Компанией в&nbsp;течение срока действия кода, является
      подтверждением того, что лицо, чей идентификатор указан в&nbsp;электронном
      документе, является автором и&nbsp;отправителем соответствующего
      электронного документа, а&nbsp;электронный документ является подписанным
      соответствующим Клиентом простой электронной подписью;
    </p>
    <p>
      Электронный документ&nbsp;&mdash; документ, в&nbsp;котором информация
      представлена в&nbsp;электронной форме;
    </p>
    <p>
      Электронный документооборот&nbsp;&mdash; обмен между Сторонами, передача
      и/или использование Сторонами электронных документов, подписанных простой
      электронной подписью в&nbsp;порядке, установленным настоящим Соглашением;
    </p>
    <p>
      Личный кабинет&nbsp;&mdash; конфиденциальный персональный раздел страницы{' '}
      ООО &laquo;УК ВЕЛЕС Менеджмент&raquo; в&nbsp;сети Интернет, расположенный
      по&nbsp;адресу: https://lk.veles-management.ru, вход в&nbsp;который
      осуществляется после прохождения Клиентом идентификации
      и&nbsp;аутентификации в&nbsp;порядке, установленном Соглашением,
      обеспечивающий обмен сторонами документами в&nbsp;ходе исполнения
      заключенных между сторонами договоров, а&nbsp;также содержащий материалы
      информационного характера;
    </p>
    <p>
      Номер мобильного телефона&nbsp;&mdash; номер личного телефона Клиента
      (заявителя), предоставленный оператором мобильной связи,
      зарегистрированным и&nbsp;действующим на&nbsp;территории Российской
      Федерации в&nbsp;порядке, предусмотренном законодательством Российской
      Федерации и&nbsp;указанный Клиентом (заявителем) в&nbsp;заявлении
      о&nbsp;присоединении к&nbsp;Соглашению либо в&nbsp;процессе присоединения
      к&nbsp;Соглашению в&nbsp;порядке, указанном в&nbsp;п. 1.5 Соглашения либо
      в&nbsp;анкете физического лица;
    </p>
    <p>
      Сайт Компании&nbsp;&mdash; страница Компании в&nbsp;сети Интернет,
      расположенная по&nbsp;адресу: www.veles-management.ru.
    </p>
    <p>
      1.2. Статус Соглашения
      <br />
      1.2.1. Настоящее Соглашение об&nbsp;электронном документообороте
      и&nbsp;использовании простой электронной подписи (далее&nbsp;&mdash;
      Соглашение) устанавливает условия и&nbsp;порядок использования простой
      электронной подписи в&nbsp;ходе осуществления электронного
      документооборота между Обществом с&nbsp;ограниченной ответственностью
      &laquo;Управляющая компания ВЕЛЕС Менеджмент&raquo; и&nbsp;Клиентом.
      <br />
      1.2.2. Стороной, присоединяющейся к&nbsp;настоящему Соглашению, может быть
      только физическое лицо, одновременно отвечающее следующим требованиям:
      <br />
      &bull; является гражданином Российской Федерации, достигшим 18-ти летнего
      возраста и&nbsp;обладающего полной дееспособностью;
      <br />
      &bull; действует лично, исключительно в&nbsp;собственных интересах;
      <br />
      &bull; имеет номер мобильного телефона, зарегистрированный
      и&nbsp;действующий на&nbsp;территории Российской Федерации.
      <br />
      1.2.3. При проведении идентификации физического лица перед заключением
      Соглашения его личность устанавливается одним из&nbsp;следующих способов:
      <br />
      &bull; при личном приеме;
      <br />
      &bull; путем прохождения авторизации в&nbsp;единой системе идентификации
      и&nbsp;аутентификации;
      <br />
      &bull; с&nbsp;использованием единой системы межведомственного электронного
      взаимодействия.
      <br />
      1.2.4. Текст Соглашения размещается Компанией на&nbsp;Сайте Компании
      и&nbsp;является предложением (офертой) предназначенным физическим лицам
      заключить соглашение, устанавливающее порядок электронного взаимодействия
      и&nbsp;использования простой электронной подписи, как они установлены
      настоящим Соглашением, при взаимодействии с&nbsp;Компанией.
      <br />
      1.2.5. Компания вправе в&nbsp;одностороннем порядке вносить
      в&nbsp;Соглашение любые изменения и/или дополнения. Изменения и/или
      дополнения в&nbsp;Соглашение утверждаются уполномоченным органом Компании.
      Изменения и/или дополнения в&nbsp;Соглашение могут быть оформлены
      в&nbsp;виде новой редакции Соглашения.
      <br />
      1.2.6. Присоединяясь к&nbsp;Соглашению (направляя в&nbsp;Компанию акцепт),
      Заявитель выражает свое согласие на&nbsp;внесение Компанией
      в&nbsp;одностороннем порядке любых изменений и/или дополнений
      в&nbsp;Соглашение. Какого-либо иного согласия Заявителя/Клиента
      на&nbsp;внесение Компанией изменений и/или дополнений в&nbsp;Соглашение
      (предварительного либо последующего) не&nbsp;требуется.
      <br />
      1.2.7. Компания уведомляет Клиента о&nbsp;внесении изменений и/или
      дополнений в&nbsp;Соглашение путем размещения текста соответствующих
      изменений, и/или дополнений, или новой редакции Соглашения на&nbsp;Сайте
      Компании, а&nbsp;Клиент обязуется отслеживать на&nbsp;регулярной основе
      изменения в&nbsp;Соглашение и&nbsp;знакомиться с&nbsp;новыми редакциями
      Соглашения.
      <br />
      1.2.8. Если более длительный срок не&nbsp;установлен органом, утверждающим
      изменения и/или дополнения в&nbsp;Соглашение, изменения и/или дополнения
      в&nbsp;Соглашение вступают в&nbsp;силу на&nbsp;5 (пятый) рабочий день
      с&nbsp;даты размещения их&nbsp;текста на&nbsp;Сайте Компании. Датой
      уведомления Клиента является дата размещения информации о&nbsp;внесении
      изменении и/или дополнений в&nbsp;Соглашение на&nbsp;Сайте Компании, кроме
      случаев, специально предусмотренных в&nbsp;Соглашении. В&nbsp;случае
      возникновения спора между Компанией и&nbsp;Клиентом относительно даты
      размещения текста изменений и/или дополнений в&nbsp;Соглашение
      на&nbsp;Сайте Компании, считается, что изменения и/или дополнения
      в&nbsp;Соглашение были размещены на&nbsp;Сайте Компании в&nbsp;срок
      (дату), установленный для их&nbsp;размещений на&nbsp;Сайте Компании
      документом об&nbsp;их&nbsp;утверждении.
      <br />
      1.2.9. Изменения и/или дополнения, вносимые Компанией в&nbsp;Соглашение
      в&nbsp;связи с&nbsp;изменениями императивных требований нормативных
      правовых актов Российской Федерации, вступают в&nbsp;силу не&nbsp;позднее
      вступления в&nbsp;силу соответствующих нормативных правовых актов.
      <br />
      1.2.10. С&nbsp;момента вступления в&nbsp;силу изменений и/или дополнений
      в&nbsp;Соглашение в&nbsp;соответствии с&nbsp;предыдущим пунктом Соглашения
      порядок взаимоотношений (а&nbsp;равно права и&nbsp;обязанности) Компании
      и&nbsp;Клиента считается соответствующим образом измененным и/или
      дополненным, если иное не&nbsp;установлено документом об&nbsp;утверждении
      соответствующих изменений и/или дополнений в&nbsp;Соглашение.
      <br />
      1.2.11. Соглашение состоит из&nbsp;основного текста Соглашения
      и&nbsp;приложений к&nbsp;нему, являющихся его неотъемлемыми частями.
      <br />
      1.3. Порядок присоединения к&nbsp;Соглашению (порядок направления акцепта)
      <br />
      1.3.1. Физическое лицо присоединяется к&nbsp;условиям настоящего
      Соглашения (акцептует его) путем полного и&nbsp;безусловного принятия этим
      лицом условий настоящего Соглашения. Соглашение заключается
      на&nbsp;неопределенный срок.
      <br />
      1.3.2. Акцепт настоящего Соглашения означает, что Клиент согласен
      со&nbsp;всеми положениями и&nbsp;условиями настоящего Соглашения,
      принимает на&nbsp;себя все обязательства, указанные в&nbsp;настоящем
      Соглашении, Клиент подтверждает, что внимательно изучил текст
      и&nbsp;условия настоящего Соглашения, осознает и&nbsp;принимает
      на&nbsp;себя риски, связанные с&nbsp;использованием простой электронной
      подписи, и&nbsp;не&nbsp;может ссылаться на&nbsp;то, что
      он&nbsp;не&nbsp;был ознакомлен с&nbsp;текстом настоящего Соглашения.
      <br />
      1.3.3. Физическое лицо присоединяется к&nbsp;условиям настоящего
      Соглашения:
      <br />
      путем составления, подписания и&nbsp;передачи в&nbsp;Компанию
      в&nbsp;бумажном виде заявления о&nbsp;присоединении к&nbsp;Соглашению
      по&nbsp;форме Приложения &#8470;&nbsp;2 к&nbsp;Соглашению; такое заявление
      является в&nbsp;соответствии со&nbsp;ст. 438 ГК&nbsp;РФ&nbsp;полным
      и&nbsp;безоговорочным принятием заявителем условий настоящего Соглашения
      <br />
      или
      <br />
      путем совершения заявителем на&nbsp;Сайте Компании (в&nbsp;том числе
      в&nbsp;Личном кабинете) действий, указанных в&nbsp;п.1.5 Соглашения;
      совершение заявителем указанных действий является в&nbsp;соответствии
      со&nbsp;ст. 438 ГК&nbsp;РФ&nbsp;полным и&nbsp;безоговорочным принятием
      заявителем условий настоящего Соглашения
      <br />
      1.3.4. Датой заключения между заявителем и&nbsp;Компанией Соглашения
      является
      <br />
      дата получения Компанией заявления о&nbsp;присоединении к&nbsp;Соглашению
      (в&nbsp;случае направления заявителем заявления о&nbsp;присоединении
      к&nbsp;Соглашению в&nbsp;бумажном виде) либо
      <br />
      дата, зафиксированная программно-аппаратным комплексом Компании, как дата
      подтверждения соответствия ранее направленного Компанией кода введенному
      заявителем в&nbsp;процессе присоединения к&nbsp;Соглашению как это описано
      в&nbsp;п.1.5.2 Соглашения (в&nbsp;случае присоединения к&nbsp;Соглашению
      путем совершения заявителем на&nbsp;Сайте Компании действий, указанных
      в&nbsp;п. 1.5 Соглашения).
      <br />
      1.4. Порядок предоставления в&nbsp;Компанию заявления о&nbsp;присоединении
      к&nbsp;Соглашению в&nbsp;бумажном виде.
      <br />
      1.4.1. Физическое лицо, намеревающееся присоединиться к&nbsp;условиям
      настоящего Соглашения, заполняет, подписывает и&nbsp;передает
      в&nbsp;Компанию заявление о&nbsp;присоединении к&nbsp;Соглашению
      в&nbsp;соответствии с&nbsp;формой заявления о&nbsp;присоединении
      к&nbsp;Соглашению, являющейся Приложением &#8470;&nbsp;2
      к&nbsp;Соглашению.
      <br />
      Одновременно с&nbsp;заявлением о&nbsp;присоединении к&nbsp;Соглашению
      заявитель передает Компании копию основного документа, удостоверяющего его
      личность.
      <br />
      1.5. Порядок присоединения к&nbsp;Соглашению посредством Сайта Компании
      (в&nbsp;том числе Личного кабинета)
      <br />
      1.5.1. Физическое лицо вправе присоединиться к&nbsp;условиям Соглашения
      посредством совершения ряда последовательных действий, если оно имеет
      подтвержденную учетную запись на&nbsp;портале государственных услуг, либо
      предоставленных им&nbsp;персональных данных достаточно для проведений его
      идентификации через единую систему межведомственного электронного
      взаимодействия.
      <br />
      1.5.2. Порядок совершения действий по&nbsp;дистанционному присоединению
      к&nbsp;Соглашению посредством Сайта Компании (в&nbsp;том числе Личного
      кабинета) включает в&nbsp;себя:
      <br />
      инициирование заявителем на&nbsp;Сайте Компании процесса регистрации
      в&nbsp;Личном кабинете либо процесса дистанционного приобретения
      инвестиционных паев (составления и&nbsp;подачи заявки на&nbsp;приобретение
      инвестиционных паев), заключения договора доверительного управления
      ценными бумагами либо иного договора об&nbsp;оказании услуг на&nbsp;рынке
      ценных бумаг, когда возможность дистанционного заключения указанных
      договоров предусмотрена Сайтом Компании, путем нажатия соответствующей
      кнопки на&nbsp;Сайте Компании;
      <br />
      ввод заявителем информации о&nbsp;себе (фамилия, имя, отчество (если иное
      не&nbsp;вытекает из&nbsp;закона или национального обычая); адрес
      электронной почты, номер телефона) либо получение таких данных
      с&nbsp;портала государственных услуг после прохождения заявителем успешной
      авторизации на&nbsp;портале государственных услуг;
      <br />
      выражение заявителем своего согласия на&nbsp;обработку его персональных
      данных путем проставления в&nbsp;специальное поле Сайта Компании отметки
      о&nbsp;даче согласия;
      <br />
      ввод заявителем информации о&nbsp;своем ИНН , о&nbsp;серии и&nbsp;номере
      паспорта либо получение таких данных с&nbsp;портала государственных услуг
      после прохождения заявителем успешной авторизации на&nbsp;портале
      государственных услуг;
      <br />
      ввод заявителем информации о&nbsp;дате и&nbsp;месте рождения, дате выдачи
      паспорта, органе, выдавшем паспорт, коде подразделения, адресе регистрации
      либо получение таких данных с&nbsp;портала государственных услуг после
      прохождения заявителем успешной авторизации на&nbsp;портале
      государственных услуг;
      <br />
      ознакомление и&nbsp;подтверждение ознакомления с&nbsp;Соглашением
      и&nbsp;непосредственное выражение заявителем своего безусловного согласия
      присоединиться к&nbsp;Соглашению путем нажатия на&nbsp;кнопку
      &laquo;Я&nbsp;принимаю соглашение&raquo; с&nbsp;последующим, вводом
      полученного от&nbsp;Компании посредством сервиса коротких сообщений (СМС)
      кода и&nbsp;его передачей Компании путем нажатия кнопки
      &laquo;Подписать&raquo;.
      <br />
      1.5.3. Используя дистанционный способ присоединения к&nbsp;Соглашению,
      Стороны признают, что:
      <br />
      лицом, присоединяющимся к&nbsp;Соглашению, является физическое лицо, чье
      имя (фамилия, имя, отчество (если иное не&nbsp;вытекает из&nbsp;закона или
      национального обычая)) и&nbsp;паспортные данные (серия и&nbsp;номер) были
      указаны в&nbsp;процессе регистрации в&nbsp;Личном кабинете либо
      в&nbsp;процессе дистанционного приобретения инвестиционных паев,
      заключения договора доверительного управления ценными бумагами либо иного
      договора об&nbsp;оказании услуг на&nbsp;рынке ценных бумаг, либо получены
      с&nbsp;портала государственных услуг после прохождения заявителем успешной
      авторизации на&nbsp;портале государственных услуг, при условии, что
      введенные (полученные с&nbsp;портала государственных услуг) данные
      позволили провести упрощенную идентификацию заявителя;
      <br />
      признают в&nbsp;качестве доказательств соблюдения установленного порядка
      присоединения к&nbsp;Соглашению протоколы, лог-файлы, иные документы,
      сформированные программно-техническими средствами Компании, и/или
      представленные третьими лицами (например, операторами связи).
      <br />
      2. Электронный документооборот
      <br />
      2.1. Общие положения об&nbsp;электронном документе
      <br />
      2.1.1. Стороны Соглашения признают, что использование простой электронной
      подписи в&nbsp;порядке, установленном Соглашением, является достаточным
      для определения авторства и&nbsp;подлинности электронного документа,
      подписанного этой простой электронной подписью.
      <br />
      2.1.2. Стороны Соглашения признают электронные документы, подписанные
      простой электронной подписью, соответствующей требованиям настоящего
      Соглашения, равнозначными документам на&nbsp;бумажном носителе,
      подписанным собственноручной подписью.
      <br />
      2.1.3. Электронный документ, подписанный простой электронной подписью,
      считается подписанным от&nbsp;имени лица, чьи идентификаторы (фамилия,
      имя, отчество (если иное не&nbsp;вытекает из&nbsp;закона или национального
      обычая) и&nbsp;серия и&nbsp;номер основного документа, удостоверяющего
      личность или фамилия, имя, отчество (если иное не&nbsp;вытекает
      из&nbsp;закона или национального обычая) и&nbsp;идентификационный номер
      Клиента (ID) во&nbsp;внутренней системе учета клиентов) содержатся
      в&nbsp;соответствующем электронном документе.
      <br />
      2.1.4. Предусмотренные для электронного документа правовые последствия
      наступают, только если получен положительный результат проверки простой
      электронной подписи этого электронного документа при условии соблюдения
      требований к&nbsp;содержанию электронного документа.
      <br />
      2.1.5. Стороны Соглашения признают в&nbsp;качестве доказательств
      подписания электронных документов простой электронной подписью протоколы,{' '}
      лог-файлы, иные документы, сформированные программно-техническими
      средствами Компании, и/или представленные операторами связи.
      <br />
      2.1.6. Электронный документооборот осуществляется посредством
      использования Сторонами Сайта Компании и&nbsp;Личного кабинета.
      <br />
      2.1.7. Если иное не&nbsp;установлено требованиями законодательства или
      соглашением сторон, простой электронной подписью могут подписываться
      электронные документы, в&nbsp;отношении которых на&nbsp;Сайте Компании или
      в&nbsp;Личном кабинете установлена возможность их&nbsp;подписания простой
      электронной подписью, в&nbsp;том числе документы на&nbsp;основании которых
      могут проводиться операции в&nbsp;реестре владельцев инвестиционных паев.
      <br />
      2.1.8. Одной электронной подписью могут быть подписаны несколько связанных
      между собой электронных документов (пакет электронных документов). При
      подписании электронной подписью пакета электронных документов каждый
      из&nbsp;электронных документов, входящих в&nbsp;этот пакет, считается
      подписанным электронной подписью того вида, которой подписан пакет
      электронных документов.
      <br />
      2.2. Этапы электронного документооборота
      <br />
      Электронный документооборот между Сторонами Соглашения включает следующие
      этапы: создание электронного документа, передачу электронного документа,
      проверку подлинности электронного документа, учет электронных документов,
      хранение электронных документов.
      <br />
      2.2.1. Создание электронного документа включает в&nbsp;себя
      непосредственное формирование электронного документа (в&nbsp;том числе
      путем заполнения полей электронного документа на&nbsp;Сайте Компании или
      Личном кабинете) и&nbsp;его подписание простой электронной подписью
      Клиента.
      <br />
      Формируемый электронный документ должен соответствовать установленным
      законодательством и&nbsp;Компанией требованиям к&nbsp;виду документа,
      подписание которого допускается простой электронной подписью,
      к&nbsp;содержанию соответствующего документа. Сформированный электронный
      документ подлежит подписанию простой электронной подписью Клиента.
      <br />
      Формирование простой электронной подписи в&nbsp;электронном документе
      производится Клиентом путем введения в&nbsp;специальное поле
      в&nbsp;процессе создания электронного документа и&nbsp;передачи Компании
      кода, полученного от&nbsp;Компании посредством сервиса коротких сообщений
      (СМС) на&nbsp;номер мобильного телефона, непосредственно перед отправкой
      электронного документа в&nbsp;Компанию.
      <br />
      Отправка Компанией кода осуществляется на&nbsp;последний
      из&nbsp;предоставленных в&nbsp;Компанию:
      <br />
      &bull; номер мобильного телефона, указанный заявителем в&nbsp;Заявлении
      о&nbsp;присоединении к&nbsp;Соглашению об&nbsp;использовании простой
      электронной подписи (Приложение &#8470;&nbsp;2
      к&nbsp;Соглашению)&nbsp;&mdash; в&nbsp;случае присоединения заявителя
      к&nbsp;Соглашению в&nbsp;порядке, указанном в&nbsp;п. 1.4 Соглашения;
      <br />
      &bull; номер мобильного телефона, указанный заявителем, в&nbsp;процессе
      присоединения к&nbsp;Соглашению в&nbsp;порядке, указанном в&nbsp;п. 1.5
      Соглашения;
      <br />
      &bull; номер мобильного телефона, указанный Клиентом в&nbsp;последней
      поданной в&nbsp;адрес Компании анкете физического лица.
      <br />
      Клиент, формируя электронный документ и&nbsp;подписывая его простой
      электронной подписью, должен убедиться в&nbsp;наличии у&nbsp;него
      мобильного телефона, доступности сотовой связи, доступности на&nbsp;номере
      мобильного телефона сервиса коротких сообщений (СМС).
      <br />
      Электронный документ, подписанный простой электронной подписью, может
      иметь неограниченное количество экземпляров, при этом все экземпляры
      такого электронного документа являются его подлинниками.
      <br />
      Электронный документ не&nbsp;может иметь копий в&nbsp;электронном виде.
      <br />
      Копии электронного документа могут быть изготовлены (распечатаны)
      на&nbsp;бумажном носителе.
      <br />
      Копии электронного документа на&nbsp;бумажном носителе должны содержать
      указание на&nbsp;то, что являются копиями электронного документа.
      <br />
      Информация, содержащаяся в&nbsp;копии на&nbsp;бумажном носителе, должна
      соответствовать содержанию электронного документа.
      <br />
      2.2.2. Передача электронного документа, подписанного простой электронной
      подписью, осуществляется Клиентом с&nbsp;использованием Сайта Компании или
      Личного кабинета.
      <br />
      2.2.3. Проверка подлинности электронного документа включает в&nbsp;себя
      проверку соответствия электронного документа требованиям законодательства
      и&nbsp;Компании к&nbsp;виду электронного документа, подписание которого
      допускается простой электронной подписью, содержанию электронного
      документа и&nbsp;его формату, проверку простой электронной подписи.
      <br />
      Проверка простой электронной подписи производится программными средствами
      Компании в&nbsp;процессе передачи Клиентом электронного документа,
      подписанного простой электронной подписью. Такая проверка осуществляется
      путем сравнения и&nbsp;установления совпадения между отправленным
      Компанией посредством сервиса коротких сообщений (СМС) на&nbsp;номер
      мобильного телефона Клиента кодом и&nbsp;кодом, полученным Компанией
      в&nbsp;процессе создания и&nbsp;передачи электронного документа. Проверка
      считается пройденной, а&nbsp;простая электронная подпись корректной
      в&nbsp;случае совпадение отправленного Компанией кода с&nbsp;кодом,
      полученного Компанией в&nbsp;течение срока действий кода.
      <br />
      Электронный документ, подписанный простой электронной подписью, прошедшей
      проверку, получает реквизит в&nbsp;виде штампа в&nbsp;тексте электронного
      документа, о&nbsp;том, что соответствующий документ подписан простой
      электронной подписью.
      <br />
      2.2.4. Учет электронных документов осуществляется путем ведения
      электронных журналов учета (логов):
      <br />
      &bull; запросов, поступивших в&nbsp;компанию на&nbsp;отправку кода (ключа
      простой электронной подписи) посредством сервиса коротких сообщений (СМС);
      <br />
      &bull; отправленных Компанией на&nbsp;номера мобильных телефонов Клиентов
      кодов (ключей простой электронной подписи);
      <br />
      &bull; результатов проверки соответствия полученного Компанией кода
      отправленному;
      <br />
      &bull; поступивших в&nbsp;Компанию электронных документов, подписанных
      простой электронной подписью.
      <br />
      Ведение электронных журналов учета (логов) осуществляется{' '}
      программно-аппаратными и&nbsp;техническими средствами Компании.
      <br />
      Срок хранения электронных журналов учета&nbsp;&mdash; не&nbsp;менее 5 лет
      с&nbsp;даты прекращения действия Соглашения.
      <br />
      2.2.5. Хранение электронных документов
      <br />
      Все электронные документы, подписанные простой электронной подписью
      Клиентов, поступившие в&nbsp;Компанию, хранятся в&nbsp;течение сроков,
      установленных для документов соответствующего вида, но&nbsp;не&nbsp;менее
      5 лет.
      <br />
      Электронные документы хранятся в&nbsp;электронных архивах Компании.
      <br />
      Если требованиями законодательства не&nbsp;установлено иное, электронные
      документы должны храниться в&nbsp;том&nbsp;же формате, в&nbsp;котором они
      были сформированы, отправлены или получены.
      <br />
      Хранение электронных документов должно сопровождаться хранением
      соответствующих электронных журналов учета и&nbsp;программного
      обеспечения, обеспечивающего возможность работы с&nbsp;электронными
      журналами и&nbsp;проверку простой электронной подписи.
      <br />
      В&nbsp;случае возникновения споров относительно содержания электронных
      документов приоритет имеют электронные документы, хранящиеся
      у&nbsp;Компании.
      <br />
      2.3. Личный кабинет
      <br />
      2.3.1. В&nbsp;целях использования Клиентом простой электронной подписи для
      подписания документов в&nbsp;ходе оказания Компанией Клиенту услуг
      на&nbsp;основании договоров, условия которых предусматривают использование
      простой электронной подписи, Компания предоставляет Клиенту доступ
      к&nbsp;Личному кабинету.
      <br />
      2.3.2. Вход в&nbsp;Личный кабинет осуществляется по&nbsp;связке данных (1)
      логин, (2) пароль доступа (передается Клиентам с&nbsp;помощью сервиса
      коротких сообщений (СМС) на&nbsp;номер мобильного телефона, указанный
      Клиентом в&nbsp;Заявлении о&nbsp;присоединении к&nbsp;Соглашению либо
      в&nbsp;процессе присоединения к&nbsp;Соглашению в&nbsp;порядке, указанном
      в&nbsp;п. 1.5 Соглашения), (3) одноразовый код-подтверждение, передаваемый
      Клиенту посредством сервиса коротких сообщений (СМС), в&nbsp;процессе
      входа в&nbsp;Личный кабинет.
      <br />
      2.3.3. Личный кабинет&nbsp;&mdash; сервис, работа которого возможна только
      при наличии доступа к&nbsp;сети Интернет, наличия и&nbsp;корректной
      настройки программно-аппаратных средств, поэтому Клиент должен осознавать
      и&nbsp;принимает на&nbsp;себя риск невозможности использования Личного
      кабинета по&nbsp;причинам отсутствия у&nbsp;него доступа к&nbsp;сети
      Интернет, отсутствия и/или некорректной настройки программно-аппаратных
      средств.
      <br />
      2.3.4. Компания не&nbsp;несет ответственности за&nbsp;возможные убытки,
      которые могут возникнуть у&nbsp;Клиента в&nbsp;результате временной
      невозможности предоставить Компании или получить от&nbsp;Компании
      те&nbsp;или иные документы через Личный кабинет в&nbsp;результате
      неработоспособности каналов связи или оборудования как со&nbsp;стороны
      Клиента, так и&nbsp;со&nbsp;стороны Компании.
      <br />
      2.3.5. В&nbsp;целях предотвращения несанкционированного доступа
      к&nbsp;Личному кабинету, а&nbsp;также в&nbsp;целях обеспечения
      конфиденциальности информации, размещаемой в&nbsp;Личном кабинете, Клиенту
      необходимо:
      <br />
      &bull; соблюдать положения, изложенные в&nbsp;Рекомендациях
      по&nbsp;обеспечению безопасности при работе с&nbsp;электронными
      документами, являющемся приложением к&nbsp;Соглашению;
      <br />
      &bull; осуществлять смену пароля доступа в&nbsp;случае появления сомнений
      в&nbsp;том, что пароль доступа недоступен посторонним лицам;
      <br />
      &bull; подавать в&nbsp;Компанию заявление о&nbsp;блокировании доступа
      в&nbsp;Личный кабинет в&nbsp;случаях утраты доступа к&nbsp;номеру
      мобильного телефона, используемого для входа в&nbsp;Личный кабинет.
      <br />
      2.3.6. Компания не&nbsp;несет ответственности за&nbsp;неблагоприятные
      последствия, возникшие у&nbsp;Клиента в&nbsp;результате нарушения Клиентом
      требований п. 2.3.5 Соглашения.
      <br />
      2.4. Уведомление о&nbsp;рисках
      <br />
      2.4.1. Заключая настоящее Соглашение, Заявитель подтверждает, что
      уведомлен о&nbsp;рисках, возникающих при использовании электронных
      документов, подписанных простой электронной подписью, возникающие риски
      ему понятны и&nbsp;он&nbsp;принимает их.
      <br />
      2.4.2. К&nbsp;числу основных рисков, которые возникают при использовании
      электронного документооборота и&nbsp;простой электронной подписи
      относятся:
      <br />
      &bull; риск невозможности использования в&nbsp;определенный момент времени
      электронного документооборота, который может возникнуть в&nbsp;результате
      сбоя или отказа программно-аппаратных средств и&nbsp;оборудования,
      планового профилактического отключения оборудования Компанией, отказ или
      отключение систем связи, электроснабжения, вмешательство третьих лиц,
      в&nbsp;результате чего Клиент не&nbsp;сможет вовремя отправить,
      а&nbsp;Компания не&nbsp;сможет вовремя получить инструкции или иную
      информацию, содержащуюся в&nbsp;электронном документе.
      <br />
      Для снижения возможных негативных последствий, которые могут возникнуть
      в&nbsp;результате реализации описанного риска, Клиенту следует иметь
      возможность использования иных способов взаимодействия с&nbsp;Компанией,
      а&nbsp;также на&nbsp;регулярной основе просматривать Сайт Компании
      на&nbsp;предмет наличия сообщений о&nbsp;приостановлении доступа
      к&nbsp;электронному документообороту, в&nbsp;общем, и&nbsp;Личному
      кабинету, в&nbsp;частности;
      <br />
      &bull; риск несанкционированного доступа третьих лиц:
      <br />
      &bull; к&nbsp;номеру мобильного телефона, используемого для получения
      коротких текстовых сообщений (СМС) с&nbsp;ключом простой электронной
      подписи;
      <br />
      &bull; к&nbsp;параметрам доступа Клиента в&nbsp;Личный кабинет Клиента
      либо к&nbsp;иному программному обеспечению, используемому для отправки
      в&nbsp;Компанию электронных документов, подписанных простой электронной
      подписью;
      <br />
      &bull; к&nbsp;информации, передаваемой Компанией Клиенту (в&nbsp;том числе
      посредством сервиса коротких сообщений (СМС), электронной почты)
      и&nbsp;имеющей отношение к&nbsp;возможности доступа к&nbsp;электронному
      документообороту, Личному кабинету Клиента, иному программному
      обеспечению, используемому для отправки в&nbsp;Компанию электронных
      документов, подписанных простой электронной подписью;
      <br />
      &bull; к&nbsp;иной информации, которая позволит третьему лицу осуществить
      замену параметров доступа Клиента к&nbsp;его Личному кабинету, иному
      программному обеспечению, используемому для отправки в&nbsp;Компанию
      электронных документов, подписанных простой электронной подписью;
      <br />
      &bull; к&nbsp;информации, необходимой для доступа Клиента к&nbsp;порталу
      государственных услуг и&nbsp;единой системе идентификации
      и&nbsp;аутентификации;
      <br />
      в&nbsp;результате чего третьи лица могут направить в&nbsp;Компанию
      электронный документ, подписанный простой электронной подписью, который
      будет идентифицирован Компанией в&nbsp;качестве документа, исходящего
      от&nbsp;Клиента.
      <br />
      Для снижения возможных негативных последствий, которые могут возникнуть
      в&nbsp;результате реализации описанного риска Клиенту следует обеспечить
      конфиденциальность параметров доступа Клиента в&nbsp;Личный кабинет,
      к&nbsp;иному программному обеспечению, используемому для отправки
      в&nbsp;Компанию электронных документов, подписанных простой электронной
      подписью, на&nbsp;портал государственных услуг, в&nbsp;единую систему
      идентификации и&nbsp;аутентификации, к&nbsp;мобильному телефону Клиента
      и/или к&nbsp;номеру мобильного телефона, а&nbsp;также обеспечить
      применение положений Рекомендации по&nbsp;обеспечению безопасности при
      работе с&nbsp;электронными документами, являющемся Приложением
      &#8470;&nbsp;1 к&nbsp;Соглашению.
      <br />
      3. Права и&nbsp;обязанности сторон
      <br />
      3.1. Права и&nbsp;обязанности Компании
      <br />
      3.1.1. Компания вправе:
      <br />
      &bull; В&nbsp;одностороннем порядке изменять порядок использования Личного
      кабинета, в&nbsp;том числе изменять схему его работы и/или его
      функциональные возможности;
      <br />
      &bull; Приостановить возможность использования электронных документов,
      подписанных простой электронной подписью, Сайта Компании, Личного кабинета
      для проведения профилактических работ, а&nbsp;также при возникновении
      обстоятельств (в&nbsp;том числе отказ или сбой оборудования,{' '}
      программно-аппаратных средств, вмешательство третьих лиц, которые влекут
      или могут повлечь невозможность или затруднения в&nbsp;использовании
      электронных документов и/или работе Личного кабинета);
      <br />
      &bull; Приостановить или ограничить доступ к&nbsp;возможности
      использования электронных документов, подписанных простой электронной
      подписью, при наличии у&nbsp;Компании оснований полагать, что
      использование электронной подписи осуществляется от&nbsp;имени Клиента
      неуполномоченным лицом.
      <br />
      3.1.2. Компания обязана:
      <br />
      &bull; Обеспечивать конфиденциальность информации, направляемой
      посредством сервиса коротких сообщений (СМС);
      <br />
      &bull; Уведомлять Клиента о&nbsp;приостановлении возможности использования
      электронных документов, подписанных простой электронной подписью, Личного
      кабинета или иного согласованного Сторонами программного обеспечения путем
      размещения соответствующей информации на&nbsp;Сайте Компании;
      <br />
      &bull; Приостановить доступ к&nbsp;Личному кабинету через использование
      сервиса коротких сообщений (СМС) и&nbsp;к&nbsp;возможности использования
      электронных документов, подписанных простой электронной подписью при
      поступлении от&nbsp;Клиента информации о&nbsp;нарушении конфиденциальности
      параметров доступа к&nbsp;Личному кабинету, об&nbsp;утрате доступа
      к&nbsp;номеру мобильного телефона, о&nbsp;наличии у&nbsp;Клиента
      подозрении на&nbsp;несанкционированное использование (доступ) третьих лиц
      к&nbsp;его мобильному телефону, номеру мобильного телефона, Личному
      кабинету. Такое приостановление осуществляется Компанией после получения
      информации, подтверждающей личность Клиента;
      <br />
      &bull; Предоставлять по&nbsp;письменному запросу Клиента копии электронных
      документов.
      <br />
      3.2. Права и&nbsp;обязанности Клиента
      <br />
      3.2.1. Клиент вправе:
      <br />
      &bull; Запрашивать у&nbsp;Компании копии электронных документов;
      <br />
      &bull; Сменить номер мобильного телефона, направив в&nbsp;Компанию анкету
      физического лица. Такая анкета передается и&nbsp;подписывается
      непосредственно Клиентом или его уполномоченным представителем,
      действующим на&nbsp;основании нотариальной доверенности, в&nbsp;офисе
      Компании.
      <br />
      3.2.2. Клиент обязан:
      <br />
      &bull; Обеспечивать конфиденциальность ключа простой электронной подписи,
      в&nbsp;том числе не&nbsp;допускать его использование другими лицами;
      <br />
      &bull; Немедленно уведомлять Компанию по&nbsp;телефону об&nbsp;утрате
      доступа к&nbsp;номеру мобильного телефона;
      <br />
      &bull; Не&nbsp;использовать ключ простой электронной подписи при наличии
      оснований полагать, что конфиденциальность данного ключа нарушена;
      <br />
      &bull; Немедленно уведомлять Компанию по&nbsp;телефону, о&nbsp;подозрении
      на&nbsp;несанкционированное использование (доступ) третьих лиц к&nbsp;его
      мобильному телефону, номеру мобильного телефона, Личному кабинету;
      <br />
      &bull; Обеспечивать конфиденциальность параметров доступа в&nbsp;Личный
      кабинет, иному программному обеспечению, предоставляющему возможность
      обмена электронными документами, подписанными простой электронной
      подписью;
      <br />
      &bull; Принимать все необходимые меры для снижения возможных негативных
      последствий, которые могут возникнуть в&nbsp;результате реализации рисков
      использования электронного документооборота.
      <br />
      4. Дополнительные положения
      <br />
      4.1. Ответственность сторон
      <br />
      4.1.1. За&nbsp;неисполнение или ненадлежащее исполнение обязательств
      по&nbsp;настоящему Соглашению Стороны несут ответственность
      в&nbsp;пределах суммы доказанного реального ущерба, причиненного одной
      Стороне невыполнением или ненадлежащим выполнением обязательств другой
      Стороной. Ни&nbsp;одна из&nbsp;Сторон не&nbsp;отвечает
      за&nbsp;неполученные доходы (упущенную выгоду), которые&nbsp;бы получила
      другая Сторона.
      <br />
      4.1.2. Компания не&nbsp;несет ответственность за&nbsp;неисполнение либо
      ненадлежащее исполнение своих обязательств по&nbsp;настоящему Соглашению,
      а&nbsp;также возникшие в&nbsp;связи с&nbsp;этим убытки в&nbsp;случае, если
      Компания обоснованно полагалась на&nbsp;сведения, сообщенные другой
      Стороной.
      <br />
      4.1.3. Компания не&nbsp;несет ответственность за&nbsp;убытки Клиента,
      возникшие в&nbsp;результате реализации рисков использования электронного
      документооборота, а&nbsp;в&nbsp;результате несоблюдения Клиентом
      обязанностей, установленных Соглашением.
      <br />
      4.1.4. Компания не&nbsp;несет ответственности за&nbsp;убытки, возникшие
      по&nbsp;вине оператора связи или в&nbsp;связи с&nbsp;выходом из&nbsp;строя
      оборудования, а&nbsp;также в&nbsp;связи со&nbsp;сбоями в&nbsp;системах,
      линиях связи иных коммуникаций, через которые проходит передача
      информации.
      <br />
      4.1.5. Компания не&nbsp;несет ответственности за&nbsp;недоставку коротких
      текстовых сообщений (СМС) на&nbsp;номер мобильного телефона, если это
      обусловлено причинами, не&nbsp;зависящими от&nbsp;Компании.
      <br />
      4.1.6. Компания не&nbsp;несет ответственности в&nbsp;случае указания
      Клиентом номера мобильного телефона, адреса электронной почты ( e-mail),
      владельцем (абонентом) которых Клиент не&nbsp;является, а&nbsp;также
      в&nbsp;случае доступа третьих лиц к&nbsp;электронной почте и/или номеру
      мобильного телефона, указанному Клиентом.
      <br />
      4.1.7. Компания не&nbsp;несет ответственности за&nbsp;возможные убытки,
      причиненные Клиенту вследствие доступа третьих лиц к&nbsp;мобильному
      телефону Клиента и&nbsp;/или номеру мобильного телефона.
      <br />
      4.1.8. Клиент самостоятельно несет ответственность за&nbsp;поддержку
      функций коротких текстовых сообщений (СМС) на&nbsp;номере мобильного
      телефона, а&nbsp;также подписку на&nbsp;услугу коротких текстовых
      сообщений (СМС) у&nbsp;оператора мобильной связи.
      <br />
      4.1.9. Ответственность Сторон, неурегулированная положениями настоящего
      Регламента, регулируется законодательством Российской Федерации.
      <br />
      4.2. Расторжение Соглашения
      <br />
      4.2.1. Каждая из&nbsp;Сторон имеет право в&nbsp;одностороннем порядке
      отказаться от&nbsp;Соглашения путем направления другой Стороне уведомления
      об&nbsp;отказе от&nbsp;Соглашения не&nbsp;менее чем за&nbsp;30 дней
      до&nbsp;предполагаемой даты расторжения.
      <br />
      4.3. Прочие условия
      <br />
      4.3.1. Клиент не&nbsp;вправе осуществлять уступку (передачу) прав
      (требований) по&nbsp;Соглашению.
      <br />
      4.3.2. Претензионный порядок разрешения разногласий по&nbsp;Соглашению,
      является обязательным и&nbsp;считается соблюденным, если в&nbsp;течение 30
      дней с&nbsp;даты получения Стороной соответствующей претензии&nbsp;другой
      Стороны, последняя не&nbsp;получит ответ на&nbsp;свою претензию.
      <br />
      4.3.3. Все споры, возникающие из&nbsp;или в&nbsp;связи с&nbsp;настоящим
      Соглашением, подлежат рассмотрению в&nbsp;суде по&nbsp;месту нахождения
      Компании, если это допускается законодательством Российской Федерации.
      <br />
      4.3.4. К&nbsp;отношениям Сторон по&nbsp;Соглашению подлежит применению
      право Российской Федерации.
    </p>
    <p>
      Приложение &#8470;&nbsp;1
      <br />
      к&nbsp;Соглашению об&nbsp;электронном документообороте
      <br />
      и&nbsp;использовании простой электронной подписи
    </p>
    <p>
      Рекомендации
      <br />
      по&nbsp;обеспечению безопасности при работе с&nbsp;электронными
      документами
    </p>
    <p>
      1. Рекомендации к&nbsp;доверенной среде
      <br />
      1.1. На&nbsp;компьютере или ином оборудовании, с&nbsp;которого
      производится работа, должно быть установлено только лицензионное
      программное обеспечение. Установленное программное обеспечение должно
      регулярно обновляться.
      <br />
      1.2. Должна быть установлена лицензионная система антивирусной защиты
      с&nbsp;регулярными обновлениями. Все съемные носители, а&nbsp;также все
      загружаемые файлы и&nbsp;программы должны проверяться на&nbsp;наличие
      вирусов.
      <br />
      1.3. Запрещается использовать функцию &laquo;автоматического
      выполнения&raquo; для съемных носителей и&nbsp;компакт-дисков .<br />
      1.4. Запрещается производить работы в&nbsp;системе с&nbsp;общедоступных
      компьютеров, например, из&nbsp;интернет-кафе.
    </p>
    <p>
      2. Рекомендации к&nbsp;идентификации и&nbsp;аутентификации
      <br />
      2.1. Для работы с&nbsp;ключами простой электронной подписи и&nbsp;Личным
      кабинетом должна быть предусмотрена отдельная учетная запись
      с&nbsp;правами пользователя.
      <br />
      2.2. Идентификация и&nbsp;аутентификация пользователя в&nbsp;системе
      производятся с&nbsp;помощью логина-пароля. Пароли всех пользователей
      системы должны быть уникальными, не&nbsp;допускается установка одинаковых
      паролей на&nbsp;учетную запись и&nbsp;на&nbsp;вход в&nbsp;систему.
      <br />
      2.3. Для формирования паролей необходимо использовать различные типы
      символов: прописные, строчные буквы, цифры, специальные знаки
      и&nbsp;символы.
      <br />
      2.4. Пароли должны быть длиной не&nbsp;менее 8 символов и&nbsp;состоять
      из&nbsp;различных типов символов.
      <br />
      2.5. В&nbsp;настройках конфиденциальности мобильного телефона,
      используемого для получения СМС с&nbsp;ключом простой электронной подписи,
      рекомендуется установить пароль доступа к&nbsp;телефону.
      <br />
      2.6. Рекомендуется отключить отображение содержимого поступающих сообщений
      на&nbsp;заблокированном экране мобильного телефона, используемого для
      получения СМС с&nbsp;ключом простой электронной подписи.
      <br />
      2.7. В&nbsp;качестве пароля не&nbsp;рекомендуется устанавливать:
      <br />
      &bull; фамилию (имя или отчество) пользователя, а&nbsp;также его логин;
      <br />
      &bull; комбинацию символов, которая радикальным образом не&nbsp;отличается
      от&nbsp;предыдущих паролей, в&nbsp;том числе создается путем приращения;
      <br />
      &bull; отдельные слова русского или английского языка, жаргонные термины
      или иные удобные для подбора элементы;
      <br />
      &bull; отдельные слова русского языка, набираемые в&nbsp;латинском
      регистре или отдельные слова английского языка, набираемые в&nbsp;русском
      регистре;
      <br />
      &bull; общепринятые сокращения или их&nbsp;сочетания;
      <br />
      &bull; персональные сведения&nbsp;&mdash; номера телефонов, дни рождений,
      серия, номер документа, удостоверяющего личность и т. п.;
      <br />
      &bull; только цифровые символы.
      <br />
      2.8. Плановая смена паролей должна проводиться не&nbsp;реже, чем раз
      в&nbsp;90 дней.
      <br />
      2.9. Не&nbsp;допускается:
      <br />
      &bull; сообщать кому-либо (разглашать) свой пароль;
      <br />
      &bull; оставлять свой пароль на&nbsp;бумажном носителе в&nbsp;любом месте,
      доступном третьим лицам;
      <br />
      &bull; выводить пароль в&nbsp;открытом виде на&nbsp;принтер, дисплей
      и&nbsp;другие внешние устройства отображения информации, доступные третьим
      лицам;
      <br />
      &bull; предоставлять доступ в&nbsp;систему другим лицам по&nbsp;своему
      идентификатору и&nbsp;паролю;
      <br />
      &bull; использовать программную опцию сохранения пароля в&nbsp;памяти
      системы.
    </p>
    <p>
      Приложение &#8470;&nbsp;2
      <br />
      к&nbsp;Соглашению об&nbsp;электронном документообороте
      <br />
      и&nbsp;использовании простой электронной подписи
    </p>
    <p>
      Заявление о&nbsp;присоединении к&nbsp;Cоглашению об&nbsp;электронном
      документообороте и&nbsp;использовании простой электронной подписи
    </p>
    <p>в&nbsp;ООО &laquo;УК ВЕЛЕС Менеджмент&raquo;</p>
    <p>ФИО заявителя</p>
    <p>
      Паспортные данные (серия, номер, наименование выдавшего паспорт органа,
      дата выдачи)
    </p>
    <p>Номер мобильного телефона</p>
    <p>Дата Заявления</p>
    <p>
      Настоящее Заявление о&nbsp;присоединении к&nbsp;Соглашению
      об&nbsp;электронном документообороте и&nbsp;использовании простой
      электронной подписи (далее&nbsp;&mdash; Соглашение) является акцептом
      лица, персональные данные которого указаны выше, адресованным ООО
      &laquo;УК ВЕЛЕС Менеджмент&raquo; (ОГРН 1047796515470, 123610,
      г.&nbsp;Москва, Краснопресненская набережная, д. 12, подъезд 7, помещение &#8544;аж,
      этаж 14, комнаты 40, 42, 43, 44) (далее&nbsp;&mdash; Компания), о&nbsp;заключении Соглашения
      на&nbsp;условиях, определяемых Соглашением.
      <br />
      Датой заключения между заявителем и&nbsp;Компанией Соглашения является
      дата получения Компанией заявления о&nbsp;присоединении к&nbsp;Соглашению.
      <br />
      Присоединение к&nbsp;Соглашению об&nbsp;электронном документообороте
      и&nbsp;использовании простой электронной подписи
      <br />
      1. Настоящим в&nbsp;соответствии со&nbsp;статьей 428 Гражданского кодекса
      РФ&nbsp;полностью и&nbsp;безусловно принимаю условия Соглашения,
      размещенного на&nbsp;странице Компании в&nbsp;сети Интернет
      по&nbsp;адресу: www.veles-management.ru.
      <br />
      Направлением настоящего Заявления подтверждаю, что с&nbsp;условиями
      Соглашения, в&nbsp;том числе с&nbsp;условиями и&nbsp;порядком
      использования электронной подписи, ознакомлен (-а) и&nbsp;согласен (-а),
      их&nbsp;содержание мне полностью понятно. О&nbsp;праве Компании
      в&nbsp;одностороннем порядке вносить изменения в&nbsp;Соглашение уведомлен
      (-а). Обязуюсь на&nbsp;регулярной основе отслеживать актуальность
      Соглашения.
      <br />
      2. С&nbsp;даты заключения Соглашения принимаю на&nbsp;себя права
      и&nbsp;обязанности Клиента, как они описаны в&nbsp;Соглашении.
      <br />
      3. Подписывая настоящее Заявление, заявляю, что являюсь дееспособным
      физическим лицом.
    </p>
    <p>
      Фамилия, имя, отчество (если иное не&nbsp;вытекает из&nbsp;закона или
      национального обычая) полностью и&nbsp;подпись
      <br />
      4. Подписывая настоящее Заявление, подтверждаю, что
      <br />
      уведомлен (-а) о&nbsp;рисках, возникающих при использовании электронных
      документов, подписанных простой электронной подписью, возникающие риски
      мне понятны и&nbsp;принимаются мною; обязуюсь принимать все необходимые
      меры для снижения возможных негативных последствий, которые могут
      возникнуть в&nbsp;результате реализации рисков;
      <br />
      ознакомлен (-а) с&nbsp;мерами, необходимыми для обеспечения безопасности
      электронных подписей и&nbsp;их&nbsp;проверки, изложенными
      в&nbsp;Рекомендациях по&nbsp;обеспечению безопасности при работе
      с&nbsp;электронными документами (Приложение &#8470;&nbsp;1
      к&nbsp;Соглашению);
      <br />
      признаю, что использование простой электронной подписи в&nbsp;порядке,
      установленном Соглашением, является достаточным для определения авторства
      и&nbsp;подлинности электронного документа, подписанного этой простой
      электронной подписью.
      <br />
      признаю информацию в&nbsp;электронной форме, подписанную простой
      электронной подписью в&nbsp;соответствии с&nbsp;условиями Соглашения,
      электронным документом, равнозначным документу на&nbsp;бумажном носителе,
      подписанному собственноручной подписью.
      <br />
      5. При использовании простой электронной подписи обязуюсь:
      <br />
      &bull; обеспечивать конфиденциальность ключа простой электронной подписи,
      в&nbsp;частности не&nbsp;допускать его использование другими;
      <br />
      &bull; уведомлять Компанию о&nbsp;нарушении конфиденциальности ключа
      электронной подписи в&nbsp;течение не&nbsp;более чем одного рабочего дня
      со&nbsp;дня получения информации о&nbsp;таком нарушении;
      <br />
      &bull; не&nbsp;использовать ключ простой электронной подписи при наличии
      оснований полагать, что конфиденциальность данного ключа нарушена.
      <br />
      6. Присоединяясь к&nbsp;Соглашению, соглашаюсь с&nbsp;тем, что простой
      электронной подписью могут быть подписаны любые электронные документы,
      в&nbsp;отношении которых функциональными возможностями Сайта Компании либо
      Личного кабинета, предусмотрена возможность использования простой
      электронной подписи, в&nbsp;том числе документы на&nbsp;основании которых
      могут проводиться операции в&nbsp;реестре владельцев инвестиционных паев.
      <br />
      7. Присоединяясь к&nbsp;Соглашению, подтверждаю, что доступ к&nbsp;Личному
      кабинету мною получен.
    </p>
  </>
);
