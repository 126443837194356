import styled from 'styled-components';

import { media } from 'assets/styles/theme';

interface IColorProps {
  color: string;
}

const Color = styled.div<IColorProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const ProductButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #edebe8;
  border-radius: 5px;
  height: 36px;
  font-weight: normal;
  padding: 0 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition-property: background;
  transition-duration: 0.35s;

  ${Color} {
    margin-right: 10px;
  }

  &:hover,
  &.active {
    background: ${({ theme }) => theme.colors.background};
  }
  
  ${media.tablet} {
    margin-top: 0;
    margin-right: 0;
    justify-content: flex-start;
    
    :last-child {
      margin-bottom: 25px;
    }
}
`;
const Title = styled.span`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 13px;
  letter-spacing: 0.05px;
`;
export default {
  ProductButton,
  Color,
  Title
};
