import { combineReducers } from 'redux';

import {
  ISmsCheck,
  IAction,
  ISmsCheckError
} from 'interfaces';
import { actionTypes } from 'store/actionTypes';

import { requestReducer } from '../request';
// eslint-disable-next-line import/no-cycle
import { requestInitialState } from './index';

const {
  SEND,
  CHECK,
  RESET_SUCCESS,
  RESET_FAIL
} = actionTypes.PURCHASE.SMS;

const send = requestReducer({ ...SEND });

const check = (
  state: ISmsCheck = { request: requestInitialState, error: { message: '' } },
  { type, payload }: IAction
): ISmsCheck => {
  const { SUCCESS, ERROR, REQUEST } = CHECK;
  switch (type) {
    case SUCCESS:
      return {
        ...state,
        request: {
          ...requestInitialState,
          success: true
        }
      };
    case ERROR:
      return {
        error: payload ? (payload as ISmsCheckError) : { message: '' },
        request: {
          ...requestInitialState,
          fail: true
        }
      };
    case REQUEST:
      return {
        ...state,
        request: {
          ...requestInitialState,
          loading: true
        }
      };
    case RESET_SUCCESS:
      return {
        ...state,
        request: {
          ...requestInitialState,
          success: false
        }
      };

    case RESET_FAIL:
      return {
        error: payload ? (payload as ISmsCheckError) : { message: '' },
        request: {
          ...requestInitialState,
          fail: false
        }
      };
    case actionTypes.PURCHASE.OPIF.SUCCESS:
      return { request: requestInitialState, error: { message: '' } };
    default:
      return state;
  }
};

export const smsReducer = combineReducers({ send, check });
