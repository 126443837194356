import { FieldTypes } from 'enums';

import { getFormatNumberValue } from '../field';
import { formatDate } from '../date';
import { formatAreaChartLabel } from '.';

export function tooltipFormatter(
  a: any,
  name: string,
  {
    payload: { data, date },
    name: currentName,
  }: any
) {
  if (name === 'value') {
    return '';
  }
  const [, productName] = currentName.split('.');
  const change = data[productName].price;

  return [
    `${formatDate(date, 'DD.MM.YYYY')}:  
    ${getFormatNumberValue(Number(change), FieldTypes.diff, true)} ₽`,
    `${formatAreaChartLabel(productName)}`
  ];
}
