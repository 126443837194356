import React from 'react';

import { PHONE_LINK } from 'constants/phone';

const DEFAULT_ERROR = (
  <>
    При обработке платежа возникла ошибка. Повторите попытку
    или обратитесь в&nbsp;&laquo;УК&nbsp;ВЕЛЕС Менеджмент&raquo;
    по&nbsp;телефону {PHONE_LINK} или адресу электронной почты{' '}
    <a href="mailto:online@veles-management.ru">online@veles-management.ru</a>
  </>
);

export const ERRORS_BY_CODE = {
  2: (
    <>
      Вы&nbsp;ввели неверные реквизиты банковской карты.
      Проверьте данные и&nbsp;повторите попытку.
    </>
  ),
  5: DEFAULT_ERROR,
  7: DEFAULT_ERROR
};
