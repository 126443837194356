import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sms } from '../edoExistStatusReducer';

/* единый slice для отправки доков на квал
инвестора и получения текущего статуса клиента (квал/не квал) */
export const qualifiedStatusSlice = createSlice({
  name: 'qualifiedStatus',
  initialState: {
    qualifiedStatus: {
      status: false,
      isLoading: true
    },
    submitSuccess: false,
    isOpenWindow: false,
    error: {},
    sms
  },
  reducers: {
    /* запрос текущего статуса инвестора (квал/не квал) */
    requestCurrentStatus: (state) => state,
    successCurrentStatus: (state, action: PayloadAction<boolean>) => {
      state.qualifiedStatus.status = action.payload
      state.qualifiedStatus.isLoading = false
      return state
    },
    errorCurrentStatus: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      return state
    },
    /* отправка данных на изменение статуса */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requestSubmit: (state, action:PayloadAction<any>) => state,
    successSubmit: (state) => {
      state.submitSuccess = true
      state.isOpenWindow = true
      return state
    },
    errorSubmit: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      return state
    },
    /* для смс */
    /* отправка смс */
    recognitionSmsSendRequest: (state) => {
      state.sms.send.loading = true
      return state
    },
    recognitionSmsSendSuccess: (state) => {
      state.sms.send.success = true
      state.sms.send.loading = false
      return state
    },
    recognitionSmsSendError: (state) => {
      state.sms.send.fail = true
      return state
    },
    /* проверка смс */
    recognitionSmsCheckSuccess: (state) => {
      state.sms.check.request.success = true
      state.sms.check.request.loading = false
      return state
    },
    recognitionSmsCheckError: (state, action: PayloadAction<string>) => {
      state.sms.check.request.fail = true
      state.sms.check.request.loading = false
      state.sms.check.error.message = action.payload
      return state
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    recognitionSmsCheckRequest: (state, action: PayloadAction<string>) => {
      state.sms.check.request.loading = true
      return state
    },
    /* доп reducer-s */
    recognitionSmsCheckResetRequest: (state) => {
      state.sms.check.request.success = false
      return state
    },
    recognitionSmsCheckResetFail: (state) => {
      state.sms.check.request.fail = false
      state.sms.check.error.message = ''
      return state
    },
    /* доп reducers для изменения статуса */
    closeWindow: (state) => {
      state.submitSuccess = false
      state.isOpenWindow = false
      return state
    },
    /* сбрасываем ошибку если пользователь вышел со страницы квала */
    resetError: (state) => {
      state.error = {}
      return state
    }
  }
})
export const {
  recognitionSmsCheckRequest,
  recognitionSmsCheckResetFail,
  recognitionSmsCheckResetRequest,
  recognitionSmsCheckError,
  recognitionSmsCheckSuccess,
  recognitionSmsSendRequest,
  recognitionSmsSendSuccess,
  recognitionSmsSendError,
  requestCurrentStatus,
  successCurrentStatus,
  errorCurrentStatus,
  requestSubmit,
  successSubmit,
  errorSubmit,
  closeWindow,
  resetError
} = qualifiedStatusSlice.actions
