import styled, { css } from 'styled-components';

import { media, gridTheme } from 'assets/styles/theme';

import StylesSidebar from '../sidebar/styles';

const Phone = styled.a`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: none;
  cursor: pointer;
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 12px;

    ${media.tablet} {
      display: none;
    }
  }
`;

interface IHeaderProps {
  activeSidebar: boolean;
}
const Header = styled.header<IHeaderProps>`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
    ${({ activeSidebar }) => (activeSidebar &&
    css`
        width: 100%;
        z-index: 21;
        position: fixed;
    `)}
  
  ${media.desktop} {
    height: 100px;
    background-color: ${({ theme }) => theme.colors.white};
  }
  ${media.tablet} {
    height: 50px;
  }


  .logo {
    ${media.tablet} {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-right: auto;
      overflow: hidden;

      svg {
        margin-left: -70px;
      }
    }
  }

  ${Phone} {
    margin-left: 80px;
    margin-right: 67px;

    ${media.tablet} {
      display: none;
    }
  }

  ${Back} {
    margin-left: 50px;

    ${media.tablet} {
      border: 1px solid ${({ theme }) => theme.colors.border};
      border-radius: ${({ theme }) => theme.borderRadius};
      background: ${({ theme }) => theme.colors.white};
      padding: 7px 11px;
      cursor: pointer;
      transition-property: background, color;
      transition-duration: 0.35s;

      :focus,
      :hover {
        background: ${({ theme }) => theme.colors.buttonHover};
      }

      a {
        color: ${({ theme }) => theme.colors.textPrimary};
        font-weight: 500;
        font-size: 12px;
        text-decoration: none;
      }
    }
  }

  ${StylesSidebar.Sidebar} {
    ${media.desktop} {
      display: none;
    }
  }
`;

const Container = styled.div`
  width: ${gridTheme.container.maxWidth.xl}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;

  ${media.tablet} {
    padding: 8px 15px;
    width: 100%;
    & > .email {
      display: none;
    }
    ${StylesSidebar.Sidebar} {
      margin-left: -15px;
      &.active {
        margin-left: 0;
      }
    }
  }
`;

export default {
  Header,
  Back,
  Phone,
  Container
};
