import styled, { css } from 'styled-components';
import { media } from 'assets/styles/theme';

export interface IRadioButtonContainerProps {
  isCurrencySwitch?: boolean;
}

const RadioButtonContainer = styled.div<IRadioButtonContainerProps>`
  display: flex;
  margin-bottom: 5px;
  
  ${media.tablet} {
    ${({ isCurrencySwitch }) => !isCurrencySwitch && css`
    flex-wrap: wrap;
    justify-content: center;
  `}
  }

  input:checked ~ label {
    cursor: default;
  }
`;

export default { RadioButtonContainer };
