import React from 'react';

import { IField } from 'interfaces';

const agree: IField = {
  name: 'agree',
  type: 'checkbox',
  placeholder: (
    <span>
      Я согласен(-а) на{' '}
      <a href="/test" style={{ fontSize: '14px' }}>
        обработку персональных данных
      </a>
    </span>
  ),
  initialValue: false
};

const checkboxes = { agree };

export default checkboxes;
