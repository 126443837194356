import styled from 'styled-components';

const DateRange = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.link};
  font-size: 16px;
  line-height: 20px;
  span {
    border-bottom: 1px solid rgba(0, 150, 183, 0.5);
  }
  svg {
    margin-left: 10px;
  }
`;

export default { DateRange };
