import styled from 'styled-components';

import { media } from 'assets/styles/theme';
import { Common } from 'components/shared';

const { Button } = Common

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: Montserrat;
  margin-bottom: 15px;
  display: block;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  font-weight: normal;
  max-width: 500px;
  
  ${media.mobile} {
    font-size: 19px;
    margin-bottom: 10px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${Button} {
    margin-bottom: 0;
    margin-right: 20px;
    :last-child {
      margin-right: 0;
    }
  }
`;
export default { Title, ButtonsWrapper }
