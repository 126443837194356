import styled, { css } from 'styled-components';

import { Common } from 'components/shared';
import { media, theme } from 'assets/styles/theme';
import { NoSelect } from 'assets/styles/mixins';

import OptionStyles from './options/styles';

const { Panel } = Common

interface ICell {
  active?: boolean;
  isReverse?: boolean;
  isVisible?: boolean;
  isIssuerCard?: boolean;
}

interface IRowProps {
  isIssuerCard?: boolean
}

export const Cell = styled.div<ICell>`
  display: flex;
  visibility: ${({ isVisible }) => (isVisible ? 'hidden' : 'visible')};
  align-items: center;
  color: ${({ active }) => (active ? theme.colors.link : theme.colors.description)};
  transition: color 0.35s;
  svg {
    .arrow_up {
      fill: ${({ active, isReverse }) => (active && isReverse ? theme.colors.link : '')};
    }
    .arrow_down {
      fill: ${({ active, isReverse }) => (active && !isReverse ? theme.colors.link : '')};
    }
  }
  ${({ isIssuerCard }) => isIssuerCard && css`
    ${media.max(1400)} {
     display: block;

     ::before {
      content: attr(aria-label);
      font-weight: bold;

      ${media.max('sm')} {
        font-size: 12px;
      }
    }
  }`}


`;

const Row = styled.div<IRowProps>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  
  ${media.max(1400)} {
    ${({ isIssuerCard }) => isIssuerCard && css`
      display: block;
      text-align: right
    `}
}
`;

const Header = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid ${theme.colors.border};

  ${Row} {
    cursor: default;
  }

  ${Cell} {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.06px;
    line-height: 18px;
    padding-left: 10px;
    text-align: right;
    cursor: pointer;
    :first-child {
      padding-left: 0;
    }

    svg {
      margin-left: 10px;
    }

    ${NoSelect}
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;

  ${media.mobile} {
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  ${Row} {
    min-height: 42px;
    padding: 20px;
    
    :hover {
      background-color: #f3fafb;
      background-clip: padding-box;
      .product-name {
        color: ${theme.colors.link};
      }
      ${OptionStyles.TableOptions} {
        border-color: ${theme.colors.link};

        svg {
          * {
            fill: ${theme.colors.link};
          }
        }
      }
    }

    ${Cell} {
      font-size: 16px;
      line-height: 20px;
      color: ${theme.colors.textPrimary};
      ${media.tablet} {
        :first-child {
          color: ${theme.colors.link};
        }
      }
    }
  }
`;

const CellSeparator = styled.span`
  color: ${theme.colors.description};
`;

const Wrapper = styled(Panel)`
  padding: 0;
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    box-shadow: none;
  }

  ${Header}, ${Body}, ${Row} {
    width: 100%;
  }

  ${Header}, ${Body} {
    ${Row} {
      flex-wrap: wrap;
    }

    ${Cell} {
      white-space: pre-line;
      width: 175px;
      max-width: 17%;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;

      ${media.tablet} {
        max-width: none;
      }

      &:first-child {
        max-width: none;
        flex-grow: 1;
        justify-content: flex-start;
      }

      &.small {
        width: 70px;
      }
    }
  }
`;

export default {
  Wrapper,
  Header,
  Row,
  Body,
  Cell,
  CellSeparator
};
