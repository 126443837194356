import styled, { css } from 'styled-components';

import { theme, media } from 'assets/styles/theme';

interface IRunnerProps {
  disabled: boolean;
}

const DisabledStyles = css`
  .rc-slider {
    &-track {
      left: 0% !important;
      width: 100%;

    }
    &-handle {
      left: 100%;
      cursor: not-allowed;
    }
    &-track, &-handle {
      background: ${theme.colors.description};
    }
  }
`;
const Runner = styled.div<IRunnerProps>`
  position: relative;
  width: 100%;
  height: 75px;
  font-size: 16px;
  line-height: 20px;
  padding-top: 27px;
  margin-bottom: 30px;
  border: 1px solid #edebe8;
  box-sizing: border-box;
  border-radius: 5px 5px 0 0;
  ${media.mobile} {
    margin: auto;
    margin-bottom: 40px;
    max-width: 90%;
    justify-content: center;
  }

  .rc-slider {
    position: relative;
    height: 14px;
    padding: 22px 0;
    width: 100%;
    border-radius: 6px;
  }

  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #e0dad5;
    height: 1px;
    border-radius: 6px;
  }

  .rc-slider-track {
    position: absolute;
    left: 0;
    height: 2px;
    border-radius: 6px;
    background-color: ${theme.colors.link};
    max-width: 100%;
  }

  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  .rc-slider-handle {
    position: absolute;
    margin-top: -9px;
    outline: none;
    width: 19px;
    height: 19px;
    cursor: grab;
    border-radius: 50%;
    border: solid 3px ${theme.colors.white};
    background-color: ${theme.colors.link};
    box-shadow: 0 2px 4px 0 rgba(59, 66, 73, 0.4);
    touch-action: pan-x;
    ${media.mobile} {
      &:after {
        content: '';
        background-color: transparent;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 50px;
        height: 50px;
      }
    }
  }

  .rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  ${({ disabled }) => (disabled
    ? DisabledStyles
    : '')
}
`;

const Error = styled.div`
  display: block;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06px;
  color: ${theme.colors.buttonPrimary};
  width: inherit;
  transition-property: height;
  transition-duration: 0.3s;
  margin-top: 5px;
  margin-left: 16px;
`;

const Label = styled.label`
  position: absolute;
  padding: 4px 5px;
  margin-top: -42px;
  margin-left: 13px;
  font-size: 14px;
  background-color: white;
  color: #a8a099;
`;

const hint = styled.span`
  padding-left: 18px;
  font-size: 30px;
  color: #424249;
  ${media.mobile} {
    font-size: 27px;
  }
`;

const hintBack = styled.span`
  float: right;
  padding-top: 2px;
  padding-right: 12px;
  text-decoration: none;
  font-size: 14px;
  color: #0d1423;
  opacity: 0.4;
  ${media.mobile} {
    position: absolute;
    right: 0;
    top: 78px;
    padding-right: 0;
  }
`;

export default {
  Runner, Error, hint, hintBack, Label
};
