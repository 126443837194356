import React, { useState } from 'react';

import { Popup } from 'components/shared';
import { Icon } from 'assets/img/svg';

import { IProps } from './interfaces';

import S from './styles';

export const PopupHint = ({ children }: IProps) => {
  const [showHint, setShowHint] = useState(false);
  return (
    <S.PatronymicHint
      onMouseEnter={() => setShowHint(true)}
      onMouseLeave={() => setShowHint(false)}
      style={{ position: 'relative' }}
      data-test="hint"
    >
      <Icon.Question />
      {showHint
        ? <Popup>{children}</Popup>
        : null}
    </S.PatronymicHint>
  );
};
