import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Loader } from 'components/shared';
import { actionTypes } from 'store/actionTypes';

import { AcquiringError } from '../acquiringError';
import { ERRORS_BY_CODE } from '../errors';
import { IPaymentFrameProps } from './interfaces';
import { getStatus, initRequest } from './asyncFunctions';

import { PaymentContainer } from './styles';

export const PaymentFrame = ({
  recommendedProducts,
  setRequisites,
  productId,
  onSuccess,
}: IPaymentFrameProps) => {
  const [paymentUrl, setPaymentUrl] = useState<string>('');
  const [loadFrame, setLoadFrame] = useState<boolean>(true);
  const [orderId, setOrderId] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<number>(0);
  const [errorCode, setErrorCode] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const [tryCount, setTryCount] = useState(0);
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch()
  const setNotRespond = () => dispatch({ type: actionTypes.PURCHASE.NOT_RESPOND })
  useEffect(() => {
    setState();
    setWidth(document.body.clientWidth < 450
      ? document.body.clientWidth - 30
      : 450);
  }, [])

  useEffect(() => {
    const handleStatusesUpdate = () => {
      if (orderStatus === 2) {
        onSuccess()
      } else if (errorCode !== 0) {
        setShowError(true);
      }
    }
    handleStatusesUpdate();
  }, [orderStatus, errorCode]);

  const { typeId } = recommendedProducts.byId[productId];
  const setState = async () => {
    if (tryCount < 5) {
      try {
        setLoadFrame(true);
        const { formUrl, orderId: oi } = await initRequest(
          recommendedProducts,
          productId,
          setRequisites,
          typeId
        );
        setLoadFrame(false);
        setPaymentUrl(formUrl);
        setOrderId(oi);
        setTryCount(tryCount + 1);
      } catch (e) {
        setErrorMessage('При проведении оплаты произошла ошибка');
        setShowError(true);
      }
    } else {
      setNotRespond();
    }
  }

  const handleOnLoad = () => {
    if (productId && orderId) {
      getStatus(productId, orderId).then((res) => {
        setOrderStatus(res.orderStatus);
        setErrorCode(res.errorCode);
        const err = ERRORS_BY_CODE[res.errorCode];
        if (err) {
          setErrorMessage(err);
        } else {
          setErrorMessage(res.errorMessage);
        }
      });
    }
  }

  const repeat = async () => {
    try {
      setState();
    } catch (e) {
      setNotRespond();
    }
    setShowError(false);
    setErrorCode(0);
    setOrderStatus(0);
  }
  switch (true) {
    case showError:
      return (
        <PaymentContainer>
          <AcquiringError
              isShow={showError}
              errorMessage={errorMessage}
              repeat={repeat}
          />
          <div className="payment-loader">
            <Loader />
          </div>
        </PaymentContainer>
      );
    case loadFrame:
    case orderStatus === 2:
      return (
        <PaymentContainer>
          <div className="payment-loader">
            <Loader />
          </div>
        </PaymentContainer>
      );
    case typeId === 4 || typeId === 5:
      return null;
    default:
      return (
        <PaymentContainer>
          <div className="payment-frame">
            <iframe
                title="iframe"
                src={paymentUrl}
                height={800}
                width={width}
                style={{ border: 'none', width: 'calc(100% + 30px)', marginLeft: '-15px' }}
                onLoad={() => handleOnLoad()}
            />
          </div>
        </PaymentContainer>
      );
  }
};
