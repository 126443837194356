import React from 'react';

import { Sector } from 'recharts';

import { IProps } from './interfaces';

import S from './styles';

export const ActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload
}: IProps) => {
  const { cost } = payload.payload;

  return (
    <S.ActiveShape>
      {cost && (
        <S.Result x={cx} y={cy} dy={8} textAnchor="middle">
          <tspan x={cx} y={cy - 25} dy="1.2em">
            Активы:
          </tspan>
          <tspan x={cx} y={cy} dy="1.2em">
            {cost.toString()}
          </tspan>
        </S.Result>
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </S.ActiveShape>
  );
};
