import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { api } from 'api';
import { Url } from 'api/url';
import { IOpifYield } from 'interfaces/opifYields';

import {
  errorYields,
  requestYields,
  successYields
} from '../../reducers/opifYield';

function* checkOpifYield() {
  try {
    const params = {
      url: Url.opifYield.get
    };
    const opifYield: IOpifYield[] = yield call(
      api.get,
      params
    );
    yield put(successYields(opifYield));
  } catch (e) {
    console.log(e)
    yield put(errorYields(e.message));
  }
}

export function* getOpifYield() {
  yield takeEvery(
    requestYields.toString(),
    checkOpifYield
  );
}
