import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import { api } from 'api';
import { Url } from 'api/url';
import { Token } from 'entities/token';
import { IAction, IRecognitionSubmitData } from 'interfaces';
import {
  approveError,
  approveRequest,
  approveSuccess
} from 'store/reducers/checkAndApproveClientData';

/* сага для подтверждения актуальности паспортных данных */
function* sendApproveClientData({ payload }: IAction<IRecognitionSubmitData>) {
  try {
    const params = {
      url: Url.client_message.submit,
      data: payload
    };
    const { token } = yield call(
      api.post,
      params
    );

    Token.set('clientApprove', token);
    yield put(approveSuccess());
  } catch (e) {
    console.log(e.message)
    yield put(approveError(e.message));
  }
}
export function* submitApproveClientData() {
  yield takeLatest(
    approveRequest.toString(),
    sendApproveClientData
  );
}
