import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPurse } from 'interfaces';

/* получаем кошелек по клиенту */
export const purseSlice = createSlice({
  name: 'purse',
  initialState: {},
  reducers: {
    requestPurse: (state) => state,
    successPurse: (state, action: PayloadAction<IPurse>): IPurse => action.payload,
    errorPurse: (state, action: PayloadAction<string>): string => action.payload
  }
})
export const {
  requestPurse,
  successPurse,
  errorPurse
} = purseSlice.actions
