import { FormField } from 'entities/formField';
import { getFakeEvent } from 'helpers/field/getFakeEvent';
import { getSuggestions, IGetSuggestionsData } from 'helpers/dadata';
import { Change, Handle } from 'types/inputEvent';

const changeBankData = (change: Handle<Change>, value: IGetSuggestionsData) => {
  getSuggestions('bank', { value: value.toString() }).then((response) => {
    if (response[0]) {
      const {
        payload,
        payload: { requisites }
      } = response[0];
      const bankPlaceString = payload && requisites
        ? requisites.bankPlace
        : null;

      const bankPlaceTypeString = payload && requisites
        ? requisites.bankPlaceType
        : null;

      if (change && bankPlaceString) {
        change(getFakeEvent(bankPlaceString, 'requisites.bankPlace') as Change);
      }

      if (change && bankPlaceTypeString) {
        change(getFakeEvent(bankPlaceTypeString, 'requisites.bankPlaceType') as Change);
      }
    }
  });
};

const correspondentAccount: FormField = new FormField(
  {
    name: 'requisites.ks',
    placeholder: 'Корреспондентский счёт',
    initialValue: '',
    type: 'text'
  },
  {
    disabled: false,
    isRequired: true,
    mask: '99999999999999999999'
  }
);

const checkingAccount: FormField = new FormField(
  {
    name: 'requisites.rs',
    placeholder: 'Расчётный счёт',
    initialValue: '',
    type: 'text'
  },
  {
    disabled: false,
    isRequired: true,
    mask: '99999999999999999999'
  }
);

const bankBik: FormField = new FormField(
  {
    name: 'requisites.bik',
    placeholder: 'БИК',
    initialValue: '',
    type: 'text'
  },
  {
    disabled: false,
    isRequired: true,
    mask: '999999999',
    withSuggestions: {
      type: 'bank',
      parts: 'bic',
      autocompleateFields: [
        'requisites.bankName',
        'requisites.bankPlace',
        'requisites.bankPlaceType',
        correspondentAccount.name
      ]
    },
    onChangeValue: (value, change) => {
      if (change) {
        changeBankData(change, value);
      }
    }
  }
);

const bankPlace = new FormField(
  {
    name: 'requisites.bankPlace',
    type: 'text',
    placeholder: '',
    initialValue: 'Поле не может быть пустым.'
  },
  {
    invisible: true
  }
);

const bankPlaceType = new FormField(
  {
    name: 'requisites.bankPlaceType',
    type: 'text',
    placeholder: '',
    initialValue: 'Поле не может быть пустым.'
  },
  {
    invisible: true
  }
);

const bankName: FormField = new FormField(
  {
    name: 'requisites.bankName',
    placeholder: 'Наименование банка',
    initialValue: '',
    type: 'text'
  },
  {
    disabled: false,
    isRequired: true,
    withSuggestions: {
      type: 'bank',
      autocompleateFields: [
        bankBik.name,
        correspondentAccount.name
      ]
    },
    onChangeValue: (value, change) => {
      if (change) {
        changeBankData(change, value);
      }
    }
  }
);

export const bankFields = {
  checkingAccount,
  correspondentAccount,
  bankBik,
  bankName,
  bankPlace,
  bankPlaceType
};
