import React, { useState, useRef } from 'react';

import useClickOutSide from 'use-onclickoutside';

import { Datepicker } from 'components/shared/datepicker';
import { Icon } from 'assets/img/svg';
import { Change } from 'types/inputEvent';
import { IDateRange } from 'interfaces';
import {
  formatDate,
  stringToDate,
  isDate
} from 'helpers/date';

import { Input } from '../index';
import { IInputProps } from '../intefaces';

import S from './styles';

export const DateInput = (props: IInputProps) => {
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [date, setDate] = useState(new Date());
  const datepickerRef = useRef(null);
  const {
    likeTextField,
    onChange,
    name
  } = props;

  useClickOutSide(datepickerRef, () => setShowDatepicker(false));

  const handleDateChange = (range?: IDateRange, day?: Date) => {
    if (day && onChange) {
      const formatDay = formatDate(day, 'DD.MM.YYYY');
      const event = {
        target: { name, value: formatDay }
      };
      // @ts-ignore
      onChange(event);
      setDate(day);
    }
  };
  const handleInputChange = (event: Change) => {
    if (onChange) {
      onChange(event);
    }

    const { value } = event.currentTarget;

    if (isDate(value)) {
      handleDateChange(undefined, stringToDate(value));
    }
  };

  const inputProps = { ...props, handleChange: handleInputChange };

  return (
    <S.DateInput>
      <Input {...inputProps} />
      {likeTextField
        ? null
        : (
          <div ref={datepickerRef}>
            <S.CalendarButton
              type="button"
              onClick={() => setShowDatepicker(true)}
            >
              <Icon.Calendar />
            </S.CalendarButton>
            <Datepicker
              isShow={showDatepicker}
              range={{ to: date, from: date }}
              onChange={handleDateChange}
              onClose={() => setShowDatepicker(false)}
            />
          </div>
        )}
    </S.DateInput>
  );
};
