import { getFormatNumberValue } from 'helpers/field';
import { FieldTypes } from 'enums';

export class Percents {
  constructor(private readonly value: number) {
    this.value = value;
  }

  get = () => this.value;

  toString(type: FieldTypes = FieldTypes.common) {
    return `${getFormatNumberValue(this.value, type)} %`;
  }
}
