import React from 'react';

import map from 'lodash/map';
import { v4 as uuid } from 'uuid';

import S from '../../pages/portfolio/styles';
import { NotesInfo } from '../../pages/portfolio/pageInformation';
import { useStore } from '../../hooks/useStore';

const { ParagraphContainer, Paragraph } = S

export const Notes = () => {
  const { opifYield: { isLoading } } = useStore()

  if (isLoading) return null;

  return (
    <>
      <ParagraphContainer>
        {map(NotesInfo, (text) => <Paragraph key={uuid()}>{text}</Paragraph>)}
      </ParagraphContainer>
    </>
  );
};
