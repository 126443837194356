import React, { FC } from 'react';

import { IField, IFormFieldProps } from 'interfaces';
import {
  CheckBox,
  Input,
  RadioButton
} from 'components/shared';
import { SuggestionsInput } from 'components/shared/input/suggestions';
import { DateInput } from 'components/shared/input/date';
// import { DropZone } from 'components/shared/dropzone';
import { Change } from 'types/inputEvent';
import { MoneyInput } from 'components/shared/input/money';
import { PieCountRunner } from 'components/shared/runner/PieCountRunner';

import { getSuggestions } from '../dadata';

export function getFieldComponent(field: IField): FC<IFormFieldProps> {
  const { type } = field;
  switch (type) {
    case 'radio':
      return getRadioButton(field);

      // case 'dropzone':
      //   return getDropZone(field);

    case 'checkbox':
      return getCheckboxInput(field);

    case 'date':
      return getInputWithDatepicker(field);

    case 'money':
      return getMoneyInput(field);

    case 'runner':
      return getRunnerInput(field);

    case 'content':
      return getContentInput(field);

    default:
      return getMaskedInput(field);
  }
}

const maskChar = '';

const getMoneyInput = (field: IField): FC<IFormFieldProps> => (
  {
    onBlur,
    onChange,
    value,
    error
  }: IFormFieldProps
) => {
  const {
    placeholder,
    name,
    mask,
    hint,
    description,
    disabled,
    likeTextField
  } = field;

  return (
    <MoneyInput
      type="text"
      placeholder={placeholder}
      name={name}
      mask={mask}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      hint={hint}
      description={description}
      disabled={disabled}
      likeTextField={likeTextField}
    />
  );
};

const getRadioButton = (field: IField): FC<IFormFieldProps> => ({
  value,
  onChange
}: IFormFieldProps) => (
  <RadioButton
      name={field.name}
      value={value}
      label={`${field.placeholder}`}
      onChange={onChange}
      checked={value === field.id}
      id={field.id || ''}
  />
);

// const getDropZone = (field: IField): FC<IFormFieldProps> => ({
//   onChange,
//   fieldError
// }: IFormFieldProps) => (
//   <DropZone
//       title={field.initialValue}
//       description={field.placeholder}
//       name={field.name}
//       onChange={onChange}
//       error={fieldError}
//   />
// );

const getCheckboxInput = (field: IField): FC<IFormFieldProps> => ({
  onChange,
  error,
  value
}: IFormFieldProps) => {
  const checkBoxValue = value === undefined ? field.initialValue : value;
  const changeHandler = (event: Change) => {
    onChange(event);

    if (field.onChangeValue) {
      field.onChangeValue(!checkBoxValue);
    }
  };

  return (
    <CheckBox
      placeholder={field.placeholder}
      checked={Boolean(checkBoxValue)}
      onChange={changeHandler}
      name={field.name}
      error={error}
      disabled={field.disabled}
    />
  );
};

const getMaskedInput = (field: IField): FC<IFormFieldProps> => {
  if (field.withSuggestions) {
    return getSuggestionsInput(field);
  }

  const {
    placeholder,
    type,
    name,
    mask,
    hint,
    description,
    likeTextField,
    invisible
  } = field;

  return ({
    onChange,
    onBlur,
    error,
    value,
    disabled
  }: IFormFieldProps) => (
    <Input
      type={type}
      placeholder={placeholder}
      name={name}
      mask={mask}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      hint={hint}
      description={description}
      disabled={disabled}
      likeTextField={likeTextField}
      maskChar={maskChar}
      invisible={invisible}
    />
  );
};

const getSuggestionsInput = (field: IField): FC<IFormFieldProps> => (
  {
    onBlur,
    onChange,
    error,
    value,
    disabled
  }: IFormFieldProps
) => {
  if (!field.withSuggestions) {
    return null;
  }

  const {
    withSuggestions: { parts, type, autocompleateFields },
    placeholder,
    name,
    hint,
    description,
    type: inputType
  } = field;
  const getInputSuggestions = (inputValue: string) => getSuggestions(type, {
    value: inputValue,
    parts: parts ? [parts] : undefined
  });

  return (
    <SuggestionsInput
      name={name}
      description={description}
      onBlur={onBlur}
      onChange={onChange}
      error={error}
      placeholder={placeholder}
      hint={hint}
      value={value}
      type={inputType}
      getSuggestions={getInputSuggestions}
      disabled={disabled}
      maskChar={maskChar}
      autocompleateFields={autocompleateFields}
      setFieldTouched={() => undefined}
    />
  );
};

const getInputWithDatepicker = ({
  name,
  mask,
  placeholder,
  likeTextField
}: IField) => ({
  onBlur,
  onChange,
  value,
  error
}: IFormFieldProps) => (
  <DateInput
    onBlur={onBlur}
    onChange={onChange}
    value={value}
    error={error}
    name={name}
    mask={mask}
    placeholder={placeholder}
    likeTextField={likeTextField}
    maskChar={maskChar}
  />
);

const getRunnerInput = (field: IField) => ({
  onBlur,
  value,
  error,
  onChange
}: IFormFieldProps) => {
  /* todo пока не знаю как сдлеать типизацию */
  const getCountDecimal = (valueCount: any) => {
    if (valueCount) {
      return valueCount.toString().indexOf('.') !== -1
        ? valueCount
          .toString()
          .split('.')
          .pop().length
        : 0;
    }
  };

  let stepSlider = 1;
  const countDecimalMax = field.max && getCountDecimal(field.max);

  if (field.max && countDecimalMax >= 1) {
    if (countDecimalMax >= 1 && countDecimalMax <= 6) {
      stepSlider = 1 / 10**countDecimalMax;
    }

    if (countDecimalMax > 6) {
      stepSlider = 1 / 10**6;
    }

    if (field.max < 1 && +value !== field.max) {
      field.min = 0;
      // realValue = field.max.toString();
    }

    if (field.max >= 1) {
      const floorMaxValue = Math.floor(field.max);

      if (+value <= floorMaxValue) {
        // realValue = Math.round(+value).toString();
      }

      if (+value > floorMaxValue && +value <= field.max) {
        // realValue = field.max.toString();
      }
    }
  }
  return (
    <PieCountRunner
        onBlur={onBlur}
        onChange={onChange}
        value={+value}
        error={error}
        name={field.name}
        step={stepSlider}
        placeholder={field.placeholder}
    />

  );
};

const getContentInput = (field: IField) => () => (field.content ? <>{field.content}</> : null);
