import {
  call,
  put,
  takeEvery
} from 'redux-saga/effects';

import { api } from 'api';
import { Url } from 'api/url';

import {
  requestCurrentStatus,
  successCurrentStatus,
  errorCurrentStatus
} from '../../../reducers/qualifiedStatusData';

interface IQualifiedInvestorStatus {
  IsQualifiedInvestor: boolean;
}

function* checkQualifiedStatus() {
  try {
    const params = {
      url: Url.qualified.check
    };
    const qualifiedInvestorStatus: IQualifiedInvestorStatus = yield call(
      api.get,
      params
    );
    yield put(successCurrentStatus(qualifiedInvestorStatus.IsQualifiedInvestor));
  } catch (e) {
    console.log(e.message)
    yield put(errorCurrentStatus(e.message));
  }
}

export function* getQualifiedStatus() {
  yield takeEvery(
    requestCurrentStatus.toString(),
    checkQualifiedStatus
  );
}
