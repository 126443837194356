import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUserMessages } from 'interfaces';
import { IUserMessage } from 'interfaces/userMessage';

/* получение, изменение статуса сообщений клиента */
export const userMessagesSlice = createSlice({
  name: 'userMessages',
  initialState: { userMessages: [], isLoading: false, error: '' } as IUserMessages,
  reducers: {
    requestMessages: (state) => {
      state.isLoading = true
      return state
    },
    successMessages: (state, action: PayloadAction<IUserMessage[]>) => {
      state.userMessages = action.payload
      state.isLoading = false
      return state
    },
    errorMessages: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      return state
    },
    /* отмечаем одно как прочитанное */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    markOneAsRead: (state, action: PayloadAction<{ mark: string, id: string }>) => state,
    /* отмечаем все как прочитанные */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    markAllAsRead: (state, action: PayloadAction<{ mark: string }>) => state
  }
})
export const {
  markAllAsRead,
  markOneAsRead,
  requestMessages,
  successMessages,
  errorMessages
} = userMessagesSlice.actions
