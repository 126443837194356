import { FormField } from 'entities/formField';

const runner = new FormField(
  {
    name: 'shareCount',
    type: 'runner',
    placeholder: '',
    initialValue: -1
  }
);

export default runner;
