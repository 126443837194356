import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { FieldTypes } from 'enums/fieldTypes';
import { staticContent } from 'staticContent';
import Burger from 'components/shared/burger';
import { getProductFinancialResults } from 'helpers/products';
import { Icon } from 'assets/img/svg';
import { NumberField } from 'components/shared';
import { CurrencySwitch } from 'components/widgets/currencySwitch'
import FinResStyle from 'components/shared/commonStyles/finResult/styles';
import { requestPurse } from 'store/reducers/purse';
import { setStatus } from 'store/reducers/view/activeSidebar';

import { Datepicker } from '../datepickers';
import { Item } from './item';
import StylesProductButton from '../portfolioCharts/productButton/styles';
import { useStore } from '../../hooks/useStore';

import S from './styles';

const { FinResult } = FinResStyle

const { ProductButton } = StylesProductButton;
const { Logo } = staticContent;
/* todo зачем эта ф-я */
const getProductId = (location?: string | object) => {
  const pathname = location
    ? (location as { pathname: string }).pathname
    : '';
  const productIdMatches = pathname && /\/(\d+)$/.exec(pathname);
  return productIdMatches && productIdMatches[1];
};

export const Header = ({ showLogOut }: { showLogOut: boolean }) => {
  const location = useLocation()
  const [isHeadActive, setIsHeadActive] = useState(false)
  const {
    purse,
    userProducts,
    currency,
    view: { activeSidebar },
    purse: {
      currentValue,
      inputValue,
      outputValue,
      result,
    }
  } = useStore()
  const productId = userProducts && getProductId(location);
  const product = (userProducts && productId && userProducts.byId[productId]) || undefined;

  const dispatch = useDispatch()
  useEffect(() => {
    if (Object.keys(purse).length < 1) dispatch(requestPurse())
  }, [])
  const toggleActiveSidebar = () => dispatch(setStatus())
  const toggleHead = () => setIsHeadActive(!isHeadActive)
  const productFinancialResults = product && getProductFinancialResults(product);
  let resultValue;
  let resultPercent;
  if (result) {
    const { value, percent } = result;
    resultValue = value;
    resultPercent = percent;
  }
  /* todo проверить этот компонент, делал рефакторинг похожего */
  return (
    <S.Header data-test="header-component">
      <div className={classNames('header-mobile', { fixed: activeSidebar }, { blur: showLogOut })}>
        <Burger
            onClick={() => toggleActiveSidebar()}
            isActive={activeSidebar}
        />
        <Link
            className="logo"
            to="/portfolio"
            aria-label="Вернуться на портфель"
        >
          <Logo />
        </Link>
        {!activeSidebar && (
        <HashLink
          to="/portfolio#recommended-products"
          scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
        >
          <ProductButton>
            <Icon.AddProduct />
            Новый продукт
          </ProductButton>
        </HashLink>
        )}
      </div>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      <div
        className={classNames('header-items-wrapper', { active: isHeadActive })}
        onClick={() => toggleHead()}
        role="button"
      >
        <div className={classNames({ activeSidebar })}>
          <div
            className={classNames('header-items', {
              'hide-tablet': productFinancialResults,
            })}
          >
            <Item title="Стоимость активов" key="Стоимость активов">
              <NumberField
                value={currentValue}
                type={FieldTypes.diff}
                currency={currency}
              />{' '}
            </Item>
            <Item title="Вводы" key="Вводы">
              <NumberField
                value={inputValue}
                type={FieldTypes.diff}
                currency={currency}
              />{' '}
            </Item>
            <Item title="Выводы" key="Выводы">
              <NumberField
                value={outputValue}
                type={FieldTypes.diff}
                currency={currency}
              />
            </Item>
            <Item title="Доходность" key="Доходность">
              <FinResult>
                <NumberField
                  value={resultPercent}
                  type={FieldTypes.diff}
                  colorize
                  currency={currency}
                />
              </FinResult>
            </Item>
            <Item title="Прирост" key="Прирост">
              <FinResult>
                <NumberField
                  value={resultValue}
                  type={FieldTypes.diff}
                  currency={currency}
                  colorize
                />
              </FinResult>
            </Item>
            <Item title="На дату" key="На дату">
              <Datepicker.HeaderDatepicker />
            </Item>
            <Item title="Валюта" key="Валюта">
              <CurrencySwitch />
            </Item>
          </div>
          {productFinancialResults
            ? (
              <div className="header-items show-tablet">
                <Item title="Активы">
                  <NumberField value={productFinancialResults.cost} />
                </Item>

                <Item title="Вводы">
                  { productFinancialResults.input &&
                    <NumberField value={productFinancialResults.input} />}
                </Item>
                <Item title="Выводы">
                  <NumberField value={productFinancialResults.output} />
                </Item>
                <Item title="Доходность">
                  <FinResult>
                    <NumberField
                        value={productFinancialResults.result.percents}
                        type={FieldTypes.diff}
                        colorize
                        hasSign
                    />
                  </FinResult>
                </Item>
                <Item title="Прирост">
                  <FinResult>
                    <NumberField
                        value={productFinancialResults.result.inMoney}
                        type={FieldTypes.diff}
                        colorize
                        hasSign
                    />
                  </FinResult>
                </Item>
                {productFinancialResults.numberShares
                  ? (
                    <Item title="Количество паев, шт.">
                      {parseFloat(
                        productFinancialResults.numberShares
                      ).toLocaleString('ru-Ru', { minimumFractionDigits: 5 })}
                    </Item>)
                  : null}
              </div>)
            : null}
        </div>
      </div>
    </S.Header>
  );
}
