import React from 'react';

import { Link } from 'react-router-dom';

import { isDev } from 'helpers/isDev';
import { velesSystem } from 'constants/links';
import { downloadRules } from 'helpers/downloadFile';
import { Common } from 'components/shared';
import { getProductTitle } from 'staticContent/getProductTitle';

import { IProductPanelProps } from './interfaces';
import { doc } from '../../pages/kval/pageIformation';
import { getData, getProductLink } from './data';

import S from './styles';

const {
  Profit,
  ProfitPercent,
  PercentItem,
  RiskItem,
  PeriodInfoWrapper,
  WrapperRisk,
  Title,
  SubTitle,
  InfoWrapper,
  LinkMore,
  ProductWrapper,
  ProductContainer,
  ButtonForRules,
  RiskIndicator,
  ProfitDynamicWrapper,
  PeriodItem
} = S

const { Button } = Common

export const ProductPanel = ({
  id,
  inLk,
  yieldProduct
}: IProductPanelProps) => {
  const urlBuy = isDev
    ? `https://devonline.veles-management.ru/purchase/invest/${id}/1/1`
    : `https://online.veles-management.ru/purchase/invest/${id}/1/1`;

  const {
    subTitle,
    rand,
    rand_prefix,
    rand_percent,
    rand3_percent,
    rand6_percent,
    rand12_percent,
    type
  } = getData(id);

  const getProductStat = (typeProduct: string): JSX.Element => {
    switch (typeProduct) {
      case 'zpif':
        return <></>;
      case 'opif':
        return (
          <>
            <Profit>Доходность</Profit>
            <ProfitPercent upOrDown={rand_percent && rand_percent > 0 ? 'up' : 'down'}>
              {yieldProduct && yieldProduct.Profitability || rand_percent}%
            </ProfitPercent>
            <ProfitDynamicWrapper>
              <PeriodInfoWrapper>
                <PeriodItem>за 3 мес.</PeriodItem>
                <PercentItem>
                  {yieldProduct && yieldProduct.ProfitabilityThreeMonths
                   || rand3_percent}%
                </PercentItem>
              </PeriodInfoWrapper>

              <PeriodInfoWrapper>
                <PeriodItem>за 6 мес.</PeriodItem>
                <PercentItem>
                  {yieldProduct && yieldProduct.ProfitabilitySixMonths
                   || rand6_percent}%
                </PercentItem>
              </PeriodInfoWrapper>

              <PeriodInfoWrapper>
                <PeriodItem>за 1 год</PeriodItem>
                <PercentItem>
                  {yieldProduct && yieldProduct.ProfitabilityTwelveMonths
                   || rand12_percent}%
                </PercentItem>
              </PeriodInfoWrapper>
            </ProfitDynamicWrapper>
          </>
        );
      default:
        return (
          <>
            <Profit>Уровень риска *</Profit>
            <RiskIndicator className={`profit-progress-${rand}`}>
              <WrapperRisk>
                <RiskItem>&nbsp;</RiskItem>
                <RiskItem>&nbsp;</RiskItem>
                <RiskItem>&nbsp;</RiskItem>
                <RiskItem>&nbsp;</RiskItem>
                <RiskItem>&nbsp;</RiskItem>
                <RiskItem>&nbsp;</RiskItem>
              </WrapperRisk>
            </RiskIndicator>
            {id !== 34 &&
            <>
              <Profit>Ожидаемая доходность **</Profit>
              <ProfitPercent
                upOrDown={rand_percent && rand_percent > 0 ? 'up' : 'down'}
              >
                {`${rand_prefix} ${rand_percent}`}%
              </ProfitPercent>
            </>}
          </>
        );
    }
  };

  return (
    <ProductContainer>
      <ProductWrapper className={`thumbnail${id}`}>
        <SubTitle>{subTitle}</SubTitle>
        <Title>{getProductTitle(`${id}`)}</Title>
        <InfoWrapper>{getProductStat(type)}</InfoWrapper>
        {/* если клиент находится в ЛК отправляем его по маршруту покупки,
         при необходимости редирект будет сделан на странице purchase */}
        {inLk
          ?
            <Link to={`/products/invest/${id}/purchase/1/1`}>
              <Button>{id === 17 ? 'Погашение' : 'Купить'}</Button>
            </Link>
          :
            <a href={urlBuy}>
              <Button>{id === 17 ? 'Погашение' : 'Купить'}</Button>
            </a>}
        {id === 35
          ?
            <ButtonForRules onClick={() => downloadRules(doc)}>
              <LinkMore>Подробнее</LinkMore>
            </ButtonForRules>
          :
            <LinkMore href={id ===17 ? velesSystem : getProductLink(id)} target="_blank">
              Подробнее
            </LinkMore>}
      </ProductWrapper>
    </ProductContainer>
  );
};
