import React from 'react';

import { useDispatch } from 'react-redux';

import { setActiveProduct } from 'store/reducers/chart';

import { IOwnProps } from './interfaces';

import S from './styles';

export const ProductButton = ({
  title,
  color,
  value,
  active
}: IOwnProps) => {
  const dispatch = useDispatch()
  const setProduct = (name: string) => dispatch(setActiveProduct(name))
  return (
    <S.ProductButton
      data-test="product-button-component"
      onClick={() => setProduct(value)}
      className={active ? 'active' : ''}
    >
      <S.Color color={color} />
      <S.Title>{title}</S.Title>
    </S.ProductButton>
  );
};
