import React, { useEffect, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import bytes from 'bytes';
import findIndex from 'lodash/findIndex';
import upperCase from 'lodash/upperCase';
import size from 'lodash/size';

import { IFileBase64 } from 'interfaces';

import { Common } from '../index';
import { IProps } from './interfaces';

import S from './styles';

const {
  FilesWrapper,
  DropZoneWrapper,
  Error,
  DropZoneContainer,
  RemoveButton,
} = S

const kb = 1024;
const mb = 1024 * kb;

const { ExtraText, DescriptionText } = Common

export const DropZone = ({
  name,
  setSelectedFile,
  title
}: IProps) => {
  const [files, setFiles] = useState <File[]>([]);
  /* state для ошибки когда клиент пытается загрузить более 50 мб суммарно */
  const [error, setError] = useState(false)
  const [touched, setTouched] = useState(false);
  useEffect(() => {
    const itemRows: IFileBase64[] = [];
    if (setSelectedFile) {
      if (!size(files)) {
        setSelectedFile([])
        return
      }
      const getBase64 = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      })
      forEach(files, (file: File) => {
        getBase64(file).then((val: string) => {
          const row : IFileBase64 = {
            fileName: file.name,
            fileType: file.type,
            value: val.substr(val.indexOf(',') + 1)
          };
          itemRows.push(row);
          setSelectedFile(itemRows)
        }, (reason: any) => {
          console.log(reason); // Ошибка!
        });
      })
    }
  }, [files])
  const onDrop = (acceptedFiles: File[]) => {
    const updatedFiles = forEach(acceptedFiles, (file: File) => {
      if (findIndex(files, file) === -1) {
        return [...files, file]
      }
      return [files]
    });
    const commonSizeLoadedFiles = reduce(updatedFiles, (sum, { size }) => sum + size, 0)
    if (commonSizeLoadedFiles/1024/1024 > 50) {
      setError(true)
      setFiles([])
      return
    }
    setFiles(updatedFiles)
    setError(false)
  };
  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    maxSize: 10 * mb,
    accept: {
      'application/pdf': ['.pdf'],
      'image/tiff': ['.tiff'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/bmp': ['.bmp']
    }
  });
  /* обнуляем стейт если есть fileRejections (актуально для групповой заливки файлов) */
  useEffect(() => {
    if (size(fileRejections)) {
      setFiles([])
    }
  }, [fileRejections])
  const checkError = touched && !!size(fileRejections)
  const handleRemoveClick = ({ name: removingFileName }: File) => {
    setFiles(files.filter(({ name }) => name !== removingFileName));
  };

  const filesList = map(files, (file, index) => {
    const { name, size: fileSize } = file
    const fileName = size(name) > 25 && `${name.slice(0, 24)}...` || name
    const [, type] = file.type.split('/');
    return (
      <DropZoneWrapper isFile hasError={checkError} key={`${name} ${index}`}>
        <RemoveButton type="button" onClick={() => handleRemoveClick(file)} />
        <ExtraText>{fileName}</ExtraText>
        <DescriptionText>
          {upperCase(type)}, {bytes(fileSize, { unitSeparator: ' ' })}
        </DescriptionText>
      </DropZoneWrapper>
    );
  });
  const handleTouch = () => setTouched(true);

  return (
    <DropZoneContainer onClick={handleTouch} onDrop={handleTouch}>
      <DropZoneWrapper {...getRootProps()} hasError={checkError || error}>
        <input {...getInputProps()} name={name} />
        <ExtraText>{isDragActive ? 'ставь сюда' : title}</ExtraText>
      </DropZoneWrapper>
      <FilesWrapper
          data-test="files-wrapper"
          fileContains={!!size(filesList)}
      >
        {filesList}
      </FilesWrapper>
      {(checkError || error) &&
      <Error> Пожалуйста, проверьте формат документов и их размер</Error>}
    </DropZoneContainer>
  );
};
