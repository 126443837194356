import styled from 'styled-components';

import SProductCard from 'components/widgets/productCard/styles';
import StylesChart from 'components/shared/commonStyles/chartsPanel/styles';
import { media } from 'assets/styles/theme';

const Product = styled.div`
  margin: 30px;
  
  ${media.max(1400)} {
     display: flex;
     flex-direction: column;
     align-items: center;
    }

  ${media.mobile} {
    margin: 25px 0;

    ${StylesChart.Title} {
      padding: 0 15px;
      text-align: center;
    }

  ${StylesChart.AreaChart} > span {
      padding: 30px 15px 15px;
      text-align: center;
    }
  }

  ${SProductCard.ProductCard} {
    margin-bottom: 30px;
  }
`;

export default { Product };
