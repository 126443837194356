import styled from 'styled-components';

import { Common } from 'components/shared';
import { media } from 'assets/styles/theme';

const { LinkText, ExtraText } = Common

interface ITitleProps {
  isLogout?: boolean;
}
const Title = styled.h3<ITitleProps>`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: Montserrat;
  display: block;
  margin-bottom: ${({ isLogout }) => isLogout && '20px'};
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  font-weight: normal;
  max-width: 100%;
  
  ${media.mobile} {
  font-size: 18px;
}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .scrollbar-container {
    padding: 26px;
  }
  ${LinkText} {
    margin-bottom: 46px;
    display: flex;
    svg {
      margin-right: 10px;
      * {
        fill: ${({ theme }) => theme.colors.link};
      }
    }
  }
  & > ${ExtraText} {
    text-align: center;
  }
`;
const Description = styled.div`
  line-height: 18px;
  letter-spacing: 0.06px;
  color: ${({ theme }) => theme.colors.description};
  font-size: 0.8em;
  text-align: center
`

export default {
  Description,
  Wrapper,
  Title,
}
