import React, { useState } from 'react';

import { Datepicker } from 'components/widgets/datepickers';
import { FieldTypes } from 'enums/fieldTypes';
import { getRange } from 'helpers/date/getRange';
import { isDev } from 'helpers/isDev';
import {
  NumberField,
  DateRange,
  TransitionHeight
} from 'components/shared';

import { IOwnProps } from './interfaces';
import { useStore } from '../../../hooks/useStore';

import S from './styles';

export const Header = ({
  withoutResult,
  withoutRange,
  isChartWidget,
  isProductWidget
}: IOwnProps) => {
  const {
    chartsDateRange,
    account: { dateBorders },
    chart: { result }
  } = useStore()
  const [showFilters, setShowFilters] = useState(false);
  const { value, percent } = result;
  if (!dateBorders) {
    return null;
  }

  return (
    <S.Header>
      <S.AreaChartInfo isProductWidget={isProductWidget}>
        <div className="date-range-wrapper">
          {!withoutRange && chartsDateRange && (
          <>
            <DateRange
              onClick={() => setShowFilters(!showFilters)}
              {...chartsDateRange}
            />
            <TransitionHeight isOpen={isDev ? showFilters : false}>
              <Datepicker.ChartsDatepicker
                isChartWidget={isChartWidget}
                show={isDev ? showFilters : false}
                range={getRange(chartsDateRange, dateBorders)}
              />
            </TransitionHeight>
          </>
          )}
        </div>
        {withoutResult
          ? null
          : (
            <S.Result>
              <S.ResultTitle>Результат: </S.ResultTitle>
              <S.ResultValue>
                <NumberField
                  value={percent}
                  type={FieldTypes.diff}
                  colorize
                  hasSign
                />
                <S.Separator> / </S.Separator>
                <NumberField
                  value={value}
                  type={FieldTypes.diff}
                  colorize
                  hasSign
                />
              </S.ResultValue>
            </S.Result>)}
      </S.AreaChartInfo>
    </S.Header>
  );
};
