import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getProductFinancialResults } from 'helpers/products';
import { requestSecurities } from 'store/reducers/securities';
import { LoaderTooltip } from 'components/widgets/tooltips/loader';
import {
  IssuerCard,
  ProductCard,
  ProductChartCard
} from 'components/widgets';

import { useStore } from '../../hooks/useStore';

import S from './styles';

export const Product = () => {
  const dispatch = useDispatch()
  const { userProducts } = useStore()
  const { id = '0' } = useParams()
  useEffect(() => {
    dispatch(requestSecurities({ id }));
  }, [id]);
  const product = userProducts.byId[id]

  if (!product) return <LoaderTooltip isShow description="Пожалуйста, подождите..." />;

  const {
    availableFunds,
    type,
    description = '',
    productNumber,
    productStartDate
  } = product;

  const isDU = type === ('2' || '4');
  return (
    <S.Product data-test="product-page">
      <ProductCard
        isDU={isDU}
        id={id}
        type={type}
        productNumber={productNumber}
        date={productStartDate}
        description={description}
        financial={getProductFinancialResults(product)}
      />
      {!isDU && type !== '5'
        ? <ProductChartCard productId={id} productType={type} />
        : <IssuerCard isIssuerCard availableFunds={availableFunds} />}
    </S.Product>
  );
};
