import styled from 'styled-components';

import { media } from 'assets/styles/theme';
import StylesRadioButton from 'components/shared/radioButton/styles';
import StylesRadioButtonContainer from 'components/shared/radioButtonContainer/styles';
import { Common } from 'components/shared';

const { LinkText } = Common

const KvalPageWrapper = styled.div`
  margin: 0 30px 40px;
  
  ${media.tablet} {
      font-weight: 600;
  }

  ${media.mobile} {
      margin: 0 20px 0;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  
  ${media.tablet} {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`;

const SubTitle = styled.h2`
  ${media.tablet} {
    font-size: 16px;
    line-height: 20px;
  }

  ${media.mobile} {
    margin: 0;
  }
`;

const Input = styled.input``;

const Span = styled.span`
  padding-left: 3px;
  vertical-align: top;
`;

const SpanCondition = styled.span``;

const TypeDocumentWrapper = styled.div`
  padding-top: 13px
`;

const DescriptionType = styled.div`
  margin-top: 10px;
  font-style: italic;
`;

const TextResult = styled.span`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

const Section = styled.section`
  margin-top: 15px;
  
  ${media.mobile} {
    margin: 0 0 20px;
      :nth-child(4) {
        margin-bottom: 0;
      }
    }
  }
`;

const DocTypeSelectWrapper = styled.div`
  margin-top: 10px;
  
  ${LinkText} {
    font-size: 13px;
    line-height: 16px;    
  }
  
  &:first-child {
    font-size: 30px;
    color: red;
    display: flex;
    flex-direction: column;
  }
  
  ${StylesRadioButtonContainer.RadioButtonContainer} {
    display: flex;
    flex-direction: column;
  }
  
  ${StylesRadioButton.RadioButton} {
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-right: -1px;    

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-right: 0;
      margin-bottom: 20px;
    }
    :hover {
      background-color: ${({ theme }) => theme.colors.white};
    }   
       

    label {      
      font-size: 13px;
      line-height: 16px;      
      color: ${({ theme }) => theme.colors.textPrimary};      
      vertical-align: middle;
      padding: 10px 12px;
      cursor: pointer;
      justify-content: left;
      width: 100%; 
    }
    
     span {      
      font-size: 13px;      
    }
  }

  input:checked ~ label {
    cursor: default;
    background-color: ${({ theme }) => theme.colors.description};
    color: ${({ theme }) => theme.colors.white};
    width: 100%; 
  }

 }
`;
export default {
  KvalPageWrapper,
  Title,
  SubTitle,
  Input,
  Span,
  SpanCondition,
  TypeDocumentWrapper,
  DescriptionType,
  TextResult,
  Section,
  DocTypeSelectWrapper
}
