import styled from 'styled-components';

import { Common } from 'components/shared';

const {
  Panel,
  MainText,
  H4
} = Common

const PromoPanel = styled(Panel)`
  padding: 55px 60px 0 60px;
  width: 370px;
  height: 280px;
  justify-content: flex-start;
  svg {
    margin-bottom: 39px;
  }
  ${MainText},${H4} {
    text-align: center;
  }
  ${H4} {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }
  ${MainText} {
    font-size: 14px;
    line-height: 20px;
    width: 252px;
  }
`;

export default { PromoPanel };
