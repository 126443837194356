import React from 'react';

import {
  CommonTooltip,
  CustomScrollBox,
  Common
} from 'components/shared';

import { agreement } from '../../applicationForms/docsManagement/docs';
import { IProps } from './interfaces';

import { DocsManagement, Title } from './styles';
import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';

const {
  Button,
  ExtraText,
  DescriptionText
} = Common

export const EdoTooltip = ({ isShow, onCancel }: IProps) => {
  useLockBodyScroll(isShow)
  return (
    <>
      <CommonTooltip
        data-test="edo-tooltip"
        isShow={isShow}
        onClose={() => null}
      >
        <DocsManagement>
          <Title>
            Соглашение об электронном документообороте
          </Title>
          <ExtraText>
            Документ необходим, чтобы Вам не пришлось посещать офис продаж лично.
          </ExtraText>
          <CustomScrollBox>
            <ExtraText>
              <p>
                <DescriptionText>
                  1. ПРЕДМЕТ СОГЛАШЕНИЯ
                </DescriptionText>
              </p>
              {agreement}
            </ExtraText>
          </CustomScrollBox>
          <Button onClick={onCancel}>
            Я принимаю соглашение
          </Button>
        </DocsManagement>
      </CommonTooltip>
    </>
  );
};
