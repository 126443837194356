import React from 'react';

import size from 'lodash/size';

import { ISuggestionsInputState } from '../../components/shared/input/suggestions/reducer';
import { ISuggestionResponse } from '../dadata/interfaces';

export function prevSuggestion(activeSuggestion: number): number {
  return activeSuggestion ? activeSuggestion - 1 : 0;
}

export function nextSuggestion(
  activeSuggestion: number,
  suggestions: ISuggestionResponse[] = []
) {
  const suggestionLength = size(suggestions);
  return activeSuggestion < suggestionLength - 1
    ? activeSuggestion + 1
    : activeSuggestion;
}

export function selectSuggestion(
  event: React.KeyboardEvent,
  state: ISuggestionsInputState,
  activeSuggestion: number,
  handleSelect: (suggestion: ISuggestionResponse) => void
) {
  const { showSuggestions, suggestions } = state;
  if (showSuggestions) {
    event.preventDefault();
    handleSelect(suggestions[activeSuggestion]);
  }
}
