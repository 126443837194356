import styled from 'styled-components';

const TrackElement = styled.div`
  width: 10px;
  padding: 6px;
  height: 100%;
  position: absolute;
  right: 0;
`;
const Thumb = styled.div`
  width: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.border};
`;
export default {
  TrackElement,
  Thumb
};
