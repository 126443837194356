import styled from 'styled-components';

import { InputPayloadPosition } from 'assets/styles/mixins';
import StylesDatepicker from 'components/shared/datepicker/styles';

const { Datepicker } = StylesDatepicker;

const CalendarButton = styled.button`
  ${InputPayloadPosition}
`;
const DateInput = styled.div`
  position: relative;
  ${Datepicker} {
    width: auto !important;
    min-width: auto !important;
  }
`;

export default { DateInput, CalendarButton };
