import styled from 'styled-components';

import { InputPayloadPosition } from 'assets/styles/mixins';
import { media } from 'assets/styles/theme';

import S from '../styles';

const InputPassword = styled.div`
  position: relative;
  width: 100%;

  & + & {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ${media.mobile} {
    &.with-restore {
      margin-bottom: 40px;
    }
  }

  ${S.Input} ~span {
    position: absolute;
    right: 17px;
    top: 15px;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 0.06px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.link};
  }
`;

const Icon = styled.div`
  cursor: pointer;
`;

const Payload = styled.div`
  ${InputPayloadPosition}

  button {
    transition: 0.3s;

    :focus,
    :hover {
      opacity: 0.7;
    }
  }
`;

interface IIndicator {
  percentage: number;
  color: string;
}

const Indicator = styled.div<IIndicator>`
  position: absolute;
  left: 5px;
  right: 5px;
  top: 49px;
  height: 1px;

  ::after {
    content: "";
    display: block;
    height: 100%;
    width: ${({ percentage }) => percentage}%;
    background-color: ${({ color }) => color};
    transition: 0.3s;
  }
`;

const Restore = styled.button`
  display: block;
  width: auto !important;
  position: absolute;
  right: 50px;
  top: 17px;
  font-size: 15px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.link};
  cursor: pointer;
  transition: 0.3s;

  :focus,
  :hover {
    opacity: 0.7;
  }

  ${media.mobile} {
    width: 100% !important;
    top: auto;
    bottom: -10px;
    right: auto;
    font-size: 14px;
    text-decoration: underline;
  }
`;

export default {
  InputPassword,
  Payload,
  Icon,
  Restore,
  Indicator
};
