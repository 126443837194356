import {
  createSlice,
  combineReducers,
  PayloadAction,
  isAnyOf
} from '@reduxjs/toolkit';

import { checkSmsRecoveryRequest } from '../../actions/actionCreators/checkSmsRecoveryRequest';

/* используется для disable кнопки  при отправке на проверку логина-паспортных данных + смс */
const sendingDataSlice = createSlice({
  name: 'sendingData',
  initialState: false,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requestCheck: (state, action: PayloadAction<{
      userName: string,
      passportSeries: string,
      passportNumber: string
    }>) => true,
    successCheck: () => false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errorCheck: (state, action: PayloadAction<string>) => false
  },
  extraReducers: ((builder) => {
    builder.addCase(checkSmsRecoveryRequest, () => true)
    builder.addMatcher(isAnyOf(successSms, errorSms), () => false)
  })
})
export const {
  requestCheck,
  successCheck,
  errorCheck
} = sendingDataSlice.actions

/* slice для флага, что пара логиин-паспортные данные верная */
const recoveryDataSlice = createSlice({
  name: 'recoveryData',
  initialState: false,
  reducers: {
    resetData: () => false
  },
  extraReducers: ((builder) => builder.addCase(successCheck, () => true))
})
export const { resetData } = recoveryDataSlice.actions
/* slice для ошибки при отправке логина-паспортных данных */
const requestRecoveryDataErrorSlice = createSlice({
  name: 'requestPasswordRecoveryError',
  initialState: '',
  reducers: {},
  extraReducers: ((builder) => {
    builder.addCase(
      errorCheck,
      (state, action: PayloadAction<string>) => action.payload
    )
  }
  )
})
/* подтверждение корреткного ввода смс при восстановлении пароля */
const recoverySmsSlice = createSlice({
  name: 'recoverySms',
  initialState: false,
  reducers: {
    successSms: () => true
  }
})
export const { successSms } = recoverySmsSlice.actions

/* slice для ошибки при неверном вводе смс */
const recoverySmsErrorSlice = createSlice({
  name: 'recoverySmsError',
  initialState: '',
  reducers: {
    errorSms: (state, action: PayloadAction<string>) => action.payload
  }
})
export const { errorSms } = recoverySmsErrorSlice.actions

export const recoveryReducer = combineReducers({
  sendingData: sendingDataSlice.reducer,
  correctData: recoveryDataSlice.reducer,
  error: requestRecoveryDataErrorSlice.reducer,
  correctCode: recoverySmsSlice.reducer,
  smsError: recoverySmsErrorSlice.reducer,
});
