import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

export class Token {
  static set(name: string, token: string) {
    localStorage.setItem(name, token);
  }

  static get(name: string): string | null {
    return localStorage.getItem(name);
  }

  static getRemainingTime() {
    try {
      const accessToken = localStorage.getItem('access');
      if (accessToken) {
        const { exp } = jwtDecode(accessToken);

        return dayjs(exp * 1000).diff(dayjs(), 'm');
      }
      throw undefined;
    } catch (e) {
      return 0;
    }
  }

  static getTokensState(): boolean {
    const accessToken = Token.get('access');
    const refreshToken = Token.get('refresh');

    return !!accessToken && !!refreshToken;
  }

  static removeTokens() {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
  }
}
