import styled from 'styled-components';

const Caption = styled.div`
  text-align: center !important;
  margin-bottom: 18px !important;
  div {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 13px;
    letter-spacing: 0.05px;
    line-height: 16px;
    font-weight: normal !important;
  }
`;
const Filters = styled.div``;
const Month = styled.div``;
export default {
  Caption,
  Filters,
  Month
};
