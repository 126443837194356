import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import { api } from 'api';
import { Token } from 'entities/token';
import { removePhoneMask } from 'helpers/phone/phoneMask';
import { Url } from 'api/url';
import { IActionRecoveryData, IActionRecoverySms } from 'interfaces/passwordRecovery';

import { checkSmsRecoveryRequest } from '../actionCreators/checkSmsRecoveryRequest';
import {
  requestCheck,
  successCheck,
  errorCheck,
  successSms, errorSms
} from '../../reducers/passwordRecovery';

function* fetchRecoveryData({ payload }: IActionRecoveryData) {
  try {
    const {
      userName,
      passportNumber,
      passportSeries
    } = payload;
    const username = removePhoneMask(userName);
    const params = {
      url: Url.passwordRecovery.sendUserData,
      data: {
        username,
        passportNumber,
        passportSeries
      }
    };

    yield call(api.post, params);
    yield put(successCheck());
  } catch (er) {
    console.log(er)
    yield put(errorCheck(er.message));
  }
}

function* fetchRecoveryCode({
  payload: {
    passportNumber,
    passportSeries,
    code,
    userName
  }
}: IActionRecoverySms) {
  try {
    const username = removePhoneMask(userName);
    const params = {
      url: Url.passwordRecovery.checkSmsCode,
      data: {
        username,
        passportNumber,
        passportSeries,
        code
      }
    };
    const { token, refresh_token } = yield call(api.post, params);

    Token.set('access', token);
    Token.set('refresh', refresh_token);

    yield put(successSms());
  } catch ({ message }) {
    yield put(errorSms(message));
  }
}

export function* passwordRecoveryData() {
  yield takeLatest(
    requestCheck.toString(),
    fetchRecoveryData
  );
}

export function* passwordRecoverySms() {
  yield takeLatest(
    checkSmsRecoveryRequest.toString(),
    fetchRecoveryCode
  );
}
