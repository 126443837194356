import { getProperty } from 'dot-prop';

import { IProduct } from 'interfaces';

import { Money } from 'entities/money';
import { Percents } from '../percents';

export const columns: IColumn[] = [
  {
    Header: 'Продукт',
    accessor: 'name'
  },
  {
    Header: 'Стоимость активов',
    accessor: 'cost'
  },
  {
    Header: 'Доля в портфеле',
    accessor: 'part'
  },
  {
    Header: 'Доходность',
    accessor: 'result.percents'
  },
  {
    Header: 'Прирост',
    accessor: 'result.value'
  }
];

export interface IColumn {
  Header: string;
  accessor: string;
}
export class ProductTable {
  static getColumns = () => columns

  static compareSort = (sort: { selected: string; isReverse: boolean }) => (
    a: IProduct,
    b: IProduct
  ): number => {
    /* todo пока не знаю как типизировать */
    let aValue = getProperty(a, sort.selected) as any;
    let bValue = getProperty(b, sort.selected) as any;

    if (
      (aValue instanceof Money || aValue instanceof Percents) &&
      (bValue instanceof Money || bValue instanceof Percents)
    ) {
      aValue = aValue.get();
      bValue = bValue.get();
    }

    let result = -1;
    if (aValue > bValue) {
      result = 1;
    }
    if (aValue === bValue) {
      result = 0;
    }

    if (sort.isReverse) result *= -1;
    return result;
  }
}
