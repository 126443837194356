import React from 'react';

import { IWizardForm } from 'interfaces';
import { FormField } from 'entities/formField';
import { useStore } from 'components/hooks/useStore';
import { getAppeal } from 'helpers/form';

import S from './styles';

const { Description, Title } = S

const SuccessTitle = () => {
  const {
    purchase: {
      data: {
        firstName,
        lastName,
        patronymic,
        gender
      }
    }
  } = useStore()
  const userName = `${firstName} ${patronymic || lastName}`;
  const apeal = getAppeal(gender, userName);
  return (
    <Title>
      {apeal} Благодарим Вас за&nbsp;выбор
      <br />
      ООО&nbsp;&laquo;УК&nbsp;ВЕЛЕС&nbsp;Менеджмент&raquo;!
    </Title>
  );
};

const SuccessDescription = () => (
  <Description>
    Ваш платёж принят.
  </Description>
);

const title = <SuccessTitle />;
const description = <SuccessDescription />;

const successField = new FormField(
  {
    name: 'success_field',
    placeholder: '',
    type: 'content',
    initialValue: ''
  },
  {
    groupTitle: title,
    groupDescription: description
  }
);

export const successAcquiring: IWizardForm = {
  title: '',
  url: '',
  fields: [successField],
  submitTitle: 'Продолжить работу с личным кабинетом',
  formComponentClassName: 'success-form'
};
