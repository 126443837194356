import {
  takeLatest,
  put,
  call
} from 'redux-saga/effects';

import { api } from 'api';
import { actionTypes } from 'store/actionTypes';
import { IAction } from 'interfaces';
import { Url } from 'api/url';
import { requestCheckEdo } from 'store/reducers/edoExistStatusReducer';
import {
  getPurchaseHeaders,
  getRecognitionHeaders,
  getRedemptionHeaders,
  getClientDataHeaders
} from 'api/options';

import { getHost } from '.';

const {
  NOT_RESPOND,
  IS_CONFLICT,
  SMS: { CHECK, SEND }
} = actionTypes.PURCHASE;

interface IPayload {
  code: string,
  extraUrl: string
}

const headers = (extraUrl: string) => {
  switch (extraUrl) {
    case 'redemption':
      return getRedemptionHeaders();
    case 'recognition':
      return getRecognitionHeaders();
    case 'client-data':
      return getClientDataHeaders();
    default:
      return getPurchaseHeaders();
  }
};

function* sendCode({ payload: { extraUrl }={ code: '', extraUrl: '' } }: IAction<IPayload>) {
  const { ERROR, SUCCESS } = SEND;

  try {
    const url = extraUrl === 'edo'
      ? `${Url.edo.sendCode}`
      : `${Url.purchase.sms.sendCode}/${extraUrl}`;

    yield call(
      api.post,
      { url },
      headers(extraUrl)
    );
    yield put({ type: SUCCESS });
  } catch ({ code, payload }) {
    if (code === 409) {
      yield put({
        type: IS_CONFLICT
      });
    } else if (code !== 500) {
      yield put({
        payload,
        type: ERROR
      });
    } else {
      yield put({
        type: NOT_RESPOND
      });
    }
  }
}

function* checkCode({ payload = { code: '', extraUrl: '' } }: IAction<IPayload>) {
  const { SUCCESS, ERROR } = CHECK;
  try {
    const { code, extraUrl } = payload;
    const url = extraUrl !== 'edo'
      ? `${Url.purchase.sms.checkCode}/${extraUrl}`
      : `${Url.edo.accept}`;
    const { host } = getHost(url);

    const params = {
      host,
      url,
      data: { code }
    };
    // @ts-expect-error
    const result = yield call(api.post, params, headers(extraUrl));
    yield put({ type: SUCCESS });
    /* повторно запрашиваем статус ЭДО клиента, т.к. фактически никакой ответ не приходит
     после отправки смс с подтверждением, что может привести к некорректному UI если статус
    ЭДО не обновится по какой-то причине на бэке */
    if (extraUrl === 'edo') {
      yield put(requestCheckEdo());
    }

    if (result.files) {
      const requisites = result.files[6] !== undefined
        ? result.files[6]
        : result.files[5];
      yield put({
        type: actionTypes.REQUISITES.GET_FILE.SUCCESS,
        payload: { data: { order: { files: [requisites] } } }
      });
    }
  } catch (error) {
    const { code, message } = error;
    if (code === 409) {
      yield put({
        type: IS_CONFLICT
      });
    } else if (code !== 500) {
      yield put({
        payload: { message },
        type: ERROR
      });
    } else {
      yield put({
        type: NOT_RESPOND
      });
    }
  }
}

export function* sms() {
  yield takeLatest(SEND.REQUEST, sendCode);
  yield takeLatest(CHECK.REQUEST, checkCode);
}
