import React from 'react';

import map from 'lodash/map';

import { Icon } from 'assets/img/svg';
import { downloadFile } from 'helpers/downloadFile';

import { IStateProps } from './interfaces';

import S from './styles';

const switchIcon = (extension: string) => {
  switch (extension) {
    case 'xls':
      return (<Icon.FileXLS />);
    case 'doc':
      return (<Icon.FileDOC />);
    case 'PDF':
      return (<Icon.FilePDF />);
    default:
      return null;
  }
};

const fileName = (name: string, extension: string) => {
  return `${name}.${extension}`;
};

export const ReportsTable = ({ items }: IStateProps) => (
  <S.Table>
    {map(items, ({ name, extension, path }, index) => (
      <S.Items key={index}>
        <S.Item className="title">
          {extension && (
            <span className="icon">
              {switchIcon(extension)}
            </span>
          )}
          {name}
        </S.Item>
        {extension && (
          <S.Item className="type">
            {extension}
          </S.Item>
        )}
        <S.Meta>
          <S.Item
            className="download"
            style={{ cursor: 'pointer' }}
            onClick={() => downloadFile(path, fileName(name, extension))}
          >
            <Icon.Download />
          </S.Item>
        </S.Meta>
      </S.Items>
    ))}
  </S.Table>
);
