import React, { useState } from 'react';

import { NumberField, Common } from 'components/shared';
import { Money } from 'entities/money';
import { useStore } from 'components/hooks/useStore';
import { downloadFile } from 'helpers/downloadFile';
import { IWizardForm, IZPIFRequisites } from 'interfaces';

import { PaymentFrame } from './paymentFrame';
import { IPurchaseComponentOwnProps } from './interfaces';
import { Disclaimer } from './disclaimer';

import S from './styles';

// eslint-disable-next-line import/no-cycle
export * from './successForm';

const { ExtraText, DescriptionText } = Common
const {
  InfoWrapper,
  Wrapper,
  DownloadButton,
  Text
} = S

const Purchase = ({ onSubmit }: IPurchaseComponentOwnProps) => {
  const [requisitesCloseFunds, setRequisitesCloseFunds] = useState<IZPIFRequisites>();
  const {
    recommendedProducts,
    purchase: {
      data: {
        productId,
        productName,
        order,
        purchase: { price } = {
          price: 0
        }
      }
    }
  } = useStore()

  const isZpif = productId
  && requisitesCloseFunds
  && (recommendedProducts.byId[productId].typeId === 4
  || recommendedProducts.byId[productId].typeId === 5);

  const handleSubmit = () => onSubmit && onSubmit();
  return (
    <>
      <InfoWrapper>
        <DescriptionText>Вы приобретаете</DescriptionText>
        <ExtraText>{productId !== '35'
          ? productName
          : `паи ЗПИФ комбинированный «${productName}»`}
        </ExtraText>
        <DescriptionText>На сумму</DescriptionText>
        <ExtraText>
          <NumberField value={new Money(price)} />
        </ExtraText>
        {requisitesCloseFunds && isZpif
          ?
            <>
              <Text>Используя реквизиты:</Text>
              <Wrapper>
                <Text>{`Получатель: ${requisitesCloseFunds.name}`}</Text>
                <Text>{`ИНН: ${requisitesCloseFunds.payeeInn}`}</Text>
                <Text>{`КПП: ${requisitesCloseFunds.kpp}`}</Text>
                <Text>{`р/с №: ${requisitesCloseFunds.personalAcc}`}</Text>
                <Text>{`${requisitesCloseFunds.bankName}, БИК ${requisitesCloseFunds.bic},
                к/с ${requisitesCloseFunds.correspAcc}`}
                </Text>
              </Wrapper>
              <Text>{`Назначение платежа: ${requisitesCloseFunds.purpose}`}</Text>
              <DownloadButton
                onClick={() => downloadFile(order?.files[0].file, 'requisites.pdf')}
              >
                Скачать
              </DownloadButton>
            </>
          : null}
      </InfoWrapper>
      {productId && (
      <PaymentFrame
        productId={productId}
        setRequisites={setRequisitesCloseFunds}
        onSuccess={handleSubmit}
        recommendedProducts={recommendedProducts}
      />
      )}
    </>
  );
};

export const acquiring: IWizardForm = {
  title: 'Оплата',
  fields: [],
  url: '',
  hiddenSubmit: true,
  disclaimer: <Disclaimer />,
  formComponentClassName: 'payment-form',
  BeforeFields: ({ onSubmit }) => <Purchase onSubmit={onSubmit} />
};
