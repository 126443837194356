import { getProperty, setProperty } from 'dot-prop';
import reduce from 'lodash/reduce';

import { FormField } from 'entities/formField';
import { IPurchaseData } from 'interfaces';

export interface IFormValues {
  [fieldName: string]: string;
}

export const getInitialValues = (
  fields: FormField[],
  stateData: IPurchaseData
): IFormValues => reduce(
  fields,
  (accumulator: IFormValues, field: FormField) => {
    let initialValue: string | number | boolean;
    const { accumulateStateFields } = field.get();
    if (accumulateStateFields) {
      accumulateStateFields.forEach((fieldName: string, index) => {
        if (index === 0) {
          initialValue = getProperty(stateData, fieldName) || '';
        } else if (fieldName === 'latinName') {
          initialValue += `\n${getProperty(stateData, fieldName) || ''}`;
        } else {
          initialValue += ` ${getProperty(stateData, fieldName) || ''}`;
        }
      });
    } else {
      initialValue = getProperty(stateData, field.name) || field.initialValue;
    }
    // @ts-ignore
    if (initialValue) {
      setProperty(accumulator, field.name, initialValue);
    }
    return accumulator;
  },
  {}
);
