import passport from './passport';
import address from './address';
import name from './name';
import contacts from './contacts';
import inn from './inn';
import birthDetails from './birthDetails';
import checkBoxes from './checkBoxes';

export const formFields = {
  passport,
  address,
  name,
  contacts,
  inn,
  birthDetails,
  checkBoxes
};
