import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

const Toggler = styled.div`
  width: 30px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.border};
  position: relative;
  padding: 2px 0;
  &.right {
    .dot {
      left: calc(100% - 13px);
    }
  }
  .dot {
    left: calc(0% + 3px);
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${theme.colors.link};
    transition: all 0.2s linear;
  }
`;

export default { Toggler };
