import styled from 'styled-components';

const PassportNotification = styled.div`
  display: flex;
  width: 370px;
  svg {
    flex-shrink: 0;
    margin-right: 22px
  }
`;

export default { PassportNotification };
