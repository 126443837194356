import React, { useEffect, useState } from 'react';

import IdleTimer, { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { LogOutTooltip } from 'components/widgets/tooltips/logout';
import { logOutAction } from 'store/actions/actionCreators/logOut';

import { requestCheckEdo } from 'store/reducers/edoExistStatusReducer';
import { requestProducts } from 'store/reducers/recommendedProducts';
import { requestMessages } from 'store/reducers/userMessages';
import { setStatus } from 'store/reducers/view/activeSidebar';
import { requestCurrentStatus } from 'store/reducers/qualifiedStatusData';
import { agreeConfirmationRequest, requestConfirmationCheck } from 'store/reducers/confirmation';
import { getUserInfo } from 'store/actions/actionCreators';
import { requestClientDataStatus } from 'store/reducers/checkAndApproveClientData';
import {
  Sidebar,
  Header,
  Footer
} from 'components/widgets';

import { useStore } from '../../hooks/useStore';
import { ConfirmationTooltip } from '../../widgets/tooltips/confirmation';
import { EdoTooltip } from '../../widgets/tooltips/edo';
import { SmsBeforeNext } from '../../widgets/applicationForms';
import { PurchaseTypes } from '../../../enums/purchaseTypes';

import S from './styles';

const { Wrapper } = S

export const LkPanel = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleActiveSidebar = () => dispatch(setStatus())
  const {
    confirmationExistStatus,
    view: { activeSidebar },
    edoExistStatus: { edoStatus, loaderStatus }
  } = useStore()

  const timeout = 1000 * 60 * 15;
  const dispatch = useDispatch()
  const [notShowEdoPopup, setNotShowEdoPopup] = useState(edoStatus);
  const [isShowSmsPopup, setShowSmsPopup] = useState(false);
  /* useEffect нужен для корректного отображения скролла при подтверждении ЭДО
   и confirmationExistStatus */
  useEffect(() => {
    if (!notShowEdoPopup || !confirmationExistStatus) {
      document.body.style.overflow = 'hidden'
    }
    if (notShowEdoPopup && confirmationExistStatus) {
      document.body.style.overflow = 'visible'
    }
  }, [confirmationExistStatus, notShowEdoPopup])
  useEffect(() => {
    setNotShowEdoPopup(edoStatus);
  }, [edoStatus]);
  useEffect(() => {
    /* получаем список рекомендуемых продуктов */
    dispatch(requestProducts());
    dispatch(requestConfirmationCheck());
    dispatch(requestCheckEdo());
    /* получаем список сообщений для клиента */
    dispatch(requestMessages());
    /* запрос текущего статуса инвестора (квал/не квал) */
    dispatch(requestCurrentStatus());
    dispatch(getUserInfo());
    /* запрашиваем актуальны ли паспортные данные клиента */
    dispatch(requestClientDataStatus());
  }, []);
  useEffect(() => () => {
    dispatch(logOutAction())
  }, [])

  const logOut = () => dispatch(logOutAction())
  const agreeConfirmation = () => dispatch(agreeConfirmationRequest())
  const onIdle = () => {
    try {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      reset();
    } catch (e) {
      console.log(e);
    }
    setShowModal(true);
  };

  const { reset } = useIdleTimer({ timeout, onIdle });
  /* состояние окна logOut - вынесено на этот уровень для blur эффекта фона header-а */
  const [showLogOut, setShowLogOut] = useState(false);

  return (
    <Wrapper data-test="lk-template-component">
      <IdleTimer
        onIdle={() => onIdle()}
        debounce={500}
        timeout={timeout}
      />
      <Sidebar
        isActive={activeSidebar}
        showLogOut={showLogOut}
        toggleSidebar={toggleActiveSidebar}
        setShowLogOut={setShowLogOut}
      />
      <div className="wrapper">
        <div className="header">
          <Header showLogOut={showLogOut} />
        </div>
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
      {showModal &&
      <LogOutTooltip
        isAuto
        isShow
        onLogOut={logOut}
        onCancel={logOut}
      />}
      {!notShowEdoPopup && !loaderStatus
      && <EdoTooltip isShow onCancel={() => setShowSmsPopup(true)} />}
      {!confirmationExistStatus &&
      <ConfirmationTooltip
        isAuto
        isShow
        onCancel={() => null}
        agreeConfirmation={agreeConfirmation}
      />}
      {isShowSmsPopup &&
      <SmsBeforeNext
        isShow
        type={PurchaseTypes.edo}
        onCancel={() => { setShowSmsPopup(false); setNotShowEdoPopup(false) }}
        onSubmit={() => { setShowSmsPopup(false); setNotShowEdoPopup(true) }}
      />}
    </Wrapper>
  );
};
