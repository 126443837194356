import { IField } from 'interfaces';

const lastName: IField = {
  name: 'lastName',
  type: 'text',
  placeholder: 'Фамилия',
  initialValue: ''
};
const firstName: IField = {
  name: 'firstName',
  type: 'text',
  placeholder: 'Имя',
  initialValue: ''
};

const patronymic: IField = {
  name: 'patronymic',
  type: 'text',
  placeholder: 'Отчество',
  initialValue: ''
};

const name = { firstName, lastName, patronymic };
export default name;
