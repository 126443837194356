import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import includes from 'lodash/includes';
import size from 'lodash/size';
import { toNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { MultiStepWizard } from 'components/widgets';
import { duProductsId } from 'constants/duProductsIdAndName';
import { actionCreator } from 'helpers/actions/actionCreator';
import { actionTypes } from 'store/actionTypes';
import { IStepForm } from 'components/widgets/multistepWizard/reducer/interfaces';
import { esia } from 'components/widgets/applicationForms';
import { steps as purchaseSteps } from 'components/widgets/multistepWizard/steps';
import { saleForms, saleSteps } from 'components/widgets/applicationForms/sale';
import { stringToDate } from 'helpers/date/';
import { opifPurchase, opifPurchaseWithIisCheck } from 'components/widgets/applicationForms/opif';
import { LoaderTooltip } from 'components/widgets/tooltips/loader';
import { requestProducts } from 'store/reducers/recommendedProducts';
import { requestUserProducts } from 'store/reducers/products';

import { addForms, addSteps } from '../../widgets/applicationForms/add';
import { SuspenseWorksUser } from '../errors/suspenseOfOperations';
import { useStore } from '../../hooks/useStore';

import S from './styles';

export const Purchase = () => {
  const {
    userProducts,
    qualifiedStatusData: {
      qualifiedStatus: {
        status: qualifiedStatus
      }
    },
    purchase: {
      data: { productName, order },
      request: { rebuy: { success, loading } },
      esiaAuth,
    },
    recommendedProducts: productsRecommended,
  } = useStore()
  const [goto, setGoto] = useState<IStepForm | undefined>(undefined);

  const dispatch = useDispatch()
  const wizardRef = useRef<HTMLDivElement>(null);

  const {
    action = '',
    productType = '',
    productId = ''
  } = useParams()
  const products = action === 'sale' || action === 'add'
    ? userProducts
    : productsRecommended;
  const isDu = !!productId && includes(duProductsId, productId) && productId !== '35';
  const isIis = !!productId && +productId < 4;
  useEffect(() => {
    dispatch(actionCreator(actionTypes.PURCHASE.RANGE_PRICE.REQUEST, productId));
    const productOnce = products.byId[productId];
    const productNumber = productOnce
      !== undefined
      && productOnce !== null
      && isDu
      ? productOnce.productNumber
      : '';
    dispatch(actionCreator(actionTypes.REBUY.GET_INFO.REQUEST, productNumber || productId))
  }, [productId]);

  useEffect(() => {
    if (!size(products.ids) && !products.fetched) {
      (() => (action === 'sale' || action === 'add'
        ? dispatch(requestUserProducts())
        : dispatch(requestProducts())))();
    } else {
      const product = products.byId[productId];
      const name = product && product.name;
      dispatch(
        actionCreator(actionTypes.PURCHASE.SET_PARAMS, {
          data: {
            productId,
            type: productType,
            productName: name,
            action
          }
        })
      );
    }
  }, [products]);

  useEffect(() => {
    if (esiaAuth) {
      opifPurchase[1][1] = esia;
      setGoto({
        currentStep: 1,
        currentForm: 1
      });
    }
  }, [esiaAuth]);
  /* получаем список актуальных продуктов */
  const { ids: availableProductsId } = productsRecommended
  /* ждем ответа на запрос REBUY.GET_INFO.REQUEST + проверяем доступен ли сейчас продукт */
  if (loading || !includes(availableProductsId, toNumber(productId))) {
    return <LoaderTooltip isShow description="Пожалуйста, подождите..." />
  }
  /* заглушка Рентавед для редиректа из адресной строки */
  if (productId === '35' && !qualifiedStatus) {
    return <Navigate to="/portfolio" />;
  }
  /* проверяем есть ли продукт у клиента */
  const isProductExist = order ? order.number : false
  /* 2 нижние проверки для редиректа, success отвечает за статус ответа REBUY.GET_INFO */
  if ((!isProductExist || productId === '35' || !success) && action === 'add') {
    return <Navigate to={`/products/invest/${productId}/purchase/1/1`} replace />;
  }
  if (isProductExist && success && action === 'purchase') {
    return <Navigate to={`/products/invest/${productId}/add/1/1`} replace />;
  }
  const actionForms = {
    sale: {
      title: 'Продажа инвестиционных паев',
      steps: saleSteps,
      forms: saleForms
    },
    purchase: {
      title: isDu
        ? 'Приобретение продуктов доверительного управления '
        : productId !== '35'
          ? 'Приобретение инвестиционных паев'
          : 'Приобретение паев ЗПИФ комбинированный',
      steps: purchaseSteps,
      forms: isIis
        ? opifPurchaseWithIisCheck
        : opifPurchase
    },
    add: {
      title: isDu
        ? 'Внесение средств в портфель стратегии '
        : productId !== '35'
          ? 'Приобретение инвестиционных паев'
          : 'Приобретение паев ЗПИФ комбинированный',
      steps: addSteps,
      forms: addForms
    }
  }[action];

  const getHoldDate = () => {
    const product = productsRecommended.byId[productId];
    if (product) {
      const holdsTime = product.holds;
      if (holdsTime) {
        const currentHold = holdsTime[action];
        if (currentHold) {
          return stringToDate(currentHold.to, 'YYYY.MM.DD');
        }
      }
    }
    return null;
  };

  const holdDate = getHoldDate();

  const getMessage = () => {
    const product = productsRecommended.byId[productId];
    if (product) {
      const { holds } = product;
      if (holds) {
        const currentHold = holds[action];
        return currentHold.message;
      }
    }
    return {
      event: '',
      head: '',
      body: '',
      link: ''
    };
  };

  let ProductTitle;
  if (productName !== undefined) {
    switch (productName) {
      case '"Российские акции - баланс"':
        ProductTitle = productName.slice(1, -1);
        break;
      case '"Российские акции - риск"':
        ProductTitle = productName.slice(1, -1);
        break;
      case '"Американские акции - баланс"':
        ProductTitle = productName.slice(1, -1);
        break;
      case '"Американские акции - риск"':
        ProductTitle = productName.slice(1, -1);
        break;
      default:
        ProductTitle = productName;
    }
  }
  return (
    holdDate
      ? (
        <SuspenseWorksUser
            dateOfCompletion={getHoldDate() || new Date()}
            message={getMessage()}
        />)
      : (
        <S.Purchase data-test="purchase-page" ref={wizardRef}>
          {actionForms
            ? (
              <MultiStepWizard
                  steps={actionForms.steps}
                  forms={actionForms.forms}
                  title={`${actionForms.title} ${
                    productName
                      ? `«${ProductTitle}»`
                      : ''
                  }`}
                  goto={goto}
              />)
            : null}
        </S.Purchase>
      )
  );
};
