import React from 'react';

import { Sms } from '../../sms/purshaseSms';
import { IProps } from './interfaces';
import { CommonTooltip } from '../../../shared';

import { Title } from './styles';

export const SmsTooltip = ({
  tooltipProps,
  smsProps,
  nextPage,
  currentReducer
}: IProps) => (
  <CommonTooltip {...tooltipProps}>
    <Title>Ввод кода из СМС</Title>
    <Sms
      {...smsProps}
      nextPage={nextPage}
      showCancelButton
      currentReducer={currentReducer}
    />
  </CommonTooltip>
);
