import React from 'react';

import { getProperty } from 'dot-prop';
import size from 'lodash/size';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';

import { getFieldError } from 'helpers/form';
import { Change } from 'types/inputEvent';
import { IField } from 'interfaces';

import { IComponentState, IProps } from './interfaces';

import S from '../styles';

export class Field extends React.Component<IProps, IComponentState> {
  state = {
    FieldComponent: this.props.field.getView(),
    options: this.props.field.get()
  };

  // eslint-disable-next-line react/sort-comp
  get value(): string {
    const { options: { name } } = this.state
    const { formProps: { values } } = this.props
    return getProperty(values, name);
  }

  get error(): string {
    const {
      formProps: { errors, touched },
      errors: purchaseErrors
    } = this.props
    const { options: { name } } = this.state
    return getFieldError(
      name,
      errors,
      touched,
      purchaseErrors
    );
  }

  componentDidUpdate() {
    this.updateOptions();
  }

  updateOptions = () => {
    const { field } = this.props
    const options = field.get();
    const prevOptions = this.state.options;

    const diffOptions = getDiffOptions(options, prevOptions);
    if (size(diffOptions)) {
      const newOptions = reduce(diffOptions, (accumulate: IField, key) => {
        accumulate[key] = options[key];
        return accumulate;
      }, prevOptions);
      this.setState({
        options: newOptions,
        FieldComponent: field.getView()
      });
    }
  }

  handleChange = (event: Change) => {
    const {
      formProps: { handleChange },
      field,
      field: { type },
      onChangeCallback
    } = this.props
    if ((type === 'checkbox' || type === 'radio') && onChangeCallback) {
      onChangeCallback(field.get());
    }
    handleChange(event);
  }

  render() {
    const {
      halfWidth,
      separatorAfter,
      disabled,
      groupTitle,
      groupDescription,
      invisible
    } = this.state.options;
    const { FieldComponent } = this.state;
    const {
      formProps: {
        handleBlur,
        setFieldTouched,
        errors
      },
      field: { name }
    } = this.props
    return (
      <>
        {!invisible && (
          <S.FieldContainer halfWidth={halfWidth}>
            {groupTitle &&
            <S.GroupTitle>
              {groupTitle}
            </S.GroupTitle>}
            {groupDescription &&
            <S.GroupDescription>
              {groupDescription}
            </S.GroupDescription>}
            <FieldComponent
              onChange={this.handleChange}
              onBlur={handleBlur}
              value={this.value}
              error={this.error}
              disabled={disabled}
              setFieldTouched={setFieldTouched}
              // @ts-expect-error
              fieldError={getProperty(errors, name)}
            />
            {separatorAfter && <S.Separator />}
          </S.FieldContainer>
        )}
      </>
    );
  }
}

function getDiffOptions(options: IField, prevOptions: IField): string[] {
  return reduce(keys(options), (accumulate: string[], key) => {
    if (options[key] !== prevOptions[key]) {
      accumulate.push(key);
    }
    return accumulate;
  }, []);
}
