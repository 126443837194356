import React, { useEffect, useState } from 'react';

import clone from 'lodash/cloneDeep';
import map from 'lodash/map';
import { v4 as uuid } from 'uuid';

import { IProduct } from 'interfaces';
import { Icon } from 'assets/img/svg';
import {
  ProductTable as ProductTableType,
  IColumn
} from 'entities/productTable';

import Table from '../table';
import { ISortTable, IOwnProps } from './interfaces';
// eslint-disable-next-line import/no-cycle
import { ProductRow } from './ProductRow';

export const ProductTable = ({
  isTrustFunds,
  products
}: IOwnProps) => {
  const columns = ProductTableType.getColumns();

  const [productsData, setRows] = useState<IProduct[]>(products);
  const [sort, setSort] = useState<ISortTable>({
    selected: '',
    isReverse: false
  });

  const { selected, isReverse } = sort;

  useEffect(() => {
    setRows(sortProducts());
  }, [sort]);

  const sortProducts = () => clone(products).sort(ProductTableType.compareSort(sort));

  const handleSort = (accessor: string) => {
    if (selected === accessor) {
      if (!isReverse) {
        reverseSort();
      } else {
        cleanSort();
      }
    } else {
      setSelectedSort(accessor);
    }
  };

  const cleanSort = () => {
    setSort({
      ...sort,
      selected: '',
      isReverse: false
    });
  };

  const reverseSort = () => {
    setSort({
      ...sort,
      isReverse: true
    });
  };

  const setSelectedSort = (accessor: string) => {
    setSort({
      ...sort,
      selected: accessor,
      isReverse: false
    });
  };
  const columnIsActive = (accessor: string): boolean => selected === accessor;

  return (
    <Table.Wrapper>
      <Table.Header>
        <Table.Row>
          {map(columns, (({ accessor, Header }: IColumn) => (
            <Table.Cell
                key={uuid()}
                onClick={() => handleSort(accessor)}
                active={columnIsActive(accessor)}
                isReverse={isReverse}
            >
              {Header}
              <Icon.Sort />
            </Table.Cell>
          )))}
          <Table.Cell className="small" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {map(productsData, ((product:IProduct) => <ProductRow
          isTrustFunds={isTrustFunds}
          product={product}
          columns={columns}
          key={uuid()}
        />))}
      </Table.Body>
    </Table.Wrapper>
  );
};
