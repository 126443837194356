import { combineReducers } from 'redux';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';

import { actionTypes } from 'store/actionTypes';
import { getProductLink, getProductRequisites } from 'staticContent/getProductLink';
import {
  IAction,
  IPurchaseData,
  IPurchaseErrors,
  IRequest,
  QuestionnaireTypes
} from 'interfaces';

// eslint-disable-next-line import/no-cycle
import { smsReducer } from './sms';

const {
  SUCCESS,
  ERROR,
  REQUEST,
  RESET_SUCCESS,
} = actionTypes.PURCHASE.OPIF;
const {
  SUCCESS: REBUY_SUCCESS,
  ERROR: REBUY_ERROR,
  REQUEST: REBUY_REQUEST
} = actionTypes.REBUY.GET_INFO;
const { REQUEST_RESET_DATA } = actionTypes.PURCHASE;

export const initialState: IPurchaseData = {
  firstName: '',
  price: 250000,
  inn: '',
  lastName: '',
  latinName: '',
  email: '',
  phone: '',
  dayBirth: '',
  placeBirth: '',
  type: '',
  passport: {
    series: '',
    number: '',
    issuesPlace: '',
    issuesDate: '',
    unitCode: ''
  },
  addressRegistration: {
    addressString: '',
    country: '',
    postcode: '',
    region: '',
    regionalDistrict: '',
    city: '',
    street: '',
    house: {
      number: '', block: '', building: '', flat: ''
    }
  },
  requisites: {
    bankName: '',
    rs: '',
    ks: '',
    bik: ''
  },
  order: {
    date: '',
    number: '',
    files: {
      niceName: '',
      file: ''
    }
  },
  productRequisites: '',
  productDocs: '',
  productId: '',
  productName: '',
  action: '',
  patronymic: '',
  fio: '',
  addressPost: {
    addressString: ''
  },
  applicantId: '',
  accountNumber: '',
  pieValue: 0,
  existIISOtherCompany: null
};

const data = (
  state: IPurchaseData = initialState,
  action: IAction
): IPurchaseData => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CLIENT_INFO.SUCCESS:
    case actionTypes.PURCHASE.SET_USER_PARAMS.SUCCESS:
    case actionTypes.PURCHASE.SET_ADDRESS:
    case actionTypes.PURCHASE.SET_PARAMS:
    case actionTypes.REBUY.GET_INFO.SUCCESS:
    case actionTypes.PURCHASE.RANGE_PRICE.SUCCESS:
    case actionTypes.REQUISITES.GET_FILE.SUCCESS:
    case SUCCESS: {
      if (payload) {
        const { data } = payload as { data: IPurchaseData };
        const productName = data.productName
          ? data.productName.trim()
          : '';
        const productDocs = getProductLink(productName);
        const productRequisites = getProductRequisites(productName);

        data.productRequisites = productRequisites || '';
        data.productDocs = productDocs || data.productDocs;
        return merge(cloneDeep(state), data);
      }
      return state;
    }
    /* в данном кейсе удаляем свойства, которые поялвяются после отработки
    экшена PURCHASE_OPIF_SUCCESS, в противном случае неверно работают
    проверки в формике (disabled кнопки) по маршруту
    покупка - запрос смс - выход назад - докупка */
    case actionTypes.PURCHASE.REQUEST_RESET_DATA: {
      const newState = { ...state }
      for (const keys in newState) {
        const shouldDeletedKeys = ['investSum', 'purchase', 'passportSeriesNumber', 'gender']
        if (includes(shouldDeletedKeys, keys)) { delete newState[keys] }
      }
      return newState
    }
    case actionTypes.PURCHASE.USER_DOCS.GET.SUCCESS: {
      if (payload) {
        return merge(cloneDeep(state), { userDocs: payload });
      }
    }
    case actionTypes.REDEMPTION.GET.SUCCESS: {
      if (payload) {
        return merge(cloneDeep(state), { userDocs: payload });
      }
    }
    default:
      return state;
  }
};

const errors = (
  state: IPurchaseErrors = {},
  action: IAction<IPurchaseErrors>
): IPurchaseErrors => {
  const { type, payload } = action;
  switch (type) {
    case ERROR: {
      return payload || {};
    }
    default:
      return state;
  }
};

type TCombineRequest = IRequest & { rebuy: IRequest };

export const requestInitialState: TCombineRequest = {
  success: false,
  fail: false,
  loading: false,
  rebuy: {
    success: false,
    fail: false,
    loading: true
  }
};
const request = (
  state: TCombineRequest = requestInitialState,
  action: IAction<{ requestId: string }>
): TCombineRequest => {
  const { type, payload } = action;

  const initialState = {
    ...state,
    requestId: payload ? payload.requestId : ''
  };

  switch (type) {
    case SUCCESS:
      return {
        ...initialState,
        success: true,
        loading: false
      };
    case REQUEST:
      return {
        ...initialState,
        loading: true
      };
    case ERROR:
      return {
        ...initialState,
        fail: true,
        success: false,
        loading: false
      };
    case RESET_SUCCESS:
      return {
        ...initialState,
        success: false,
        loading: false
      };
    case REBUY_SUCCESS:
      return {
        ...initialState,
        rebuy: {
          ...initialState.rebuy,
          fail: false,
          success: true,
          loading: false
        }
      };
    case REBUY_REQUEST:
      return {
        ...initialState,
        rebuy: {
          ...initialState.rebuy,
          loading: true
        }
      };
    case REBUY_ERROR:
      return {
        ...initialState,
        rebuy: {
          ...initialState.rebuy,
          success: false,
          fail: true,
          loading: false
        }
      };
    case REQUEST_RESET_DATA:
      return {
        ...initialState,
        success: false,
        fail: false,
        loading: false,
        rebuy: {
          ...initialState.rebuy,
          success: false,
          fail: false,
          loading: true
        }
      };
    default:
      return state;
  }
};

const type = (
  state: QuestionnaireTypes = '',
  action: IAction<{ data: { type: QuestionnaireTypes } }>
): QuestionnaireTypes => {
  switch (action.type) {
    case actionTypes.PURCHASE.SET_PARAMS:
      return action.payload
        ? action.payload.data.type
        : state;
    default: {
      return state;
    }
  }
};

const notRespond = (state = false, { type }: IAction): boolean => {
  if (type === actionTypes.PURCHASE.NOT_RESPOND) return true;
  if (type === actionTypes.PURCHASE.RESET_NOT_RESPOND) return false;
  return state;
};

const notRebuy = (state = false, { type }: IAction): boolean => {
  if (type === actionTypes.REBUY.NOT_REBUY) return true;
  return state;
};

const isConflict = (state = false, { type }: IAction): boolean => {
  if (type === actionTypes.PURCHASE.IS_CONFLICT) return true;
  return state;
};

const terroristStatus = (
  state: boolean | null = null,
  { type }: IAction
): boolean | null => {
  const { ERROR, SUCCESS } = actionTypes.PURCHASE.CHECK_TERRORIZM;
  switch (type) {
    case ERROR:
      return false;
    case SUCCESS:
      return true;
    default:
      return state;
  }
};

const shareCount = (state = 0, action: IAction) => {
  if (action.type === actionTypes.SET_SHARE_COUNT) {
    return action.payload || 0;
  }
  return state;
};

export const purchaseReducer = combineReducers({
  data,
  errors,
  request,
  type,
  terroristStatus,
  notRespond,
  notRebuy,
  isConflict,
  shareCount,
  sms: smsReducer
});
