import React, { useEffect, useState } from 'react';

import map from 'lodash/map';
import includes from 'lodash/includes';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Style from 'components/widgets/form/styles';
import { Common } from 'components/shared';
import { IRecognitionSubmitData } from 'interfaces';
import { getSubmitData } from 'helpers/getSubmitData';
import Arrow from 'assets/img/svg/arrow.svg';
import { PHONE_LINK } from 'constants/phone';
import {
  acceptedNowReset,
  approveRequest,
  dataSmsCheckResetFail, dataSmsCheckResetRequest
} from 'store/reducers/checkAndApproveClientData';

import { TextField } from '../../shared/input/textField';
import { SmsBeforeNext } from '../../widgets/applicationForms';
import { PurchaseTypes } from '../../../enums/purchaseTypes';
// tslint:disable-next-line:max-line-length
import { DescriptionApproveClientData } from '../../widgets/applicationForms/checkData/sms/description';
import Kval from '../kval/styles';
import { ServiceError } from '../../widgets/applicationForms/error/serviceError';
import { useStore } from '../../hooks/useStore';
import Styles from '../purchase/styles';
import { LoaderAndCap } from '../../shared';
import { title } from './tittle';
import { WrapperTooltip } from '../../shared/wrapperTooltip/wrapperTooltip';

import S from './styles';

const { TextResult } = Kval
const { Purchase } = Styles
const {
  Wrapper,
  ClientDataPanel,
  Description,
  ButtonWrapper,
  Text
} = S

const { LinkStyled, ButtonLarge } = Common
export const ClientData = () => {
  const {
    checkOfClientData: {
      submitSuccess,
      status,
      isAcceptedNow,
      sms: {
        check: {
          error: { message },
          request: {
            success
          }
        }
      },
    },
    purchase: {
      data: {
        dayBirth,
        fio,
        placeBirth,
        inn,
        passport: {
          number,
          series,
          issuesPlace,
          issuesDate,
          unitCode
        } = {
          number: '',
          series: '',
          issuesPlace: '',
          issuesDate: '',
          unitCode: ''
        },
      }
    }
  } = useStore()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const resetSMS = () => {
    dispatch(dataSmsCheckResetRequest());
  }
  const resetFail = () => {
    dispatch(dataSmsCheckResetFail())
  }
  const submitApprove = (submitData: IRecognitionSubmitData) => {
    dispatch(approveRequest(submitData))
  }

  useEffect(() => () => {
    /* диспатчим экшн чтобы сбросить флаг, что клиент сейчас подтверждает свои данные */
    dispatch(acceptedNowReset())
    resetFail();
  }, []);
  const [isShowSMSWindow, setIsShowSMSWindow] = useState(false);
  const confirm = () => {
    submitApprove(getSubmitData());
    setIsShowSMSWindow(true);
  };

  const userData = [
    fio,
    dayBirth,
    placeBirth,
    `${series} ${number}`,
    issuesPlace,
    issuesDate,
    unitCode,
    inn
  ];
  /* текущий текст сообщения:
   1) для случая когда клиент сейчас подтвердил данные
   2) если данные подтверждены и клиент повторно заходит на эту страницу */
  const currentText = isAcceptedNow
    ?
      <>
        Благодарим Вас за актуализацию сведений.<br />
        Подтверждение анкетных данных было успешно осуществлено.
      </>
    : `Уважаемый клиент! С Вашей стороны подтверждение анкетных
       данных уже было осуществлено и на данный момент повторно не требуется.`

  if (status) {
    return (
      <LoaderAndCap icon={Arrow} text>
        {currentText}
      </LoaderAndCap>
    );
  }

  const isShowErrorWindow = message !== ''
  && message !== 'Введен неверный код'
  && message !== 'Срок действия кода истек';
  const isShowStatus = !success && submitSuccess && isShowSMSWindow;
  return (
    <Wrapper>
      <Purchase>
        <ClientDataPanel>
          {includes(userData, '')
            ? <LoaderAndCap isLoader />
            :
            <>
              <Style.GroupTitle>
                Проверьте Ваши данные
              </Style.GroupTitle>
              {map(userData, (currentData, index) => (
                <React.Fragment key={uuid()}>
                  <Description>{title[index]}</Description>
                  <TextField isCheckClientData value={currentData} />
                </React.Fragment>
              ))}
              <Text>Я подтверждаю, что указанные выше сведения не изменились.</Text>
              <ButtonWrapper>
                <ButtonLarge onClick={confirm}>Подтвердить</ButtonLarge>
              </ButtonWrapper>
              <Text>
                Если Ваши сведения изменились, свяжитесь с Управляющей компанией
                по телефону {PHONE_LINK}.
              </Text>
            </>}
        </ClientDataPanel>
      </Purchase>
      {isShowStatus &&
      <SmsBeforeNext
        onCancel={() => {
          resetFail();
          setIsShowSMSWindow(false);
        }}
        isShow
        description={() => <DescriptionApproveClientData />}
        type={PurchaseTypes.client_data_check}
      />}
      {success &&
        <WrapperTooltip
        showCloseButton
        isShow
        onClose={() => {
          resetSMS();
          navigate('/portfolio');
        }}
        >
          <TextResult>
            Данные успешно подтверждены
          </TextResult>
          <LinkStyled
            onClick={resetSMS}
            to="/portfolio"
          >
            Продолжить работу с личным кабинетом
          </LinkStyled>
        </WrapperTooltip>}
      {isShowErrorWindow && <ServiceError isShow />}
    </Wrapper>
  );
};
