import React from 'react';

import { LoginHeader, Footer } from 'components/widgets';
import { useStore } from 'components/hooks/useStore';

import Styles from '../../login/styles';
import { IErrorGuest } from './interfaces';

import S from './styles';

const { LoginPageWrapper } = Styles
const { ErrorPanel } = S

export const ErrorGuest = ({ children }: IErrorGuest) => {
  const { view: { activeSidebar } } = useStore()
  return (
    <LoginPageWrapper data-test="login-page">
      <LoginHeader />
      <ErrorPanel activeSidebar={activeSidebar}>{children}</ErrorPanel>
      <Footer />
    </LoginPageWrapper>
  )
};
