import styled from 'styled-components';

const SidebarLogo = styled.section`
  cursor: pointer;
  margin: 0 -30px;
  height: 127px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  svg {
    transform: scale(1.2);
  }
`;

export default { SidebarLogo };
