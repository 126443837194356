import React, { useEffect, useState } from 'react';

import throttle from 'lodash/throttle';

import { formatDate } from 'helpers/date/format';
import { Icon } from 'assets/img/svg';
import { getFakeEvent } from 'helpers/field/getFakeEvent';
import { Change } from 'types/inputEvent';
import { useStore } from 'components/hooks/useStore';

import { Runner } from '.';
import ShareCountSumHint from '../input/shareCount/styles';
import { IInputProps } from '../input/intefaces';
import { ILimits } from './interfaces';
import { Common } from '..';

interface IProps extends IInputProps {
  // eslint-disable-next-line react/no-unused-prop-types
  value?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  step?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  placeholder?: string | JSX.Element;
}
const { ExtraText, Panel } = Common
const { Question } = Icon

type RunnersType = 'pie' | 'money';

const PIE_ROUND = 5;

export const PieCountRunner = ({
  error,
  name,
  onChange
}: IProps) => {
  const {
    purchase: { data: { productId, pieValue: storePieValue } },
    userProducts: { byId },
    account: { dateBorders }
  } = useStore()

  const product = productId && byId[productId];

  const shareCount = product
    ? product.shareCount
      ? +product.shareCount
      : 0
    : 0;

  const pieValue = product
    ? product.shareCount
      ? storePieValue
        ? +storePieValue
        : 0
      : 0
    : 0;

  const lastDay = dateBorders
    ? dateBorders.to
    : undefined;

  const [value, setValue] = useState(shareCount < 1 ? shareCount : 1);
  const [moneyValue, setMoneyValue] = useState(pieValue);

  const moneyLimits = {
    min: pieValue,
    max: shareCount < 1 ? pieValue : pieValue * shareCount
  };
  const limits = {
    min: Math.min(shareCount, 1),
    max: shareCount
  };

  useEffect(() => {
    const changeFormValue = () => {
      return throttle(() => {
        if (onChange && name) {
          const event = getFakeEvent(+value.toFixed(PIE_ROUND), name);
          onChange(event as Change);
        }
      }, 250);
    }
    changeFormValue()
  }, []);

  function handleChange(newValue: number, type: RunnersType) {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'money': {
        updateValue(newValue, setMoneyValue, moneyLimits, type);
        const newPieValue = newValue / pieValue;

        updateValue(newPieValue, setValue, limits, 'pie');
        break;
      }
      case 'pie': {
        const updatedValue = updateValue(newValue, setValue, limits, type);
        const newMoneyValue = updatedValue * pieValue;

        updateValue(newMoneyValue, setMoneyValue, moneyLimits, 'money');
        break;
      }
    }
  }

  function updateValue(
    newValue: number,
    setter: (newValue: number) => void,
    { min, max }: ILimits,
    type: RunnersType
  ) {
    const roundedValue = type === 'pie'
      ? newValue > Math.floor(max) - 1
        ? max
        : Math.round(newValue)
      : newValue > max - (max % pieValue) - pieValue
        ? max
        : Math.round(newValue / pieValue) * pieValue;

    const correctedValue =
      (newValue < 1 && min >= 1)
        ? Math.ceil(newValue)
        : roundedValue;
    setter(correctedValue);
    return correctedValue;
  }

  const step = shareCount < 1 ? 0 : 0.0001;

  return (
    <>
      <Runner
        label="Количество паев на продажу"
        value={value}
        limits={limits}
        onChange={(newValue) => handleChange(newValue, 'pie')}
        displayingMax={shareCount.toFixed(PIE_ROUND)}
        step={step}
        displayingValue={+value.toFixed(PIE_ROUND)}
        error={error}
        tooltip={<PieRunnerTooltip lastDay={lastDay} />}
      />
      <Runner
        label="Или сумма"
        value={moneyValue}
        step={step}
        limits={moneyLimits}
        onChange={(newValue) => handleChange(newValue, 'money')}
        displayingValue={`${moneyValue.toLocaleString('ru-Ru', { minimumFractionDigits: 2 })} ₽`}
        displayingMax={`${(shareCount * pieValue).toLocaleString('ru-Ru')} ₽`}
        error={error}
      />
    </>
  );
};

const PieRunnerTooltip = ({ lastDay = new Date() }) => (
  <ShareCountSumHint>
    <Question />
    <Panel>
      <ExtraText>
        Стоимость инвестиционных паев указана на{' '}
        {formatDate(lastDay, 'DD.MM.YYYY ')}в информационных целях и
        будет отличаться от стоимости инвестиционных паев на дату, по
        состоянию на которую будут погашены инвестиционные паи.
      </ExtraText>
    </Panel>
  </ShareCountSumHint>
);
