import React, { useState } from 'react';

import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import { getProductTitle } from 'staticContent/getProductTitle';
import { getProductActionLink } from 'helpers/products';
import { FieldTypes } from 'enums';
import { Icon } from 'assets/img/svg';

import Table from '../table';
// eslint-disable-next-line import/no-cycle
import {
  CommonTooltip,
  NumberField,
  Common
} from '../../shared';
import { IGetProductRow } from './interfaces';

import S from '../table/options/styles';

const { MainText } = Common
const { TableOption, TableOptions } = S

const Text = styled.div``;

export const ProductRow = ({
  product,
  columns,
  isTrustFunds
}: IGetProductRow) => {
  const {
    id,
    name,
    cost,
    part,
    result,
    type
  } = product;

  const [showTooltip, setShowTooltip] = useState(false);

  const productName = getProductTitle(id);
  const navigate = useNavigate()
  const reBuy = () => navigate(`${getProductActionLink(id, 'add')}`);
  const sell = () => navigate(`${getProductActionLink(id, 'sale')}`);
  return (
    <Link to={`/products/${id}`}>
      <Table.Row>
        <Table.Cell className="product-name">{productName || name}</Table.Cell>
        <Table.Cell>
          <Text className="product-label">{columns[1].Header}</Text>
          <NumberField value={cost} />
        </Table.Cell>
        <Table.Cell>
          <NumberField value={part} />
        </Table.Cell>
        <Table.Cell>
          <Text className="product-label">{columns[3].Header}</Text>
          {result &&
            <MainText className="show-tablet">
              <NumberField value={result.value} type={FieldTypes.common} colorize />
              <Table.CellSeparator> / </Table.CellSeparator>
            </MainText>}
          {result &&
            <NumberField
              value={result.percents}
              type={FieldTypes.common}
              colorize
            />}
        </Table.Cell>
        <Table.Cell>
          {result && <NumberField value={result.value} type={FieldTypes.common} colorize />}
        </Table.Cell>
        <Table.Cell className="small" isVisible={type === '5'}>
          <TableOptions
            data-test="product-options-component"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowTooltip(true);
            }}
          >
            <Icon.TableOptions />
            {showTooltip &&
            <CommonTooltip
              notBlur
              isShow
              onClose={() => setShowTooltip(false)}
            >
              {isTrustFunds
                ?
                  <TableOption role="button" onClick={reBuy}>
                    Пополнить
                  </TableOption>
                :
                  <>
                    <TableOption role="button" onClick={reBuy}>
                      Докупить
                    </TableOption>
                    <TableOption role="button" onClick={sell}>
                      Продать
                    </TableOption>
                  </>}
            </CommonTooltip>}
          </TableOptions>
        </Table.Cell>
      </Table.Row>
    </Link>
  );
};
