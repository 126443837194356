import React from 'react';

import { isDev } from 'helpers/isDev';

import { IDatepickerLabel } from './interfaces';

import { StylesDatepickerLabel } from './styles';

const DatepickerLabel = ({ onClick, children }: IDatepickerLabel) => (
  <button
      type="button"
      tabIndex={0}
      onClick={onClick}
      data-test="datepicker-label"
      className="datepicker-label"
      style={{ cursor: isDev ? 'pointer' : 'default' }}
  >
    <StylesDatepickerLabel>{children}</StylesDatepickerLabel>
  </button>
);
export { DatepickerLabel, StylesDatepickerLabel };
