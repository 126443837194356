import { IRequestParams } from 'api';
import { IDateRange } from 'interfaces';

import { formatDate, compareDates } from '../date';

export const getDateRangeParams = (
  range: IDateRange | undefined | { id: string } | {},
  url: string
): IRequestParams => {
  const params: IRequestParams = { url };

  if (range && 'from' in range && range.from && range.to) {
    let { from } = range;
    const { to } = range;

    if (!compareDates(from, to)) {
      from = new Date(from);
      from.setDate(from.getDate() - 1);
    }

    params.urlParams = {
      from: formatDate(from),
      to: formatDate(to)
    };
  }

  return params;
};
