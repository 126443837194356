import styled from 'styled-components';

import StylesRadioButton from 'components/shared/radioButton/styles';
import StylesDatepickerNavbar from 'components/shared/datepicker/navBar/styles';
import { media } from 'assets/styles/theme';
import FinResStyle from 'components/shared/commonStyles/finResult/styles';

import StylesDatepicker from '../../datepickers/header/styles';

interface IItemProps {
  hideOnMobile?: boolean;
}
const { FinResult } = FinResStyle

const Item = styled.div<IItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: 0 30px;
  border-left: 1px solid #f9f8f6;
  ${media.tablet} {
    ${({ hideOnMobile }) => (hideOnMobile ? 'display:none;' : '')}
  }

  &:first-child {
    border-left: none;
  }

  ${media.tablet} {
    width: 33%;
    min-width: 180px;
    margin-bottom: 15px;
    border: none;
    padding: 0;

    &:nth-child(2) {
      order: 4;
    }
    &:nth-child(3) {
      order: 5;
    }
    &:last-child {
      order: 6;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ${media.mobile} {
    width: 100%;
    max-width: none;
    margin-right: 0;

    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.06px;
    line-height: 18px;
    color: #a8a099;
    margin-bottom: 14px;
    white-space: nowrap;
    ${media.tablet} {
      margin-bottom: 4px;
    }

    ${media.mobile} {
      font-size: 11px;
      max-width: 40%;
    }
  }

  .value {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.41px;
    line-height: 22px;

    ${media.mobile} {
      font-size: 15px;
      max-width: 55%;

      ${FinResult} {
        flex-direction: column;
        text-align: right;

        span {
          display: none;
        }

        div:last-child {
          font-size: 14px;
        }
      }
    }

    ${StylesDatepicker.HeaderDatepicker} {
      font-weight: normal;
      font-size: 13px;

      ${media.mobile} {
        justify-content: flex-end;
      }

      & button {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.41px;
        line-height: 22px;
      }

      svg {
        margin-left: 10px;
      }

      ${StylesRadioButton.RadioButton} {
        label {
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            font-size: 13px;
            font-weight: normal;
            outline: none;
            svg {
              margin-left: 10px;
            }
          }

          svg {
            margin: 0;
          }
        }
      }
    }

    ${StylesDatepickerNavbar.NavBar} {
      svg {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
`;

export default { Item };
