import styled from 'styled-components';

import { media } from 'assets/styles/theme';
import RecommendedProductsStyles from 'components/widgets/recommendedProducts/styles';
import S from 'components/widgets/productPanel/styles';
import StylesTable from 'components/widgets/table';
import StylesNumber from 'components/shared/numberField/styles';
import { Common } from 'components/shared';

const { Button } = Common

interface ISubtitle {
  isRecommended?: boolean;
  isFundGroup?: boolean;
}

const Portfolio = styled.div`
  
  #recommended-products {
    margin-bottom: 0!important;
    margin-left: 0;
    margin-right: 0;

    ${RecommendedProductsStyles.Wrapper} {
      justify-content: flex-start;
      padding: 0 30px;

      ${S.ProductWrapper} {
        &:last-child {
          margin-right: 30px;
        }
      }
    }
    ${media.tablet} {
      ${RecommendedProductsStyles.Wrapper} {
        padding: 0;
        ${S.ProductWrapper} {
          &:last-child {
            margin-right: 30px;
            ${media.mobile} {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
`;

const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px 22px 30px;
  
  ${media.tablet} {
    margin-bottom: 10px;
  }

  ${media.mobile} {
    margin: 0 15px 10px;
  }
`;
const NewProductButton = styled(Button)`
  height: 52px;

  ${media.tablet} {
    display: none;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  margin: 0;

  ${media.tablet} {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`;

const Wrapper = styled.div`
  ${media.desktop} {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`

const Paragraph = styled.p``;

const ParagraphContainer = styled.div`
  border-top: 1px solid rgb(237, 236, 230);
  padding: 10px 30px 15px;
  margin-top: 15px;
  text-align: justify;

  ${media.mobile} {
    padding: 10px 15px;
  }
`;

const SubTitle = styled.h2<ISubtitle>`
  margin-bottom: 22px;
  margin-left: ${({ isRecommended }) => (isRecommended ? '30px' : '')}; 
  
  ${media.tablet} {
    font-size: ${({ isRecommended }) => (isRecommended ? '18px' : '16px')};
    line-height: 20px;
    font-weight: ${({ isFundGroup }) => (isFundGroup ? 'normal' : '600')};
  }
  
  ${media.mobile} {
    margin: 0 15px 16px;
  }
`;

const Section = styled.section`
  margin: 0 30px 40px 30px;
  &:last-child {
    margin-bottom: 25px;
  }
  ${media.mobile} {
    margin: 0 0 40px;
  }

  ${StylesTable.Row} {
    .product-name {
      display: flex;
      justify-content: flex-start;
      text-align: left;

      div {
        margin-right: 20px;
      }
    }
  }

  ${media.desktop} {
    ${StylesTable.Cell} {
      .product-label {
        display: none;
      }
    }
  }

  ${media.tablet} {
    ${StylesTable.Header} {
      display: none;
    }

    ${StylesTable.Row} {
      position: relative;
      flex-wrap: wrap;

      ${StylesTable.Cell} {
        display: block;
        text-align: left;
        margin-top: 17px;

        &:nth-child(3),
        &:nth-child(5) {
          display: none;
        }

        &:nth-child(4) {
          ${StylesNumber.NumberField} {
            display: inline-block;
          }
        }

        .product-label {
          color: ${({ theme }) => theme.colors.description};
          margin-bottom: 9px;
        }
      }

      .product-name {
        width: 100%;
        text-decoration: underline;
        text-decoration-color: #0096b744;
        margin-top: 17px;
      }
    }

    a + a ${StylesTable.Row} {
      border-top: 1px solid ${({ theme }) => theme.colors.background};
    }
  }

  ${media.mobile} {
    ${StylesTable.Row} {
      padding: 20px 15px;

      ${StylesTable.Cell} {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        margin-top: 0;

        &:nth-child(4) {
          > ${StylesNumber.NumberField} {
            width: 100%;
            font-size: 13px;
            text-align: right;
            line-height: 1;
          }
        }

        &:nth-child(5) {
          position: absolute;
          width: auto;
          top: 108px;
          right: 15px;
        }
      }

      ${StylesTable.CellSeparator} {
        display: none;
      }

      .product-name {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 14px;
        max-width: calc(100% - 50px);
      }
    }

    ${StylesNumber.NumberField} {
      font-size: 15px;
    }
  }
`;
export default {
  Paragraph,
  Section,
  SubTitle,
  ParagraphContainer,
  Portfolio,
  Header,
  NewProductButton,
  Title,
  Wrapper
}
